import React from 'react'
import emptyJobPost from '../../../assets/emptyJobPost.png'
import {EventButton, AuthFillButton} from '../../../User/component/ReUseComps/Button'
import { LuPlus } from "react-icons/lu";
import './Index.css'
import { useNavigate } from 'react-router-dom';

export default function EmptyJob() {
    const navigate = useNavigate();
    return (
        <>
            <div className="main_empty">
                <h1>Job Posts</h1>
                <div className='empty-container d-flex flex-column align-items-center justify-content-center gap-3'>
                    <img src={emptyJobPost} alt="emptyJobPost" />
                    <p>No Job Post have been created, create now!</p>
                    <AuthFillButton onClick={()=>navigate('/company/create-job')}> <LuPlus fontSize={30} /> Create Job Post</AuthFillButton>
                </div>
            </div>
        </>
    )
}
