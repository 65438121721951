import { useContext, useEffect, useState } from 'react';
import { RxDownload } from 'react-icons/rx';
import { FcCollapse } from 'react-icons/fc';
import { Oval } from 'react-loader-spinner';
import Offcanvas from 'react-bootstrap/Offcanvas';
import mailIcon from '../../../assets/mail_icon.png'
import phoneIcon from '../../../assets/phone_icon.png'
import dummyImage from '../../../assets/dummy-image.jpg'
import { COMPANY_URLS } from '../../../Utils/Company_App_Urls';
import gpsIcon from '../../../assets/weui_location-outlined.png'
import CompanyContext from '../../Component/ContextAPI/CompanyContext';
import calendar_icon from '../../../assets/teenyicons_calendar-tick-outline.png'
import { errorPopup, successPopup } from '../../../Utils/ToasterMessage';
import './JobAppliedCandidate.css'
import axios from 'axios';


function ResponsiveExample() {
    const { showCandidateProfile, setShowCandidateProfile, setNeedTorender, appliedCandidateDetails } = useContext(CompanyContext)
    const [open, setOpen] = useState(false);
    const handleClose = () => setShowCandidateProfile(false);
    const handleShow = () => setShowCandidateProfile(true);
    const [statusUpdateLoader, setStatusUpdateLoader] = useState(false)

    const updateCandidatesAppliedStatus = (userId, status) => {
        setStatusUpdateLoader(true)
        axios.patch(`${COMPANY_URLS.candidatesAppliedStatus}`,
            {
                userId: userId,
                status: status
            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
            .then((res) => {
                console.log('appliedStatus', res);
                successPopup(res?.data?.message);
                setNeedTorender(prev => !prev)
            })
            .catch((err) => {
                console.log('Error updating status:', err);
                errorPopup(err?.response?.data?.message || 'Something went wrong');
            })
            .finally(() => setStatusUpdateLoader(false))
    };
    const [isLoading, setIsLoading] = useState(true);

    return (
        <>
            <Offcanvas show={showCandidateProfile} placement='end' onHide={handleClose} className="custom-close-btn mb-2">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='title_text'>Candidate Profile</Offcanvas.Title>
                </Offcanvas.Header>
                {statusUpdateLoader && <div className='position-absolute loader_container'><Oval /></div>}
                <Offcanvas.Body>
                    <div className="candidate_profile_header d-flex justify-content-between align-items-center flex-wrap gap-2">
                        <div className='icon_name_box d-flex align-items-center gap-3'>
                            <img src={appliedCandidateDetails?.profile || dummyImage} alt="dummyImage" />
                            <div>
                                <p>{appliedCandidateDetails?.name || 'N/A'}</p>
                                <p>{appliedCandidateDetails?.JobTitle || 'N/A'}</p>
                            </div>
                        </div>
                        <div className='join_status_box d-flex align-items-center justify-content-start gap-1'>
                            <img src={calendar_icon} alt="calendar_icon" />
                            <span>Availability :</span>
                            <span>{appliedCandidateDetails?.availablity || 'N/A'}</span>
                        </div>
                    </div>

                    <div className='address_section mt-4'>
                        <div className='d-flex justify-content-between align-items-center gap-md-4 gap-2 mb-2 flex-wrap'>
                            <p><img src={phoneIcon} alt="phone icon" /> {appliedCandidateDetails?.mobileNo || 'N/A'}</p>
                            <p><img src={mailIcon} alt="mail icon" /> rahul54545@gmail.com</p>
                        </div>
                        <p><img src={gpsIcon} alt="gps icon" />{appliedCandidateDetails?.address}</p>
                    </div>

                    <div className="skill_section d-flex gap-2 mt-4 flex-wrap">
                        {
                            appliedCandidateDetails?.skills?.map((skill, idx) => (
                                <div key={idx}>{skill}</div>
                            ))
                        }
                    </div>

                    <div className={`${open ? 'openResume' : 'resumeClose'} resume_view mt-4`} id='resumeSize'>
                        <div className='candi_header d-flex flex-wrap gap-1 align-items-center justify-content-between w-100'>
                            <h1>Resume</h1>
                            <div className='d-flex align-items-center gap-4'>
                                <div className='download-btn d-flex align-items-center gap-2'>
                                    {appliedCandidateDetails?.resume ? <a className='SAKLDNAKWK' href={appliedCandidateDetails?.resume} target="_blank">
                                        Download Resume <RxDownload fontSize={18} />
                                    </a>
                                        : 'Resume not available'
                                    }
                                </div>
                                <div onClick={() => setOpen(prev => !prev)} className='preview-btn d-flex align-items-center gap-2'><span>Preview</span><FcCollapse className={`${!open && 'rotation'}`} fontSize={18} /></div>
                            </div>
                        </div>
                        <div className={`${open ? 'd-block' : 'd-none'}`}>
                            {!appliedCandidateDetails?.resume ? <p>Resume Not Available</p>
                                : <>
                                    <iframe
                                        src={`${appliedCandidateDetails?.resume}#toolbar=0`}
                                        // src={`https://icseindia.org/document/sample.pdf#toolbar=0`}
                                        style={{ width: '100%', height: '600px', border:'none' }}
                                    />
                                </>
                            }
                        </div>
                    </div>

                    <table className='mt-4'>
                        <tbody>
                            <tr>
                                <td>
                                    <div className='candi_extra_items'>
                                        <p>Education</p>
                                        <h1>Graduation</h1>
                                    </div>
                                </td>
                                <td>
                                    <div className='candi_extra_items'>
                                        <p>Experience</p>
                                        <h1>{appliedCandidateDetails?.experience}</h1>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className='candi_extra_items'>
                                        <p>Language</p>
                                        {
                                            appliedCandidateDetails?.languages?.map((data, index) => (
                                                <h1 key={index}>{data?.languageFromMenu} | {data?.proficiency}</h1>
                                            ))
                                        }
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className='d-flex align-items-center justify-content-between gap-3 flex-wrap mt-4'>
                        <div className="job_staus_btn job_staus_rejected_btn" onClick={() => updateCandidatesAppliedStatus(appliedCandidateDetails?.userId, 'Rejected')}>Rejected</div>
                        <div className="job_staus_btn job_staus_shortlisted_btn" onClick={() => updateCandidatesAppliedStatus(appliedCandidateDetails?.userId, 'Shortlisted')}>Shortlisted</div>
                        <div className="job_staus_btn job_staus_hired_btn" onClick={() => updateCandidatesAppliedStatus(appliedCandidateDetails?.userId, 'Hired')}>Hired</div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default ResponsiveExample;