import React, { useContext, useEffect, useState } from 'react'
import { errorPopup, successPopup } from '../../../../Utils/ToasterMessage';
import UserContext from '../../ContextApi/UserContext'
import { EventButton } from '../../ReUseComps/Button';
import { URLS } from '../../../../Utils/App_urls';
import { IoCloseOutline } from 'react-icons/io5';
import { Form, Spinner } from 'react-bootstrap';
import { GoPlus } from 'react-icons/go';
import Select from "react-select";
import axios from 'axios';

export default function EditDepartment() {

    const { editProfilePopUPToggle, setEditProfilePopUpToggle, profileModalData, setNeedToReload, setLoaderOn } = useContext(UserContext);
    const [department, setDepartment] = useState([])
    const [manualDepartment, setManualDepartment] = useState('')
    const [departmentList, setDepartmentList] = useState([])
    const [loader, setLoader] = useState(false);
    const [selectedOptions,setSelectedOptions] = useState('')
    const [error, setError] = useState({
        errorStatus: true,
        errorMessage: ''
    })
    const departArr = ['Accounting', 'Arts & Design', 'Advertising', 'Data Science & Analytics', 'Finance', 'Human Resources', 'Consulting']

    const handleSubmitDepart = (e) => {
        e.preventDefault();
        if (manualDepartment) {
            if (!department.includes(manualDepartment)) {
                setDepartment(prevArr => [...prevArr, manualDepartment])
                setError({ errorStatus: false, errorMessage: '' })
            }
            setManualDepartment('')
        }
    }

    const handleClosePopUPModal = () => {
        setEditProfilePopUpToggle({ ...editProfilePopUPToggle, departmentModal: false, mainModalToggle: false })
    }

    const handleMenuOptions = (e) => {
        const optionValue = e.value
        if(!department?.includes(optionValue)){
            setDepartment(department => [...department, optionValue])
            setSelectedOptions(optionValue)
        }
        setError({ errorStatus: false, errorMessage: '' })
    }

    const handleRemoveDepart = (index) => {
        setDepartment(department => department.filter((_, id) => id !== index))
    }

    const handleUpdateDepartment = () => {
        if (!department.length <= 0) {
            updateBasicInfo()
            setEditProfilePopUpToggle({ ...editProfilePopUPToggle, departmentModal: false, mainModalToggle: false })

        } else {
            setError({ errorStatus: true, errorMessage: 'Department is required' })
        }
    }

    const updateBasicInfo = async () => {
        delete profileModalData['_id']
        delete profileModalData['userId']
        delete profileModalData['auth0UserId']
        delete profileModalData['createdAt']
        delete profileModalData['updatedAt']
        delete profileModalData['__v']

        try {
            setLoader(true)
            await axios.patch(`${URLS.createUserProfile}`, { ...profileModalData, "department": department }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('userToken')}`
                }
            })
                .then((res) => {
                    setNeedToReload(true)
                    successPopup(res?.data?.message)
                    setLoader(false)
                })
                .catch((err) => {
                    errorPopup(err?.response?.data?.message);
                    setLoader(false)
                })
        } catch (err) {
            console.error('err', err);
        }
    }

    const getDepartmentList=()=>{
        axios.get(`${URLS.getJoblist}`,{
            headers:{
                Authorization:`Bearer ${localStorage.getItem('userToken')}`
            }
        })
        .then((res)=>{
            const citiesData = res?.data?.getList || [];
            const formattedCities = citiesData.map((dep,_) => ({ value: dep.name, label: dep.name }));
            setDepartmentList(formattedCities);
        })
        .catch((err)=>{console.log('skill', err)})
    }

    useEffect(() => {
        if (profileModalData?.department) {
            setDepartment(profileModalData?.department)
        }
    }, [profileModalData])
    useEffect(() => {
        getDepartmentList();
    }, [])

    return (
        <>
            <div className="department_moda_main d-flex flex-column gap-3">
                <div className="ModalTitle mb-1">
                    <h1>Edit/Add Department</h1>
                    <span onClick={handleClosePopUPModal}><IoCloseOutline fontSize={26} /></span>
                </div>
                <div className='department-select-option'>
                    <p className='m-0 mb-2'>Suggested Departments</p>
                    <Select
                        options={departmentList}
                        placeholder='Search Department'
                        onChange={handleMenuOptions}
                        value={departmentList.find(option => option.value === selectedOptions) || null}
                        label="Single select"
                    />
                    {/* <Form.Select onChange={handleMenuOptions} aria-label="Default select example">
                        <option value="" disabled selected>Select Department</option>
                        {
                            departArr.map((data, index) => (
                                <option key={index} value={data}>{data}</option>
                            ))
                        }
                    </Form.Select> */}
                </div>

                {/* <form onSubmit={handleSubmitDepart}>
                    <div className='manual-select-input-group'>
                        <p className='m-0 mb-2'>Add Department Manually</p>
                        <div>
                            <input type="text"
                                value={manualDepartment}
                                onChange={(e) => setManualDepartment(e.target.value)}
                            />
                            <button type='submit' onClick={handleSubmitDepart}> <GoPlus fontSize={20} /> </button>
                        </div>
                    </div>
                </form> */}
                <div>
                    <div className="selected_deprt_stock">
                        <p>Your Selected Departments</p>
                        <div className='d-flex align-items-center flex-wrap gap-1'>
                            {
                                department?.map((data, index) => (
                                    <div key={index} className='selected-deprt-items'>
                                        <span>{data}</span>
                                        <IoCloseOutline onClick={() => handleRemoveDepart(index)} fontSize={20} />
                                    </div>

                                ))
                            }
                        </div>
                    </div>
                    {error?.errorMessage && <p className='text-danger m-0'>{error?.errorMessage}</p>}
                </div>
                <EventButton className='modal-update-btn d-flex align-items-center justify-content-center' onClick={handleUpdateDepartment}>
                    Update
                    {loader && <Spinner className='m-0 ms-2' style={{ width: '18px', height: '18px', borderWidth: '3px' }} animation="border" />}
                </EventButton>
            </div>
        </>
    )
}
