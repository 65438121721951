import React from 'react'
import { Form } from 'react-bootstrap'
import { CiSearch } from "react-icons/ci";

import './Home.css'

export default function SearchCandidate() {
    return (
        <>
            <div className="search_main">
                <div className='search_condidate_box'>
                    <h1>Search candidate database</h1>
                    <div className='d-flex gap-4 align-items-end flex-wrap justify-content-around'>
                        <div className="searchCandSec">
                            <span>Select job title</span>
                            <Form.Select className='roleOptionCond' aria-label="Default select example">
                                <option>Eg. Back Office job</option>
                                <option value="Company">Eg. Back Office job</option>
                                <option value="Employee">Employee</option>
                                <option value="Other">Other</option>
                            </Form.Select>
                        </div>
                        <div className="searchCandSec">
                            <span>Select city</span>
                            <Form.Select className='roleOption' aria-label="Default select example">
                                <option>Pick Your City</option>
                                <option value="Company">Company</option>
                                <option value="Employee">Employee</option>
                                <option value="Other">Other</option>
                            </Form.Select>
                        </div>
                        <div className="searchCanbtn">
                            <CiSearch color='#fff' fontSize={25}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
