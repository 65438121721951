// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.previewJob_container {
  box-shadow: 0px 0px 16px 0px #0000000a;
  background-color: #ffffff;
  padding: 24px 32px;
  border-radius: 8px;
}

.previewJob_container >div > h1 {
  font-size: 24px;
  font-weight: 700;
  color: #000000;
}

.editJob_postBtn {
  border: 1px solid #3284ffb2;
  border-radius: 55px;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.18px;
  color: #3284ffb2;
  background: transparent;
  width: 178px;
  height: 45px;
}

.editJob_postBtn:hover{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.jobdetailsTable >h1{
    font-weight: 700;
    font-size: 20px;
    color: #000000;
}

.tage_value_div{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 5px;
}

.tage_value_div > div:first-child{
  width: 200px;
  color: #5E5E5E;
  font-size: 16px;
  font-weight: 300;
}
.tage_value_div > div:last-child{
  width: 50%;
  text-align: left;
  flex-grow: 1;
  color: #1E1E1E;
  font-size: 16px;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/Company/Pages/PreviewJobPost/PreviewJobPost.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,2BAA2B;EAC3B,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,gBAAgB;EAChB,uBAAuB;EACvB,YAAY;EACZ,YAAY;AACd;;AAEA;IACI,2CAA2C;AAC/C;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,eAAe;EACf,QAAQ;AACV;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,UAAU;EACV,gBAAgB;EAChB,YAAY;EACZ,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".previewJob_container {\n  box-shadow: 0px 0px 16px 0px #0000000a;\n  background-color: #ffffff;\n  padding: 24px 32px;\n  border-radius: 8px;\n}\n\n.previewJob_container >div > h1 {\n  font-size: 24px;\n  font-weight: 700;\n  color: #000000;\n}\n\n.editJob_postBtn {\n  border: 1px solid #3284ffb2;\n  border-radius: 55px;\n  font-size: 16px;\n  font-weight: 700;\n  line-height: 19.18px;\n  color: #3284ffb2;\n  background: transparent;\n  width: 178px;\n  height: 45px;\n}\n\n.editJob_postBtn:hover{\n    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;\n}\n\n.jobdetailsTable >h1{\n    font-weight: 700;\n    font-size: 20px;\n    color: #000000;\n}\n\n.tage_value_div{\n  display: flex;\n  align-items: flex-start;\n  flex-wrap: wrap;\n  gap: 5px;\n}\n\n.tage_value_div > div:first-child{\n  width: 200px;\n  color: #5E5E5E;\n  font-size: 16px;\n  font-weight: 300;\n}\n.tage_value_div > div:last-child{\n  width: 50%;\n  text-align: left;\n  flex-grow: 1;\n  color: #1E1E1E;\n  font-size: 16px;\n  font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
