// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.experiece_main_modal{
    height: -moz-fit-content;
    height: fit-content;
}

.form-label{
    font-weight: 400;
    font-size: 16px;
    color: #1E1E1E;
}

.isExperience_options > p{
    font-family: Inria Sans;
    color: #1E1E1E;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
}

.experi_radio_button > span{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}

.expeStore_main{
    background: #EEF5FFB2;
    border-radius: 12px;
    padding: 16px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.expStore_child > h1{
    color: #1E1E1E;
    font-size: 16px;
    font-weight: 700;
}

.addMoreExpeInStore{
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 50px;
    padding: 8px 16px;
    border: 1px solid #3284FF;
    color: #3284FF;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.addMoreExpeInStore:hover{
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.expStore_child > h1 > svg:hover{ 
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}`, "",{"version":3,"sources":["webpack://./src/User/component/EditProfilePopUps/EditExperience/EditExperience.css"],"names":[],"mappings":"AAAA;IACI,wBAAmB;IAAnB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,uBAAuB;IACvB,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,QAAQ;AACZ;;AAEA;IACI,qBAAqB;IACrB,mBAAmB;IACnB,aAAa;IACb,iDAAiD;AACrD;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,uBAAkB;IAAlB,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;IACjB,yBAAyB;IACzB,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,QAAQ;AACZ;;AAEA;IACI,eAAe;IACf,yFAAyF;AAC7F;;AAEA;IACI,eAAe;IACf,yFAAyF;AAC7F","sourcesContent":[".experiece_main_modal{\n    height: fit-content;\n}\n\n.form-label{\n    font-weight: 400;\n    font-size: 16px;\n    color: #1E1E1E;\n}\n\n.isExperience_options > p{\n    font-family: Inria Sans;\n    color: #1E1E1E;\n    font-size: 16px;\n    font-weight: 400;\n    margin: 0;\n}\n\n.experi_radio_button > span{\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    gap: 5px;\n}\n\n.expeStore_main{\n    background: #EEF5FFB2;\n    border-radius: 12px;\n    padding: 16px;\n    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;\n}\n\n.expStore_child > h1{\n    color: #1E1E1E;\n    font-size: 16px;\n    font-weight: 700;\n}\n\n.addMoreExpeInStore{\n    width: fit-content;\n    border-radius: 50px;\n    padding: 8px 16px;\n    border: 1px solid #3284FF;\n    color: #3284FF;\n    font-size: 16px;\n    font-weight: 400;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 8px;\n}\n\n.addMoreExpeInStore:hover{\n    cursor: pointer;\n    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;\n}\n\n.expStore_child > h1 > svg:hover{ \n    cursor: pointer;\n    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
