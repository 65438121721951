import React, { useContext, useEffect, useState } from 'react'
import FilterJob from '../ApplyJob/FilterJob/FilterJob'
import zomato from '../../../assets/octicon_alert-24.png'
import bagExp from '../../../assets/bag-solid.png'
import currencyIcon from '../../../assets/cash-multiple.png'
import { FaLocationDot } from 'react-icons/fa6'
import axios from 'axios'
import { URLS } from '../../../Utils/App_urls'
import { GoArrowRight } from 'react-icons/go'
import { FaAngleLeft, FaAngleRight, FaBookmark } from "react-icons/fa";
import UserContext from '../../component/ContextApi/UserContext'
import FilterSavedJobs from './FilterSavedJobs'
import dummayIcon from '../../../assets/dummy-image.jpg'
import { Spinner } from 'react-bootstrap'
import { errorPopup, successPopup } from '../../../Utils/ToasterMessage'
import { PaginationBtn } from '../../component/ReUseComps/Button'
import './SavedJob.css'
import { useNavigate } from 'react-router-dom'


export default function SavedJob() {
    const navigate = useNavigate();
    const { pureSavedJobs, setPureSavedJobs } = useContext(UserContext)
    const [paginationBtnArr, setpaginationBtnArr] = useState([1, 2, 3, 4, 5]);
    const [clipArrLength, setClipArrLength] = useState({ min: 0, max: 10 })
    // const [savedJobDetails, setSavedJobsDetails] = useState([]);

    // const [pureSavedJobs, setPureSavedJobs] = useState([]);
    const [loader, setLoader] = useState(false)
    const getAllSavedJobs = () => {
        axios.get(`${URLS.getSaveLater}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('userToken')}`
            }
        })
            .then((res) => {
                console.log('savedJobDetails Res', res?.data?.result)
                newObj(res?.data?.result)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const newObj = (dataArr) => {
        const updatedSet = new Set();
        dataArr?.forEach((data) => {
            const newdata = data?.userSavedJobs;
            if (newdata) {
                updatedSet.add(newdata);
            }
        });
        setPureSavedJobs([...updatedSet]);
    };

    const unSaveLaterJob = (id) => {
        setLoader(true)
        axios.post(`${URLS.saveLater}`,
            {
                JobId: id,
                status: false
            }, {
            headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` }
        })
            .then((res) => {
                successPopup(res?.data?.message)
                setLoader(false)
                getAllSavedJobs();
            })
            .catch((err) => {
                setLoader(false)
                errorPopup(err?.response?.data?.message);
            })
    }

    const handlePaginationSize = (pgNo) => {
        let max = pgNo * 10;
        let min = max - 10 >= 0 ? max - 10 : 0;
        setClipArrLength({ min: min, max: max })
    }

    const handleIncPagination = () => {
        setpaginationBtnArr(paginationBtnArr.map((item, _) => item + 1))
        setClipArrLength({ min: (paginationBtnArr[paginationBtnArr.length - 1] * 10) - 10, max: paginationBtnArr[paginationBtnArr.length - 1] * 10 })
    }
    const handleDecPagination = () => {
        if (paginationBtnArr[0] > 1) {
            setpaginationBtnArr(paginationBtnArr.map((item, _) => item - 1))
        }
        paginationBtnArr[0] >= 0 && setClipArrLength({ min: (paginationBtnArr[0] * 10) - 10, max: paginationBtnArr[0] * 10 })
    }

    const postedDate = (data) => {
        const firstDate = new Date(data);
        const currentDate = new Date();
        const timeDifference = currentDate - firstDate;
        const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
        return daysDifference > 0 ? `${daysDifference} Day${daysDifference > 1 ? 's' : ''} ago` : 'Posted Today';
    }

    const redirectTojobDetails = (id) => {
        navigate(`/user/savedJobs/job-details/${id}`, {
            state: { DATA: id }
        })
    }

    useEffect(() => {
        getAllSavedJobs()
    }, [])

    console.log('pureSavedJobs',pureSavedJobs)

    return (
        <>
            <div className="saved_main d-flex flex-wrap gap-3">
                <div className='filter-cntainer-savedJob px-1 py-1'>
                    <FilterSavedJobs />
                </div>
                <div className="saved_container">
                    {/* {
                        pureSavedJobs?.map((saveJobs, index) => (
                            <div className="savedjobCards d-flex justify-content-between flex-wrap gap-3 mb-3">
                                <div className='d-flex flex-column flex-wrap justify-content-center gap-3'>
                                    <div className='d-flex align-items-start justify-content-between gap-2'>
                                        <div className='d-flex align-items-start justify-content-start gap-2'>
                                            <div className="imageConatiner">
                                                <img src={zomato} alt="zomato" />
                                            </div>
                                            <div className="savedJobTitle">
                                                <h1>{saveJobs?.userSavedJobs?.[0]?.title}</h1>
                                                <p>Advancing Packaging Private Limited</p>
                                            </div>
                                        </div>
                                        <p>Posted 1 Day Ago</p>
                                    </div>
                                    <div className="job_salary_loc d-flex align-items-center flex-wrap gap-4">
                                        <div><img src={bagExp} alt="bagforexp" /><span>Fresher</span></div>
                                        {
                                            saveJobs?.userSavedJobs?.[0]?.salaryType === "Fixed Salary" &&
                                            <div><img src={currencyIcon} alt="cash multiple" /> <span>{saveJobs?.userSavedJobs?.[0]?.FixedSalary}k</span></div>
                                        }
                                        {
                                            saveJobs?.userSavedJobs?.[0]?.salaryType === "Salary Range" &&
                                            <div><img src={currencyIcon} alt="cash multiple" /> <span>{saveJobs?.userSavedJobs?.[0]?.minSalary}k - {saveJobs?.userSavedJobs?.[0]?.maxSalary}k</span></div>
                                        }
                                        {
                                            saveJobs?.userSavedJobs?.[0]?.salaryType === "Not Disclose" &&
                                            <div><img src={currencyIcon} alt="cash multiple" /> <span>{saveJobs?.userSavedJobs?.[0]?.salaryType}</span></div>
                                        }
                                        <div><FaLocationDot fontSize={18} /><span>{saveJobs?.userSavedJobs?.[0]?.workPlace}</span></div>
                                    </div>
                                    <div className="preferences_savedJob d-flex align-items-center justify-content-start flex-wrap gap-2">
                                        <div>{saveJobs?.userSavedJobs?.[0]?.deparmentType}</div>
                                    </div>
                                    <div className="savedJobDes">
                                        {saveJobs?.userSavedJobs?.[0]?.jobSummary?.slice(0, 400)}
                                        {saveJobs?.userSavedJobs?.[0]?.jobSummary?.length > 400 && '...'}
                                    </div>
                                    <div className="savedJobSkills d-flex align-items-center justify-content-start flex-wrap gap-2">

                                        {
                                            saveJobs?.userSavedJobs?.[0]?.skills?.slice(0, 5)?.map((skill, index) => (
                                                <div key={index}>{skill}</div>
                                            ))
                                        }
                                        {
                                            saveJobs?.userSavedJobs?.[0]?.skills?.length > 5 &&
                                            <div>{saveJobs?.userSavedJobs?.[0]?.skills?.length - 5} + More</div>
                                        }

                                    </div>
                                </div>
                                <div className="jobCardsButton d-flex flex-column align-items-end justify-content-center gap-3">
                                    <button className="viewJobbtn">View <GoArrowRight fontSize={20} /></button>
                                    <button className="UnsavedJob">Unsaved <FaBookmark fontSize={17} /></button>
                                </div>
                            </div>
                        ))
                    } */}
                    {
                        pureSavedJobs?.map((saveJobs, index) => (
                            (index >= clipArrLength.min && index < clipArrLength.max) &&
                            <div className="savedjobCards d-flex justify-content-between flex-wrap gap-3 mb-3 mx-1 my-1">
                                <div className='d-flex flex-column flex-wrap justify-content-center gap-3'>
                                    <div className='d-flex align-items-start justify-content-between gap-2'>
                                        <div className='d-flex align-items-start justify-content-start gap-2'>
                                            <div className="imageConatiner">
                                                <img src={saveJobs?.companyProfileDetails?.companylogo || dummayIcon} alt="zomato" />
                                            </div>
                                            <div className="savedJobTitle">
                                                <h1>{saveJobs?.title}</h1>
                                                <p>{saveJobs?.companyProfileDetails?.companyName}</p>
                                            </div>
                                        </div>
                                        <p>{postedDate(saveJobs?.createdAt?.split('T')[0])}</p>
                                    </div>
                                    <div className="job_salary_loc d-flex align-items-center flex-wrap gap-4">
                                        <div><img src={bagExp} alt="bagforexp" /><span>{saveJobs?.experience}</span></div>
                                        {
                                            saveJobs?.salaryType === "Fixed Salary" &&
                                            <div><img src={currencyIcon} alt="cash multiple" /> <span>{saveJobs?.FixedSalary}k</span></div>
                                        }
                                        {
                                            saveJobs?.salaryType === "Salary Range" &&
                                            <div><img src={currencyIcon} alt="cash multiple" /> <span>{saveJobs?.minSalary}k - {saveJobs?.maxSalary}k</span></div>
                                        }
                                        {
                                            saveJobs?.userSavedJobs === "Not Disclose" &&
                                            <div><img src={currencyIcon} alt="cash multiple" /> <span>{saveJobs?.salaryType}</span></div>
                                        }
                                        <div><FaLocationDot fontSize={18} /><span>{saveJobs?.workPlace}</span></div>
                                    </div>
                                    <div className="preferences_savedJob d-flex align-items-center justify-content-start flex-wrap gap-2">
                                        <div>{saveJobs?.deparmentType}</div>
                                        <div>{saveJobs?.workPlace}</div>
                                        <div>{saveJobs?.shift}</div>
                                    </div>
                                    <div className="savedJobDes">
                                        {saveJobs?.jobSummary?.slice(0, 400)}
                                        {saveJobs?.jobSummary?.length > 400 && '...'}
                                    </div>
                                    <div className="savedJobSkills d-flex align-items-center justify-content-start flex-wrap gap-2">
                                        {
                                            saveJobs?.skills?.slice(0, 3)?.map((skill, index) => (
                                                <div key={index}>{skill}</div>
                                            ))
                                        }
                                        {saveJobs?.skills?.length > 3 && <div>{saveJobs?.skills?.length - 3}+ More</div>}

                                    </div>
                                </div>
                                <div className="jobCardsButton d-flex flex-column align-items-end justify-content-center gap-3">
                                    <button className="viewJobbtn" onClick={()=>redirectTojobDetails(saveJobs?._id)}>View <GoArrowRight fontSize={20} /></button>
                                    <button className="UnsavedJob" onClick={() => unSaveLaterJob(saveJobs?._id)}>
                                        {loader && <Spinner style={{ width: '15px', height: '15px', borderWidth: '2px', marginRight: '2px' }} animation="border" />}
                                        Unsaved <FaBookmark fontSize={17} />
                                    </button>
                                </div>
                            </div>
                        ))
                    }
                    {pureSavedJobs?.length === 0 && <p className='text-center fs-2'>No Job Found</p>}
                    {pureSavedJobs?.length > 0 && <div className="footer-pagination d-flex align-items-center flex-wrap justify-content-center gap-2 pb-3">
                        <p className='vailable-job-para page-table-typo'>Page No. {clipArrLength?.max / 10} / {Math.ceil(pureSavedJobs?.length / 10)}</p>
                        <div className='d-flex align-items-center gap-2'>
                            <PaginationBtn style={{ width: 'fit-content' }} className='pagination-btn edgePageBtn'
                                onClick={handleDecPagination}
                                disabled={paginationBtnArr[0] === 1}>
                                <FaAngleLeft fontSize={20} />
                                Previous
                            </PaginationBtn>
                            {
                                paginationBtnArr.map((data, idx) => (
                                    <PaginationBtn
                                        className={`pagination-btn ${clipArrLength?.max / 10 === data && 'activePageBtn_saved'}`}
                                        onClick={() => handlePaginationSize(data)}
                                        disabled={Math.ceil(data > Math.ceil(pureSavedJobs?.length / 10))}
                                        >
                                        {data}
                                    </PaginationBtn>
                                ))
                            }
                            <PaginationBtn className={`pagination-btn ${pureSavedJobs?.length <= 60 && 'd-none'}`} disabled>...</PaginationBtn>
                            <PaginationBtn
                                style={{ width: 'fit-content' }}
                                className='pagination-btn edgePageBtn'
                                onClick={handleIncPagination}
                                disabled={pureSavedJobs?.length < (paginationBtnArr[paginationBtnArr?.length - 1] * 10)}
                            >
                                Next
                                <FaAngleRight fontSize={20} />
                            </PaginationBtn>
                        </div>

                    </div>}
                </div>
            </div>
        </>
    )
}

