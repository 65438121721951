import React from 'react'
import './GetStarted.css'
import kaamwalaStartImage from '../../../assets/kaamwalaStartImage.png'
import kaamwalaLogo from '../../../assets/KAAMVALALogo.png'
import { useNavigate } from 'react-router-dom'
import getStartedimgae from '../../../assets/getStartedImage.png'

export default function GetStarted() {
    const navigation = useNavigate()
    return (
        <>
            <div className="getStaretd_main">
                <div className="newbox">

                </div>
                <div className='right-image-box-getstarted'>
                    <img src={getStartedimgae} alt="getStartedimgae" />
                </div>
                <div className="postion-adjust-box-main">
                    <div className="started_box">
                        <div className="startbtnGrp">
                            <img src={kaamwalaLogo} alt="kaamwalaLogo" />
                            <div className='getStartTitle my-2'>
                                <h1 className='m-0 text-center'>Let’s Get Started !</h1>
                                <p className='m-0 text-center'>Find Your Dream Job here !</p>
                            </div>
                            <div className='w-100 authbtnGrp '>
                                <button onClick={() => navigation('/register')} className='authBtnStyle'>Sign Up</button>
                                <button onClick={() => navigation('/login')} className='authBtnStyle'>Log in</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
