import React, { useContext, useEffect, useState } from 'react'
import dummyprofileimage from '../../../assets/dummy-image.jpg'
import { Dropdown, Form, InputGroup } from 'react-bootstrap'
import { RiSearch2Fill } from "react-icons/ri";
import teenyicons_calendar from '../../../assets/teenyicons_calendar-tick-outline.png'
import suitcase from '../../../assets/solar_suitcase-outline.png'
import educationIcon from '../../../assets/fluent-mdl2_education.png'
import mage_email from '../../../assets/mage_email.png'
import weui_location from '../../../assets/weui_location-outlined.png'
import cashMultiple from '../../../assets/cash-multiple.png'
import bagSolid from '../../../assets/bag-solid.png'
import googleLoc from '../../../assets/fluent_location-28-filled.png'
import { RiArrowDropDownLine } from "react-icons/ri";
import { LiaAngleDownSolid } from "react-icons/lia";
import { FaAngleDown, FaDownload } from "react-icons/fa6";
import { COMPANY_URLS } from '../../../Utils/Company_App_Urls';
import { IoSearch } from "react-icons/io5";
import NoCandidateApplied from '../PostedJobDetails/NoCandidateApplied';
import { PaginationBtn } from '../../../User/component/ReUseComps/Button';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import axios from 'axios';
import './Candidate.css'
import { useNavigate } from 'react-router-dom';
import CandaidateProfileView from '../../Component/PopupModal/AppliedCandidateProfile/CandaidateProfileView';
import CompanyContext from '../../Component/ContextAPI/CompanyContext';
import Loader from '../../Component/Loader/Loader';
import { Skeleton } from 'antd';
import JobAppliedCandidate from './JobAppliedCandidate';
import downloadResume from '../../Component/CustomHook/downloadResume';


export default function Candidate() {
    const navigate = useNavigate()
    const { setAppliedCandidateModalOpen, setAppliedCandidateDetails, needTorender, showCandidateProfile, setShowCandidateProfile } = useContext(CompanyContext)
    const [clipArrLength, setClipArrLength] = useState({ min: 0, max: 10 })
    const [paginationBtnArr, setpaginationBtnArr] = useState([1, 2, 3, 4, 5])
    const [allPostedJobs, setAllPostedJobs] = useState([]);
    const [singleJobDetails, setSingleJobDetails] = useState({});
    const [allAppliedCandidates, setAllAppliedCandidates] = useState([]);
    const [filterInTitle, setFilterInTitle] = useState([])
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [nameTitle, setNameTitle] = useState('')

    const [postedJobsFilter, setPostedJobsFilter] = useState([]);
    const [jobSearchValue, setJobsSearchValue] = useState('');
    const [finalJobFilter, setFinalJobFIlter] = useState([]);
    const [totalPage, setTotalPage] = useState(1)
    const [loader, setLoader] = useState(false);
    const [filterBtn, setFilterBtn] = useState({
        all: true,
        shortlisted: false,
        hired: false,
        rejected: false
    })

    const handlePaginationSize = (pgNo) => {
        let max = pgNo * 10;
        let min = max - 10 >= 0 ? max - 10 : 0;
        setClipArrLength({ min: min, max: max })
        getAppliedCandidates('', pgNo)
    }

    const handleIncPagination = () => {
        setpaginationBtnArr(paginationBtnArr.map((item, _) => item + 1))
        if(paginationBtnArr[4] < totalPage){
            getAppliedCandidates('', paginationBtnArr[4])
        }else{
            getAppliedCandidates('', totalPage)
        }
    }
    const handleDecPagination = () => {
        if (paginationBtnArr[0] > 1) {
            setpaginationBtnArr(paginationBtnArr.map((item, _) => item - 1))
            getAppliedCandidates('', paginationBtnArr[0])
        }else{
            getAppliedCandidates('', 1)
        }
    }

    const handleFilterBtnChange = (type) => {
        type === 'all' && setFilterBtn({ all: true, shortlisted: false, hired: false, rejected: false })
        type === 'shortlisted' && setFilterBtn({ all: false, shortlisted: true, hired: false, rejected: false })
        type === 'hired' && setFilterBtn({ all: false, shortlisted: false, hired: true, rejected: false })
        type === 'rejected' && setFilterBtn({ all: false, shortlisted: false, hired: false, rejected: true })
        handleFilterAppliedCandidates(type)
    }

    const getAllPostJobs = () => {
        axios.get(`${COMPANY_URLS.getAllJobs}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            params:{
                page:1,
                limit:10
            }
        })
            .then((res) => {
                setAllPostedJobs(res?.data?.data?.jobs)
                setPostedJobsFilter(res?.data?.data?.jobs)
                setFinalJobFIlter(res?.data?.data?.jobs) //remove after filter functionality done

                setSingleJobDetails(res?.data?.data?.jobs?.[0])
                getAppliedCandidates(res?.data?.data?.jobs?.[0]?._id)
            })
            .catch((err) => { })
    }

    const getAppliedCandidates = (id='', pageNo=1) => {
        console.log('jobId', id)
        setLoader(true)
        axios.get(`${COMPANY_URLS.getSingleJob}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            params: {
                id: id,
                page:pageNo,
                limit:10
            },
        })
            .then((res) => {
                console.log('res?.data?.result?.[0]?.appliedUserProfile',res?.data?.result?.totalPages)
                setAllAppliedCandidates(res?.data?.result?.data?.[0]?.appliedUserProfile)
                setFilteredJobs(res?.data?.result?.data?.[0]?.appliedUserProfile)
                setNameTitle('')
                setLoader(false)
                setTotalPage(res?.data?.result?.totalPages)
            })
            .catch((err) => {
                setLoader(false)
            })
    }

    const handleOnJobsMenuChanged = (jobData, id) => {
        setSingleJobDetails(jobData);
        getAppliedCandidates(id)
    }

    const handleFilterAppliedCandidates = (opt) => {
        if (opt === 'all') {
            setFilteredJobs(allAppliedCandidates)
            setFilterInTitle(allAppliedCandidates)
        }
        else {
            const filteredJobs = allAppliedCandidates?.filter((data) => {
                let status = data?.status?.toLowerCase();
                return status === opt.toLowerCase();
            });
            setFilteredJobs(filteredJobs);
            setFilterInTitle(filteredJobs)
        }
    }

    const handleFilterInputSearch = (e) => {
        let keyWord = e.target.value
        setNameTitle(e.target.value);

        const filteredJobInInput = filterInTitle?.filter((data) => {
            const title = data?.JobTitle?.toLowerCase();
            const name = data?.name?.toLowerCase();
            return (title?.includes(keyWord) || name?.includes(keyWord));
        });
        setFilteredJobs(filteredJobInInput);
        e.target.value === '' && setFilteredJobs(filterInTitle)
    }

    const filterjobsByCategory = (type) => {
        if (type === 'all') {
            setFinalJobFIlter(allPostedJobs)
            setPostedJobsFilter(allPostedJobs)
        } else {
            const filteredJobs = allPostedJobs?.filter((data) => {
                let status = data?.status?.toLowerCase();
                return status === type.toLowerCase();
            });
            setFinalJobFIlter(filteredJobs);
            setPostedJobsFilter(filteredJobs)
        }
    }

    const handleFilterJobPosts = (e) => {
        let keyWord = e.target.value
        setJobsSearchValue(e.target.value);

        const filteredJobInInput = postedJobsFilter?.filter((data) => {
            const title = data?.title?.toLowerCase();
            return title?.includes(keyWord);
        });
        setFinalJobFIlter(filteredJobInInput);
        e.target.value === '' && setFinalJobFIlter(postedJobsFilter)
    }

    const handleToggleModalBox = (index) => {
        // setAppliedCandidateModalOpen(prev => !prev)
        setShowCandidateProfile(true)
        setAppliedCandidateDetails(allAppliedCandidates[index])
    }

    useEffect(() => {
        getAllPostJobs();
        handleFilterAppliedCandidates('all')
    }, [])

    console.log('singleJobDetails',singleJobDetails?._id)

    useEffect(() => {
        singleJobDetails?._id && getAppliedCandidates(singleJobDetails?._id)
    }, [needTorender])

    return (
        <div className='container-fluid main_candi_container'>
            <JobAppliedCandidate />
            <p className='candidate_title'>Candidates</p>
            <div className='row m-0 mb-3 main_wrapper wrapper_menu_sj px-1'>
                <Dropdown className='px-0'>
                    <Dropdown.Toggle className='applied_candi_menu p-0 border-0' variant="none" id="dropdown-basic">
                        <div className='main_menu_cont d-flex align-items-center'>
                            {
                                !singleJobDetails?.companyProfileDetail?.[0]?.companyName ?
                                    <div style={{ width: '500px' }}>
                                        <Skeleton
                                            avatar
                                            paragraph={{
                                                rows: 1,
                                            }}
                                        />
                                        <Skeleton.Avatar active={true} size={'large'} style={{ width: '450px', height: '20px' }} shape={'square'} />

                                    </div>
                                    :
                                    <div className="select-job-menu d-flex flex-column align-items-start justify-content-start gap-1">
                                        <div className="cards_candititle d-flex align-items-center flex-wrap gap-3">
                                            <img src={singleJobDetails?.companyProfileDetail?.[0]?.companylogo || dummyprofileimage} alt="dummyprofileimage" />
                                            <div className='text-start text-capitalize'>
                                                <h1>{singleJobDetails?.title}</h1>
                                                <p>{singleJobDetails?.companyProfileDetail?.[0]?.companyName}</p>
                                            </div>
                                            {
                                                singleJobDetails?.status === 'open' ?
                                                    <div className='open_job_co mx-4 me-0 d-flex align-items-center justify-content-center gap-2'>
                                                        <div className='open_dot' />
                                                        <span>Open</span>
                                                    </div>
                                                    : <div className='close_job_co mx-4 me-0 d-flex align-items-center justify-content-center gap-2'>
                                                        <div className='close_dot' />
                                                        <span>Close</span>
                                                    </div>
                                            }
                                        </div>
                                        <div className='filter_candi_track d-flex align-items-center flex-wrap gap-3 text-capitalize'>
                                            <div>
                                                <img src={bagSolid} alt="bagSolid" />
                                                <span>{singleJobDetails?.experience}</span>
                                            </div>
                                            {singleJobDetails?.salaryType === "Fixed Salary" && <div><img src={cashMultiple} alt="cash multiple" /> <span>{singleJobDetails?.FixedSalary}k</span></div>}
                                            {singleJobDetails?.salaryType == "Salary Range" && <div><img src={cashMultiple} alt="cash multiple" /> <span>{singleJobDetails?.minSalary}k - {singleJobDetails?.maxSalary}k</span></div>}
                                            {singleJobDetails?.salaryType == "Not Disclose" && <div><img src={cashMultiple} alt="cash multiple" /> <span>{singleJobDetails?.salaryType}</span></div>}
                                            <div>
                                                <img src={googleLoc} alt="googleLoc" />
                                                <span>{singleJobDetails?.jobLocations || 'N/A'}</span>
                                            </div>
                                        </div>
                                    </div>

                            }
                            {/* <div className="select-job-menu d-flex flex-column align-items-start justify-content-start gap-1">
                                <div className="cards_candititle d-flex align-items-center flex-wrap gap-3">
                                    <img src={singleJobDetails?.companyProfileDetail?.[0]?.companylogo || dummyprofileimage} alt="dummyprofileimage" />
                                    <div className='text-start text-capitalize'>
                                        <h1>{singleJobDetails?.title}</h1>
                                        <p>{singleJobDetails?.companyProfileDetail?.[0]?.companyName}</p>
                                    </div>
                                    {
                                        singleJobDetails?.status === 'open' ?
                                            <div className='open_job_co mx-4 me-0 d-flex align-items-center justify-content-center gap-2'>
                                                <div className='open_dot' />
                                                <span>Open</span>
                                            </div>
                                            : <div className='close_job_co mx-4 me-0 d-flex align-items-center justify-content-center gap-2'>
                                                <div className='close_dot' />
                                                <span>Close</span>
                                            </div>
                                    }
                                </div>
                                <div className='filter_candi_track d-flex align-items-center flex-wrap gap-3 text-capitalize'>
                                    <div>
                                        <img src={bagSolid} alt="bagSolid" />
                                        <span>{singleJobDetails?.experience}</span>
                                    </div>
                                    {singleJobDetails?.salaryType === "Fixed Salary" && <div><img src={cashMultiple} alt="cash multiple" /> <span>{singleJobDetails?.FixedSalary}k</span></div>}
                                    {singleJobDetails?.salaryType == "Salary Range" && <div><img src={cashMultiple} alt="cash multiple" /> <span>{singleJobDetails?.minSalary}k - {singleJobDetails?.maxSalary}k</span></div>}
                                    {singleJobDetails?.salaryType == "Not Disclose" && <div><img src={cashMultiple} alt="cash multiple" /> <span>{singleJobDetails?.salaryType}</span></div>}
                                    <div>
                                        <img src={googleLoc} alt="googleLoc" />
                                        <span>{singleJobDetails?.jobLocations || 'N/A'}</span>
                                    </div>
                                </div>
                            </div> */}
                            <FaAngleDown color='#3284FF' fontSize={30} />
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='job_items_box pt-0'>
                        <div className="filtei_in_menuOption my-3 d-flex gap-1 flex-sm-row flex-column gap-md-3 flex-wrap">
                            <InputGroup className="filterTite filterMennuJobs">
                                <IoSearch fontSize={18} />
                                <Form.Control
                                    placeholder="Search by title"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    value={jobSearchValue}
                                    onChange={handleFilterJobPosts}
                                />
                            </InputGroup>
                            <Form.Select
                                className='filter-menu-canfd filterBydtsuMenu'
                                aria-label="Default select example"
                                onChange={(e) => filterjobsByCategory(e.target.value)}
                            >
                                <option value="" defaultValue="" disabled>
                                    Filter
                                </option>
                                <option value="all">All</option>
                                <option value="Open">Open</option>
                                <option value="Close">Close</option>
                            </Form.Select>
                        </div>
                        <Dropdown.Item className='p-0 mb-3'>
                            {
                                finalJobFilter?.map((jobs, index) => (
                                    // jobs?._id != singleJobDetails?._id &&
                                    <div key={index} onClick={() => handleOnJobsMenuChanged(jobs, jobs?._id)} className={`dropItems-job-menu d-flex flex-column align-items-start justify-content-start gap-3 mb-2 ${singleJobDetails?._id === jobs?._id && 'active_job_brd'}`}>
                                        <div className="cards_candititle d-flex align-items-center flex-wrap gap-3">
                                            <img src={jobs?.companyProfileDetail?.[0]?.companylogo || dummyprofileimage} alt="dummyprofileimage" />
                                            <div className='text-start text-capitalize'>
                                                <h1>{jobs?.title}</h1>
                                                <p>{jobs?.companyProfileDetail?.[0]?.companyName}</p>
                                            </div>
                                            {
                                                jobs?.status === 'open' ?
                                                    <div className='open_job_co mx-4 me-0 d-flex align-items-center justify-content-center gap-2'>
                                                        <div className='open_dot' />
                                                        <span>Open</span>
                                                    </div>
                                                    : <div className='close_job_co mx-4 me-0 d-flex align-items-center justify-content-center gap-2'>
                                                        <div className='close_dot' />
                                                        <span>Close</span>
                                                    </div>
                                            }
                                        </div>
                                        <div className='filter_candi_track d-flex align-items-center flex-wrap gap-3 text-capitalize'>
                                            <div>
                                                <img src={bagSolid} alt="bagSolid" />
                                                <span>{jobs?.experience}</span>
                                            </div>
                                            {jobs?.salaryType === "Fixed Salary" && <div><img src={cashMultiple} alt="cash multiple" /> <span>{singleJobDetails?.FixedSalary}k</span></div>}
                                            {jobs?.salaryType == "Salary Range" && <div><img src={cashMultiple} alt="cash multiple" /> <span>{singleJobDetails?.minSalary}k - {singleJobDetails?.maxSalary}k</span></div>}
                                            {jobs?.salaryType == "Not Disclose" && <div><img src={cashMultiple} alt="cash multiple" /> <span>{singleJobDetails?.salaryType}</span></div>}
                                            <div>
                                                <img src={googleLoc} alt="googleLoc" />
                                                <span className='text-capitalize'>{jobs?.jobLocations || 'N/A'}</span>
                                            </div>
                                        </div>
                                        <div className='track_preferences d-flex align-items-center gap-2 flex-wrap'>
                                            {
                                                jobs?.skills?.map((skill, idx) => (
                                                    (idx <= 2) && <div key={idx}>{skill}</div>
                                                ))
                                            }
                                            {(jobs?.skills?.length >= 3 && jobs?.skills?.length - 3 > 0) && <div>{jobs?.skills?.length - 3}+ More</div>}
                                        </div>
                                    </div>
                                ))
                            }
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className="candidate_container">
                <div className="filterBtnGrp_cint mb-5 d-flex align-items-center justify-content-start justify-content-md-between flex-wrap gap-2">
                    <div className="filterbtns d-flex gap-1 flex-md-row flex-column flex-wrap justify-content-between">
                        <div
                            onClick={() => handleFilterBtnChange('all')}
                            className={`${filterBtn.all && 'active-candi-filterBtn'}`}>
                            All
                        </div>
                        <div
                            onClick={() => handleFilterBtnChange('shortlisted')}
                            className={`${filterBtn.shortlisted && 'active-candi-filterBtn'}`}>
                            Shortlisted
                        </div>
                        <div
                            onClick={() => handleFilterBtnChange('hired')}
                            className={`${filterBtn.hired && 'active-candi-filterBtn'}`}>
                            Hired
                        </div>
                        <div
                            onClick={() => handleFilterBtnChange('rejected')}
                            className={`${filterBtn.rejected && 'active-candi-filterBtn'}`}>
                            Rejected
                        </div>
                    </div>
                    <InputGroup className="filterTite">
                        <IoSearch fontSize={18} />
                        <Form.Control
                            placeholder="Search by name, title or status"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={handleFilterInputSearch}
                            value={nameTitle}
                        />
                    </InputGroup>
                </div>
                {
                    allAppliedCandidates?.length > 0 &&
                    filteredJobs?.map((data, index) => (
                        <div key={index} className="candi_xards mb-3 d-flex justify-content-between gap-2 flex-wrap gap-md-3">
                            <div className='flex-grow-1'>
                                <div className="cndi_card_title mb-3 d-flex align-items-center justify-content-between flex-wrap gap-3">
                                    <div className='user-profile0imaeg d-flex align-items-center flex-wrap gap-3'>
                                        <img src={data?.profile || dummyprofileimage} alt="dummyprofileimage" />
                                        <div className="title-cards text-capitalize me-3">
                                            <h1>{data?.name}</h1>
                                            <p>{data?.JobTitle}</p>
                                        </div>
                                        <div className="candi-join-time d-flex align-items-center gap-1">
                                            <img src={teenyicons_calendar} alt="teenyicons_calendar" />
                                            <span>Availability :</span>
                                            <span>{data?.startMonth?.[0] || 'N/A'}</span>
                                        </div>
                                    </div>
                                    {data?.status === 'Hired' && <div className="job_staus job_staus_hired">Hired</div>}
                                    {data?.status === 'Rejected' && <div className="job_staus job_staus_rejected">Rejected</div>}
                                    {data?.status === 'Shortlisted' && <div className="job_staus job_staus_shortlisted">Shortlisted</div>}
                                    {!data?.status?.includes('Hired') &&
                                        !data?.status?.includes('Shortlisted') &&
                                        !data?.status?.includes('Rejected') &&
                                        <div className="job_staus job_staus_pending">Status: Pending</div>}
                                </div>
                                <div className='candi_tract_sec mb-3 d-flex align-items-center flex-wrap gap-3'>
                                    <div className='text-capitalize'>
                                        <img src={suitcase} alt="suitcase" />
                                        <span>{data?.experience}</span>
                                    </div>
                                    <div>
                                        <img src={mage_email} alt="mage_email" />
                                        <span>fakeemail@yopmail.com</span>
                                    </div>
                                    <div className='text-capitalize'>
                                        <img src={weui_location} alt="weui_location" />
                                        <span>{data?.address?.slice(0, 17) + '...'}</span>
                                    </div>
                                </div>
                                <div className='skilled_cont d-flex align-items-center gap-2 flex-wrap'>
                                    {
                                        data?.skills?.map((skill, idx) => (
                                            (idx <= 3) && <div key={idx}>{skill}</div>
                                        ))
                                    }
                                    {(data?.skills?.length >= 4 && data?.skills?.length - 4 > 0) && <div>{data?.skills?.length - 4}+ More</div>}
                                </div>
                            </div>
                            <div className='btnGroupCandiPg d-flex flex-column align-items-center justify-content-center gap-3'>
                                <div onClick={()=>downloadResume(allAppliedCandidates?.[0]?.resume)} className="downloadRes"><FaDownload/>Download Resume</div>
                                {/* <div className="downloadRes">
                                    <a
                                        download="Resume.pdf"
                                        href={allAppliedCandidates?.[0]?.resume}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaDownload /> Download Resume
                                    </a>
                                </div> */}
                                {console.log('allAppliedCandidates', allAppliedCandidates?.[0]?.resume)}
                                <div className="viewProfile" onClick={() => handleToggleModalBox(index)} >View Profile</div>
                            </div>
                        </div>
                    ))
                }
                {filteredJobs?.length === 0 && allAppliedCandidates?.length != 0 && <p className='text-center fs-4'>No Application Yet!</p>}
                {!allAppliedCandidates?.length && <NoCandidateApplied />}
                {/* {allAppliedCandidates?.length == 0 && <NoCandidateApplied />} */}
                {
                    allAppliedCandidates?.length > 0 &&
                    filteredJobs?.length > 0 &&
                    <div className="footer-pagination overflow-auto d-flex align-items-center justify-content-center pb-3">
                        <div className='d-flex align-items-center gap-2'>
                            <PaginationBtn className='pagination-btn'
                                onClick={handleDecPagination}
                                disabled={paginationBtnArr[0] === 1}
                            >
                                <FaAngleLeft fontSize={20} /> Previous</PaginationBtn>
                            {
                                paginationBtnArr.map((data, idx) => (
                                    <PaginationBtn
                                        key={idx}
                                        onClick={() => handlePaginationSize(data)}
                                        className={`pagination-btn ${(clipArrLength.max === data * 10 && 'pagination-active')}`}
                                        disabled={idx > 0 && allAppliedCandidates?.length < (data * 10)}
                                    >
                                        {data}
                                    </PaginationBtn>
                                ))
                            }
                            <PaginationBtn
                                disabled={allAppliedCandidates?.length < (paginationBtnArr[paginationBtnArr?.length - 1] * 10)}
                                className='pagination-btn' onClick={handleIncPagination}>
                                Next
                                <FaAngleRight fontSize={20} />
                            </PaginationBtn>
                        </div>
                    </div>}
            </div>
            {/* <NoCandidateApplied /> */}
        </div>
    )
}
