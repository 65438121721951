let AllEnvApiUrls = {
  production: {
    baseUrl: process.env.REACT_APP_PROD_USER_API_URL,
  },
  staging: {
    baseUrl: process.env.REACT_APP_DEV_USER_API_URL,
  },
  development: {
    baseUrl: process.env.REACT_APP_DEV_USER_API_URL,
  },
};

const ApiUrls = AllEnvApiUrls[process.env.REACT_APP_ENVIRONMENT];
const UserAuthUrl = `${ApiUrls?.baseUrl}/user-service/v1`;
// console.log("API_URLS", UserAuthUrl);
// console.log("API_URLS",  process.env);

export const URLS = {
  // Auth APIs
  register: `${UserAuthUrl}/auth/register`,
  login: `${UserAuthUrl}/auth/login`,
  forgotPassword: `${UserAuthUrl}/auth/forget-password`,
  logout: `${UserAuthUrl}/auth/logout`,
  userDetails:`${UserAuthUrl}/user/UserDetails`,

  // User profile
  createUserProfile: `${UserAuthUrl}/user/userProfile`,
  getcities: `${UserAuthUrl}/country/getcities`,
  get_userProfile: `${UserAuthUrl}/user/userProfile`,
  create_userProfile: `${UserAuthUrl}/user/userProfile`,
  uploadImage: `${UserAuthUrl}/user/userImage`,
  uploadUserResume:`${UserAuthUrl}/user/user-resume`,
  getUserResume:`${UserAuthUrl}/user/download-resume`,

  // Job API's
  getAllJob: `${UserAuthUrl}/user/getAllJob`,
  getSingleJob:`${UserAuthUrl}/user/getSingleJob`,
  userJobApplie:`${UserAuthUrl}/user/userJobApplie`,
  getAllAppliedJobs:`${UserAuthUrl}/user/getAllAppliedJobs`,
  getSkillList: `${UserAuthUrl}/user/getSkillslist`,
  getJoblist: `${UserAuthUrl}/user/getJoblist`,
  notInterestedJob: `${UserAuthUrl}/user/notInterestedJob`,
  getNotInterestedJob: `${UserAuthUrl}/user/getNotInterestedJob`,
  saveLater: `${UserAuthUrl}/user/saveLater`,
  getSaveLater: `${UserAuthUrl}/user/getSaveLater`,
  
  // Langauge
  getLanguages: `${UserAuthUrl}/language/getLanguages`,
};
