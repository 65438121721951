// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fallback_container{
    width: 100%;
    height: 100vh;
    background-color: #f9fafbe5;
}

.fallback_container > img{
    width: 400px;
    height: 400px;
}

.fallback_container > h1{
    font-size: 24px;
    font-weight: 600;
}

.fallback_container > div > svg{
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/ProtectedRouter/FallbackPage.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,2BAA2B;AAC/B;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".fallback_container{\n    width: 100%;\n    height: 100vh;\n    background-color: #f9fafbe5;\n}\n\n.fallback_container > img{\n    width: 400px;\n    height: 400px;\n}\n\n.fallback_container > h1{\n    font-size: 24px;\n    font-weight: 600;\n}\n\n.fallback_container > div > svg{\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
