import React, { useState } from 'react'
import './Notification.css'
import { MdDelete } from 'react-icons/md'
import multipleMarkedIcon from '../../../assets/multiple-marked.png'

export default function Notification() {
    const [filterBtn, setFilterBtn] = useState({
        all: true,
        newApplication: false,
        jobUpdates: false,
        accountUpdates: false
    })

    const handleFilterBtnChange = (type) => {
        type === 'all' && setFilterBtn({ all: true, newApplication: false, jobUpdates: false, accountUpdates: false })
        type === 'newApplication' && setFilterBtn({ all: false, newApplication: true, jobUpdates: false, accountUpdates: false })
        type === 'jobUpdates' && setFilterBtn({ all: false, newApplication: false, jobUpdates: true, accountUpdates: false })
        type === 'accountUpdates' && setFilterBtn({ all: false, newApplication: false, jobUpdates: false, accountUpdates: true })
        // handleFilterAppliedCandidates(type)
    }

    const notifications = [
        {
            title: "New Job Application Steps",
            message: "Stay updated on your applications! Visit the 'Applied Jobs'",
            date: "04 April, 2021",
            time: "04:00 PM"
        },
        {
            title: "Profile Update Reminder",
            message: "Your profile is incomplete. Complete it to get better job recommendations.",
            date: "05 April, 2021",
            time: "10:30 AM"
        },
        {
            title: "New Job Posting in Your Field",
            message: "Check out the latest job posting in your preferred category.",
            date: "06 April, 2021",
            time: "02:15 PM"
        },
        {
            title: "Interview Invitation",
            message: "You have been invited for an interview. Check your email for details.",
            date: "07 April, 2021",
            time: "09:00 AM"
        },
        {
            title: "Application Status Update",
            message: "Your application status has been updated. Visit 'Applied Jobs' for details.",
            date: "08 April, 2021",
            time: "03:45 PM"
        },
        {
            title: "New Job Posting in Your Field",
            message: "Check out the latest job posting in your preferred category.",
            date: "06 April, 2021",
            time: "02:15 PM"
        },
        {
            title: "Interview Invitation",
            message: "You have been invited for an interview. Check your email for details.",
            date: "07 April, 2021",
            time: "09:00 AM"
        },
        {
            title: "Application Status Update",
            message: "Your application status has been updated. Visit 'Applied Jobs' for details.",
            date: "08 April, 2021",
            time: "03:45 PM"
        }
    ];


    return (
        <>
            <h1 className='notication_title'>Notification</h1>
            <div className="notification-main">
                <div className='d-flex justify-content-between align-items-center gap-2 flex-wrap'>
                    <div className="notification-filterbtns d-flex gap-1 flex-md-row flex-column flex-wrap justify-content-between">
                        <div
                            onClick={() => handleFilterBtnChange('all')}
                            className={`${filterBtn.all && 'active-notifi-filterBtn'}`}>
                            All
                        </div>
                        <div
                            onClick={() => handleFilterBtnChange('newApplication')}
                            className={`${filterBtn.newApplication && 'active-notifi-filterBtn'}`}>
                            Shortlisted
                        </div>
                        <div
                            onClick={() => handleFilterBtnChange('jobUpdates')}
                            className={`${filterBtn.jobUpdates && 'active-notifi-filterBtn'}`}>
                            Hired
                        </div>
                        <div
                            onClick={() => handleFilterBtnChange('accountUpdates')}
                            className={`${filterBtn.accountUpdates && 'active-notifi-filterBtn'}`}>
                            Rejected
                        </div>
                    </div>
                    <div className="notification_action_btns d-flex gap-4">
                        <button><img src={multipleMarkedIcon} alt="multipleMarkedIcon" />Marked All as Read</button>
                        <button><MdDelete fontSize={24} /> Remove All</button>
                    </div>
                </div>
                <div className="notification_items_list mt-4">
                    {
                        notifications?.map((notifi, idx) => (
                            <div key={idx} className='d-flex justify-content-between align-items-center gap-2'>
                                <div className="notication-list mb-3">
                                    <p>{notifi.title}</p>
                                    <p>{notifi?.message}</p>
                                    <p>{notifi?.date} | {notifi?.time}</p>
                                </div>
                                <div className="delete_wrapper">
                                    <MdDelete fontSize={24}/>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}
