import React, { useContext } from 'react'
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import SimilarJob from './SimilaryJob/SimilarJob'
import JobDetails from './JobDetails/JobDetails'
import './Index.css'
import { useNavigate } from 'react-router-dom';
import UserContext from '../../component/ContextApi/UserContext';
import Loader from '../../component/Loader/Loader';

export default function Index() {

    const navigate = useNavigate()
    return (
        <>
        {/* {loader && <Loader/>} */}
            <div className='main-unknown'>
                <div className='back-btn-onJob mb-2'>
                    <MdOutlineKeyboardBackspace fontSize={24} onClick={() => navigate(-1)} /> <span onClick={() => navigate(-1)}>All Jobs</span>
                </div>
                <div className='job-detais-container d-flex gap-3 flex-wrap'>
                    <div className='job-left-container'>
                        <SimilarJob />
                    </div>
                    <div className='similarJob-right-container'>
                        <JobDetails />
                    </div>
                </div>
            </div>
        </>
    )
}
