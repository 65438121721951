import React, { useContext, useState } from 'react'
import './CloseJobModal.css'
import { EventButton, StepperBackButton } from '../../../../User/component/ReUseComps/Button'
import alertIcon from '../../../../assets/octicon_alert-24.png'
import CompanyContext from '../../ContextAPI/CompanyContext'
import axios from 'axios'
import { COMPANY_URLS } from '../../../../Utils/Company_App_Urls'
import { errorPopup, successPopup } from '../../../../Utils/ToasterMessage'
import { Spinner } from 'react-bootstrap'

export default function CloseJobModal() {
    const [spinners, setSpinners] = useState(false)
    const { closeJobModalOpen, setCloseJobModalOpen, needTorender, setNeedTorender } = useContext(CompanyContext)

    const handleCloseModal = () => {
        setCloseJobModalOpen({ status: false })
    }

    const closeJob = () => {
        setSpinners(true)
        axios.patch(`${COMPANY_URLS.jobClosed}`, {}, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            params: {
                id: closeJobModalOpen?.data?.id
            }
        })
            .then((res) => {
                successPopup('Job closed successfully.');
                setNeedTorender(prev => !prev);
                setCloseJobModalOpen({ status: false })
                setSpinners(true)
            })
            .catch((err) => {
                errorPopup(err?.response?.data?.message)
                setCloseJobModalOpen({ status: false })
                setSpinners(true)
            })
    }
    return (
        <>
            <div className="popUpmodal_main">
                <div className="modalContent_box">
                    <img src={alertIcon} alt="alertIcon" />
                    <h1>Are You Sure You Want to Close This Job?</h1>
                    <p>Closing this job is permanent. You won't be able to reopen it or receive more applications. Are you sure you want to proceed?</p>

                    <div className='modalBtns d-flex justify-content-between align-items-center gap-3'>
                        <StepperBackButton onClick={handleCloseModal}>Cancle</StepperBackButton>
                        <EventButton onClick={closeJob}>Confirm {spinners && <Spinner animation="border" role="status" />} </EventButton>
                    </div>
                </div>
            </div>
        </>
    )
}
