import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import CompanyProtecter from "./ProtectedRouter/CompanyProtector";
import Layout from "./User/component/Layout/Layout";
import Home from "./User/Pages/Home/Home";
import UserContextProvider from "./User/component/ContextApi/UserContextProvider";
import UserLogin from "./User/component/Auth/Login";
import UserRegistered from "./User/component/Auth/Registered";
import UserForgotPassword from "./User/component/Auth/ForgotPassword";
import UserGetStarted from "./User/component/Auth/GetStarted";
import UserEnterCode from "./User/component/Auth/EnterCode";
import { ToastContainer } from "react-toastify";
import SuccessfulSubmit from "./User/Pages/CreateProfile/SuccessfulSubmit";
import Feed from "./User/Pages/Feed/Feed";
import AppliedJob from ".//User/Pages/AppliedJob/AppliedJob";
import SearchJob from "./User/Pages/SearchJob/SearchJob";
import ProfileStepper from "./User/Pages/CreateProfile/ProfileStepper";
import Chat from "./User/Pages/Chat/Chat";
import UserProfile from "./User/Pages/Profile/Profile";
import ApplyJob from "./User/Pages/ApplyJob/ApplyJob";
import Index from "./User/Pages/SingleJobDetails/Index";
import CreateProfile from "./Company/Pages/CreateProfile/CreateProfile";
import CompanyLayout from "./Company/Component/Layout/CompanyLayout";
import CreateJobPost from "./Company/Pages/CreatJob/JobPost";
import Applications from "./Company/Pages/Applications/Applications";
import Candidate from "./Company/Pages/Candidates/Candidate";
import CompanyContextProvider from "./Company/Component/ContextAPI/CompanyContextProvider";
import CompanyProfile from "./Company/Pages/CompanyProfile/CompanyProfile";
import JobPost from "./Company/Pages/JobPost/Index";
import CompanyLogin from "./Company/Component/Auth/Login";
import CompanyRegistered from "./Company/Component/Auth/Registered";
import CompanyForgotPassword from "./Company/Component/Auth/ForgotPassword";
import {
  CompanyProtectedRoute,
  UserProtectedRoute,
} from "./ProtectedRouter/Router";
import FallbackPage from "./ProtectedRouter/FallbackPage";
import PostedJobDetails from "./Company/Pages/PostedJobDetails/PostedJobDetails";
import BillingInvoices from "./Company/Pages/BillingInvoices/BillingInvoices";
import SubscriptionPlan from "./Company/Pages/SubscriptionPlan/SubscriptionPlan";
import Setting from "./Company/Pages/Setting/Setting";
import HelpSupport from "./Company/Pages/HelpSupport/HelpSupport";
import { useEffect, useState } from "react";
import NotInterested from "./User/Pages/NotInterested/NotInterested";
import SavedJob from "./User/Pages/SavedJob/SavedJob";
import JobDetails from "./User/Pages/SingleJobDetails/JobDetails/JobDetails";
import SmallDescriptionCard from "./User/Pages/AppliedJob/SmallDescriptionCard";
import SuggestedJob from "./User/Pages/SuggestedJob/Index";
import PreviewJobPost from "./Company/Pages/PreviewJobPost/PreviewJobPost";
import Notification from "./Company/Pages/Notification/Notification";
import PurchagedPlan from "./Company/Pages/SubscriptionPlan/PurchagedPlan";

function App() {

  const decidePath=()=>{
    if(localStorage.getItem('token')) return '/company/job-post'
    if(localStorage.getItem('userToken')) return '/user/find-job'
    return "/user/login"
  }
 
  return (
    <>
      <UserContextProvider>
        <CompanyContextProvider>
          <ToastContainer />
          <Routes>
            <Route path="/" element={<Navigate to={decidePath()} />} />
            <Route path="/user">
              <Route path="login" element={<UserLogin />} />
              <Route path="getstart" element={<UserGetStarted />  } />
              <Route path="entercode" element={<UserEnterCode />} />
              <Route path="register" element={<UserRegistered />} />
              <Route path="forgot-password" element={<UserForgotPassword />} />
            </Route>
            
            {/* User protected routes */}
            <Route exact path="/user" element={ <UserProtectedRoute> <Layout /> </UserProtectedRoute> }>
              <Route exact path="home" element={<Home />} />
              <Route exact path="create-profile" element={<ProfileStepper />} />
              <Route exact path="user-profile" element={<UserProfile />} />
              <Route exact path="profile-submit" element={<SuccessfulSubmit />}/>
              <Route exact path="find-job" element={<ApplyJob />} />
              <Route exact path="find-job/job-details/:id" element={<Index />}/>
              <Route exact path="notIntested-jobs" element={<NotInterested />} />
              <Route exact path="notIntested-jobs/job-details/:id" element={<JobDetails />} />
              <Route exact path="savedJobs/job-details/:id" element={<JobDetails />} />
              <Route exact path="feed" element={<Feed />} />
              <Route exact path="applied-job" element={<AppliedJob />} />
              <Route exact path="applied-job/job-details/:id" element={<SmallDescriptionCard />} />
              <Route exact path="search-job" element={<SearchJob />} />
              <Route exact path="chat" element={<Chat />} />
              <Route exact path="savedJobs" element={<SavedJob />} />
              <Route exact path="suggested-jobs" element={<SuggestedJob />} />
            </Route>

            {/* Company protected routes */}
            <Route path="/company">
              <Route path="login" element={<CompanyLogin />} />
              <Route path="getstart" element={<UserGetStarted />} />
              <Route path="entercode" element={<UserEnterCode />} />
              <Route path="register" element={<CompanyRegistered />} />
              <Route path="forgot-password" element={<CompanyForgotPassword />}/> 
            </Route>

            <Route path="/company" element={<CompanyProtectedRoute> <CompanyLayout /> </CompanyProtectedRoute>}>
              <Route path="" element={<CompanyProtecter />}>
                <Route exact path="create-profile" element={<CreateProfile />} />
                <Route exact path="job-post" element={<JobPost />} />
                <Route exact path="job-post/job-details/:id" element={<PostedJobDetails />} />
                <Route exact path="create-job" element={<CreateJobPost />} />
                <Route exact path="preview-job" element={<PreviewJobPost />} />
                <Route exact path="applications" element={<Applications />} />
                <Route exact path="candidates" element={<Candidate />} />
                <Route exact path="billing-invoices" element={<BillingInvoices />} />
                <Route exact path="subscription-plan" element={<SubscriptionPlan />} />
                <Route exact path="purchanged-plan" element={<PurchagedPlan />} />
                <Route exact path="setting" element={<Setting />} />
                <Route exact path="help-support" element={<HelpSupport />} />
                <Route exact path="profile" element={<CompanyProfile />} />
                <Route exact path="notification" element={<Notification />} />
              </Route>
            </Route>
            <Route path="*" element={<FallbackPage />} />
          </Routes>
        </CompanyContextProvider>
      </UserContextProvider>
    </>
  );
}

export default App;
