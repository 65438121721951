import React, { useState } from 'react'
import menu3dot from '../../../assets/menu3dot.png'
import { Button } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import { BsCaretDownFill } from "react-icons/bs";
import { BsThreeDotsVertical } from "react-icons/bs";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import './BillingInvoices.css'


const invoiceData = [
    {
        invoiceId: "#48394",
        date: "23 Jul 2023",
        plan: "Enterprise Plan",
        amount: "4999",
        status: "Paid",
        action: "Download"
    },
    {
        invoiceId: "#58321",
        date: "12 Aug 2023",
        plan: "Pro Plan",
        amount: "2999",
        status: "Paid",
        action: "Download"
    },
    {
        invoiceId: "#64921",
        date: "05 Sep 2023",
        plan: "Basic Plan",
        amount: "999",
        status: "Failed",
        action: "Retry"
    },
    {
        invoiceId: "#74230",
        date: "19 Jun 2023",
        plan: "Enterprise Plan",
        amount: "5999",
        status: "Paid",
        action: "Download"
    },
    {
        invoiceId: "#32918",
        date: "10 Oct 2023",
        plan: "Basic Plan",
        amount: "1199",
        status: "Failed",
        action: "Retry"
    },
    {
        invoiceId: "#98211",
        date: "01 Nov 2023",
        plan: "Pro Plan",
        amount: "3499",
        status: "Paid",
        action: "Download"
    },
    {
        invoiceId: "#41832",
        date: "16 May 2023",
        plan: "Basic Plan",
        amount: "1499",
        status: "Paid",
        action: "Retry"
    },
    {
        invoiceId: "#52894",
        date: "20 Jul 2023",
        plan: "Enterprise Plan",
        amount: "4799",
        status: "Paid",
        action: "Download"
    },
    {
        invoiceId: "#69312",
        date: "07 Mar 2023",
        plan: "Pro Plan",
        amount: "3899",
        status: "Failed",
        action: "Retry"
    },
    {
        invoiceId: "#12984",
        date: "29 Dec 2023",
        plan: "Basic Plan",
        amount: "1299",
        status: "Paid",
        action: "Download"
    }
];


export default function BillingInvoices() {

    const [filterOtion, setFilterOption] = useState({ invoiceId: false, billingDate: false, plan: false, amount: false, status: false})
    const [tnxHistory, setTnxHistory] = useState(invoiceData)

    const handleOptionToggle = (option, value = '') => {
        setFilterOption((prev) => {
            const resetOptions = Object.keys(prev).reduce((acc, key) => {
                acc[key] = false;
                return acc;
            }, {});
            return { ...resetOptions, [option]: !prev[option] };
        });

        //Filter function call
        handleFilterByOption(option)
    }

    const handleFilterByOption = (option = '') => {
        option === 'invoiceId' &&
            invoiceData?.sort((a, b) => {
                if (filterOtion?.invoiceId) {
                    return (a?.invoiceId?.localeCompare(b?.invoiceId))
                } else {
                    return (b?.invoiceId?.localeCompare(a?.invoiceId))
                }
            })
        option === 'billingDate' &&
            invoiceData?.sort((a, b) => {
                if (filterOtion?.billingDate) {
                    return new Date(a?.date) - new Date(b?.date);
                } else {
                    return new Date(b?.date) - new Date(a?.date);
                }
            })
        option === 'plan' &&
            invoiceData?.sort((a, b) => {
                if (filterOtion?.plan) {
                    return (a?.plan?.localeCompare(b?.plan))
                } else {
                    return (b?.plan?.localeCompare(a?.plan))
                }
            })
        option === 'status' &&
            invoiceData?.sort((a, b) => {
                if (filterOtion?.status) {
                    return (a?.status?.localeCompare(b?.status))
                } else {
                    return (b?.status?.localeCompare(a?.status))
                }
            })
        option === 'amount' &&
            invoiceData?.sort((a, b) => {
                if (filterOtion?.amount) {
                    return (Number(a?.amount) - Number(b?.amount))
                } else {
                    return (Number(b?.amount) - Number(a?.amount))
                }
            })
    }

    return (
        <>
            <div className='billingNinvoice_main'>
                <h1>Billing & Invoice</h1>
                <div className='active-plan-card d-flex justify-content-between gap-md-4 gap-3'>
                    <div className="row d-flex flex-grow-1 justify-content-between pb-2">
                        <dov className="col-sm-5 col-md-5 leftSide-section">
                            <p>Active Plan Info</p>
                            <p>Activate Since</p>
                            <h2>on December 12, 2025</h2>
                        </dov>
                        <dov className="col-sm-5 col-md-5 d-flex flex-column align-items-sm-end justify-content-sm-end leftSide_wraper">
                            <div className='d-flex align-items-center justify-content-sm-end mb-4'>
                                <div><span>₹4,999</span><span>/Month</span></div>
                            </div>
                            <button>Enterprise Plan</button>
                        </dov>
                    </div>
                    <div className='download_menuIcon'>
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 150, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">Simple tooltip</Tooltip>}
                        >
                            <BsThreeDotsVertical fontSize={28} color="#B9BEC1" />
                        </OverlayTrigger>
                        {/* <img src={menu3dot} alt="menu3dot" /> */}
                    </div>
                </div>
                <div className='table_billing_dwn_section d-flex gap-2 px-3 px-md-0 mb-2 mt-5 flex-wrap'>
                    <div className="">
                        <h1>Invoice</h1>
                        <p>Effortlessly handle your billing and invoices right here.</p>
                    </div>
                    <div className="">
                        <Button className='w-100' variant="primary">Download All</Button>
                    </div>
                </div>
                <div className='tnx_htry_table d-flex'>
                    <table>
                        <thead>
                            <tr>
                                <th> <span onClick={() => handleOptionToggle('invoiceId')} className='make_res'>
                                    Invoice ID <BsCaretDownFill className={`rorate ${filterOtion?.invoiceId && 'rotate-active'}`} fontSize={14} />
                                </span></th>
                                <th> <span onClick={() => handleOptionToggle('billingDate')} className='make_res'>
                                    Billing Date <BsCaretDownFill className={`rorate ${filterOtion?.billingDate && 'rotate-active'}`} fontSize={14} />
                                </span></th>
                                <th> <span onClick={() => handleOptionToggle('plan')} className='make_res'>
                                    Plan <BsCaretDownFill className={`rorate ${filterOtion?.plan && 'rotate-active'}`} fontSize={14} />
                                </span></th>
                                <th> <span onClick={() => handleOptionToggle('amount')} className='make_res'>
                                    Amount <BsCaretDownFill className={`rorate ${filterOtion?.amount && 'rotate-active'}`} fontSize={14} />
                                </span></th>
                                <th> <span onClick={() => handleOptionToggle('status')} className='make_res'>
                                    Status <BsCaretDownFill className={`rorate ${filterOtion?.status && 'rotate-active'}`} fontSize={14} />
                                </span></th>
                                <th> <span className='make_res'>Action</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                invoiceData?.map((data, idx) => (
                                    <tr key={idx} className='mb-3'>
                                        <td>{data?.invoiceId}</td>
                                        <td>{data?.date}</td>
                                        <td>{data?.plan}</td>
                                        <td>₹{data?.amount}</td>
                                        <td><div className={`${data?.status === 'Paid' ? 'paid' : 'pending'}`}>{data?.status}</div></td>
                                        <td><div className='dwnld_btn'>Download</div></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div >
        </>
    )
}
