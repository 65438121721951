import React from 'react'
import './Chat.css'
import UserContactList from './UserContactList'
import ChatDisplay from './ChatDisplay'

export default function Chat() {
    return (
        <>
            <div className="main_chat">
                <div className="leftChatList">
                    <UserContactList/>
                </div>
                <div className="right_display_chat">
                    <ChatDisplay/>
                </div>
            </div>
        </>
    )
}
