import React, { useContext, useState } from 'react'
import kaamwalaLogo from '../../../assets/KAAMVALALogo.png'
import { TfiEmail } from "react-icons/tfi";
import { Link, useNavigate } from 'react-router-dom';
import { IoMdEyeOff, IoMdEye } from "react-icons/io";
import { URLS } from '../../../Utils/App_urls';
import { errorPopup, successPopup, warningPopup } from '../../../Utils/ToasterMessage';
import CompanyContext from '../../../Company/Component/ContextAPI/CompanyContext';
import loginAuthImage from '../../../assets/loginAuthImage.png'
import padlock from '../../../assets/Padlock.png'
import UserContext from '../ContextApi/UserContext';
import { Dropdown } from 'react-bootstrap';
import Loader from '../Loader/Loader';
import axios from 'axios';
import './Auth.css'

export default function Login() {
  const { userProfileComaplete, setUserprofileComplete, setProfileImageUrl } = useContext(UserContext)
  const navigate = useNavigate()
  const [showPass, setShowPass] = useState(false)
  const [loader, setLoader] = useState(false)
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginAs, setLoginAs] = useState('')
  const [customError, setCustomError] = useState({
    errorStatus: true,
    emailError: '',
    passwordError: ''
  });

  const loginAPICall = async () => {
    const data = {
      email: email,
      password: password
    }

    setLoader(true);
    await axios.post(`${URLS.login}`, data)
      .then((res) => {
        redirectAsPerRole(res?.data?.data?.tokens?.access?.token, res?.data?.data?.user?.isEmailVerified, res?.data?.data?.user?.role)
      })
      .catch(err => {
        errorPopup(err?.response?.data?.message);
        setLoader(false);
      })

  }

  const getUserDetails = () => {
    axios.get(`${URLS.userDetails}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('userToken')}`
      }
    })
      .then((res) => {
        successPopup("Login successfully");
        setLoader(false);
        localStorage.setItem('isprofileUserComplete', res?.data?.data?.profileComplete);
        setUserprofileComplete(res?.data?.data?.profileComplete)
        res?.data?.data?.profileComplete && getUserProfile();
        res?.data?.data?.profileComplete ? navigate('/user/find-job') : navigate('/user/create-profile');
      })
      .catch((err) => {
        console.log(err)
        setLoader(false)
        errorPopup(err?.response?.data?.message || err?.message);
      })
  }
  const getUserProfile = () => {
    setLoader(true)
    axios.get(`${URLS.get_userProfile}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('userToken')}`
      }
    })
      .then((res) => {
        setProfileImageUrl(res?.data?.data?.profile)
      })
      .catch((err) => { })
  }

  const redirectAsPerRole = async (token, isEmailVerified, role) => {
    if (isEmailVerified) {

      localStorage.setItem('userToken', token);
      localStorage.setItem('userRole', role);
      if (role === 'user') {
        getUserDetails();
      } else {
        warningPopup('Invalid Credentials.')
        setLoader(false)
      }
    } else {
      warningPopup('Please verify your email');
      setLoader(false)
    }
    setEmail('');
    setPassword('')
  }

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setCustomError({ ...customError, emailError: '' });
  }

  const handlePassword = (e) => {
    setPassword(e.target.value);
    setCustomError({ ...customError, passwordError: '' })
  }

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$/;
    return passwordRegex.test(password);
  };

  const handleSubmitAndValidation = (e) => {
    e.preventDefault();
    if (email === '' || !email) {
      setCustomError({ ...customError, emailError: 'Email is required.' })
    } else if (password === '' || !password) {
      setCustomError({ ...customError, passwordError: 'Password is required.' })
    }
    else if (!validatePassword(password)) {
      setCustomError({ ...customError, passwordError: 'Password must be at least 8 characters long, contain one uppercase letter, one lowercase letter, one number, and one special character.' })
    } else {
      loginAPICall()
    }
  }

  return (
    <>

      {loader && <Loader />}
      <div className="main_login">
        <Dropdown className='switch-pannel-button'>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            Switch to company
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item className='py-0'>
              <Link className='urlAnckro py-0' to="/company/login">Switch to company</Link>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <div className='left-color-box'>
        </div>
        <div className='right-image-box'>
          {/* <div><img src={loginAuthImage} alt="loginAuthImage" /></div> */}
        </div>
        <div className='auth-box-adjust d-flex justify-ceontent-between align-items-center gap-4'>
          <div className="login_box">
            <img className='mb-2' src={kaamwalaLogo} alt="kaamwalaLogo" />
            <h1 className='AuthTitle'>User LogIn</h1>
            <form onSubmit={handleSubmitAndValidation} className='d-flex flex-column gap-3'>
              <div>
                <label className='d-block inputName' htmlFor="email">
                  <TfiEmail fontSize={18} />
                  <span className='mx-1 align-middle'>Email</span>
                </label>
                <input
                  className='form-input'
                  id='email'
                  type="email"
                  placeholder='abc@gmail.com'
                  value={email}
                  onChange={handleEmail}
                  isInvalid={customError.emailError}
                />
                {customError.emailError && <p className='text-danger'>{customError.emailError}</p>}
              </div>
              <div>
                <label className='d-block inputName' htmlFor="password">
                  {/* <RiLockPasswordFill fontSize={18} /> */}.
                  <img className='authPassIcon' src={padlock} alt="padlock" />
                  <span className='mx-1 align-middle'>Password</span>
                </label>
                <div className='form-input d-flex align-items-center p-0'>
                  <input
                    className='form-inputPass'
                    type={showPass ? 'text' : 'password'}
                    id='password'
                    placeholder='Enter Password'
                    value={password}
                    onChange={handlePassword}
                    autoComplete="current-password"
                  />
                  <span className='eye-hover me-2' onClick={() => setShowPass(prev => !prev)}> {showPass ? <IoMdEyeOff fontSize={20} /> : <IoMdEye fontSize={20} />}</span>
                </div>
                {customError.passwordError && <p className='text-danger'>{customError.passwordError}</p>}
              </div>
              <div className='d-flex justify-content-between'>
                <div className='rememberMe'>
                  <input id='rememberMe' type="checkbox" />
                  <label htmlFor="rememberMe">Remember Me</label>
                </div>
                <span className='forgotPassword' onClick={() => navigate('/user/forgot-password')}>Forgot Password?</span>
              </div>
              <button type='submit' className='authbtn'> Login </button>
              <p className='dntAccSignUp'>Don’t have a account? <span onClick={() => navigate('/user/register')}>Sign Up</span></p>
            </form>
          </div>
          <div className='d-flex flex-grow-1 align-items-center d-none d-md-block'><img src={loginAuthImage} alt="loginAuthImage" /></div>
        </div>
      </div>
    </>
  )
}
