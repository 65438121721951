import React from 'react'
import image1 from '../../../assets/image1.png'
import image2 from '../../../assets/image 4.png'
import image3 from '../../../assets/image 5.png'
import image4 from '../../../assets/image 6.png'
import image5 from '../../../assets/image 7.png'
import image6 from '../../../assets/image 8.png'
import image7 from '../../../assets/image 9.png'
import image8 from '../../../assets/image 10.png'
import './Home.css'

export default function Companies() {

    const imagesArr = [image1, image2, image3, image4, image5, image6, image7, image8]

    return (
        <>
            <div className='companies_main py-4'>
                <div className='container'>
                    <h1>10L+ top companies trust Kaamvala for their
                        {/* <span className='d-md-block underlineTitle'>hiring needs</span> */}
                        <div className="stepsTitle">
                            <h1>hiring needs</h1>
                            <div className='hlfbtnBorder' />
                        </div></h1>
                    <div className='companiesCardBox'>
                        {imagesArr.map((img, index) => (
                            <div className='companyNameCard'>
                                <img src={img} alt={'img' + index} />
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        </>
    )
}
