import React, { useState } from 'react'
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import './HelpSupport.css'
import { IoIosSearch } from 'react-icons/io';
import emailIcon from '../../../assets/mage_email.png'
import callIcon from '../../../assets/callIcon.png'
import { IoCallOutline } from 'react-icons/io5';

export default function HelpSupport() {

  const faqCategories = [
    { name: 'Account Management', key: 'accountManagement' },
    { name: 'Job Postings', key: 'jobPostings' },
    { name: 'Applications', key: 'applications' },
    { name: 'Subscription & Payment', key: 'subscriptionAndPayment' },
    { name: 'Technical Support', key: 'technicalSupport' },
    { name: 'General Queries', key: 'generalQueries' }
  ];

  const allFaqsCollection = {
    accountManagement: [
      {
        question: "How do I create an account?",
        answer: "Click on the 'Sign Up' button and follow the steps to create your account."
      },
      {
        question: "How can I update my account details?",
        answer: "Go to the 'Account Settings' page and edit the fields you want to update."
      },
      {
        question: "How do I reset my account password?",
        answer: "Use the 'Forgot Password' link to receive a reset email."
      },
      {
        question: "Can I temporarily deactivate my account?",
        answer: "Yes, you can deactivate your account from the 'Settings' page and reactivate it later."
      },
      {
        question: "How do I manage user roles in my account?",
        answer: "Navigate to the 'Roles & Permissions' section to manage user roles."
      }
    ],
    jobPostings: [
      {
        question: "How do I post a new job?",
        answer: "Click on 'Post a Job', fill in the job details, and submit."
      },
      {
        question: "Can I edit a job posting after publishing?",
        answer: "Yes, you can edit a job posting by navigating to 'My Jobs' and selecting the job."
      },
      {
        question: "How do I close a job posting?",
        answer: "Go to the job posting and select 'Close Job' to deactivate it."
      },
      {
        question: "Can I duplicate an existing job posting?",
        answer: "Yes, use the 'Duplicate Job' option to quickly create a similar posting."
      },
      {
        question: "How can I track the performance of my job postings?",
        answer: "Visit the 'Job Analytics' section to view metrics like views and applications."
      }
    ],
    applications: [
      {
        question: "How do I view job applications?",
        answer: "Go to the 'Applications' tab under your job posting to see all submissions."
      },
      {
        question: "Can I filter applications by status?",
        answer: "Yes, use the filters to sort applications by 'Pending', 'Reviewed', or 'Rejected'."
      },
      {
        question: "How do I contact an applicant?",
        answer: "Click on the applicant's profile and use the 'Contact' button."
      },
      {
        question: "Can I export application data?",
        answer: "Yes, you can export application data to a CSV file from the 'Applications' section."
      },
      {
        question: "How do I set up auto-responses for applicants?",
        answer: "Use the 'Auto-Reply' feature under 'Settings' to configure responses."
      }
    ],
    subscriptionAndPayment: [
      {
        question: "What subscription plans are available?",
        answer: "We offer monthly, quarterly, and annual plans with different features."
      },
      {
        question: "How do I update my payment method?",
        answer: "Go to 'Billing' under 'Account Settings' and add a new payment method."
      },
      {
        question: "Can I get a refund for my subscription?",
        answer: "Refunds are available within 14 days of purchase for eligible plans."
      },
      {
        question: "How do I view my billing history?",
        answer: "Navigate to 'Billing History' in the 'Account Settings' section."
      },
      {
        question: "What payment methods do you accept?",
        answer: "We accept credit cards, PayPal, and bank transfers."
      }
    ],
    technicalSupport: [
      {
        question: "How do I report a technical issue?",
        answer: "Go to the 'Support' page and submit a ticket with details of the issue."
      },
      {
        question: "Can I get 24/7 technical support?",
        answer: "Yes, our premium plan includes 24/7 technical support."
      },
      {
        question: "How do I reset my account if it's locked?",
        answer: "Contact technical support to unlock your account."
      },
      {
        question: "How do I check the system status?",
        answer: "Visit the 'System Status' page to view real-time service updates."
      },
      {
        question: "What should I do if the website is down?",
        answer: "Check the 'System Status' page first, then contact support if necessary."
      }
    ],
    generalQueries: [
      {
        question: "Where can I find the company terms and policies?",
        answer: "You can find our terms and policies in the 'About' section of the website."
      },
      {
        question: "How do I update my contact information?",
        answer: "Go to 'Profile Settings' and update your contact details."
      },
      {
        question: "Can I request a demo of your product?",
        answer: "Yes, you can request a demo through the 'Contact Us' page."
      },
      {
        question: "What is the response time for customer support?",
        answer: "Our average response time is within 24 hours on business days."
      },
      {
        question: "How do I provide feedback about the platform?",
        answer: "Use the 'Feedback' form in the 'Help Center' to share your thoughts."
      }
    ]
  };

  const [inputSearch, setInputSearch] = useState('')
  const [activeCatTag, setActiveCatTag] = useState(faqCategories[0] || {})
  const [filteredFaqs, setFilteredFaqs] = useState(allFaqsCollection);

  const handleSearchSumbit = (e) => {
    e.preventDefault()
    console.log(inputSearch)
  }

  const handleClick = (activetagItem) => {
    setActiveCatTag(activetagItem)
  }

  const handleSearchChange = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    setInputSearch(searchQuery);

    // Filter FAQs
    const newFilteredFaqs = Object.entries(allFaqsCollection).reduce((acc, [category, faqs]) => {
      const filtered = faqs.filter(faq => faq.question.toLowerCase().includes(searchQuery));
      if (filtered.length > 0) {
        acc[category] = filtered;
      }
      return acc;
    }, {});

    setFilteredFaqs(newFilteredFaqs);
  };

  console.log('filteredFaqs',filteredFaqs)

  return (
    <>
      <h1 className='help_support_title'>Help & Support</h1>
      <div className='main_support_container'>
        <div className="helpNsupport_main d-flex gap-4">
          <div className="sub_navItems">
            <form onSubmit={handleSearchSumbit} className='mb-4'>
              <div className='seachInput d-flex align-items-center'>
                <IoIosSearch />
                <input
                  type="text"
                  placeholder='Search your query'
                  value={inputSearch}
                  onChange={(e) =>{
                      handleSearchChange(e)
                     setInputSearch(e.target.value)}}
                />
              </div>
            </form>
            <div className="categoryTag d-flex flex-column gap-3">
              {
                faqCategories?.map((items, idx) => (
                  <p onClick={() => handleClick(items)}
                    className={`m-0 catgory_items ${activeCatTag?.name === items?.name && 'active-faq-item'}`}
                    key={idx}>
                    {items?.name}
                  </p>
                ))
              }
            </div>
          </div>
          <div className="accordian_faqs">
            <Accordion defaultActiveKey="0">
              {
                (allFaqsCollection[activeCatTag?.key])?.map((data, idx) => (
                  <Accordion.Item eventKey={String(idx)} key={idx} className='mb-3'>
                    <Accordion.Header>{data?.question}</Accordion.Header>
                    <Accordion.Body className='pt-0'>{data?.answer}</Accordion.Body>
                  </Accordion.Item>
                ))
              }
            </Accordion>
          </div>
        </div >
        <div className="needSupport d-flex flex-md-row flex-column align-items-start justify-content-center gap-4 px-3 py-3 mt-4">
          <div>
            <h1>Still Need a Support?</h1>
            <p>We’re here to help! If you have any questions, concerns, or need support, feel free to reach out to us.</p>
          </div>
          <div>
            <h1>Contact Us</h1>
            <p><img className='emailIcon' src={emailIcon} alt="email icon" /> kaamvala@support.com</p>
            <p><img className='callIcon' src={callIcon} alt="call icon" /> +91 767494939455</p>
          </div>
        </div>
      </div>
    </>
  )
}
