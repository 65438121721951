import React, { useContext, useEffect, useState } from 'react'
import { RiEditFill } from "react-icons/ri";
import { FaPlus } from "react-icons/fa6";
import { URLS } from '../../../Utils/App_urls';
import axios from 'axios';
import UserContext from '../../component/ContextApi/UserContext';
import ProfileModalBox from '../../component/EditProfilePopUps/ProfileModalBox';

export default function ProfileInfo() {
    const { editProfilePopUPToggle, setEditProfilePopUpToggle, profilModalEventType, setProfileModalEventType, profileModalData, setProfileModalData, needToReload, setNeedToReload } = useContext(UserContext)
    const [userDetails, setUserDetails] = useState({})
    const dummySkill = ['C++', 'marketing', 'Digital Marketing', 'Google Analytics', 'Core Jave']
    const dummyDepartment = ['Computer Science', 'Information technology', 'BBA'];
    const dummyLangauge = [
        {
            language: 'Hindi',
            proficency: 'Advance'
        },
        {
            language: 'English',
            proficency: 'Intermediate'
        },
        {
            language: 'Urdu',
            proficency: 'Basic'
        },
    ]
    
    const getUserProfile = () => {
        axios.get(`${URLS.get_userProfile}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('userToken')}`
            }
        })
        .then((res) => {
                setUserDetails(res?.data?.data)
            })
            .catch((err) => { console.log(err) })
            setNeedToReload(false)
        }

    const convertDate = (dateString = '') => {
        const [year, month, day] = dateString?.split('-');
        return `${day}/${month}/${year}`;
    }

    const handleOpenEditModal = (type, eventType = 'add') => {
        type === 'education' && setEditProfilePopUpToggle({ ...editProfilePopUPToggle, educationModal: true, mainModalToggle: true })
        type === 'experience' && setEditProfilePopUpToggle({ ...editProfilePopUPToggle, experienceModal: true, mainModalToggle: true })
        type === 'skills' && setEditProfilePopUpToggle({ ...editProfilePopUPToggle, skillsModal: true, mainModalToggle: true })
        type === 'language' && setEditProfilePopUpToggle({ ...editProfilePopUPToggle, languageModal: true, mainModalToggle: true })
        type === 'preferences' && setEditProfilePopUpToggle({ ...editProfilePopUPToggle, preferenceModal: true, mainModalToggle: true })
        type === 'department' && setEditProfilePopUpToggle({ ...editProfilePopUPToggle, departmentModal: true, mainModalToggle: true })
        setProfileModalData(userDetails);
        setProfileModalEventType(eventType);

    }

    const handleEditLanguage = (type, eventType) => {
        setProfileModalEventType(eventType)
        setProfileModalData(userDetails)
        type === 'language' && setEditProfilePopUpToggle({ ...editProfilePopUPToggle, languageModal: true, mainModalToggle: true })

    }

    useEffect(() => {
        getUserProfile();
    }, [needToReload])

    return (
        <>
            {!editProfilePopUPToggle?.basicInfoModal && <ProfileModalBox />}
            <div className="profileInfo_main d-flex flex-column gap-3">
                {/* Education */}
                <div className="general-info-card">
                    <div><h1>Education</h1><span onClick={() => handleOpenEditModal('education')}><RiEditFill fontSize={18} />Edit</span></div>
                    <div className='card-data-section mt-2'>
                        <p>Highest Education</p>
                        <p>{userDetails?.education || 'N/A'}</p>
                    </div>
                </div>
                {/* Experience */}
                <div className="general-info-card">
                    <div><h1>Experience</h1><span onClick={() => handleOpenEditModal('experience')}><RiEditFill fontSize={18} />Edit</span></div>
                    <div className='d-flex align-items-center justify-content-between flex-wrap'>
                        <div className='experience-card-data mt-2'>
                            <p className='mb-1'>{userDetails?.JobTitle}</p>
                            {
                                userDetails?.companyName?.map((exp, idx) => (
                                    <div key={idx}>
                                        <p className='m-0'>{exp?.companyName || 'N/A'}</p>
                                        <p className='mb-1'>{convertDate(exp?.startDate)}{' '}-{' '}{convertDate(exp?.endDate)} | {userDetails?.employeType}</p>
                                    </div>

                                ))
                            }
                        </div>
                        <span className='edit-pen' onClick={() => handleOpenEditModal('experience', 'edit')}><RiEditFill fontSize={18} /></span>
                    </div>
                </div>
                {/* Skill */}
                {/* <div className="general-info-card">
                    <div><h1>Skills</h1><span onClick={() => handleOpenEditModal('skills')}><RiEditFill fontSize={18} />Edit</span></div>
                    <div className='d-flex align-items-center justify-content-start gap-2 flex-wrap mt-3'>
                        {
                            userDetails?.skills?.map((data, index) => (
                                <div className='skill-sticker' key={index}>{data}</div>
                            ))
                        }
                    </div>
                </div> */}

                {/* Languages */}
                <div className="general-info-card">
                    <div><h1>Languages</h1><span onClick={() => handleEditLanguage('language', 'add')}><FaPlus fontSize={18} />Add</span></div>
                    <div className='langiage-data-section d-flex flex-column gap-2 mt-2'>
                        {
                            userDetails?.languages?.map((lag, index) => (
                                <p key={index}>
                                    <span>{lag?.languageFromMenu} |  {lag?.proficiency}</span>
                                    <span onClick={() => handleEditLanguage('language', 'edit')} className='edit-pen'><RiEditFill fontSize={18} /></span>
                                </p>
                            ))
                        }
                    </div>
                </div>
                {/* {Preferences} */}
                <div className="general-info-card">
                    <div><h1>Preferences</h1><span onClick={() => handleOpenEditModal('preferences')}><RiEditFill fontSize={16} />Edit</span></div>
                    <div className='card-data-section pref-xyz mt-2'>
                        <p>Preferred Workplace</p>
                        {
                            userDetails?.workPlace?.map((items, index) => (
                                <p className='m-0' key={index}>{items}</p>
                            ))
                        }
                    </div>
                    <div className='card-data-section pref-xyz mt-2'>
                        <p>Preferred Shift</p>
                        {
                            userDetails?.shift?.map((items, index) => (
                                <p className='m-0' key={index}>{items}</p>
                            ))
                        }
                    </div>
                </div>

                {/* Department */}
                <div className="general-info-card">
                    <div><h1>Department</h1><span onClick={() => handleOpenEditModal('department')}><RiEditFill fontSize={18} />Edit</span></div>
                    <div className='d-flex align-items-center justify-content-start gap-3 flex-wrap mt-3'>
                        {
                            userDetails?.department?.map((data, index) => (
                                <div className='skill-sticker' key={index}>{data}</div>
                            ))
                        }
                    </div>
                </div>

                {/* City */}
                {/* <div className="general-info-card">
                    <div><h1>City</h1><span><RiEditFill fontSize={18} />Edit</span></div>
                    <div className='d-flex align-items-center justify-content-start gap-3 flex-wrap mt-3'>
                        {
                            userDetails?.city?.map((data, index) => (
                                <div className='skill-sticker' key={index}>{data}</div>
                            ))
                        }
                    </div>
                </div> */}
            </div>
        </>
    )
}
