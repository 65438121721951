// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.job_post_main_index{
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
}

.job_container{
    background: #FFFFFF;
    box-shadow: 0px 0px 16px 0px #0000000D;
    padding: 40px 48px;
    border-radius: 12px;
}

/* Empty Job */
.main_empty{
    background: #FFFFFF;
    box-shadow: 0px 0px 16px 0px #0000000D;
    padding: 40px 48px;
    border-radius: 12px;
    width: 100%;
    height: 84vh;
}
.main_empty > h1{
    color: #000000;
    font-weight: 700;
    font-size: 24px;
}
.empty-container > p{
    color: #979797;
    font-weight: 400;
    font-size: 20px;
}
.empty-container > button{
    width: 40%;
}

.empty-container > img{
    width: 400px;
    height: -moz-fit-content;
    height: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/Company/Pages/JobPost/Index.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,wBAAmB;IAAnB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,sCAAsC;IACtC,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA,cAAc;AACd;IACI,mBAAmB;IACnB,sCAAsC;IACtC,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;IACX,YAAY;AAChB;AACA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,UAAU;AACd;;AAEA;IACI,YAAY;IACZ,wBAAmB;IAAnB,mBAAmB;AACvB","sourcesContent":[".job_post_main_index{\n    width: 100%;\n    height: fit-content;\n}\n\n.job_container{\n    background: #FFFFFF;\n    box-shadow: 0px 0px 16px 0px #0000000D;\n    padding: 40px 48px;\n    border-radius: 12px;\n}\n\n/* Empty Job */\n.main_empty{\n    background: #FFFFFF;\n    box-shadow: 0px 0px 16px 0px #0000000D;\n    padding: 40px 48px;\n    border-radius: 12px;\n    width: 100%;\n    height: 84vh;\n}\n.main_empty > h1{\n    color: #000000;\n    font-weight: 700;\n    font-size: 24px;\n}\n.empty-container > p{\n    color: #979797;\n    font-weight: 400;\n    font-size: 20px;\n}\n.empty-container > button{\n    width: 40%;\n}\n\n.empty-container > img{\n    width: 400px;\n    height: fit-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
