import React, { useEffect, useState } from 'react'
import CompanyContext from './CompanyContext'
import axios from 'axios';
import { COMPANY_URLS } from '../../../Utils/Company_App_Urls';

export default function CompanyContextProvider({ children }) {

  const [text, setTest] = useState('');
  const [loader, setLoader] = useState(false);
  const [CompanyProfileComaplete, setCompanyProfileComplete] = useState(false)
  const [toggleSideBar, setToggleSideBar] = useState(true)
  const [isCompanyProfileComplate, setIsCompanyProfileComplate] = useState(false);
  const [isJobCreated, setIsJobCreated] = useState(true);
  const [needTorender, setNeedTorender] = useState(false);
  const [profileLogo, setProfileLogo] = useState('')
  const [closeJobModalOpen, setCloseJobModalOpen] = useState({ status: false, data: {} });
  const [appliedCandidateModalOpen, setAppliedCandidateModalOpen] = useState(false)
  const [isLogin, setIsLogin] = useState(false);
  const [appliedCandidateDetails, setAppliedCandidateDetails] =  useState([])
  const [previewData, setPreviewData] = useState({})
  const [showCandidateProfile, setShowCandidateProfile] = useState(false)
  

  const getCompanyDetails = () => {
    axios(COMPANY_URLS.companyDetail, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then((res) => {
        setIsLogin(true);
        if (res?.data?.data?.profileComplete) {
          localStorage.setItem('isprofileComplete', "true");
        }
        setIsCompanyProfileComplate(res?.data?.data?.profileComplete)
      })
      .catch((err) => {
        setIsLogin(false);
      })
  }

  const getCompanyProfileDetails = () => {
    axios.get(`${COMPANY_URLS.get_profile}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then((res) => {
        setProfileLogo(res?.data?.data?.companylogo);
      })
      .catch((err) => {
      })
  }

  useEffect(() => {
    getCompanyDetails()
    getCompanyProfileDetails()
    setIsCompanyProfileComplate(localStorage.getItem('isprofileComplete') === 'true')
  }, [])

  return (
    <CompanyContext.Provider value={{
      text, setTest,
      loader, setLoader,
      CompanyProfileComaplete, setCompanyProfileComplete,
      toggleSideBar, setToggleSideBar,
      isCompanyProfileComplate, setIsCompanyProfileComplate,
      isJobCreated, setIsJobCreated,
      getCompanyDetails,
      needTorender, setNeedTorender,
      closeJobModalOpen, setCloseJobModalOpen,
      profileLogo, setProfileLogo,
      appliedCandidateModalOpen, setAppliedCandidateModalOpen,
      appliedCandidateDetails, setAppliedCandidateDetails,
      isLogin,
      previewData, setPreviewData,
      showCandidateProfile, setShowCandidateProfile,
    }}>
      {children}
    </CompanyContext.Provider>
  )
}
