import React, { useContext, useEffect, useRef, useState } from 'react';
import '../EditBasicInfo/EditBasicInfo.css';
import './EditEducation.css';
import { IoCloseOutline } from 'react-icons/io5';
import UserContext from '../../ContextApi/UserContext';
import { EventButton } from '../../ReUseComps/Button';
import axios from 'axios';
import { URLS } from '../../../../Utils/App_urls';
import { errorPopup, successPopup } from '../../../../Utils/ToasterMessage';
import { Spinner } from 'react-bootstrap';

export default function EditEducation() {
    const buttonsRef = useRef([]);
    const { editProfilePopUPToggle, setEditProfilePopUpToggle, setLoaderOn, setNeedToReload, profileModalData, setProfileModalData } = useContext(UserContext);
    const qualificationArr = ['UnEducated', '10th or below 10th', '12th Pass', 'Graduation', 'ITI', 'Diploma', 'Polytechnic'];
    const [education, setEducation] = useState('');
    const [loader, setLoader] = useState(false)
    const [error, setError] = useState({
        errorStatus: true,
        errorMessage: ''
    });

    const handleClosePopUPModal = () => {
        setEditProfilePopUpToggle({ ...editProfilePopUPToggle, educationModal: false, mainModalToggle: false });
    };

    const handleEducation = (data) => {
        setEducation(data);
        setError({ ...error, errorStatus: false, errorMessage: '' });
    };


    const updateEducation = async () => {
        delete profileModalData['_id']
        delete profileModalData['userId']
        delete profileModalData['auth0UserId']
        delete profileModalData['createdAt']
        delete profileModalData['updatedAt']
        delete profileModalData['__v']
        delete profileModalData['education']

        setLoader(true)
        await axios.patch(`${URLS.createUserProfile}`, { ...profileModalData, 'education': education }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('userToken')}`
            }
        })
            .then((res) => {
                setNeedToReload(true)
                successPopup(res?.data?.message);
                handleClosePopUPModal();
                setLoader(false)
            })
            .catch((err) => {
                errorPopup(err?.response?.data?.message);
                handleClosePopUPModal();
                setLoader(false)
            })
    }

    const handleFormSubmit = () => {
        if (!education) {
            setError({ ...error, errorStatus: true, errorMessage: 'Education is required.' });
        } else {
            console.log('Final Submit', education);
            updateEducation()
        }
    };

    useEffect(() => {
        setEducation(profileModalData?.education);
    }, [profileModalData]);

    useEffect(() => {
        if (education !== '') {
            const index = qualificationArr.indexOf(education);
            if (index !== -1) {
                buttonsRef.current[index].focus();
            }
        }
    }, [education, qualificationArr]);

    return (
        <>
            <div className="education_main_modal d-flex flex-column">
                <div className="ModalTitle mb-3">
                    <h1>Education</h1>
                    <span onClick={handleClosePopUPModal}><IoCloseOutline fontSize={26} /></span>
                </div>

                <div className="education-option d-flex flex-column gap-3">
                    {qualificationArr.map((data, index) => (
                        <button
                            key={index}
                            onClick={() => handleEducation(data)}
                            className={`edu_certification ${education === data ? 'activeBg' : ''}`}
                            ref={el => buttonsRef.current[index] = el}
                        >
                            {data}
                        </button>
                    ))}
                </div>
                {error.errorMessage && <p className='text-danger m-0 mt-1'>{error.errorMessage}</p>}
                <EventButton className='modal-update-btn mt-3 d-flex align-items-center justify-content-center' onClick={handleFormSubmit}>
                    Update
                    {loader && <Spinner className='m-0 ms-2' style={{ width: '18px', height: '18px', borderWidth: '3px' }} animation="border" />}
                </EventButton>
            </div>
        </>
    );
}
