import React, { useState } from 'react'
import { TfiEmail } from "react-icons/tfi";
import { RiLockPasswordFill } from "react-icons/ri";
import { LuUserCog } from "react-icons/lu";
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { IoMdEyeOff, IoMdEye } from "react-icons/io";
import { URLS } from '../../../Utils/App_urls';
import { errorPopup, successPopup } from '../../../Utils/ToasterMessage';
import kaamwalaLogo from '../../../assets/KAAMVALALogo.png'
import registerPageImage from '../../../assets/registerPageImage.png'
import Loader from '../Loader/Loader';
import userRole from '../../../assets/userRole.png'
import padlock from '../../../assets/Padlock.png'
import './Auth.css'
import { Dropdown } from 'react-bootstrap';

export default function Registered() {

    const navigate = useNavigate();
    const [showPass, setShowPass] = useState(false)
    const [showConfPass, setShowConfPass] = useState(false)
    const [loader, setLoader] = useState(false)
    const [userAuthDetails, setUserAuthDetails] = useState({ role: '', email: '', password: '', confirmPassword: '' })
    const [error, setError] = useState({
        errorStatus: true,
        roleError: '',
        emailError: '',
        passwordError: '',
        confirmPasswordError: ''
    })

    const registerUser = async () => {
        setLoader(true);
        try {
            await axios.post(`${URLS.register}`, {
                email: userAuthDetails?.email,
                password: userAuthDetails?.password,
            })
                .then(res => {
                    localStorage.setItem('auth0UserId', res?.data?.data?.user?.auth0UserId)
                    successPopup(res?.data?.message)
                    setLoader(false)

                    setTimeout(() => {
                        navigate('/user/login')
                    }, 1000)
                })
                .catch(err => {
                    errorPopup(err?.response?.data?.message);
                    setLoader(false)
                })
        } catch (error) {
            setLoader(false)
        }
    }

    const handleRoleChange = (e) => {
        setUserAuthDetails({ ...userAuthDetails, role: e.target.value });
        setError({ ...error, errorStatus: false, roleError: '' })
    }
    const handleEmailChange = (e) => {
        setUserAuthDetails({ ...userAuthDetails, email: e.target.value });
        setError({ ...error, errorStatus: false, emailError: '' })
    }
    const handlePasswordChange = (e) => {
        setUserAuthDetails({ ...userAuthDetails, password: e.target.value });
        setError({ ...error, errorStatus: false, passwordError: '' })
    }

    const handleConfirmPassword = (e) => {
        setUserAuthDetails({ ...userAuthDetails, confirmPassword: e.target.value });
        setError({ ...error, errorStatus: false, confirmPasswordError: '' })
    }

    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$/;
        return passwordRegex.test(password);
    };

    const handleSubmitWithValidation = (e) => {
        e.preventDefault();
        if (!userAuthDetails.email || userAuthDetails.email === '') {
            setError({ ...error, errorStatus: false, emailError: 'Email is required.' })
        } else if (!userAuthDetails.password || userAuthDetails.password === '') {
            setError({ ...error, errorStatus: false, passwordError: 'Password is required.' })
        } else if (!userAuthDetails.confirmPassword || userAuthDetails.confirmPassword === '') {
            setError({ ...error, errorStatus: false, confirmPasswordError: 'Confirm Password is required.' })
        } else if (!validatePassword(userAuthDetails?.password)) {
            setError({ ...error, errorStatus: false, passwordError: 'Password must be at least 8 characters long, contain one uppercase letter, one lowercase letter, one number, and one special character.' })
        }
        // else if (!validatePassword(userAuthDetails?.confirmPassword)) {
        //     setError({ ...error, errorStatus: false, confirmPasswordError:'Password must be at least 8 characters long, contain one uppercase letter, one lowercase letter, one number, and one special character.'})
        // }
        else if (userAuthDetails.password !== userAuthDetails.confirmPassword) {
            setError({ ...error, errorStatus: false, confirmPasswordError: 'Password and Confirm password must be match.' })
        } else {
            //API Call
            registerUser()
        }
    }

    // console.log('userAuthDetails', userAuthDetails)
    // console.log('error', validatePassword('test@123'))

    return (
        <>
            {loader && <Loader />}
            <div className="main_login">
                <Dropdown className='switch-pannel-button'>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        Switch to company
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item className='py-0'>
                            <Link className='urlAnckro py-0' to="/company/register">Switch to company</Link>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <div className='left-color-box'>
                </div>
                <div className='right-image-box'>
                    {/* <div className='registerd-image-size'> <img src={registerPageImage} alt="registerPageImage" /> </div> */}
                </div>
                <div className='auth-box-adjust-signUp d-flex justify-ceontent-between align-items-center gap-5'>
                    <div className="login_box">
                        <img className='mb-2' src={kaamwalaLogo} alt="kaamwalaLogo" />
                        <h1 className='AuthTitle'>User Sign Up</h1>
                        <form onSubmit={handleSubmitWithValidation} className='d-flex flex-column gap-3'>
                            {/* <div>
                                <label className='d-block inputName' htmlFor="role">
                                    <img className='role-image' src={userRole} alt="userRole" />
                                    <span className='mx-1 align-middle'>Role</span>
                                </label>
                                <Form.Select
                                    id='role'
                                    name='role'
                                    className='roleOption'
                                    value={userAuthDetails.role}
                                    onChange={handleRoleChange}
                                    aria-label="Default select example"
                                >
                                    <option disabled selected value={""}>Select Role</option>
                                    <option value="company">Company</option>
                                    <option value="user">User</option>
                                    <option value="other">Other</option>
                                </Form.Select>
                                {error.roleError && <p className='text-danger'>{error.roleError}</p>}
                            </div> */}
                            <div>
                                <label className='d-block inputName' htmlFor="email">
                                    <TfiEmail fontSize={18} />
                                    <span className='mx-1'>Email</span>
                                </label>
                                <input
                                    className='form-input'
                                    id='email'
                                    type="email"
                                    placeholder='abc@gmail.com'
                                    onChange={handleEmailChange}
                                    value={userAuthDetails.email}
                                    isInvalid={error.emailError}
                                />
                                {error.emailError && <p className='text-danger'>{error.emailError}</p>}
                            </div>
                            <div>
                                <label className='d-block inputName' htmlFor="password">
                                    {/* <RiLockPasswordFill fontSize={18} /> */}
                                    <img className='authPassIcon' src={padlock} alt="padlock" />
                                    <span className='mx-1'>Password</span>
                                </label>
                                <div className='form-input p-0 pe-2'>
                                    <input
                                        className='form-inputPass'
                                        autoComplete="current-password"
                                        type={showPass ? 'text' : 'password'}
                                        id='password'
                                        placeholder='Enter Password'
                                        onChange={handlePasswordChange}
                                        value={userAuthDetails.password}
                                    />
                                    <span className='eye-hover' onClick={() => setShowPass(prev => !prev)}> {showPass ? <IoMdEyeOff fontSize={20} /> : <IoMdEye fontSize={20} />}</span>
                                </div>
                                {error.passwordError && <p className='text-danger'>{error.passwordError}</p>}
                            </div>
                            <div>
                                <label className='d-block inputName' htmlFor="password">
                                    {/* <RiLockPasswordFill fontSize={18} /> */}
                                    <img className='authPassIcon' src={padlock} alt="padlock" />
                                    <span className='mx-1'>Confirm Password</span>
                                </label>
                                <div className='form-input p-0 pe-2'>
                                    <input
                                        className='form-inputPass'
                                        autoComplete='current-password'
                                        type={showConfPass ? 'text' : 'password'}
                                        id='confirmPassword'
                                        placeholder='Enter Password'
                                        value={userAuthDetails.confirmPassword}
                                        onChange={handleConfirmPassword}
                                    />
                                    <span className='eye-hover' onClick={() => setShowConfPass(prev => !prev)}> {showConfPass ? <IoMdEyeOff fontSize={20} /> : <IoMdEye fontSize={20} />}</span>
                                </div>
                                {error.confirmPasswordError && <p className='text-danger'>{error.confirmPasswordError}</p>}
                            </div>
                            <button type='submit' className='authbtn'>Sign Up</button>
                            <p className='dntAccSignUp'>Already have a account? <span className='' onClick={() => navigate('/user/login')}>Log In</span></p>
                        </form>
                    </div>
                    <div className='registerd-image-size d-flex align-items-center'> <img src={registerPageImage} className='' alt="registerPageImage" /> </div>
                </div>
            </div>
        </>
    )
}
