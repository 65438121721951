import React from 'react'
import InterviewIcon from '../../../assets/interview.png'
import CitiesIcon from '../../../assets/cities.png'
import qualified from '../../../assets/Quilified.png'

export default function Results() {

    const imagesArr = [qualified, InterviewIcon, CitiesIcon];
    const cardData = [
        {
            image: qualified,
            number: '2Cr+',
            Desp: 'Qualified Candidates'
        },
        {
            image: InterviewIcon,
            number: '34L+',
            Desp: 'Interviews per month'
        },
        {
            image: CitiesIcon,
            number: '750++',
            Desp: 'Cities in India'
        },

    ]
    return (
        <>
            <div className="result_section py-3">
                <div className="container resultCardBox">
                    {
                        cardData?.map((data, index) => (
                            <div className="resultsCard">
                                <img src={data.image} alt="qualified" />
                                <h1>{data.number}</h1>
                                <p>{data.Desp}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}
