import React, { useContext, useState } from 'react'
import kaamwalaLogo from '../../../assets/KAAMVALALogo.png'
import './Sidebar.css'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { RiSearch2Fill } from "react-icons/ri";
import { IoBag } from "react-icons/io5";
import { BsChatRightTextFill } from "react-icons/bs";
import { IoMdSettings } from "react-icons/io";
import { FcFeedback } from "react-icons/fc";
import { MdOutlineClose } from "react-icons/md";
import { TbLogout2 } from "react-icons/tb";
import Loader from '../Loader/Loader'
import axios from 'axios'
import { URLS } from '../../../Utils/App_urls'
import UserContext from '../ContextApi/UserContext'
import { errorPopup, successPopup } from '../../../Utils/ToasterMessage'


export default function Sidebar() {
    const { openDrawer, setOpenDrawer, userProfileComaplete, setUserprofileComplete } = useContext(UserContext)
    const navigate = useNavigate()
    const location = useLocation()
    const [loader, setLoader] = useState(false)

    const [navIndexNum, setNavIndexNum] = useState(0)

    const navItems = [
        {
            image: <RiSearch2Fill fontSize={26} />,
            name: 'Search',
            navUrl: '/search-job'
        },
        {
            image: <IoBag fontSize={26} />,
            name: 'Applied Jobs',
            navUrl: '/applied-job'
        },
        {
            image: <BsChatRightTextFill fontSize={26} />,
            name: 'Chat',
            navUrl: '/chat'
        },
        {
            image: <FcFeedback fontSize={26} />,
            name: 'Feed',
            navUrl: '/feed'
        },
        {
            image: <IoMdSettings fontSize={26} />,
            name: 'Setting',
            navUrl: '/setting'
        },
    ]

    const handleactiveNavItems = (index) => {
        setNavIndexNum(index)
        setOpenDrawer(false)
    }

    const handleLogoClick = () => {
        setOpenDrawer(false);
        navigate('/home');
    }

    const handleLogOut = () => {
        setLoader(true)
        axios.post(`${URLS.logout}`, {
            "refreshToken": localStorage.getItem('acessToken')
        })
            .then(res => {
                console.log('logoutRes', res)
                successPopup(res?.data?.message)
                localStorage.removeItem('userToken')
                localStorage.removeItem('isprofileUserComplete')
                setLoader(false)

                setTimeout(() => {
                    navigate('/login')
                }, 1000)
            })
            .catch((err) => {
                console.log(err)
                errorPopup(err?.response?.data?.message);
                setLoader(false)
            })
    }

    return (
        <>
            {loader && <Loader />}
            <div className={`sidebar_main_parent  ${openDrawer && 'd-block'}`}>
                <div className={`side-parent`}>
                    <div className={`sidebar_main`}>
                        <div className='d-flex flex-column'>
                            <div className={`mt-3 d-flex justify-content-end w-100 px-1 closeSideBar`}>
                                <MdOutlineClose onClick={() => setOpenDrawer(false)} fontSize={25} />
                            </div>
                            <img onClick={handleLogoClick} src={kaamwalaLogo} alt="kaamwalaLogo" />
                        </div>
                        <div className='nav-items'>
                            <ul>
                                {
                                    navItems.map((data, index) => (
                                        <li onClick={() => handleactiveNavItems(index)} key={index} className={`${!userProfileComaplete && 'cursor-pointer-none'}`}>
                                            <div className={`border-box-nav ${data.navUrl === location.pathname && 'border-box-nav-bg-clr'}`}></div>
                                            <Link className={`${data.navUrl === location.pathname && 'active-color'} ${!userProfileComaplete && 'disabled-anchror'}`} to={data.navUrl}>
                                                {data?.image}
                                                <span>{data?.name}</span>
                                            </Link>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div className='logoutClass'>
                        <span onClick={handleLogOut}>
                            <TbLogout2 fontSize={25} />
                            <span>Logout</span>
                        </span>

                    </div>
                </div>
            </div>
        </>
    )
}
