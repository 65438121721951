import axios from "axios"
import { COMPANY_URLS } from "../../../Utils/Company_App_Urls"

const downloadResume=(url='https://devkaam.s3.ap-south-1.amazonaws.com/Pdf.pdf-1733233622819-991628175')=>{
// const downloadResume=(url='https%3A%2F%2Fdevkaam.s3.ap-south-1.amazonaws.com%2FPdf.pdf-1733233622819-991628175')=>{
    axios.get(`${COMPANY_URLS.download_resume}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        params:{
            file:url
        }
    })
    .then((res=>{
        console.log('resumeDownload', res)
    }))
    .catch((err)=>console.log(err))
}

export default downloadResume;