// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.enlishSpeakingLevel {
  display: flex;
  flex-direction: column;
  /* gap: 8px; */
}

.enlishSpeakingLevel > div > input {
  cursor: pointer;
}

.enlishSpeakingLevel > h2,
.moreLanguageOptionBox > h2 {
  font-size: 16px;
  font-weight: 400;
  color: #1e1e1e;
}
.enlishSpeakingLevel > div > input {
  width: 18px;
  height: 18px;
  /* border: 1.5px solid #000000;
    border-radius: unset !important; */
}
.enlishSpeakingLevel > div > input:checked {
  background-color: #fff;
  border-color: #000000;
}
`, "",{"version":3,"sources":["webpack://./src/User/component/EditProfilePopUps/EditPreferences/EditPreferences.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;;EAEE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,WAAW;EACX,YAAY;EACZ;sCACoC;AACtC;AACA;EACE,sBAAsB;EACtB,qBAAqB;AACvB","sourcesContent":[".enlishSpeakingLevel {\n  display: flex;\n  flex-direction: column;\n  /* gap: 8px; */\n}\n\n.enlishSpeakingLevel > div > input {\n  cursor: pointer;\n}\n\n.enlishSpeakingLevel > h2,\n.moreLanguageOptionBox > h2 {\n  font-size: 16px;\n  font-weight: 400;\n  color: #1e1e1e;\n}\n.enlishSpeakingLevel > div > input {\n  width: 18px;\n  height: 18px;\n  /* border: 1.5px solid #000000;\n    border-radius: unset !important; */\n}\n.enlishSpeakingLevel > div > input:checked {\n  background-color: #fff;\n  border-color: #000000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
