import React from 'react'
import JobOffer from '../../../assets/JobOffer.png'
import './Home.css'
import SearchCandidate from './SearchCandidate'
import Results from './Results'
import Companies from './Companies'
import ApplySteps from './ApplySteps'
import Features from './Features'
import DownloadApp from './DownloadApp'

export default function Home() {
    return (
        <div className=''>
            <div className="lading_main">
                <div className='container jobHomeTitle'>
                    <div className='landing-title'>
                        <h1>India’s Largest Job <span className='d-md-block'>Portal</span></h1>
                        <p>Kaamvala helps you hire staff in 2 days</p>
                        <div className='btn-grp-box'>
                            <button className="hireBtn">
                                Hire Now
                            </button>
                            <button className="hireBtn">
                                Get a Job
                            </button>
                        </div>
                    </div>
                    <img className='jobOfferImage' src={JobOffer} alt="JobOffer" />
                </div>
            </div>
            {/* <SearchCandidate/>
            <Results/>
            <Companies/>
            <ApplySteps/>
            <Features/>
            <DownloadApp/> */}
        </div>
    )
}
