import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../../component/ContextApi/UserContext';
import { AuthFillButton, StepperBackButton } from '../../component/ReUseComps/Button';
import preferencesImage from '../../../assets/preferenceImage.png'
import { FaAngleLeft } from 'react-icons/fa6';
import { Form } from 'react-bootstrap';
import Select from "react-select";
import './Language.css';
import './BasicInfo.css';

export default function Preferrence() {
    const { step, setStep, userForm, setUserForm } = useContext(UserContext);
    const [availability, setAvailability] = useState('')
    const [preferences, setPreferences] = useState([
        // { category: 'Employment type', selectedOptions: [] },
        { category: 'Preferred workplace', selectedOptions: [] },
        { category: 'Preferred shift', selectedOptions: [] },
    ]);

    const availabilityOptions = [
        {value:'Immediate', label:'Immediate'},
        {value:'15 Days', label:'15 Days'},
        {value:'1 Month', label:'1 Month'},
        {value:'2 Months', label:'2 Months'},
        {value:'3 Months', label:'3 Months'},
    ]

    const [error, setError] = useState({ errorMessage: '', errorStatus: true, availError: '' });

    useEffect(() => {
        console.log('userForm.xxxxxxxxxx', userForm?.preference?.length, ' ', !(userForm?.preference?.length === 0));
        if (!(userForm?.preference?.length === 0)) {
            setPreferences(userForm?.preference);
        }
        if (userForm?.availability) {
            setAvailability(userForm?.availability)
        }
    }, [userForm?.preference]);

    const handleCheckboxChange = (category, event) => {
        const { id, checked } = event.target;
        setPreferences((prevPreferences) => {
            console.log('id0>>', preferences)
            return prevPreferences.map((preference) => {
                if (preference.category === category) {
                    if (checked) {
                        if (!preference.selectedOptions.includes(id)) {
                            return {
                                ...preference,
                                selectedOptions: [...preference.selectedOptions, id],
                            };
                        }
                    } else {
                        return {
                            ...preference,
                            selectedOptions: preference.selectedOptions.filter((option) => option !== id),
                        };
                    }
                }
                return preference;
            });
        });
        setError({ errorMessage: '', errorStatus: false });
    };

    const handleChangeAvailability = (e) => {
        setAvailability(e.value)
        setError({ availError: '', errorStatus: false })
    }

    const handleSubmit = () => {
        const allSelected = preferences.every(option => option.selectedOptions.length > 0);
        if (!availability) {
            setError({ availError: 'Your availability is required.', errorStatus: true });
        }
        else if (!allSelected) {
            setError({ errorMessage: 'All categories are required.', errorStatus: true });
        } else {
            setError({ errorMessage: '', errorStatus: false });
            setUserForm((userForm) => ({
                ...userForm,
                preference: preferences,
                availability: availability
            }));
            setStep(step => step + 1);
        }
    };

    const handleBackStep = () => {
        setUserForm((userForm) => ({
            ...userForm,
            preference: preferences,
            availability: availability
        }));
        setStep(prev => prev - 1)
    }

    const isCheckOnBack = (category, options) => {
        const categoryPreference = preferences.find(item => item.category === category);
        return categoryPreference?.selectedOptions.includes(options);
    };

    useEffect(() => {

    }, [userForm?.preference])
    // console.log('preferences', preferences)
    console.log('preferencesuserForm:::', userForm)

    return (
        <>
            <div className='w-100 d-flex '>
                <div className="preferrences_main responsive_cont language_main pt-3 pt-md-5 w-50 flex-grow-1">
                    <h1 className='page-title-tag d-flex align-items-center m-0'>
                        <span onClick={() => setStep(prev => prev - 1)} className='d-flex'><FaAngleLeft /></span>
                        Preferrence
                    </h1>
                    {error.errorMessage && <p className='m-0 text-danger'>{error.errorMessage}</p>}
                    <div className="yourAvailability">
                        <Form.Label>Your Availability*</Form.Label>
                        <Select
                            options={availabilityOptions}
                            onChange={(e) => handleChangeAvailability(e)}
                            value={availabilityOptions.find(option => option.value === availability)}
                            label="Single select"
                        />
                        {error.availError && <p className='m-0 text-danger'>{error.availError}</p>}
                    </div>
                    <div className='enlishSpeakingLevel mt-3'>
                        <h2>Preferred workplace*</h2>
                        <Form.Check
                            type='checkbox'
                            label={<p className='m-0'>On-site</p>}
                            id={`On-site`}
                            checked={isCheckOnBack('Preferred workplace', 'On-site')}
                            onChange={(e) => handleCheckboxChange('Preferred workplace', e)}
                        />
                        <Form.Check
                            type='checkbox'
                            label={<p className='m-0'>Off-site</p>}
                            id={`Off-site`}
                            checked={isCheckOnBack('Preferred workplace', 'Off-site')}
                            onChange={(e) => handleCheckboxChange('Preferred workplace', e)}
                        />
                        <Form.Check
                            type='checkbox'
                            label={<p className='m-0'>Hybrid</p>}
                            id={`Hybrid`}
                            checked={isCheckOnBack('Preferred workplace', 'Hybrid')}
                            onChange={(e) => handleCheckboxChange('Preferred workplace', e)}
                        />
                    </div>
                    <div className='enlishSpeakingLevel mt-3'>
                        <h2>Preferred shift*</h2>
                        <Form.Check
                            type='checkbox'
                            label={<p className='m-0'>Day Shift</p>}
                            id={`Day Shift`}
                            checked={isCheckOnBack('Preferred shift', 'Day Shift')}
                            onChange={(e) => handleCheckboxChange('Preferred shift', e)}
                        />
                        <Form.Check
                            type='checkbox'
                            label={<p className='m-0'>Night Shift</p>}
                            id={`Night Shift`}
                            checked={isCheckOnBack('Preferred shift', 'Night Shift')}
                            onChange={(e) => handleCheckboxChange('Preferred shift', e)}
                        />
                    </div>
                    <div className="navigation-buttons d-flex gap-3 mt-3">
                        <StepperBackButton className={`${step === 0 && 'd-none'}`} onClick={handleBackStep} disabled={step === 0}>Back</StepperBackButton>
                        <AuthFillButton className='' onClick={handleSubmit} disabled={step === 7}>Next</AuthFillButton>
                    </div>
                </div>
                <div className='steper-image-container h-md-100'>
                    <img src={preferencesImage} alt="preferencesImage" />
                </div>
            </div>
        </>
    );
}
