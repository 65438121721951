import React, { useContext, useEffect, useState } from 'react'
import './PreviewJobPost.css'
import { RiEditFill } from 'react-icons/ri'
import CompanyContext from '../../Component/ContextAPI/CompanyContext'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { COMPANY_URLS } from '../../../Utils/Company_App_Urls';
import { errorPopup, successPopup } from '../../../Utils/ToasterMessage';
import { AuthFillButton, StepperBackButton } from '../../../User/component/ReUseComps/Button';

export default function PreviewJobPost() {
    const { previewData, setPreviewData, setNeedTorender } = useContext(CompanyContext);
    const navigate = useNavigate()

    const createJob=()=>{
        const data = {
            "status": "open",
            "title": previewData?.jobTitle,
            "jobSummary": previewData?.jobSummary,
            "jobDescription": previewData?.jobDescription,
            "jobDeparment": previewData?.jobDeparment,
            "deparmentType": previewData?.employmentType,
            // "salaryType": "",
            // "minSalary": 50000,
            // "maxSalary": 80000,
            // "FixedSalary": 250000,
            // "NonDisclose": "not disclose",
            "shift": previewData?.shift,
            "workPlace": previewData?.workPlace,
            "educationRequiement": previewData?.education,
            "experience": previewData?.experience,
            "minExp": "2",
            "maxExp": "4",
            "skills": previewData?.skills,
            "languages": previewData?.languages,
            "noOfOpening": previewData?.noOfOpening,
            // "age": previewData?.age || '',
            // "gender": previewData?.gender || '',
            "jobLocations": previewData?.jobLocation,
            "jobDeparment":"optional"
          }
          if (previewData?.salaryType === 'Fixed Salary') {
            data.salaryType = previewData?.salaryType;
            data.FixedSalary = previewData?.fixedSalary;
        } else if (previewData?.salaryType === 'Salary Range') {
            data.salaryType = previewData?.salaryType;
            data.minSalary = previewData?.minSalary;
            data.maxSalary = previewData?.maxSalary;
        } else if (previewData?.salaryType === 'Not Disclose') {
            data.salaryType = 'Not Disclose';
        }
        if(previewData?.gender){
            data.gender = previewData?.gender;
        }
        if(previewData?.age){
            data.age = previewData?.age;
        }
         axios.post(`${COMPANY_URLS.create_job_post}`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                successPopup(res?.data?.message)
                setNeedTorender(prev => !prev)
                navigate('/company/job-post')
                setPreviewData({})
            })
            .catch((err) => {
                errorPopup(err?.response?.data?.message)
            })
    }
console.log('previewData',previewData)
    return (
        <>
            <div className="previewJob_container ">
                <div className='d-flex align-items-center justify-content-between'>
                    <h1>Review Job Post</h1>
                    <button onClick={()=>navigate(-1)} className="editJob_postBtn"><RiEditFill fontSize={18} /> Edit Post</button>
                </div>
                {/* Job Details */}
                <div className="jobdetailsTable ps-3 mt-4 d-flex flex-column gap-2">
                    <h1>Job Details</h1>
                    <div className="tage_value_div">
                        <div>Job Title</div>
                        <div>{previewData?.jobTitle}</div>
                    </div>
                    <div className="tage_value_div">
                        <div>Workplace</div>
                        <div>{previewData?.workPlace}</div>
                    </div>
                    <div className="tage_value_div">
                        <div>Employment Type</div>
                        <div>{previewData?.employmentType}</div>
                    </div>
                    <div className="tage_value_div">
                        <div>Salary</div>
                        {previewData?.salaryType === 'Fixed Salary' && <div>₹ {previewData?.FixedSalary} LPA</div>}
                        {previewData?.salaryType === 'Salary Range' && <div>₹ {previewData?.minSalary} - {previewData?.maxSalary} LPA</div>}
                        {previewData?.salaryType === 'Not Disclose' && <div>{previewData?.salaryType}</div>}
                    </div>
                    <div className="tage_value_div">
                        <div>Shift</div>
                        <div>{previewData?.shift}</div>
                    </div>
                    <div className="tage_value_div">
                        <div>Job Description</div>
                        <div>{previewData?.jobSummary} </div>
                    </div>
                    <div className="tage_value_div">
                        <div>Job Location</div>
                        <div>{previewData?.jobLocation} </div>
                    </div>
                </div>
                {/* Candidate Requirements */}
                <div className="jobdetailsTable ps-3 mt-5 d-flex flex-column gap-2">
                    <h1>Candidate Requirements</h1>
                    <div className="tage_value_div">
                        <div>Education</div>
                        <div>{previewData?.education}</div>
                    </div>
                    <div className="tage_value_div">
                        <div>Experience</div>
                        <div>{previewData?.experience}</div>
                    </div>
                    <div className="tage_value_div">
                        <div>Skills</div>
                        <div>
                            {
                                previewData?.skills?.map((skill, index) => (
                                    <span key={index}>
                                        <span className={`${index === 0 && 'd-none'}`}>{', '}</span>
                                        {skill}
                                    </span>
                                ))
                            }
                        </div>
                    </div>
                    <div className="tage_value_div">
                        <div>Language</div>
                        {
                            previewData?.languages?.map((lang,idx)=>(
                                <div>{lang?.languageFromMenu} {`(${lang?.proficiency})`}</div>
                            ))
                        }
                    </div>
                    <div className="tage_value_div">
                        <div>No. of Openings</div>
                        <div>{previewData?.noOfOpening}</div>
                    </div>
                    <div className="tage_value_div">
                        <div>Gender</div>
                        <div>{previewData?.gender || 'N/A'}</div>
                    </div>
                    <div className="tage_value_div">
                        <div>Age</div>
                        <div>{previewData?.age || 'N/A'}</div>
                    </div>
                </div>
                {/* <StepperBackButton>Publish Job Post</StepperBackButton> */}
                <div className='d-flex justify-content-center mt-4'>
                <AuthFillButton onClick={createJob} style={{width:'350px', margin:'0 auto'}}>Publish Job Post</AuthFillButton>
                </div>
            </div>
        </>
    )
}
