import React from 'react'
import './SearchJob.css'

export default function SearchJob() {
    return (
        <>
            <div className="search_main">
                <div>
                    Comming Soon
                </div>
            </div>
        </>
    )
}
