import React, { useState } from 'react';
import greenTick from '../../../assets/greenTick.png';
import redCrossTick from '../../../assets/redCrossTick.png';
import './SubscriptionPlan.css';

const planData = [
  {
    name: "Basic Plan",
    monthlyPrice: 999,
    yearlyPrice: 11988,
    discount: 20,
    features: [
      { text: "Job will be active for 15 days", included: true },
      { text: "5 posts/month", included: true },
      { text: "2 members", included: true },
      { text: "WhatsApp Job Notify", included: false },
      { text: "Higher visibility to candidates", included: false },
      { text: "Immediate hiring tag", included: false },
    ],
  },
  {
    name: "Pro Plan",
    monthlyPrice: 2999,
    yearlyPrice: 35988,
    discount: 20,
    features: [
      { text: "Job will be active for 30 days", included: true },
      { text: "15 posts/month", included: true },
      { text: "5 members", included: true },
      { text: "WhatsApp Job Notify", included: true },
      { text: "Higher visibility to candidates", included: true },
      { text: "Immediate hiring tag", included: true },
    ],
  },
  {
    name: "Enterprise Plan",
    monthlyPrice: 2999,
    yearlyPrice: 35988,
    discount: 20,
    features: [
      { text: "Job will be active for 30 days", included: true },
      { text: "15 posts/month", included: true },
      { text: "5 members", included: true },
      { text: "WhatsApp Job Notify", included: true },
      { text: "Higher visibility to candidates", included: true },
      { text: "Immediate hiring tag", included: true },
    ],
  },
];

export default function SubscriptionPlan() {
  const [isYearly, setIsYearly] = useState(false);

  const calculatePrice = (price, discount) => {
    return isYearly ? Math.round((price * (100 - discount)) / 100) : Math.round(price / 12);
  };

  return (
    <div className="subscriptionPlan_main">
      <h1>Subscription Plan</h1>
      <div className="subscri_plan d-flex flex-column gap-5">
        <div>
          <h1>Purchase a subscription</h1>
          <p>Choose the plan that works for you.</p>
        </div>
        <div className="btn_group mb-sm-5">
          <button
            className={`${!isYearly && 'active_subsBtn'}`}
            onClick={() => setIsYearly(false)}
          >
            Monthly
          </button>
          <button
            className={`${isYearly && 'active_subsBtn'}`}
            onClick={() => setIsYearly(true)}
          >
            Yearly <span>-20% off</span>
          </button>
        </div>
        <div className="offer_card_grp d-flex flex-wrap justify-content-around gap-4 mb-4">
          {planData.map((plan, index) => (
            <div
              key={index}
              className={`offer_card ${
                index === 1 ? 'most_popular' : ''
              } px-4 py-4 d-flex flex-column justify-content-between`}
            >
              <div>
                <p>{plan.name}</p>
                <div>
                  <span className="me-1">
                    {calculatePrice(plan.yearlyPrice, plan.discount).toLocaleString()}
                  </span>
                  <span>{isYearly ? '/Year' : '/Month'}</span>
                </div>
                <ul className="feature-list ps-0 mt-3">
                  {plan.features.map((feature, idx) => (
                    <li key={idx}>
                      <span className="tick_box">
                        <img
                          src={feature.included ? greenTick : redCrossTick}
                          alt={feature.included ? 'green tick' : 'red cross'}
                        />
                      </span>
                      <span>{feature.text}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <button className="choose_plan">Choose Plan</button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
