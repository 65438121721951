import React, { useRef } from 'react'
import { LiaEdit } from "react-icons/lia";
import { LiaIdCard } from "react-icons/lia";
import { FaAngleRight } from "react-icons/fa6";
import galleryIcon from '../../../assets/galleryIcon.png'
import educationBook from '../../../assets/educationBook.png'
import networkIcon from '../../../assets/network.png'
import { GiNetworkBars } from "react-icons/gi";

import AuthOutlineBtn from '../../component/ReUseComps/Button';
import { useNavigate } from 'react-router-dom';
import './Information.css'

export default function Information() {

    const fileRef = useRef(null)
    const navigate = useNavigate()

    const dummySkill = ['C++', 'Core Java', 'Python', 'Machine Learning']
    const handleFileUpload = () => {
        fileRef.current.click()
    }
    const otherDetails = [
        {
            type: 'e KYC',
            descr: 'Not completed'
        },
        {
            type: 'Preferred Department',
            descr: 'Receptionist - Plumber'
        },
        {
            type: 'Location',
            descr: 'Noida'
        },
        {
            type: 'Job Preference',
            descr: 'Full Time - Night Shift'
        },
        {
            type: 'Job Preference',
            descr: 'Full Time - Night Shift'
        },
        {
            type: 'Documents',
            descr: 'Driving License - Adhaar card'
        },
        {
            type: 'Assests',
            descr: 'Bike - Cycle'
        },
    ]
    const handleEditBasicInfo=()=>{
        navigate('/create-profile')
    }

    return (
        <>
            <div className="Information_main">
                <div className="baiscInfo">
                    <div className="card-title">
                        <div>
                            <span>Basic Info</span>
                            <span onClick={handleEditBasicInfo} className='edit-info'><LiaEdit fontSize={27} /><span>Edit</span></span>
                        </div>
                        <div className="basic-info-card">
                            <div className='d-flex gap-2'>
                                <LiaIdCard className='mt-1' fontSize={30} color='#777777' />
                                <div className='info-row-idData'>
                                    <p>Gender</p>
                                    <p>Male</p>
                                </div>
                            </div>
                            <div className='d-flex gap-2'>
                                <LiaIdCard className='mt-1' fontSize={30} color='#777777' />
                                <div className='info-row-idData'>
                                    <p>Date of Birth</p>
                                    <p>12 Jan 1980</p>
                                </div>
                            </div>
                            <div className='d-flex gap-2 border-bottom-0'>
                                <LiaIdCard className='mt-1' fontSize={30} color='#777777' />
                                <div className='info-row-idData'>
                                    <p>Mobile Number</p>
                                    <p>87858606975</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-title">
                        <div>
                            <span>Experience</span>
                        </div>
                        <div className="basic-info-card">
                            <div className='border-bottom-0 expr-cards-sec d-flex justify-content-between flex-wrap align-items-center'>
                                <span>Experience Level :</span>
                                <span>Fresher <span className='ms-2 text-dark fs-4'>{'>'}</span></span>
                            </div>
                        </div>
                    </div>
                    <div className="card-title">
                        <div>
                            <span>Education</span>
                        </div>
                        <div className="basic-info-card">
                            <div className='expr-cards-sec d-flex justify-content-between flex-wrap align-items-center'>
                                <span>12th Pass :</span>
                                <span className='ms-2 text-dark fs-4'>{'>'}</span>
                            </div>
                            <div className='educationCard border-bottom-0'>

                                <img src={educationBook} alt="galleryIcon" />
                                <div>
                                    <p>12th Pass</p>
                                    <p>School Name</p>
                                </div>
                            </div>
                            <input ref={fileRef} type="file" className='d-none' />
                            <button onClick={handleFileUpload} className='upload-cerficated'><img src={galleryIcon} alt="galleryIcon" /><span>Upload Certificate</span></button>
                        </div>
                    </div>
                    <div className="card-title">
                        <div>
                            <span>Language</span>
                        </div>
                        <div className="basic-info-card d-flex flex-column gap-1">
                            <div className='w-100 expr-cards-sec d-flex justify-content-between flex-wrap align-items-center'>
                                <div className='d-flex align-items-center gap-2'>
                                    <GiNetworkBars fontSize={24} />
                                    <span>Hindi - Advance</span>
                                </div>
                                <span>Fresher <span className='ms-2 text-dark fs-4'>{'>'}</span></span>
                            </div>
                            <div className='w-100 border-bottom-0 expr-cards-sec d-flex justify-content-between flex-wrap align-items-center'>
                                <div className=' d-flex align-items-center gap-2'>
                                    <GiNetworkBars fontSize={24} />
                                    <span>Hindi - Advance</span>
                                </div>
                                <span>Fresher <span className='ms-2 text-dark fs-4'>{'>'}</span></span>
                            </div>
                        </div>
                    </div>
                    <div className="card-title">
                        <div className="basic-info-card">
                            <div className='border-bottom-0'>
                                <h1 className='skill-title'>Skill</h1>
                                <div className='d-flex gap-2 align-items-center flex-wrap'>
                                    {
                                        dummySkill.map((item, index) => (
                                            <span key={index} className='skillItems'>{item}</span>
                                        ))
                                    }
                                </div>
                            </div>
                            <span className='ms-2 text-dark fs-4'>{'>'}</span>
                        </div>
                    </div>
                </div>
                <div className='other-service pt-3 pb-2'>
                    <h1>Other Details</h1>
                    <p className='m-0'>Can only be seen to the Hr</p>
                </div>

                <div className='mt-3 d-flex flex-column gap-2'>
                    {
                        otherDetails.map((data, index) => (
                            <div key={index} className="otherDetailsCard">
                                <div>
                                    <h1>{data.type}</h1>
                                    <p>{data.descr}</p>
                                </div>
                                <span className='ms-2 text-dark fs-4'>{'>'}</span>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}
