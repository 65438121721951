import React, { useState } from 'react';
import './Setting.css';

export default function ResetNotification() {
    const [notificatonSetting, setNotificationSetting] = useState({
        security: false,
        applicationUpdate: false,
        emailNotification: false,
        jobPost: false,
    });

    const handleChangeNotification = (type) => {
        setNotificationSetting((prev) => ({
            ...prev,
            [type]: !prev[type],
        }));
    };

    const notificationOption = [
        {
            option: 'security',
            label: 'Security Alert',
            description: 'Be alerted of any unusual activity on your account.',
        },
        {
            option: 'applicationUpdate',
            label: 'Application Updates',
            description: 'Stay informed about new applicants that match your preferences.',
        },
        {
            option: 'emailNotification',
            label: 'Email Notification',
            description: 'Get Every Notification Through Email',
        },
        {
            option: 'jobPost',
            label: 'Job Post',
            description: 'Receive updates on the status of your job post, from submission to offer.',
        },
    ];

    return (
        <div className="notification_main">
            <div className="reset-pass-title mb-4">
                <h1>Change Notification Setting</h1>
                <p>Customize How You Stay Informed and Connected</p>
            </div>
            {notificationOption.map((data, idx) => (
                <div
                    className="toggle_group d-flex justify-content-between mb-3"
                    key={idx}
                >
                    <div className="notification-items">
                        <p>{data.label}</p>
                        <p>{data.description}</p>
                    </div>
                    <label className="switch">
                        <input
                            type="checkbox"
                            checked={notificatonSetting[data.option]}
                            onChange={() => handleChangeNotification(data.option)}
                        />
                        <span className="slider round"></span>
                    </label>
                </div>
            ))}
            <div className='btn_group_reset d-flex gap-5 justify-content-between align-items-center mt-5 flex-wrap border-0 p-0'>
                <button>Cancle</button>
                <button type='submit'>Save Changes</button>
            </div>
        </div>
    );
}
