// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: "Poppins";
  -webkit-font-smoothing: "Poppins";
  -moz-osx-font-smoothing: "Poppins";
}

code {
  font-family: "Poppins";
}

body > div > .Toastify > div{
  z-index: 9999999999;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,sBAAsB;EACtB,iCAAiC;EACjC,kCAAkC;AACpC;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["body {\n  margin: 0;\n  font-family: \"Poppins\";\n  -webkit-font-smoothing: \"Poppins\";\n  -moz-osx-font-smoothing: \"Poppins\";\n}\n\ncode {\n  font-family: \"Poppins\";\n}\n\nbody > div > .Toastify > div{\n  z-index: 9999999999;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
