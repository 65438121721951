import React, { useContext } from 'react'
import successIcon from '../../../assets/successIcon (2).png'
import { AuthFillButton } from '../../component/ReUseComps/Button'
import { useNavigate } from 'react-router-dom'
import UserContext from '../../component/ContextApi/UserContext'
import completeImage from '../../../assets/finalSuccessImage.png'
import './SuccessfulSubmit.css'

export default function SuccessfulSubmit() {
    const { userForm, setUserForm } = useContext(UserContext)
    console.log('userForm', userForm)
    const navigate = useNavigate();
    return (
        <>
            <div className='d-flex'>
                <div className="successfulSubmit_main w-50 flex-grow-1">
                    <div className="suceessBox_card">
                        <div className='succ-icon-img d-flex flex-column align-items-center justify-content-center gap-1'>
                            <img src={successIcon} alt="successIcon" />
                            <h1>Congratulations</h1>
                            <p>You Have Successfully completed your profile</p>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <AuthFillButton onClick={() => navigate('/user/find-job')}>Explore Jobs Now!</AuthFillButton>
                        </div>
                    </div>
                </div>
                <div className='steper-image-container h-md-100'>
                    <img src={completeImage} alt="complete image" />
                </div>
            </div>
        </>
    )
}
