import React, { useState } from 'react'
import './ApplyJob.css'
import FilterJob from './FilterJob/FilterJob'
import JobCards from './JobCards/JobCards'

export default function ApplyJob() {
    const [isFilterShow, setIsFilterShow] = useState(false)
    return (
        <>
            <div className="applyJob-main d-flex align-items-start justify-content-between gap-4 flex-wrap">
                {/* <div className='filter-job-btn'>
                    Filter Job
                </div> */}
                <div onClick={()=>setIsFilterShow(prev=>!prev)} className="responsiveFilter">
                   <div>{isFilterShow ? 'Hide Filter' : 'Open Filter'}</div>
                </div>
                <div className={`left-job-section responsiveFilterBox px-1 py-1 ${isFilterShow && 'd-block'}`}>
                    <FilterJob />
                </div>
                <div className='job-card-component flex-grow-1'>
                    <JobCards />
                </div>
            </div>
        </>
    )
}
