// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.update-avatar-main {
  width: 100%;
}

.web-cam-container > div {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d9d9d9;
}

.web-cam-container > img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.web-cam-container > button {
  width: 90%;
}
`, "",{"version":3,"sources":["webpack://./src/User/component/EditProfilePopUps/UploadAvatar/UpdateAvatar.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".update-avatar-main {\n  width: 100%;\n}\n\n.web-cam-container > div {\n  width: 150px;\n  height: 150px;\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: #d9d9d9;\n}\n\n.web-cam-container > img {\n  width: 150px;\n  height: 150px;\n  border-radius: 50%;\n}\n\n.web-cam-container > button {\n  width: 90%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
