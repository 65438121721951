import React from 'react'
import rightArrow from '../../../assets/rarr.png'
import members from '../../../assets/members.png'
import colorBag from '../../../assets/colorBag.png'
import greenTick from '../../../assets/greenTick.png';
import totalAppli from '../../../assets/totalAppli.png'
import redCrossTick from '../../../assets/redCrossTick.png';
import communityCrowd from '../../../assets/communityCrowd.png'
import './PurchagedPlan.css'
import { useNavigate } from 'react-router-dom'

export default function PurchagedPlan() {
    const navigate = useNavigate()

    const cardData = [
        {
            title: 'Members',
            available: '04',
            total: '10',
            img: members
        },
        {
            title: 'Job Posted',
            available: '10',
            total: 'Unlimited',
            img: colorBag
        },
        {
            title: 'Total Appicants',
            total: '',
            available: 99,
            img: totalAppli
        },

    ]

    const features = [
        { text: "Job will be active for 30 days", included: true },
        { text: "15 posts/month", included: true },
        { text: "5 members", included: true },
        { text: "WhatsApp Job Notify", included: true },
        { text: "Higher visibility to candidates", included: false },
        { text: "Immediate hiring tag", included: false },
      ];

    return (
        <>
            <div className='purchage-container px-3'>
                <h1 className='puchange-title'>Subscription Plan</h1>
                <div className="row plan-tym d-flex gap-4 mb-4 flex-wrap">
                    <div className="col-md-8 d-flex align-items-center justify-content-between flex-wrap flex-grow-1">
                        <div className='sub-box-left'>
                            <div className="plan_btn mb-4">Enterprise Plan</div>
                            <p>Activate Since</p>
                            <p>on December 12, 2025</p>
                        </div>
                        <div className='sub-box-right'>
                            <span>₹4,999</span><span>/month	</span>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3 upgrade_box flex-grow-1">
                        <p>Next Payment</p>
                        <p>on January 12, 2025</p>
                        <button onClick={()=>navigate('/company/subscription-plan')}>Upgrade Plan <img src={rightArrow} alt="rightArrow" /></button>
                    </div>
                </div>
                <div className="purchagedPlan_main row gap-3 flex-wrap">
                    {
                        cardData?.map((data, idx) => (
                            <div key={idx} className="col-sm-3 flex-grow-1 feture-wrapper d-flex align-items-center justify-content-between">
                                <div>
                                    <p>{data?.title}</p>
                                    <h1><span>{data?.available}</span><span>{data?.total && '/'+data?.total}</span></h1>
                                </div>
                                <img src={data?.img} alt="members" />
                            </div>
                        ))
                    }
                </div>
                <div className="purchagedPlan_main row px-3 mt-4">
                    <div className="col-md-6 feature_available">
                        <h1 className='mb-4'>Your Subscription Plan Features</h1>
                        <ul className='p-0'>
                            {
                                features?.map((data,idx)=>(
                                    <li key={idx}>
                                        <div className='tick-box'>
                                            <img src={data?.included ? greenTick : redCrossTick} alt={data?.included ? greenTick : redCrossTick} />
                                        </div>
                                        <span>{data?.text}</span>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className="col-md-6 communityCrowd_box d-flex justify-content-md-end">
                        <img src={communityCrowd} alt="communityCrowd" />
                    </div>
                </div>
            </div>
        </>
    )
}
