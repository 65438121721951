import React, { useContext, useEffect, useState } from 'react'
import dummyCompanyLogo from '../../../../assets/postJob.png'
import { FaLocationDot } from 'react-icons/fa6'
import bagImage from '../../../../assets/bag-solid.png'
import cashMultiple from '../../../../assets/cash-multiple.png'
import { PiArrowUpRightBold } from "react-icons/pi";
import { TiLocation } from "react-icons/ti";
import { IoMdShare } from "react-icons/io";
import { FiBookmark } from "react-icons/fi";
import { FaBookmark } from "react-icons/fa";
import { EventButton, StepperBackButton } from '../../../component/ReUseComps/Button'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { URLS } from '../../../../Utils/App_urls'
import { errorPopup, successPopup, warningPopup } from '../../../../Utils/ToasterMessage'
import useHtmlToText from '../../../../Company/Component/CustomHooks/convertHtmlToText'
import { FaArrowLeftLong } from "react-icons/fa6";
import UserContext from '../../../component/ContextApi/UserContext'
import Loader from '../../../component/Loader/Loader'
import { Spinner } from 'react-bootstrap'
import '../SimilaryJob/SimilarJob.css'
import './JobDetails.css'

export default function JobDetails() {
    // const {setLoader, loader} = useContext(UserContext)
    const location = useLocation();
    const navigate = useNavigate();
    const jobId = location?.state?.DATA;
    const [singleJobDetails, setSingleJobDetails] = useState({});
    const paramsId = useParams()
    const [isSaveForLater, setIsSaveForLater] = useState(false);
    const [loader, setLoader] = useState(false)

    const getSingleJobDetails = async () => {
        // setLoader(true)
        await axios.get(`${URLS.getSingleJob}`, {
            params: { id: jobId },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('userToken')}`
            }
        })
            .then((res) => {
                const result = res?.data?.result || [];
                setSingleJobDetails(result[0] || {});
                setIsSaveForLater(result?.[0]?.savedLaterJobs?.status)
                // setLoader(false)
            })
            .catch((err) => {
                console.log(err)
                // setLoader(false)
            })
    }

    const applyJob = () => {
        // setLoader(true)
        axios.post(`${URLS.userJobApplie}`,
            { JobId: jobId },
            {
                headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` }
            })
            .then((res) => {
                successPopup(res?.data?.message)
                getSingleJobDetails();
                // setLoader(false)
            })
            .catch((err) => {
                errorPopup(err?.response?.data?.message)
                // setLoader(false)
            })
    }

    const saveLaterJob = () => {
        if (!isSaveForLater) {
            setLoader(true)
            axios.post(`${URLS.saveLater}`,
                {
                    JobId: jobId,
                    status: true
                }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` }
            })
                .then((res) => {
                    successPopup(res?.data?.message)
                    getSingleJobDetails()
                    setLoader(false)
                })
                .catch((err) => {
                    setLoader(false)
                    errorPopup(err?.response?.data?.message);
                })
        } else {
            warningPopup('Already saved.')
        }
    }

    const shareLink = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Kaamval job url',
                text: 'I found matched interesting  job link:',
                url: window.location.href,
            })
                .then((res) => { })
                .catch((error) => console.log('Error sharing:', error));
        } else {
            alert('Your browser does not support the Web Share API');
        }
    }

    const postedDate = (data) => {
        const firstDate = new Date(data);
        const currentDate = new Date();
        const timeDifference = currentDate - firstDate;
        const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
        return daysDifference > 0 ? `${daysDifference} Day${daysDifference > 1 ? 's' : ''} ago` : 'Posted Today';
    }

    console.log('statusss', isSaveForLater)

    useEffect(() => {
        getSingleJobDetails()
    }, [paramsId?.id])

    return (
        <>
            <div className="job-details-main find_jobDetails_jobs px-2 pb-2">
                {
                    location?.pathname?.includes('notIntested-jobs') ||
                    location?.pathname?.includes('savedJobs') &&
                    <h1 className='backCondiationaly mb-3' onClick={() => navigate(-1)}><FaArrowLeftLong fontSize={20} /> Job Details</h1>
                }
                <div className="similar-job-card d-flex flex-column gap-3">
                    <div className="similar-job-header d-flex flex-sm-row flex-column-reverse align-items-start justify-content-between flex-wrap gap-2">
                        <div className='d-flex align-items-center gap-3'>
                            <div className="compy-logo-box">
                                {/* <img src={dummyCompanyLogo} alt="dummyCompanyLogo" /> */}
                                <img src={singleJobDetails?.companyProfileDetail?.[0]?.companylogo || dummyCompanyLogo} alt="dummyCompanyLogo" />
                            </div>
                            <div className='compy-title-descr'>
                                <h1>{singleJobDetails?.title}</h1>
                                <p>{singleJobDetails?.companyProfileDetail?.[0]?.companyName || 'N/A'}</p>
                                <a href={singleJobDetails?.companyProfileDetail?.[0]?.weburl} target='_blank'>{singleJobDetails?.companyProfileDetail?.[0]?.weburl}</a>
                            </div>
                        </div>
                        <p className='m-0'>{postedDate(singleJobDetails?.createdAt?.split('T'))}</p>
                    </div>
                    <div className='compay-benif d-flex align-items-start justify-content-start flex-wrap gap-3'>
                        <div> <img src={bagImage} alt="bag solid image" /> <span>{singleJobDetails?.experience}</span></div>
                        {singleJobDetails?.salaryType === "Fixed Salary" && <div><img src={cashMultiple} alt="cash multiple" /> <span>{singleJobDetails?.FixedSalary}k</span></div>}
                        {singleJobDetails?.salaryType == "Salary Range" && <div><img src={cashMultiple} alt="cash multiple" /> <span>{singleJobDetails?.minSalary}k - {singleJobDetails?.maxSalary}k</span></div>}
                        {singleJobDetails?.salaryType == "Not Disclose" && <div><img src={cashMultiple} alt="cash multiple" /> <span>{singleJobDetails?.salaryType}</span></div>}
                        <div><TiLocation color='#4B4B4B' fontSize={24} /><span>{singleJobDetails?.workPlace}</span></div>
                    </div>

                    <div className="highlight-skills d-flex align-items-center flex-wrap gap-3">
                        {/* <span>Employenment Type :</span> */}
                        <div>{singleJobDetails?.deparmentType}</div>
                        <div>{singleJobDetails?.workPlace}</div>
                        <div>{singleJobDetails?.shift}</div>
                    </div>
                    <div className="skill-section-job-card d-flex align-items-center flex-wrap gap-3">
                        {/* <span>Skills :</span> */}
                        {
                            singleJobDetails?.skills?.map((data, index) => (
                                <div key={index}>{data}</div>
                            ))
                        }
                    </div>
                    <div className="button-section-jobCard d-flex flex-wrap gap-3">
                        {!(singleJobDetails?.isUserApplied) ?
                            <EventButton onClick={applyJob}><span>Apply Now </span><PiArrowUpRightBold fontSize={22} /></EventButton>
                            : <EventButton className='applied-btn' disabled onClick={applyJob}><span>Applied</span></EventButton>}
                        {
                            isSaveForLater ?
                                <StepperBackButton disabled onClick={saveLaterJob}>Saved<FaBookmark fontSize={18} /> </StepperBackButton>
                                : <StepperBackButton onClick={saveLaterJob}>
                                    {loader && <Spinner style={{ width: '15px', height: '15px', borderWidth: '2px', marginRight: '2px' }} animation="border" />}
                                    Save for Later <FiBookmark fontSize={18} />
                                </StepperBackButton>
                        }
                        <StepperBackButton onClick={shareLink}>Share <IoMdShare fontSize={18} /> </StepperBackButton>
                    </div>
                    <div className="job-description-container sum_bg_remove mt-3">
                        <div>
                            <h1>Job Description</h1>
                            {/* <p>{singleJobDetails?.jobDescription}</p> */}
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: useHtmlToText(singleJobDetails?.jobDescription) }} />
                    </div>
                </div>
            </div>
        </>
    )
}
