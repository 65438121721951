import React, { useState } from 'react';
import { errorPopup, successPopup } from '../../../Utils/ToasterMessage';
import registerPageImage from '../../../assets/registerPageImage.png';
import { COMPANY_URLS } from '../../../Utils/Company_App_Urls';
import kaamwalaLogo from '../../../assets/KAAMVALALogo.png';
import Loader from '../../../User/component/Loader/Loader';
import { IoMdEyeOff, IoMdEye } from "react-icons/io";
import { RiLockPasswordFill } from "react-icons/ri";
import { Link, useNavigate } from 'react-router-dom';
import PasswordChecklist from "react-password-checklist";
import { TfiEmail } from "react-icons/tfi";
import axios from 'axios';
import './Auth.css';
import { Dropdown } from 'react-bootstrap';

export default function Registered() {

    const navigate = useNavigate();
    const [showPass, setShowPass] = useState(false);
    const [showConfPass, setShowConfPass] = useState(false);
    const [loader, setLoader] = useState(false);
    const [userAuthDetails, setUserAuthDetails] = useState({ role: '', email: '', password: '', confirmPassword: '' });
    const [isPasswordValid, setIsPasswordValid] = useState(false); // Track password validation
    const [error, setError] = useState({
        errorStatus: true,
        roleError: '',
        emailError: '',
        passwordError: '',
        confirmPasswordError: ''
    });

    const registerUser = async () => {
        setLoader(true);
        try {
            await axios.post(`${COMPANY_URLS.register}`, {
                email: userAuthDetails?.email,
                password: userAuthDetails?.password,
            })
                .then(res => {
                    localStorage.setItem('auth0UserId', res?.data?.data?.user?.auth0UserId);
                    successPopup(res?.data?.message);
                    setLoader(false);
                    navigate('/company/login');
                })
                .catch(err => {
                    errorPopup(err?.response?.data?.message);
                    setLoader(false);
                });
        } catch (error) {
            setLoader(false);
        }
    };

    const handleEmailChange = (e) => {
        let emailInput = e?.target?.value;
        const emailRegex = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
        if (!emailRegex?.test(emailInput)) {
            setError({ ...error, errorStatus: false, emailError: 'Invalid Email' });
        } else {
            setError({ ...error, errorStatus: false, emailError: '' });
        }
        emailInput === '' && setError({ ...error, errorStatus: false, emailError: '' });
        setUserAuthDetails({ ...userAuthDetails, email: emailInput });
    };

    const handlePasswordChange = (e) => {
        setUserAuthDetails({ ...userAuthDetails, password: e.target.value });
        setError({ ...error, errorStatus: false, passwordError: '' });
    };

    const handleConfirmPassword = (e) => {
        setUserAuthDetails({ ...userAuthDetails, confirmPassword: e.target.value });
        setError({ ...error, errorStatus: false, confirmPasswordError: '' });
    };

    const handleSubmitWithValidation = (e) => {
        e.preventDefault();
        if (!userAuthDetails.email || userAuthDetails.email === '') {
            setError({ ...error, errorStatus: false, emailError: 'Email is required.' });
        } else if (!userAuthDetails.password || userAuthDetails.password === '') {
            setError({ ...error, errorStatus: false, passwordError: 'Password is required.' });
        } else if (!isPasswordValid) {
            return setError({ ...error, errorStatus: false, passwordError: 'Password must meet all requirements.' });
        } else if (!userAuthDetails.confirmPassword || userAuthDetails.confirmPassword === '') {
            return setError({ ...error, errorStatus: false, confirmPasswordError: 'Confirm Password is required.' });
        } else if (userAuthDetails.password !== userAuthDetails.confirmPassword) {
            setError({ ...error, errorStatus: false, confirmPasswordError: 'Password and Confirm password must match.' });
        } else {
            registerUser();
        }
    };

    return (
        <>
            {loader && <Loader />}
            <div className="main_login">
                <Dropdown className='switch-pannel-button'>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        Switch To User
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item className='py-0'>
                            <Link className='urlAnckro py-0' to="/user/register">Switch to User</Link>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <div className='left-color-box'>
                </div>
                <div className='right-image-box'>
                </div>
                <div className='auth-box-adjust-signUp d-flex justify-content-start align-items-center gap-4'>
                    <div className="login_box mt-5 mt-md-0">
                        <img className='mb-2' src={kaamwalaLogo} alt="kaamwalaLogo" />
                        <h1 className='AuthTitle'>Company Sign Up</h1>
                        <form onSubmit={handleSubmitWithValidation} className='d-flex flex-column gap-3'>
                            <div>
                                <label className='d-block inputName' htmlFor="email">
                                    <TfiEmail fontSize={18} />
                                    <span className='mx-1'>Email</span>
                                </label>
                                <input
                                    className='form-input'
                                    id='email'
                                    type="email"
                                    placeholder='abc@gmail.com'
                                    autoComplete='username'
                                    onChange={handleEmailChange}
                                    value={userAuthDetails.email}
                                    isInvalid={error.emailError}
                                />
                                {error.emailError && <p className='text-danger m-0'>{error.emailError}</p>}
                            </div>
                            <div>
                                <label className='d-block inputName' htmlFor="password">
                                    <RiLockPasswordFill fontSize={18} />
                                    <span className='mx-1'>Password</span>
                                </label>
                                <div className='form-input p-0 pe-2'>
                                    <input
                                        className='form-inputPass'
                                        autoComplete="password"
                                        type={showPass ? 'text' : 'password'}
                                        id='password'
                                        placeholder='Enter Password'
                                        onChange={handlePasswordChange}
                                        value={userAuthDetails.password}
                                    />
                                    <span className='eye-hover' onClick={() => setShowPass(prev => !prev)}> {showPass ? <IoMdEyeOff fontSize={20} /> : <IoMdEye fontSize={20} />}</span>
                                </div>
                                {userAuthDetails.password != '' &&
                                    <PasswordChecklist
                                        rules={["minLength", "specialChar", "number", "capital"]}
                                        minLength={8}
                                        value={userAuthDetails.password}
                                        onChange={(isValid) => setIsPasswordValid(isValid)}
                                    />}
                                {error.passwordError && <p className='text-danger m-0'>{error.passwordError}</p>}
                            </div>
                            <div>
                                <label className='d-block inputName' htmlFor="password">
                                    <RiLockPasswordFill fontSize={18} />
                                    <span className='mx-1'>Confirm Password</span>
                                </label>
                                <div className='form-input p-0 pe-2'>
                                    <input
                                        className='form-inputPass'
                                        autoComplete='password'
                                        type={showConfPass ? 'text' : 'password'}
                                        id='confirmPassword'
                                        placeholder='Enter Password'
                                        value={userAuthDetails.confirmPassword}
                                        onChange={handleConfirmPassword}
                                    />
                                    <span className='eye-hover' onClick={() => setShowConfPass(prev => !prev)}> {showConfPass ? <IoMdEyeOff fontSize={20} /> : <IoMdEye fontSize={20} />}</span>
                                </div>
                                {error.confirmPasswordError && <p className='text-danger m-0'>{error.confirmPasswordError}</p>}
                            </div>
                            <button type='submit' className='authbtn'>Sign Up</button>
                            <p className='dntAccSignUp'>Already have an account? <span className='' onClick={() => navigate('/company/login')}>Log In</span></p>
                        </form>
                    </div>
                    <div className='registerd-image-size_comp image_container_assign-cpm'><img src={registerPageImage} alt="registerPageImage" /></div>
                </div>
            </div>
        </>
    );
}
