import React, { useState } from 'react'
import './GetStarted.css'
import codeImage from '../../../assets/codeImage.png'
import OTPInput from "otp-input-react";
import AuthOutlineBtn, { AuthFillButton } from '../ReUseComps/Button';
import { useNavigate } from 'react-router-dom';


export default function EnterCode() {
    const [OTP, setOTP] = useState("");
    const navigation = useNavigate()

    return (
        <>
            {/* <div className="enterCode_main">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil, saepe?
            </div> */}
            <div className="getStaretd_main">
                <div className="started_box pb-5">
                    <div className="imageSection">
                        <img src={codeImage} alt="kaamwalaStartImage" />
                    </div>
                    <div className="startbtnGrp">
                        {/* <img src={kaamwalaLogo} alt="kaamwalaLogo" /> */}
                        <div className='getStartTitle my-2'>
                            <h1 className='m-0 text-center'>Enter Code</h1>
                            <p className='m-0 text-center'>We sent a OTP code to your mobile</p>
                        </div>
                        <div className='mb-4 mt-2 optInputsField'>
                            <OTPInput value={OTP} onChange={setOTP} autoFocus={true} OTPLength={4} otpType="number" disabled={false} />
                        </div> 
                        <div className='w-100 authbtnGrp '>
                            <AuthOutlineBtn>Resend</AuthOutlineBtn>
                            <AuthFillButton>Confirm</AuthFillButton>
                            {/* <button className='resetCode'>Resend</button> */}
                            {/* <button className='authBtnStyle'>Confirm</button> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
