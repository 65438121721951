// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yetToApply{
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.yetToApply{
    padding: 24px 32px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    font-size: 20px;
    background-color: #ffffff;
  }

.yetToApply > img{
    width: 300px;
    height: 300px;
}

.yetToApply > p{
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}

.yetToApply > h1{
    font-size: 20px;
    font-weight: 700;
    color: #000000;
}

.invite_buttons{
    background-color: #3284FF;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    outline: unset;
    padding: 10px 14px;
    border: unset;
}
.invite_buttons:hover{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.pagination-activex {
    background-color: #3284ff !important;
    color: #ffffff !important;
  }`, "",{"version":3,"sources":["webpack://./src/Company/Pages/PostedJobDetails/NoCandidateApplied.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,wBAAmB;IAAnB,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,2CAA2C;IAC3C,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,wBAAmB;IAAnB,mBAAmB;IACnB,eAAe;IACf,yBAAyB;EAC3B;;AAEF;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,cAAc;IACd,kBAAkB;IAClB,aAAa;AACjB;AACA;IACI,wFAAwF;AAC5F;AACA;IACI,oCAAoC;IACpC,yBAAyB;EAC3B","sourcesContent":[".yetToApply{\n    width: 100%;\n    height: fit-content;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: 12px;\n}\n\n.yetToApply{\n    padding: 24px 32px;\n    border-radius: 8px;\n    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n    height: fit-content;\n    font-size: 20px;\n    background-color: #ffffff;\n  }\n\n.yetToApply > img{\n    width: 300px;\n    height: 300px;\n}\n\n.yetToApply > p{\n    font-size: 16px;\n    font-weight: 400;\n    text-align: center;\n}\n\n.yetToApply > h1{\n    font-size: 20px;\n    font-weight: 700;\n    color: #000000;\n}\n\n.invite_buttons{\n    background-color: #3284FF;\n    border-radius: 8px;\n    font-size: 16px;\n    font-weight: 400;\n    color: #FFFFFF;\n    outline: unset;\n    padding: 10px 14px;\n    border: unset;\n}\n.invite_buttons:hover{\n    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;\n}\n.pagination-activex {\n    background-color: #3284ff !important;\n    color: #ffffff !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
