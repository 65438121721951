import React, { useContext, useEffect, useState } from 'react'
import { IoCloseOutline } from 'react-icons/io5'
import UserContext from '../../ContextApi/UserContext'
import { Form, Spinner } from 'react-bootstrap'
import { EventButton } from '../../ReUseComps/Button'
import { errorPopup, successPopup } from '../../../../Utils/ToasterMessage'
import axios from 'axios'
import { URLS } from '../../../../Utils/App_urls'
import './EditPreferences.css'
import '../EditBasicInfo/EditBasicInfo.css'


export default function EditPreferences() {
    const { editProfilePopUPToggle, setEditProfilePopUpToggle, setNeedToReload, profileModalData, setLoaderOn } = useContext(UserContext)

    const handleClosePopUPModal = () => {
        setEditProfilePopUpToggle({ ...editProfilePopUPToggle, preferenceModal: false, mainModalToggle: false })
    }
    const [preferences, setPreferences] = useState([
        { category: 'Preferred workplace', selectedOptions: [] },
        { category: 'Preferred shift', selectedOptions: [] },
    ]);
    const [loader, setLoader] = useState(false)

    const [error, setError] = useState({
        errorMessage: '',
        errorStatus: true
    });

    const handleCheckboxChange = (category, event) => {
        const { id, checked } = event.target;
        setPreferences((prevPreferences) => {
            return prevPreferences.map((preference) => {
                if (preference.category === category) {
                    if (checked) {
                        if (!preference.selectedOptions.includes(id)) {
                            return {
                                ...preference,
                                selectedOptions: [...preference.selectedOptions, id],
                            };
                        }
                    } else {
                        return {
                            ...preference,
                            selectedOptions: preference.selectedOptions.filter((option) => option !== id),
                        };
                    }
                }
                return preference;
            });
        });
        setError({ errorMessage: '', errorStatus: false });
    };

    const handleSubmit = () => {
        const allSelected = preferences.every(option => option.selectedOptions.length > 0);
        if (!allSelected) {
            setError({ errorMessage: 'All categories are required.', errorStatus: true });
        } else {
            setError({ errorMessage: '', errorStatus: false });
            console.log('FinalSubmit', preferences)
            updatePreferences()
        }
    };

    const isCheckOnBack = (category, options) => {
        const categoryPreference = preferences?.find(item => item.category === category);
        return categoryPreference?.selectedOptions.includes(options);
    };

    const updatePreferences = async () => {

        const prefData = {
            'shift': preferences.find(data => data?.category === "Preferred shift")?.selectedOptions,
            'workPlace': preferences.find(data => data?.category === "Preferred workplace")?.selectedOptions,
        }

        delete profileModalData['_id']
        delete profileModalData['userId']
        delete profileModalData['auth0UserId']
        delete profileModalData['createdAt']
        delete profileModalData['updatedAt']
        delete profileModalData['__v']

        setLoader(true)
        await axios.patch(`${URLS.createUserProfile}`, { ...profileModalData, ...prefData }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('userToken')}`
            }
        })
            .then((res) => {
                setNeedToReload(true)
                console.log('prefEditData::', res)
                successPopup(res?.data?.message)
                handleClosePopUPModal();
                setLoader(false);
            })
            .catch((err) => {
                console.log('prefEditData', err)
                errorPopup(err?.response?.data?.message);
                handleClosePopUPModal();
                setLoader(false);
            })
    }

    useEffect(() => {
        setPreferences(prev => prev.map((data) => {
            if (data.category === 'Preferred shift') {
                return { ...data, selectedOptions: [...new Set([...data.selectedOptions, ...profileModalData?.shift])] };
            } else if (data.category === 'Preferred workplace') {
                return { ...data, selectedOptions: [...new Set([...data.selectedOptions, ...profileModalData?.workPlace])] };
            } else {
                return data;
            }
        }));
    }, [profileModalData]);

    return (
        <>
            <div className="preferences_modal_main d-flex flex-column">
                <div className="ModalTitle">
                    <h1>Edit Preferences</h1>
                    <span onClick={handleClosePopUPModal}><IoCloseOutline fontSize={26} /></span>
                </div>
                {error?.errorMessage && <p className='text-danger m-0'>{error?.errorMessage}</p>}
                <div className='d-flex flex-column align-items-start'>
                    <div className='enlishSpeakingLevel mt-3'>
                        <h2>Preferred workplace*</h2>
                        <Form.Check
                            type='checkbox'
                            label={<p className='m-0'>On-site</p>}
                            id={`On-site`}
                            checked={isCheckOnBack('Preferred workplace', 'On-site')}
                            onChange={(e) => handleCheckboxChange('Preferred workplace', e)}
                        />
                        <Form.Check
                            type='checkbox'
                            label={<p className='m-0'>Off-site</p>}
                            id={`Off-site`}
                            checked={isCheckOnBack('Preferred workplace', 'Off-site')}
                            onChange={(e) => handleCheckboxChange('Preferred workplace', e)}
                        />
                        <Form.Check
                            type='checkbox'
                            label={<p className='m-0'>Hybrid</p>}
                            id={`Hybrid`}
                            checked={isCheckOnBack('Preferred workplace', 'Hybrid')}
                            onChange={(e) => handleCheckboxChange('Preferred workplace', e)}
                        />
                    </div>
                    <div className='enlishSpeakingLevel mt-3'>
                        <h2>Preferred shift*</h2>
                        <Form.Check
                            type='checkbox'
                            label={<p className='m-0'>Day Shift</p>}
                            id={`Day Shift`}
                            checked={isCheckOnBack('Preferred shift', 'Day Shift')}
                            onChange={(e) => handleCheckboxChange('Preferred shift', e)}
                        />
                        <Form.Check
                            type='checkbox'
                            label={<p className='m-0'>Night Shift</p>}
                            id={`Night Shift`}
                            checked={isCheckOnBack('Preferred shift', 'Night Shift')}
                            onChange={(e) => handleCheckboxChange('Preferred shift', e)}
                        />
                    </div>
                </div>
                <EventButton className='modal-update-btn mt-3 mx-0 w-100 d-flex align-items-center justify-content-center' onClick={handleSubmit}>
                    Update
                    {loader && <Spinner className='m-0 ms-2' style={{ width: '18px', height: '18px', borderWidth: '3px' }} animation="border" />}
                </EventButton>
            </div>
        </>
    )
}
