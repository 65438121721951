import React, { useContext } from 'react'
import { Outlet } from 'react-router-dom'
import NavBar from '../NavBar/Navbar'
import Loader from '../../../User/component/Loader/Loader'
import CompanyContext from '../ContextAPI/CompanyContext'
import './CompanyLayout.css'
import Sidebar from '../Sidebar/Sidebar'
import CloseJobModal from '../PopupModal/CloseJobModal/CloseJobModal'
import Footer from '../../../User/component/Footer/Footer'

export default function CompanyLayout() {
    const { loader, closeJobModalOpen, setCloseJobModalOpen } = useContext(CompanyContext)
    return (
        <>
            {loader && <Loader />}
            {closeJobModalOpen?.status && <CloseJobModal />}
            <div className='layout-formate d-flex flex-column'>
                <NavBar />
                <div className='out_let_conatiner_company d-flex'>
                    <div className="sidebar_container">
                        <Sidebar />
                    </div>
                    <div className='outLet_main_company w-100'>
                        <Outlet />
                    </div>
                </div>
                <div>
                    <Footer />
                </div>
            </div>
        </>
    )
}
