// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.applyJob-main{
    /* height: 100%;
    overflow-y: auto; */
    margin-top: 6rem;
}

.left-job-section{
    width: 330px;
    height: -moz-fit-content;
    height: fit-content;
    height: 86vh;
    overflow-y: auto;
}

.left-job-section::-webkit-scrollbar {
    display: none;
}

.job-card-component{
    width: 70%;
    flex-grow: 1;
    height: -moz-fit-content;
    height: fit-content;
}

.responsiveFilter{
    width: 100px;
    height: 40px;
    background-color:#3284FF;
    color: white;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 10px;
    border-radius: 10px 0px 0px 10px;
    position: absolute; 
    right: 0px;
    top: 100px;
    display: none;
    vertical-align: middle;
}

@media screen and (max-width:1040px) {
    .responsiveFilter{
        display: block;
    }
    .responsiveFilterBox{
        position: absolute;
        left: 0;
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/User/Pages/ApplyJob/ApplyJob.css"],"names":[],"mappings":"AAAA;IACI;uBACmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,wBAAmB;IAAnB,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,wBAAmB;IAAnB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,wBAAwB;IACxB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;IAClB,gCAAgC;IAChC,kBAAkB;IAClB,UAAU;IACV,UAAU;IACV,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI;QACI,cAAc;IAClB;IACA;QACI,kBAAkB;QAClB,OAAO;QACP,aAAa;IACjB;AACJ","sourcesContent":[".applyJob-main{\n    /* height: 100%;\n    overflow-y: auto; */\n    margin-top: 6rem;\n}\n\n.left-job-section{\n    width: 330px;\n    height: fit-content;\n    height: 86vh;\n    overflow-y: auto;\n}\n\n.left-job-section::-webkit-scrollbar {\n    display: none;\n}\n\n.job-card-component{\n    width: 70%;\n    flex-grow: 1;\n    height: fit-content;\n}\n\n.responsiveFilter{\n    width: 100px;\n    height: 40px;\n    background-color:#3284FF;\n    color: white;\n    display: flex;\n    align-items: center;\n    justify-content: start;\n    padding-left: 10px;\n    border-radius: 10px 0px 0px 10px;\n    position: absolute; \n    right: 0px;\n    top: 100px;\n    display: none;\n    vertical-align: middle;\n}\n\n@media screen and (max-width:1040px) {\n    .responsiveFilter{\n        display: block;\n    }\n    .responsiveFilterBox{\n        position: absolute;\n        left: 0;\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
