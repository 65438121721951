import React, { useContext, useState } from 'react'
import { errorPopup, successPopup, warningPopup } from '../../../Utils/ToasterMessage';
import loginAuthImage from '../../../assets/loginAuthImage.png'
import { COMPANY_URLS } from '../../../Utils/Company_App_Urls';
import kaamwalaLogo from '../../../assets/KAAMVALALogo.png'
import Loader from '../../../User/component/Loader/Loader';
import { IoMdEyeOff, IoMdEye } from "react-icons/io";
import { RiLockPasswordFill } from "react-icons/ri";
import { Link, useNavigate } from 'react-router-dom';
import { TfiEmail } from "react-icons/tfi";
import axios from 'axios';
import './Auth.css'
import { Dropdown } from 'react-bootstrap';
import CompanyContext from '../ContextAPI/CompanyContext';

export default function Login() {
  const { isCompanyProfileComplate, setIsCompanyProfileComplate } = useContext(CompanyContext)
  const navigate = useNavigate()
  const [showPass, setShowPass] = useState(false)
  const [loader, setLoader] = useState(false)
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [customError, setCustomError] = useState({
    errorStatus: true,
    emailError: '',
    passwordError: ''
  });

  const loginAPICall = async () => {
    const data = {
      email: email,
      password: password
    }
    setLoader(true);
    await axios.post(`${COMPANY_URLS.login}`, data)
      .then((res) => {
        setLoader(false)
        // navigate('/company/job-post')
        redirectAsPerRole(res?.data?.data?.tokens?.access?.token, res?.data?.data?.user?.isEmailVerified, res?.data?.data?.user?.role)
      })
      .catch(err => {
        errorPopup(err?.response?.data?.message);
        // setCustomError({ ...customError, passwordError: err?.response?.data?.message})
        setLoader(false);
      })

  }

  const getCompanyDetails = () => {
    try {
      axios.get(`${COMPANY_URLS.companyDetail}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
        .then((res) => {
          localStorage.setItem('isprofileComplete', res?.data?.data?.profileComplete)
          localStorage.setItem('registeredEmail', res?.data?.data?.email)
          successPopup("Login successfully");
          setIsCompanyProfileComplate(res?.data?.data?.profileComplete)
          res?.data?.data?.profileComplete ? navigate('/company/job-post') : navigate('/company/create-profile');
        })
        .catch((err) => {
          errorPopup(err?.response?.data?.message || err?.message);
          setLoader(false)
        })
    } catch (error) {
      successPopup('Some technical issue occured.')
    }
  }

  const redirectAsPerRole = async (token, isEmailVerified, role) => {
    if (isEmailVerified) {
      localStorage.setItem('token', token);
      localStorage.setItem('companyRole', role);

      if (role === 'company') {
        getCompanyDetails()
      } else {
        warningPopup('Invalid Credentials.')
        setLoader(false)
      }
    } else {
      warningPopup('Please verify your email');
    }
    setEmail('');
    setPassword('')
  }

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setCustomError({ ...customError, emailError: '' });
  }

  const handlePassword = (e) => {
    setPassword(e.target.value);
    setCustomError({ ...customError, passwordError: '' })

  }

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$/;
    return passwordRegex.test(password);
  };

  const handleSubmitAndValidation = (e) => {
    e.preventDefault();
    if (email === '' || !email) {
      setCustomError({ ...customError, emailError: 'Email is required.' })
    } else if (password === '' || !password) {
      setCustomError({ ...customError, passwordError: 'Password is required.' })
    }
    // else if (!validatePassword(password)) {
    //   setCustomError({ ...customError, passwordError: 'Password must be at least 8 characters long, contain one uppercase letter, one lowercase letter, one number, and one special character.'})
    // } 
    else {
      loginAPICall()
    }
  }

  // console.log('customError', customError)
  // console.log('email', email)
  return (
    <>

      {loader && <Loader />}
      <div className="main_login">
        <Dropdown className='switch-pannel-button'>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            Switch To User
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item className='py-0'>
              <Link className='urlAnckro py-0' to="/user/login">Switch to User</Link>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <div className='position-absolute'>
        </div>
        <div className='left-color-box'>
        </div>
        <div className='right-image-box'>
          {/* <div><img src={loginAuthImage} alt="loginAuthImage" /></div> */}
        </div>
        <div className='auth-box-adjust d-flex justify-content-start align-items-center gap-4'>
          <div className="login_box">
            <img className='mb-2' src={kaamwalaLogo} alt="kaamwalaLogo" />
            <h1 className='AuthTitle'>Company LogIn</h1>
            <form onSubmit={handleSubmitAndValidation} className='d-flex flex-column gap-3'>
              <div>
                <label className='d-block inputName' htmlFor="email">
                  <TfiEmail fontSize={18} />
                  <span className='mx-1 align-middle'>Email</span>
                </label>
                <input
                  className='form-input'
                  id='email'
                  type="email"
                  placeholder='abc@gmail.com'
                  value={email}
                  onChange={handleEmail}
                  isInvalid={customError.emailError}
                />
                {customError.emailError && <p className='text-danger m-0'>{customError.emailError}</p>}
              </div>
              <div>
                <label className='d-block inputName' htmlFor="password">
                  <RiLockPasswordFill fontSize={18} />
                  <span className='mx-1 align-middle'>Password</span>
                </label>
                <div className='form-input p-0 pe-2'>
                  <input
                    className='form-inputPass'
                    type={showPass ? 'text' : 'password'}
                    id='password'
                    placeholder='Enter Password'
                    value={password}
                    onChange={handlePassword}
                    autoComplete="current-password"
                  />
                  <span className='eye-hover' onClick={() => setShowPass(prev => !prev)}> {showPass ? <IoMdEyeOff fontSize={20} /> : <IoMdEye fontSize={20} />}</span>
                </div>
                {customError.passwordError && <p className='text-danger'>{customError.passwordError}</p>}
              </div>
              <div className='d-flex justify-content-between'>
                <div className='rememberMe'>
                  <input id='rememberMe' type="checkbox" />
                  <label htmlFor="rememberMe">Remember Me</label>
                </div>
                <span className='forgotPassword' onClick={() => navigate('/company/forgot-password')}>Forgot Password?</span>
              </div>
              <button type='submit' className='authbtn'> Login </button>
              <p className='dntAccSignUp'>Don’t have a account? <span onClick={() => navigate('/company/register')}>Sign Up</span></p>
            </form>
          </div>
          <div className='image_container_assign'><img src={loginAuthImage} alt="loginAuthImage" /></div>
        </div>
      </div>
    </>
  )
}
