import React, { useContext, useEffect, useState } from 'react'
import { IoCloseOutline } from "react-icons/io5";
import Form from 'react-bootstrap/Form';
import { EventButton } from '../../ReUseComps/Button';
import { SlLocationPin } from 'react-icons/sl';
import UserContext from '../../ContextApi/UserContext';
import axios from 'axios';
import { URLS } from '../../../../Utils/App_urls';
import { errorPopup, successPopup } from '../../../../Utils/ToasterMessage';
import Loader from '../../Loader/Loader';
import { Spinner } from 'react-bootstrap';
import './EditBasicInfo.css'

export default function EditBasicInfo() {
  const { editProfilePopUPToggle, setEditProfilePopUpToggle, profileModalData, setNeedToReload, setLoaderOn } = useContext(UserContext)
  const [basicInfoModalData, setBasicInfoModalData] = useState({
    name: '',
    DOB: '',
    gender: '',
    address: '',
    mobileNo: '',
  })
  const [error, setError] = useState({
    errorStatus: true,
    nameError: '',
    DobError: '',
    genderError: '',
    addressError: '',
    mobileNo: '',
  })
  const [loader, setLoader] = useState(false)

  const handleNameChange = (e) => {
    setBasicInfoModalData({ ...basicInfoModalData, name: e.target.value })
    setError({ ...error, errorStatus: false, nameError: '' })
  }

  const handleMobileNumberChange = (e) => {
    const regex = new RegExp(/^[0-9]*$/)
    regex.test(e.target.value) && setBasicInfoModalData({ ...basicInfoModalData, mobileNo: e.target.value })
    setError({ ...error, errorStatus: false, mobileNo: '' })
  }
  const handleDOBChange = (e) => {
    setBasicInfoModalData({ ...basicInfoModalData, DOB: e.target.value })
    setError({ ...error, errorStatus: false, DobError: '' })
  }
  const handleGenderChange = (e) => {
    const { id } = e.target
    setBasicInfoModalData({ ...basicInfoModalData, gender: id })
    setError({ ...error, errorStatus: false, genderError: '' })
  }
  const handleAddressChange = (e) => {
    setBasicInfoModalData({ ...basicInfoModalData, address: e.target.value })
    setError({ ...error, errorStatus: false, addressError: '' })
  }

  const pickLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, errorGPS);
    } else { }
  };

  function success(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
    getCityName(latitude, longitude);
  }

  function errorGPS() {
    console.log("Unable to retrieve your location");
  }

  async function getCityName(latitude, longitude) {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
      );
      const data = await response.json();
      const address = `City - ${data?.address?.city || ''} ${data?.address?.city && ','} Dist - ${data?.address?.state_district || ''} ${data?.address?.state_district && ','} State - ${data?.address?.state || ''}  ${data?.address?.state && ','} Pin - ${data?.address?.postcode || ''} ${data?.address?.postcode && ','} ${data?.address?.country_code || ''}`;
      setBasicInfoModalData({ ...basicInfoModalData, address: address })
    } catch (error) {
      return null;
    }
  }

  const updateBasicInfo = async () => {
    const data = { ...basicInfoModalData }
    delete profileModalData['_id']
    delete profileModalData['userId']
    delete profileModalData['auth0UserId']
    delete profileModalData['createdAt']
    delete profileModalData['updatedAt']
    delete profileModalData['__v']
    delete profileModalData['name']
    delete profileModalData['DOB']
    delete profileModalData['gender']
    delete profileModalData['address']
    setLoader(true)
    try {
      await axios.patch(`${URLS.createUserProfile}`, { ...profileModalData, ...basicInfoModalData }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`
        }
      })
        .then((res) => {
          setNeedToReload(true)
          successPopup(res?.data?.message)
          handleClosePopUPModal();
          setLoader(false)

        })
        .catch((err) => {
          errorPopup(err?.response?.data?.message);
          handleClosePopUPModal();
          setLoader(false)
        })
    } catch (err) {
      console.error('err', err);
    }
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!basicInfoModalData?.name) {
      setError({ ...error, errorStatus: false, nameError: 'Full name is required.' })
    } else if (!basicInfoModalData?.DOB) {
      setError({ ...error, errorStatus: false, DobError: 'DOB is required.' })
    } else if (!basicInfoModalData?.mobileNo) {
      setError({ ...error, errorStatus: false, DobError: 'Mobile Number is required.' })
    }
    else if (!basicInfoModalData?.gender) {
      setError({ ...error, errorStatus: false, genderError: 'Gender is required.' })
    } else if (!basicInfoModalData?.address) {
      setError({ ...error, errorStatus: false, addressError: 'Address is required.' })
    } else {
      updateBasicInfo()
    }
  }
  const handleClosePopUPModal = () => {
    setEditProfilePopUpToggle({ ...editProfilePopUPToggle, basicInfoModal: false, mainModalToggle: false })
  }

  useEffect(() => {
    setBasicInfoModalData({
      name: profileModalData?.name,
      DOB: profileModalData?.DOB?.split('T')[0],
      gender: profileModalData?.gender,
      address: profileModalData?.address,
      mobileNo: profileModalData?.mobileNo
    })
  }, [])

  // console.log('basicInfoModalData', basicInfoModalData)
  // console.log('basicModalError', error)
  // console.log('basicInfoModal', editProfilePopUPToggle)

  return (
    <>
      <div className="modal_basic_info">
        <div className="ModalTitle">
          <h1>Basic Info</h1>
          <span onClick={handleClosePopUPModal}><IoCloseOutline fontSize={26} /></span>
        </div>
        <div className="modal_body">
          <Form onSubmit={handleFormSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <div>
                <Form.Label>Full Name*</Form.Label>
                <Form.Control type="text"
                  value={basicInfoModalData.name}
                  onChange={handleNameChange}
                  placeholder="Michael Johnson"
                  isInvalid={error.nameError}
                />
              </div>
              <Form.Control.Feedback className='d-block' type="invalid">
                {error.nameError}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <div>
                <Form.Label>Date of Birth*</Form.Label>
                <Form.Control type="date"
                  value={basicInfoModalData.DOB}
                  onChange={handleDOBChange}
                  max={'2007-12-01'}
                  min={'1900-01-01'}
                  placeholder="Michael Johnson"
                  isInvalid={error.DobError}
                />
              </div>
              <Form.Control.Feedback className='d-block' type="invalid">
                {error.DobError}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <div>
                <Form.Label>Mobile Number*</Form.Label>
                <Form.Control type="text"
                  minLength="10"
                  maxLength="10"
                  value={basicInfoModalData.mobileNo}
                  onChange={handleMobileNumberChange}
                  placeholder="87xxxxxxxx"
                  isInvalid={error.mobileNo}
                />
              </div>
              <Form.Control.Feedback className='d-block' type="invalid">
                {error.mobileNo}
              </Form.Control.Feedback>
            </Form.Group>
            <div className='modal-dob-section mb-3'>
              <div>
                <span>
                  <input
                    type="radio"
                    name='gender'
                    id='male'
                    value={'male'}
                    onChange={handleGenderChange}
                    checked={basicInfoModalData.gender === 'male'}
                  />
                  <label htmlFor="male">Male</label>
                </span>
                <span>
                  <input
                    type="radio"
                    name='gender'
                    id='female'
                    value={'female'}
                    onChange={handleGenderChange}
                    checked={basicInfoModalData.gender === 'female'}
                  />
                  <label htmlFor="Female">Female</label>
                </span>
              </div>
              {error?.genderError && <p className='text-danger m-0'>{error?.genderError}</p>}
            </div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <div>
                <Form.Label>
                  <span>Address*</span>
                </Form.Label>
                <Form.Control as="textarea" rows={3}
                  value={basicInfoModalData.address}
                  onChange={handleAddressChange}
                  isInvalid={error.addressError}
                />
              </div>
              <Form.Control.Feedback className='d-block' type="invalid">
                {error.addressError}
              </Form.Control.Feedback>
            </Form.Group>
            <div className='gps-location mb-3'>
              <div onClick={() => pickLocation()} className='locationbtn px-3 py-2' tabIndex="0"> <SlLocationPin fontSize={18} /> <span>Pick current location</span></div>
            </div>
            <EventButton className='modal-update-btn' onClick={handleFormSubmit}>
              Update
              {loader && <Spinner className='m-0 ms-2' style={{ width: '18px', height: '18px', borderWidth: '3px' }} animation="border" />}
            </EventButton>
          </Form>

        </div>
      </div>
    </>
  )
}
