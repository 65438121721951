// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.suggested-jobs-main{
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
}

.filter_suggsted_container{
    width: 320px;
    /* flex-grow: 1; */
    height: -moz-fit-content;
    height: fit-content;
    /* border: 1px solid red; */
    overflow-y: auto;
    height: 100vh;
}

.suggestedJobcards-container{
    width: 50%;
    flex-grow: 1;
    height: -moz-fit-content;
    height: fit-content;
    overflow-y: auto;
    height: 100vh;
    /* border: 1px solid yellow; */
}

.suggestedJobcards-container::-webkit-scrollbar, .filter_suggsted_container::-webkit-scrollbar{
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/User/Pages/SuggestedJob/Index.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,wBAAmB;IAAnB,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,2BAA2B;IAC3B,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,wBAAmB;IAAnB,mBAAmB;IACnB,2BAA2B;IAC3B,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,wBAAmB;IAAnB,mBAAmB;IACnB,gBAAgB;IAChB,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".suggested-jobs-main{\n    width: 100%;\n    height: fit-content;\n    display: flex;\n    align-items: flex-start;\n    justify-content: flex-start;\n    gap: 16px;\n}\n\n.filter_suggsted_container{\n    width: 320px;\n    /* flex-grow: 1; */\n    height: fit-content;\n    /* border: 1px solid red; */\n    overflow-y: auto;\n    height: 100vh;\n}\n\n.suggestedJobcards-container{\n    width: 50%;\n    flex-grow: 1;\n    height: fit-content;\n    overflow-y: auto;\n    height: 100vh;\n    /* border: 1px solid yellow; */\n}\n\n.suggestedJobcards-container::-webkit-scrollbar, .filter_suggsted_container::-webkit-scrollbar{\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
