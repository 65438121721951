// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout-formate{
    display: flex;
    justify-content: space-between;
    background: #F3F8FF;
}

.sidebar-container{
    width: 300px;
    height: 100%;
}

.outlet-main{
    height: 100vh;
    overflow-y: auto;
    flex-grow: 1;
}

.out_let_conatiner{
    margin-top: 80px;
    margin: 6rem 1rem 0rem 1rem;
    /* padding: 40px 40px; */
    /* background-color: #ffffff; */
    /* box-shadow: 1px 1px 20px 0px #00000014; */
    border-radius: 12px;
    min-height: 77vh;
}

.outlet-main::-webkit-scrollbar {
    width: 10px;
}

.outlet-main::-webkit-scrollbar-track{
    width: 6px;
    background-color: rgb(244, 244, 244);
}

.outlet-main::-webkit-scrollbar-thumb {
    background-color: #818B99;
    border: 2px solid transparent;
    border-radius: 3px;
    background-clip: content-box;
    width: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/User/component/Layout/Layout.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,2BAA2B;IAC3B,wBAAwB;IACxB,+BAA+B;IAC/B,4CAA4C;IAC5C,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,UAAU;IACV,oCAAoC;AACxC;;AAEA;IACI,yBAAyB;IACzB,6BAA6B;IAC7B,kBAAkB;IAClB,4BAA4B;IAC5B,UAAU;AACd","sourcesContent":[".layout-formate{\n    display: flex;\n    justify-content: space-between;\n    background: #F3F8FF;\n}\n\n.sidebar-container{\n    width: 300px;\n    height: 100%;\n}\n\n.outlet-main{\n    height: 100vh;\n    overflow-y: auto;\n    flex-grow: 1;\n}\n\n.out_let_conatiner{\n    margin-top: 80px;\n    margin: 6rem 1rem 0rem 1rem;\n    /* padding: 40px 40px; */\n    /* background-color: #ffffff; */\n    /* box-shadow: 1px 1px 20px 0px #00000014; */\n    border-radius: 12px;\n    min-height: 77vh;\n}\n\n.outlet-main::-webkit-scrollbar {\n    width: 10px;\n}\n\n.outlet-main::-webkit-scrollbar-track{\n    width: 6px;\n    background-color: rgb(244, 244, 244);\n}\n\n.outlet-main::-webkit-scrollbar-thumb {\n    background-color: #818B99;\n    border: 2px solid transparent;\n    border-radius: 3px;\n    background-clip: content-box;\n    width: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
