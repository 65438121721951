import React, { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  useNavigate,
  Outlet, 
} from "react-router-dom";


// Helper functions
const getAccessTokenForUser = () => localStorage.getItem("userToken");
const isAuthenticatedForUser = () => !!getAccessTokenForUser();
const getUserRole = () => localStorage.getItem("userRole");
const isUserProfileComplete = () => localStorage.getItem("isprofileUserComplete") === "true";

const getCompanyRole = () => localStorage.getItem("companyRole");
const getAccessTokenForCompany = () => localStorage.getItem("token");
const isAuthenticatedForCompany = () => !!getAccessTokenForCompany();
const isCompanyProfileComplete = () => localStorage.getItem("isprofileComplete") === "true";

// User Protected Route
export const UserProtectedRoute = ({ children }) => {
  const role = getUserRole();
  const navigate = useNavigate()
  let u_token = localStorage.getItem('userToken')

  useEffect(() => {
    if (!u_token) {
      navigate("/user/login");
    }
     else if (isUserProfileComplete() && window.location.pathname === "/user/create-profile") {
      navigate("/user/user-profile");
    } else if (!isUserProfileComplete() && window.location.pathname !== "/user/create-profile") {
      navigate("/user/create-profile");
    }
  }, [navigate, u_token]);

  if(!localStorage.getItem('userToken')){
    return <Navigate to="/user/login" />
  }

  if (isUserProfileComplete() && window?.location?.pathname === "/user/create-profile") {
    return <Navigate to="/user/user-profile" />;
  }

  if (!isUserProfileComplete() && window?.location?.pathname !== "/user/create-profile") {
    return <Navigate to="/user/create-profile" />;
  }

  return isAuthenticatedForUser() && role === "user" ? (
  // return isAuthenticatedForUser() ? (
    children
  ) : (
    <Navigate to="/user/login" />
  );
};

// Company Protected Route
export const CompanyProtectedRoute = ({ children }) => {
  const navigate = useNavigate()
  const role = getCompanyRole();
  let c_company = localStorage.getItem('token')

  useEffect(() => {
    if (!c_company) {
      navigate("/company/login");
    } else if (isCompanyProfileComplete() && window.location.pathname === "/company/create-profile") {
      navigate("/company/profile");
    } else if (!isCompanyProfileComplete() && window.location.pathname !== "/company/create-profile") {
      navigate("/company/create-profile");
    }
  }, [ navigate, c_company]);

  if (isCompanyProfileComplete() && window.location.href.includes("/company/create-profile")) {
    return <Navigate to="/company/profile" />;
  }

  if (!isCompanyProfileComplete() && window.location.pathname !== "/company/create-profile") {
    return <Navigate to="/company/create-profile" />;
  }

  return isAuthenticatedForCompany() && role === "company" ? (
  // return isAuthenticatedForCompany() ? (
    children
  ) : (
    <Navigate to="/company/login" />
  );
};
