// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popUpmodal_main {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: auto;
  right: auto;
  background: #00000080;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContent_box {
  background-color: #ffffff;
  width: 560px;
  height: -moz-fit-content;
  height: fit-content;
  padding: 32px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 15px;
}

.modalContent_box > img{
    width: 55px;
    height: 55px;
}

.modalContent_box > h1 {
  color: #181818;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
}
.modalContent_box > p {
  color: #555555;
  font-size: 14px;
  font-weight: 400;
}

.modalBtns > button {
  width: 230px;
  height: 55px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
.modalBtns > button > .spinner-border{
  width: 24px;
  height: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/Company/Component/PopupModal/CloseJobModal/CloseJobModal.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,eAAe;EACf,SAAS;EACT,WAAW;EACX,qBAAqB;EACrB,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,wBAAmB;EAAnB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,6BAA6B;EAC7B,SAAS;AACX;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,SAAS;AACX;AACA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,QAAQ;AACV;AACA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".popUpmodal_main {\n  width: 100%;\n  height: 100vh;\n  position: fixed;\n  top: auto;\n  right: auto;\n  background: #00000080;\n  z-index: 1000;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.modalContent_box {\n  background-color: #ffffff;\n  width: 560px;\n  height: fit-content;\n  padding: 32px;\n  border-radius: 10px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-around;\n  gap: 15px;\n}\n\n.modalContent_box > img{\n    width: 55px;\n    height: 55px;\n}\n\n.modalContent_box > h1 {\n  color: #181818;\n  font-size: 18px;\n  font-weight: 400;\n  margin: 0;\n}\n.modalContent_box > p {\n  color: #555555;\n  font-size: 14px;\n  font-weight: 400;\n}\n\n.modalBtns > button {\n  width: 230px;\n  height: 55px;\n  border-radius: 10px;\n  font-size: 16px;\n  font-weight: 400;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 6px;\n}\n.modalBtns > button > .spinner-border{\n  width: 24px;\n  height: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
