import React from 'react'
import './Profile.css'
import ProfileBasicInfo from './ProfileBasicInfo'
import ProfileInfo from './ProfileInfo'

export default function Profile() {
  return (
    <>
      <div className="profile_main">
        <div className="left_container">
          <ProfileBasicInfo />
        </div>
        <div className='rigt_container'>
          <ProfileInfo />
        </div>
      </div>
    </>
  )
}
