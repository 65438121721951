import React, { useContext, useEffect, useRef, useState } from 'react'
import './CompanyProfile.css'
import { EventButton, StepperBackButton } from '../../../User/component/ReUseComps/Button'
import { RiEditFill } from 'react-icons/ri'
import dummyLogo from '../../../assets/dummy-image.jpg'
import { Link } from 'react-router-dom'
import { Form } from 'react-bootstrap'
import axios from 'axios'
import { COMPANY_URLS } from '../../../Utils/Company_App_Urls'
import { errorPopup, successPopup } from '../../../Utils/ToasterMessage'
import CompanyContext from '../../Component/ContextAPI/CompanyContext'
import Select from 'react-select'

export default function CompanyProfile() {
    const { setNeedTorender, profileLogo, setProfileLogo, needTorender } = useContext(CompanyContext)
    const fileInputRef = useRef(null)
    const [yearList, setYearList] = useState([]);
    const [imageUrl, setImageUrl] = useState('');
    const [industryType, setIndustryType] = useState([])
    const [gstValidationError, setGstValidationError] = useState({ gstValError: '', gstValStatus: false })
    const [profileDetails, setProfileDetails] = useState({
        ownerName: '',
        gstNumber: '',
        establishYear: '',
        noOfEmployees: '',
        email: '',
        address: '',
        contactNo: '',
        about: '',
        companyName: '',
        industryType: '',
        websiteLink: '',
        companyLogo: ''
    })

    const [error, setError] = useState({
        ownerName: '',
        gstNumber: '',
        establishYear: '',
        noOfEmployees: '',
        email: '',
        address: '',
        contactNo: '',
        about: '',
        companyName: '',
        industryType: '',
        websiteLink: '',
        companyLogo: ''
    })

    const [isEnableEidt, setIsEnableEdit] = useState(false)

    const handleEnableEdit = () => {
        setIsEnableEdit(true)
    }

    const handleDisableEdit = () => {
        setIsEnableEdit(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const handleInputChange = (e, field) => {
        const regex = new RegExp(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/);
        if (field === "establishYear") {
            setProfileDetails({ ...profileDetails, [field]: e.value });
        } else {
            setProfileDetails({ ...profileDetails, [field]: e.target.value });
        }
        setError({ ...error, [field]: '' })

        if (field === 'gstNumber') {
            !regex.test(e.target.value) && setError({ ...error, [field]: ' Gst number is not valid' })
        }

    };
    const handleInputChangeEmployees = (e, field) => {
        setProfileDetails({ ...profileDetails, [field]: e.value });
        setError({ ...error, [field]: '' })
    };
    const handleInputChangeIndustry = (e, field) => {
        setProfileDetails({ ...profileDetails, [field]: e.value });
        setError({ ...error, [field]: '' })
    };

    const no_of_employee = [
        {
            value: '1-10',
            label: '1-10'
        },
        {
            value: '11-50',
            label: '11-50'
        },
        {
            value: '51-100',
            label: '51-100'
        },
        {
            value: '101-200',
            label: '101-200'
        },
        {
            value: '201-500',
            label: '201-500'
        },
        {
            value: '500 +',
            label: 'above 500'
        }
    ]

    const getIndustryType = () => {
        axios.get(COMPANY_URLS?.getIndustryType, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                Accept: 'application/json'
            }
        })
            .then((res) => {
                let arr = res?.data?.IndustryList?.map((data) => {
                    return { value: data?.name, label: data?.name }
                })
                setIndustryType(arr);
            })
            .catch((err) => {
            })
    }

    const handleGSTInputChange = (e, field) => {
        let gstNumber = e.target.value;
        setProfileDetails({ ...profileDetails, [field]: e.target.value });
        if (!validateGSTIN(gstNumber)) {
            setGstValidationError({ gstValError: 'Invalid GST number', gstValStatus: true })
        }
        if (validateGSTIN(gstNumber)) {
            setGstValidationError({ gstValError: '', gstValStatus: false })
        }
    }

    const validateGSTIN = (gstin) => {
        const isValidFormat = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(gstin);
        return isValidFormat;
    };


    const profileValidation = () => {

        if (!profileDetails?.companyName) {
            setError({ ...error, companyName: 'Company name is required' });
            return true
        }
        if (!profileDetails?.industryType) {
            setError({ ...error, industryType: 'Industry Type is required' });
            return true
        }
        if (!profileDetails?.websiteLink) {
            setError({ ...error, websiteLink: 'Website link is required' });
            return true
        }
        if (!profileDetails?.about) {
            setError({ ...error, about: 'About is required' });
            return true
        }
        if (!profileDetails?.ownerName) {
            setError({ ...error, ownerName: 'Owner name is required' });
            return true
        }
        if (!profileDetails?.establishYear) {
            setError({ ...error, establishYear: 'Establish year is required' });
            return true
        }
        if (!profileDetails?.gstNumber) {
            setError({ ...error, gstNumber: 'GST number year is required' });
            return true
        }
        if (!profileDetails?.noOfEmployees) {
            setError({ ...error, noOfEmployees: 'Number of employees year is required' });
            return true
        }
        if (!profileDetails?.email) {
            setError({ ...error, email: 'Email is required' });
            return true
        }
        if (!profileDetails?.contactNo) {
            setError({ ...error, contactNo: 'Contact number is required' });
            return true
        }
        if (!profileDetails?.address) {
            setError({ ...error, contactNo: 'Contact number is required' });
            return true
        }

        return false;
    }

    const getCompanyProfileDetails = () => {
        axios.get(`${COMPANY_URLS.get_profile}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                console.log('updateProfileRes>>>', res)
                setProfileDetails({
                    ownerName: res?.data?.data?.companyName || '',
                    gstNumber: res?.data?.data?.gstNumber || '',
                    establishYear: res?.data?.data?.establishYear || '',
                    noOfEmployees: res?.data?.data?.noOfEmployees || '',
                    email: res?.data?.data?.email || '',
                    address: res?.data?.data?.location || '',
                    contactNo: res?.data?.data?.contactNo || '',
                    about: res?.data?.data?.description || '',
                    companyName: res?.data?.data?.companyName || '',
                    industryType: res?.data?.data?.preferredIndustry || '',
                    websiteLink: res?.data?.data?.weburl || '',
                    companyLogo: res?.data?.data?.companylogo || ''
                })
                setNeedTorender(prev => !prev);
                setProfileLogo(res?.data?.data?.companylogo);
            })
            .catch((err) => { })
    }

    const handleClickFileUPload = (e) => {
        fileInputRef.current.click();
    }
    const handleFileInputChange = (e) => {
        let files = e?.target?.files[0]
        setImageUrl(URL.createObjectURL(files))
        uploadImageInS3(files)
    }

    const uploadImageInS3 = async (files) => {
        const formData = new FormData()
        formData.append('image', files);

        await axios.post(`${COMPANY_URLS.companyImage}`, formData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res) => {
                successPopup(res?.data?.message)
                setImageUrl(res?.data?.data?.imageUrl)
                setProfileDetails({ ...profileDetails, companyLogo: res?.data?.data?.imageUrl })
            })
            .catch((err) => {
                errorPopup(err?.response?.data?.message);
            })
    }

    const updateCompanyProfile = async () => {
        const data = {
            companyName: profileDetails?.companyName,
            companylogo: profileDetails?.companyLogo,
            contactNo: profileDetails?.contactNo,
            description: profileDetails?.about,
            email: profileDetails?.email,
            establishYear: profileDetails?.establishYear,
            gstNumber: profileDetails?.gstNumber,
            location: profileDetails?.address,
            noOfEmployees: profileDetails?.noOfEmployees,
            preferredIndustry: profileDetails.industryType,
            weburl: profileDetails?.websiteLink,
        }

        if (!profileValidation()) {
            await axios.patch(`${COMPANY_URLS.update_profile}`, data, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            })
                .then((res) => {
                    successPopup(res?.data?.message)
                    setIsEnableEdit(false)
                    getCompanyProfileDetails()
                })
                .catch(err => {
                    errorPopup(err?.response?.data?.message);
                })
        }

    }

    const establishYearList = () => {
        let currYear = new Date().getFullYear();
        let yearListArr = [];
        for (let i = 0; i <= currYear - 1900; i++) {
            yearListArr.push({ value: currYear - i, label: currYear - i });
        }
        setYearList(yearListArr)
    }

    useEffect(() => {
        establishYearList();
        getCompanyProfileDetails();
        getIndustryType()
    }, [])

    // console.log('profileLogo::', profileLogo)
    // console.log('profileDetails::',profileDetails?.industryType )

    console.log('profileDetails::', profileDetails)
    return (
        <>
            <div className="company-profile-main d-flex flex-column gap-3 mb-3">
                <div className="profile-header d-flex justify-content-between">
                    {
                        isEnableEidt ?
                            <>
                                <h1>Edit Company Profile</h1>
                                <div className='editProfile-btn-group d-flex gap-4'>
                                    <StepperBackButton onClick={handleDisableEdit}>Cancel</StepperBackButton>
                                    <EventButton onClick={updateCompanyProfile}>Save Changes</EventButton>
                                </div>
                            </> :
                            <>
                                <h1>Company Profile</h1>
                                <StepperBackButton onClick={handleEnableEdit}> <RiEditFill /> Edit Profile</StepperBackButton>
                            </>
                    }
                </div>
                {/* Company name edit */}
                {
                    isEnableEidt ?
                        <div className='edit-company-name d-flex gap-4 align-items-center flex-wrap'>
                            <div className="Edit-company-logo-box">
                                <img src={profileDetails?.companyLogo || dummyLogo} alt="dummyLogo" />
                                <input className='d-none' ref={fileInputRef} onChange={handleFileInputChange} type="file" />
                                <StepperBackButton onClick={handleClickFileUPload}>Change Logo</StepperBackButton>
                            </div>
                            <div className='company-title-edit'>
                                <Form.Group className="mb-2" controlId="formBasicEmail">
                                    <Form.Label>Company Name*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={profileDetails?.companyName}
                                        onChange={(e) => handleInputChange(e, 'companyName')}
                                        isInvalid={error.companyName}
                                    />
                                    <Form.Control.Feedback type="invalid"> {error.companyName}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-2" controlId="formBasicEmail">
                                    <Form.Label>Industry Type*</Form.Label>
                                    {/* <Form.Control
                                        type="text"
                                        value={profileDetails?.industryType}
                                        onChange={(e) => handleInputChangeIndustry(e, 'industryType')}
                                        isInvalid={error.industryType}
                                    /> */}
                                    <div className='industrySelect_profile'>
                                        <Select
                                            options={industryType}
                                            value={{
                                                value: profileDetails.industryType,
                                                label: profileDetails.industryType
                                            }}
                                            onChange={(e) => handleInputChangeIndustry(e, 'industryType')}
                                        />
                                    </div>
                                    {error.industryType && <p className='text-danger m-0'>{error.industryType}</p>}

                                    <Form.Control.Feedback type="invalid">{error.industryType}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-2" controlId="formBasicEmail">
                                    <Form.Label>Website Link*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={profileDetails?.websiteLink}
                                        onChange={(e) => handleInputChange(e, 'websiteLink')}
                                        isInvalid={error.websiteLink}
                                    />
                                    <Form.Control.Feedback type="invalid">{error.websiteLink}</Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        </div> :
                        <div className="profile-title d-flex gap-3">
                            <div className="company-logo-box">
                                <img src={profileDetails?.companyLogo || dummyLogo} alt="dummyLogo" />
                            </div>
                            <div className='company-title-name d-flex flex-column'>
                                <h1 className='text-capitalize'>{profileDetails?.companyName}</h1>
                                <span className='text-capitalize'>{profileDetails?.industryType}</span>
                                <a className='' 
                                href={profileDetails?.websiteLink?.startsWith('http') 
                                    ? profileDetails.websiteLink 
                                    : `http://${profileDetails?.websiteLink}`} 
                                target='_blank'>{profileDetails?.websiteLink}</a>
                            </div>
                        </div>
                }

                <div className={`profile-about about-textEdit ${isEnableEidt && 'active-about-textEdit'}`}>
                    <h1>About*</h1>
                    <Form.Control as="textarea" className='textAreaResize' rows={5}
                        value={profileDetails?.about}
                        onChange={(e) => handleInputChange(e, 'about')}
                        disabled={!isEnableEidt}
                        isInvalid={error.about}
                    />
                    <Form.Control.Feedback type="invalid">{error.about}</Form.Control.Feedback>
                </div>
                <div className="profile-about">
                    <h1>Company Basic Info</h1>
                    <Form onSubmit={handleSubmit} className={`${isEnableEidt && 'formInput-active'}`}>
                        <div className='profile-input-group d-flex flex-column flex-md-row justify-content-between flex-wrap align-items-center gap-0 gap-md-5'>
                            <Form.Group className="mb-2" controlId="formBasicEmail">
                                <Form.Label>Owner Name*</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={profileDetails?.ownerName}
                                    onChange={(e) => handleInputChange(e, 'ownerName')}
                                    disabled={!isEnableEidt}
                                    isInvalid={error.ownerName}
                                />
                                <Form.Control.Feedback type="invalid">{error.ownerName}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-2" controlId="formBasicEmail">
                                <Form.Label>Establish Year*</Form.Label>
                                {/* <Form.Control
                                    type="text"
                                    value={profileDetails?.establishYear}
                                    onChange={(e) => handleInputChange(e, 'establishYear')}
                                    isInvalid={error.establishYear}
                                    /> */}
                                <Select
                                    className={`borderdeProf ${!isEnableEidt && 'disbledSelect'} `}
                                    isDisabled={!isEnableEidt}
                                    onChange={(e) => handleInputChange(e, 'establishYear')}
                                    value={{
                                        value: Number(profileDetails?.establishYear),
                                        label: Number(profileDetails?.establishYear)
                                    }}
                                    options={yearList}
                                />
                                {error.establishYear && <p className='text-danger m-0'>{error.establishYear}</p>}
                                {/* <Form.Control.Feedback type="invalid">{error.establishYear}</Form.Control.Feedback> */}
                            </Form.Group>
                        </div>
                        <div className='profile-input-group d-flex flex-column flex-md-row justify-content-between flex-wrap align-items-center gap-0 gap-md-5'>
                            <Form.Group className="mb-2" controlId="formBasicEmail">
                                <Form.Label>GST Number*</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={profileDetails?.gstNumber}
                                    onChange={(e) => handleGSTInputChange(e, 'gstNumber')}
                                    disabled={!isEnableEidt}
                                    isInvalid={error.gstNumber || gstValidationError.gstValError}
                                // isInvalid={!gstValidationStatus}
                                // isValid={gstValidationStatus}
                                />
                                <Form.Control.Feedback type="invalid">{error.gstNumber}</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{gstValidationError.gstValError}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-2" controlId="formBasicEmail">
                                <Form.Label>Number Of Employees*</Form.Label>
                                {/* <Form.Control
                                    type="text"
                                    value={profileDetails?.noOfEmployees}
                                    onChange={(e) => handleInputChange(e, 'noOfEmployees')}
                                    isInvalid={error.noOfEmployees}
                                    /> */}
                                {/* <Form.Select
                                    disabled={!isEnableEidt}
                                    value={profileDetails?.noOfEmployees}
                                    onChange={(e) => handleInputChange(e, 'noOfEmployees')}
                                    isInvalid={error.noOfEmployees}
                                >
                                    <option value="" disabled selected>Select Number of Employees</option>
                                    <option value="1-10">1-10</option>
                                    <option value="11-50">11-50</option>
                                    <option value="51-100">51-100</option>
                                    <option value="101-200">101-200</option>
                                    <option value="201-500">201-500</option>
                                    <option value="500 +">500 +</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">{error.noOfEmployees}</Form.Control.Feedback> */}
                                <Select
                                    className={`borderdeProf ${!isEnableEidt && 'disbledSelect'} `}
                                    isDisabled={!isEnableEidt}
                                    placeholder="Select Number of Employees"
                                    options={no_of_employee}
                                    value={{
                                        value: profileDetails.noOfEmployees,
                                        label: profileDetails.noOfEmployees
                                    }}
                                    onChange={(e) => handleInputChangeEmployees(e, 'noOfEmployees')}
                                />
                                {error.noOfEmployees && <p className='text-danger m-0'>{error.noOfEmployees}</p>}
                            </Form.Group>
                        </div>
                    </Form>
                </div>
                <div className="profile-about">
                    <h1>Contact & Address</h1>
                    <Form
                    // className={`${isEnableEidt && 'formInput-active'}`}
                    >
                        <div className='profile-input-group d-flex flex-column flex-md-row justify-content-between flex-wrap align-items-center gap-0 gap-md-5'>
                            <Form.Group className="mb-2" controlId="formBasicEmail">
                                <Form.Label>Email Address*</Form.Label>
                                <Form.Control
                                    type="email"
                                    value={profileDetails?.email}
                                    onChange={(e) => handleInputChange(e, 'email')}
                                    disabled={true}
                                    isInvalid={error.email}
                                />
                                <Form.Control.Feedback type="invalid">{error.email}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-2" controlId="formBasicEmail">
                                <Form.Label>Contact Number*</Form.Label>
                                <Form.Control
                                    type="number"
                                    className='contact-address'
                                    value={profileDetails?.contactNo}
                                    onChange={(e) => handleInputChange(e, 'contactNo')}
                                    disabled={!isEnableEidt}
                                    isInvalid={error.contactNo}
                                />
                                <Form.Control.Feedback type="invalid">{error.contactNo}</Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        <div className='profile-input-group d-flex flex-column flex-md-row justify-content-between flex-wrap align-items-center gap-0 gap-md-5'>
                            <Form.Group className="mb-3 w-100">
                                <Form.Label>Address*</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    className='textAreaResize contact-address'
                                    rows={5}
                                    value={profileDetails?.address}
                                    onChange={(e) => handleInputChange(e, 'address')}
                                    disabled={!isEnableEidt}
                                    isInvalid={error.address}
                                />
                                <Form.Control.Feedback type="invalid">{error.address}</Form.Control.Feedback>
                            </Form.Group>
                        </div>
                    </Form>
                </div>
                {isEnableEidt && <EventButton id='edit-co-btn' style={{ width: '350px', margin: '0 auto' }} onClick={updateCompanyProfile}>Save Changes</EventButton>}
            </div>
        </>
    )
}
