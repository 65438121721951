import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import AuthOutlineBtn, { AuthFillButton, StepperBackButton } from '../../component/ReUseComps/Button';
import galleryIcon from '../../../assets/cameraIcon.png';
import UserContext from '../../component/ContextApi/UserContext';
import photoImage from '../../../assets/photoImage.png'
import { errorPopup, successPopup } from '../../../Utils/ToasterMessage';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../../Utils/App_urls';
import Webcam from 'react-webcam';
import axios from 'axios';
import './ProfilePhoto.css';
import './BasicInfo.css';
import { FaAngleLeft } from 'react-icons/fa6';

const videoConstraints = {
    width: '100%',
    height: '100%',
    facingMode: "user"
};

export default function ProfilePhoto() {
    const { step, setStep, userForm, setProfileImageUrl, setUserForm, setUserprofileComplete } = useContext(UserContext);
    const navigate = useNavigate();
    const fileRef = useRef(null);
    const webcamRef = useRef(null);
    const [image, setImage] = useState(null);
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [showCamera, setShowCamera] = useState(false);
    const [error, setError] = useState({ errorMessage: '', errorStatus: true });

    const onImageChange = async (event) => {
        const file = event?.target?.files?.[0];
        if (file) {
            setProfilePhoto(file);
            setImage(URL.createObjectURL(file));
            setError({ errorMessage: '', errorStatus: false });
        }
    };

    const handleOpenGallery = () => {
        setShowCamera(false);
        fileRef.current.click();
    };

    const handleCaptureImage = () => {
        setShowCamera(true);
    };

    const capture = useCallback(async () => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImage(imageSrc);
        setShowCamera(false);
        handleConvert(imageSrc);
        setError({ errorMessage: '', errorStatus: false });
    }, [webcamRef]);

    const handleConvert = (base64ImageSrc) => {
        const byteCharacters = atob(base64ImageSrc?.split(',')?.[1]);
        const byteNumbers = new Array(byteCharacters?.length);
        for (let i = 0; i < byteCharacters?.length; i++) {
            byteNumbers[i] = byteCharacters?.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/jpeg' });
        setProfilePhoto(blob);
    };

    const uploadImageinS3 = async (data) => {
        const formData = new FormData();
        formData.append('image', data);
        await axios.post(`${URLS?.uploadImage}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('userToken')}`
            }
        })
            .then((res) => {
                console.log('Imageres', res);
                setProfilePhoto(res?.data?.data?.imageUrl)
                setImage(res?.data?.data?.imageUrl)
                successPopup(res?.data?.message)
                setUserForm(userForm => ({ ...userForm, profilePhoto: res?.data?.data?.imageUrl }));

                // return res
            })
            .catch((err) => {
                console.error('ImageS3Err', err);
            })
    };

    const uploadProfileData = async () => {

        const imageUploadRes = await uploadImageinS3(profilePhoto);
        // setUserForm(userForm => ({ ...userForm, profilePhoto: imageUploadRes?.data?.data?.imageUrl || profilePhoto }));
        console.log('imageUrl::', userForm);


    };

    const handleSubmitProfile = async () => {
        if (!profilePhoto) {
            setError({ errorMessage: 'Image is required.', errorStatus: true });
        } else {
            // setUserForm(userForm => ({ ...userForm, profilePhoto: imageUploadRes?.data?.data?.imageUrl || profilePhoto }));
            // setUserprofileComplete(true);
            await uploadImageinS3(profilePhoto);
            setStep(prev => prev + 1);

        }
    };

    useEffect(() => {
        if (userForm.profilePhoto) {
            setProfilePhoto(userForm?.profilePhoto);
            setImage(userForm?.profilePhoto)
        }
    }, [userForm]);

    console.log('userDoe::', userForm)

    return (
        <>
            <div className='d-flex'>
                <div className='w-50 flex-grow-1 pe-2 profile_photo_img responsive_cont'>
                    <div className="profilePhoto_main ">
                        <h1 className='page-title-tag d-flex align-items-center mt-md-3'>
                            <span onClick={() => setStep(prev => prev - 1)} className='d-flex'><FaAngleLeft /></span>
                            Upload Profile Photo
                        </h1>
                        {error.errorMessage && <p className='m-0 text-danger'>{error.errorMessage}</p>}
                        {/* <div className='picture-sub-heading mb-3'>
                            <p>Upload Profile Photo</p>
                            <p>Add your photo so that people can recognize you</p>
                        </div> */}
                        <div className="photoEmptyFrame mb-3">
                            {showCamera ? (
                                <div className='cameraBox'>
                                    <Webcam
                                        audio={false}
                                        ref={webcamRef}
                                        screenshotFormat="image/jpeg"
                                        videoConstraints={videoConstraints}
                                    />
                                </div>
                            ) : (
                                image ? (
                                    <img style={{ width: '100%', height: '100%', borderRadius: 'inherit' }} alt="preview image" src={image} />
                                ) : (
                                    <img src={galleryIcon} alt="galleryIcon" />
                                )
                            )}
                        </div>
                        <div className='picAccessbtns d-flex flex-column gap-3 w-100 align-items-center justify-content-between'>
                            <StepperBackButton className='hoverSkip w-100' onClick={handleOpenGallery}>Upload from gallery</StepperBackButton>
                            {!showCamera ? (
                                <StepperBackButton className='hoverSkip w-100' onClick={handleCaptureImage}>Open Camera</StepperBackButton>
                            ) : (
                                <StepperBackButton className='hoverSkip w-100' onClick={capture}>Capture Image</StepperBackButton>
                            )}
                            <input className='d-none' onChange={onImageChange} ref={fileRef} type="file" />
                        </div>
                    </div>
                    <div className="navigation-buttons d-flex gap-3 w-100">
                        <StepperBackButton className={`${step === 0 && 'd-none'} hoverSkip w-100`} onClick={() => setStep(prev => prev - 1)} disabled={step === 0}>Back</StepperBackButton>
                        <AuthFillButton className='hoverSkip w-100' onClick={handleSubmitProfile}>Next</AuthFillButton>
                    </div>
                </div>
                <div className='steper-image-container h-md-100'>
                    <img src={photoImage} alt="photoImage" />
                </div>
            </div>
        </>
    );
}
