import React from 'react'
import './Index.css'
import SuggestedFilterOptions from './SuggestedFilterOptions'
import SuggestedJob from './SuggestedJob'
import FilterJob from '../ApplyJob/FilterJob/FilterJob'

export default function Index() {
  return (
    <>
        <div className="suggested-jobs-main">
            <div className="filter_suggsted_container py-1 px-1">
                {/* <SuggestedFilterOptions/> */}
                <FilterJob/>
            </div>
            <div className="suggestedJobcards-container">
                <SuggestedJob/>
            </div>
        </div>
    </>
  )
}
