import React, { useContext, useEffect, useState } from 'react'
import JobDetails from './JobDetails'
import AppliedCandidatesList from './AppliedCandidatesList'
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate, useParams } from 'react-router-dom';
import './PostedJobDetails.css'
import CompanyContext from '../../Component/ContextAPI/CompanyContext';
import { COMPANY_URLS } from '../../../Utils/Company_App_Urls';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';

export default function PostedJobDetails() {
    const {needTorender} = useContext(CompanyContext)
    const [isLoaderOnDetailsPage, setIsLoaderOnDetailsPage] = useState(false)
    const [jobDetails, setJobDetails] = useState(null)
    const navigate = useNavigate()
    const jobId = useParams()


    const getSingleJobDetails = async () => {
        await axios.get(`${COMPANY_URLS.getSingleJob}`, {
            params: {
                id: jobId?.id,
                page:1,
                limit:10
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                const result = res?.data?.result?.data?.[0] || [];
                setJobDetails(result)
            })
            .catch((err) => {})
            .finally(()=>setIsLoaderOnDetailsPage(false))
    }

    useEffect(() => {
        jobId?.id && getSingleJobDetails()
    }, [needTorender])

    return (
        <>
            <div className="postedJobDetails_main">
                <h1 className='back-naviagate-arrow-grp'><FaArrowLeftLong onClick={() => navigate(-1)} /> <span onClick={() => navigate(-1)}>Posted Job Details</span></h1>
                {!isLoaderOnDetailsPage ?
                    <div className='d-flex flex-column flex-md-row justify-content-between align-items-start gap-3'>
                        <div className='job-details-wrapper p-sm-1'>
                            <h1>Job Details</h1>
                            <JobDetails singleJobDetails={jobDetails}  />
                        </div>
                        <div className='applied-candidates-wrapper p-sm-1'>
                            <AppliedCandidatesList getAllAppliedCandidates={jobDetails?.appliedUserProfile} />
                        </div>
                    </div> :
                    <div className='w-100 h-100 d-flex align-items-center justify-content-center h-100'>
                        <Spinner style={{ width: '50px', height: '50px' }} animation="border" role="status" />
                    </div>
                }
            </div>
        </>
    )
}
