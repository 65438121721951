// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.educationButton{
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
}

.education-option{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
}

.edu_certification {
    width: 100%;
    background-color: #fff;
    box-shadow: 1px 1px 5px 0px #00000026;
    padding: 10px 0px;
    border-radius: 10px;
    font-size: 16px;
    border: none;
  }
  
  .edu_certification:focus,
  .edu_certification:active {
    background-color: #3284ff;
    color: #ffffff;
  }
  
  .edu_certification:hover {
    opacity: 0.9;
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
      rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
      rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  }`, "",{"version":3,"sources":["webpack://./src/User/component/EditProfilePopUps/EditEducation/EditEducation.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,wBAAmB;IAAnB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,SAAS;AACb;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,2BAA2B;IAC3B,SAAS;AACb;;AAEA;IACI,WAAW;IACX,sBAAsB;IACtB,qCAAqC;IACrC,iBAAiB;IACjB,mBAAmB;IACnB,eAAe;IACf,YAAY;EACd;;EAEA;;IAEE,yBAAyB;IACzB,cAAc;EAChB;;EAEA;IACE,YAAY;IACZ;;iDAE6C;EAC/C","sourcesContent":["\n.educationButton{\n    width: 100%;\n    height: fit-content;\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    gap: 1rem;\n}\n\n.education-option{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: flex-start;\n    gap: 1rem;\n}\n\n.edu_certification {\n    width: 100%;\n    background-color: #fff;\n    box-shadow: 1px 1px 5px 0px #00000026;\n    padding: 10px 0px;\n    border-radius: 10px;\n    font-size: 16px;\n    border: none;\n  }\n  \n  .edu_certification:focus,\n  .edu_certification:active {\n    background-color: #3284ff;\n    color: #ffffff;\n  }\n  \n  .edu_certification:hover {\n    opacity: 0.9;\n    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,\n      rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,\n      rgba(255, 255, 255, 0.08) 0px 1px 0px inset;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
