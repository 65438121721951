import React from 'react'
import { Form } from 'react-bootstrap'
import playStore from '../../../assets/playStore.png'

export default function DownloadApp() {
    return (
        <>
            <div className='downloadApp_main'>
                <div className="stepsTitle">
                    <h1 className='w-75'>Download the Kaamvala for Recruiters App</h1>
                    <div className='hlfbtnBorder' />
                </div>

                <div className="container downloadAppBox">
                    <div className="qrApp">
                        <p>Scan this QR code</p>
                    </div>
                    <div className="appContent">
                        <p className='mb-1'>or tell us your phone number, we’ll send you an SMS link</p>
                        <div className='d-flex gap-4 flex-wrap'>
                            <div className='d-flex flex-wrap gap-2'>
                                <Form.Select className='countryCodeBox' aria-label="Default select example">
                                    <option defaultValue={'+91'} value="+91">+91</option>
                                    <option value="+1">+1</option>
                                    <option value="+21">+21</option>
                                </Form.Select>
                                <input className='phoneNumberInput' type="text" placeholder='938 - 289 - 4892' />
                            </div>
                            <button className='postJobbtn'>Download App</button>
                        </div>
                        <img className='mt-2' src={playStore} alt="playStore" />
                    </div>
                </div>
            </div>
        </>
    )
}
