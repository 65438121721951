import React from 'react'
import './Applications.css'

export default function Applications() {
  return (
    <>
      <div>
        <h1>Coming Soon</h1>
      </div>
    </>
  )
}
