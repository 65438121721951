import React from 'react'
import { FaLocationDot } from 'react-icons/fa6'
import { GoArrowRight } from 'react-icons/go'
import { FaBookmark } from 'react-icons/fa'
import zomato from '../../../assets/octicon_alert-24.png'
import bagExp from '../../../assets/bag-solid.png'
import currencyIcon from '../../../assets/cash-multiple.png'
import './SuggestedJob.css'

export default function SuggestedJob() {
    
    return (
        <>
            <div className="savedjobCards d-flex justify-content-between flex-wrap gap-3 mb-3 mx-1 my-1">
                <div className='d-flex flex-column flex-wrap justify-content-center gap-3'>
                    <div className='d-flex align-items-start justify-content-between gap-2'>
                        <div className='d-flex align-items-start justify-content-start gap-2'>
                            <div className="imageConatiner">
                                <img src={zomato} alt="zomato" />
                            </div>
                            <div className="savedJobTitle">
                                <h1>Software Develper</h1>
                                <p>Advancing Packaging Private Limited</p>
                            </div>
                        </div>
                        <p>Posted 1 Day Ago</p>
                    </div>
                    <div className="job_salary_loc d-flex align-items-center flex-wrap gap-4">
                        <div><img src={bagExp} alt="bagforexp" /><span>Fresher</span></div>
                        <div><img src={currencyIcon} alt="currencyIcon" /><span>1000k</span></div>
                        <div><FaLocationDot fontSize={18} /><span>On-Site</span></div>
                    </div>
                    <div className="preferences_savedJob d-flex align-items-center justify-content-start flex-wrap gap-2">
                        <div>Full-Time</div>
                        <div>Part-Time</div>
                    </div>
                    <div className="savedJobDes">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, vel esse dolores repellendus ipsa laboriosam voluptates illum quos quisquam perferendis excepturi ut iusto repellat architecto incidunt porro laudantium temporibus. Explicabo modi quibusdam ad totam nulla quaerat corporis qui delectus eaque quis voluptate excepturi animi harum aperiam, odio quam unde numquam ex quasi doloribus.
                    </div>
                    <div className="savedJobSkills d-flex align-items-center justify-content-start flex-wrap gap-2">
                        <div>Core Jave</div>
                        <div>Communication</div>
                    </div>
                </div>
                <div className="jobCardsButton d-flex flex-column align-items-end justify-content-center gap-3">
                    <button className="viewJobbtn">View <GoArrowRight fontSize={20} /></button>
                    {/* <button className="UnsavedJob">Not Interested <FaBookmark fontSize={17} /></button> */}
                    <button className="UnsavedJob">Not Interested</button>
                </div>
            </div>
            <div className="savedjobCards d-flex justify-content-between flex-wrap gap-3 mb-3 mx-1 my-1">
                <div className='d-flex flex-column flex-wrap justify-content-center gap-3'>
                    <div className='d-flex align-items-start justify-content-between gap-2'>
                        <div className='d-flex align-items-start justify-content-start gap-2'>
                            <div className="imageConatiner">
                                <img src={zomato} alt="zomato" />
                            </div>
                            <div className="savedJobTitle">
                                <h1>Software Develper</h1>
                                <p>Advancing Packaging Private Limited</p>
                            </div>
                        </div>
                        <p>Posted 1 Day Ago</p>
                    </div>
                    <div className="job_salary_loc d-flex align-items-center flex-wrap gap-4">
                        <div><img src={bagExp} alt="bagforexp" /><span>Fresher</span></div>
                        <div><img src={currencyIcon} alt="currencyIcon" /><span>1000k</span></div>
                        <div><FaLocationDot fontSize={18} /><span>On-Site</span></div>
                    </div>
                    <div className="preferences_savedJob d-flex align-items-center justify-content-start flex-wrap gap-2">
                        <div>Full-Time</div>
                        <div>Part-Time</div>
                    </div>
                    <div className="savedJobDes">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, vel esse dolores repellendus ipsa laboriosam voluptates illum quos quisquam perferendis excepturi ut iusto repellat architecto incidunt porro laudantium temporibus. Explicabo modi quibusdam ad totam nulla quaerat corporis qui delectus eaque quis voluptate excepturi animi harum aperiam, odio quam unde numquam ex quasi doloribus.
                    </div>
                    <div className="savedJobSkills d-flex align-items-center justify-content-start flex-wrap gap-2">
                        <div>Core Jave</div>
                        <div>Communication</div>
                    </div>
                </div>
                <div className="jobCardsButton d-flex flex-column align-items-end justify-content-center gap-3">
                    <button className="viewJobbtn">View <GoArrowRight fontSize={20} /></button>
                    {/* <button className="UnsavedJob">Not Interested <FaBookmark fontSize={17} /></button> */}
                    <button className="UnsavedJob">Not Interested</button>
                </div>
            </div>
            <div className="savedjobCards d-flex justify-content-between flex-wrap gap-3 mb-3 mx-1 my-1">
                <div className='d-flex flex-column flex-wrap justify-content-center gap-3'>
                    <div className='d-flex align-items-start justify-content-between gap-2'>
                        <div className='d-flex align-items-start justify-content-start gap-2'>
                            <div className="imageConatiner">
                                <img src={zomato} alt="zomato" />
                            </div>
                            <div className="savedJobTitle">
                                <h1>Software Develper</h1>
                                <p>Advancing Packaging Private Limited</p>
                            </div>
                        </div>
                        <p>Posted 1 Day Ago</p>
                    </div>
                    <div className="job_salary_loc d-flex align-items-center flex-wrap gap-4">
                        <div><img src={bagExp} alt="bagforexp" /><span>Fresher</span></div>
                        <div><img src={currencyIcon} alt="currencyIcon" /><span>1000k</span></div>
                        <div><FaLocationDot fontSize={18} /><span>On-Site</span></div>
                    </div>
                    <div className="preferences_savedJob d-flex align-items-center justify-content-start flex-wrap gap-2">
                        <div>Full-Time</div>
                        <div>Part-Time</div>
                    </div>
                    <div className="savedJobDes">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, vel esse dolores repellendus ipsa laboriosam voluptates illum quos quisquam perferendis excepturi ut iusto repellat architecto incidunt porro laudantium temporibus. Explicabo modi quibusdam ad totam nulla quaerat corporis qui delectus eaque quis voluptate excepturi animi harum aperiam, odio quam unde numquam ex quasi doloribus.
                    </div>
                    <div className="savedJobSkills d-flex align-items-center justify-content-start flex-wrap gap-2">
                        <div>Core Jave</div>
                        <div>Communication</div>
                    </div>
                </div>
                <div className="jobCardsButton d-flex flex-column align-items-end justify-content-center gap-3">
                    <button className="viewJobbtn">View <GoArrowRight fontSize={20} /></button>
                    {/* <button className="UnsavedJob">Not Interested <FaBookmark fontSize={17} /></button> */}
                    <button className="UnsavedJob">Not Interested</button>
                </div>
            </div>
        </>
    )
}
