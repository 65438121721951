import styled from "styled-components";

export const AuthOutlineBtn = styled.button`
  width: 90%;
  padding: 5px 1rem;
  border: 1px solid #00034533;
  border-radius: 30px;
  box-shadow: 1px 1px 5px 0px #00000033;
  color: #383838;
  font-size: 20px;
  font-weight: 400;
  background: #fff;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.35);
  }
`;

export const AuthFillButton = styled.button`
  width: 90%;
  padding: 5px 1rem;
  border: 1px solid #00034533;
  background-color: #3284ff;
  border-radius: 30px;
  box-shadow: 1px 1px 5px 0px #00000033;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.35);
  }
`;
export const StepperBackButton = styled.button`
  width: 100%;
  padding: 5px 1rem;
  border: 2px solid #3284ff;
  background-color: #ffffff;
  border-radius: 30px;
  box-shadow: 1px 1px 5px 0px #00000033;
  color: #3284ff;
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  &:hover {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.35);
  }
`;

export const EventButton = styled.button`
  min-width: 150px;
  background-color: #3284ff;
  border-radius: 32px;
  padding: 8px 16px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  border: none;
  outline: none;

  &:hover {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.35);
  }
`;
export const PaginationBtn = styled.button`
  background-color: transparent;
  color: #3284ff;
  border-radius: 10px;
  font-weight: 500;
  font-size: 17px;
  min-width: 40px;
  height: 40px;
  outline: none;
  border: unset;

  &:hover {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.35);
  }
`;

export default AuthOutlineBtn;
