// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-main{
    width: 100%;
    padding: 1rem 1rem;
    background: #3284ff;
    /* border-top: 1px solid #EAF4FF; */
}
.footer-main > div{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 15px;
    font-weight: 400;
}

.iconBox{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #3284FF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-section{
    width: 100%;
    border-top: 1px solid #00000015;
    /* margin-top: 1rem; */
}

.footer-partSection{
    width: 300px;
}

.footerList{
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 1px;
    font-size: 18px;
    font-weight: 400;
}

.footer-partSection > h1{
    border-bottom:2px solid;
    border-image: linear-gradient(to right, #2D4DA8 50%, transparent 50%) 100% 1;
}`, "",{"version":3,"sources":["webpack://./src/User/component/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,mBAAmB;IACnB,mCAAmC;AACvC;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,+BAA+B;IAC/B,sBAAsB;AAC1B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,qBAAqB;IACrB,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;IACvB,4EAA4E;AAChF","sourcesContent":[".footer-main{\n    width: 100%;\n    padding: 1rem 1rem;\n    background: #3284ff;\n    /* border-top: 1px solid #EAF4FF; */\n}\n.footer-main > div{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: #fff;\n    font-size: 15px;\n    font-weight: 400;\n}\n\n.iconBox{\n    width: 50px;\n    height: 50px;\n    border-radius: 50%;\n    background-color: #3284FF;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.footer-section{\n    width: 100%;\n    border-top: 1px solid #00000015;\n    /* margin-top: 1rem; */\n}\n\n.footer-partSection{\n    width: 300px;\n}\n\n.footerList{\n    list-style-type: none;\n    display: flex;\n    flex-direction: column;\n    gap: 1px;\n    font-size: 18px;\n    font-weight: 400;\n}\n\n.footer-partSection > h1{\n    border-bottom:2px solid;\n    border-image: linear-gradient(to right, #2D4DA8 50%, transparent 50%) 100% 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
