import React, { useContext, useEffect, useRef, useState } from 'react'
import UserContext from '../../component/ContextApi/UserContext'
import { AuthFillButton, StepperBackButton } from '../../component/ReUseComps/Button'
import { LiaAngleLeftSolid } from "react-icons/lia";
import { FaAngleLeft } from "react-icons/fa6";
import educationImage from '../../../assets/educationImage.svg'
import './BasicInfo.css'


export default function Education() {
  const buttonsRef = useRef([])
  const { step, setStep, userForm, setUserForm } = useContext(UserContext)
  const [highEducation, setHighEducation] = useState('')
  const [error, setError] = useState('')
  const qualificationArr = ['Uneducated', '10th or below 10th', '12th Pass', 'Graduation', 'ITI', 'Diploma', 'Polytechnic']

  const handleEdication = (qualif) => {
    setHighEducation(qualif)
    setError('')
  }

  const handlleSubmit = () => {
    if (highEducation === '' || highEducation?.length === 0) {
      setError('Education is required.')
    } else {
      setUserForm(userForm => ({ ...userForm, education: highEducation }));
    }
    setStep(step => step + 1);
  }

  const handleBackStep = () => {
    setUserForm(userForm => ({ ...userForm, education: highEducation }));
    setStep(prev => prev - 1);
  }

  useEffect(() => {
    if (userForm?.education !== '') {
      const index = qualificationArr.indexOf(highEducation)
      // if (index !== -1) {
      //   buttonsRef.current[index].focus()
      // }
    }
  }, [highEducation])

  useEffect(() => {
    if (userForm?.education) {
      setHighEducation(userForm?.education)
    }
  }, [userForm?.education])

  console.log('userFormDepartment', userForm)

  return (
    <>
      <div className='w-100 d-flex'>
        <div className="educationQalification responsive_cont w-50 pt-md-5 pt-3 flex-grow-1 basicInfo">
          <h1 className='page-title-tag d-flex align-items-center'>
            <span onClick={() => setStep(prev => prev - 1)} className='d-flex'><FaAngleLeft fontSize={24} />
            </span>Education</h1>
          <p>Your Highest Education*</p>
          <div className="eduOption d-flex flex-column gap-1">
            {
              qualificationArr.map((data, index) => (
                <button
                  key={index}
                  onClick={() => handleEdication(data)}
                  className={`edu_certification_stepper ${data ===highEducation && 'activeBgEduce'}`}
                  // className={`edu_certification`}
                  ref={el => buttonsRef.current[index] = el}
                >
                  {data}
                </button>
              ))
            }
          </div>
          <span className='text-danger'>{error}</span>
          <div className="navigation-buttons d-flex gap-3 mt-3">
            <StepperBackButton className={`${step === 0 && 'd-none'}`} onClick={handleBackStep} disabled={step === 0}>Back</StepperBackButton>
            <AuthFillButton className='' onClick={handlleSubmit} disabled={step === 7}>Next</AuthFillButton>
          </div>
        </div>
        <div className='steper-image-container h-md-100'>
          <img src={educationImage} alt="basicInfoImage" />
        </div>
      </div>
    </>
  )
}
