export const formattedEducationList = [
    { value: "Uneducated", label: "Uneducated" },
    { value: "10th or below 10th", label: "10th or below 10th" },
    { value: "12th pass", label: "12th Pass" },
    { value: "ITI", label: "ITI" },
    { value: "Diploma", label: "Diploma" },
    { value: "Polytechnic", label: "Polytechnic" },
    { value: "Graduation", label: "Graduation" },
    { value: "Post Graduate", label: "Post Graduate" },
];
export const formattedWorkPlace = [
    { value: "On-site", label: "On-site" },
    { value: "Off-site", label: "Off-site" },
    { value: "Hybrid", label: "Hybrid" }
];
export const formattedShiftType = [
    { value: "Day Shift", label: "Day Shift" },
    { value: "Night Shift", label: "Night Shift" }
];

export const formattedEmploymentType = [
    { value: "Full Time", label: "Full Time" },
    { value: "Part Time", label: "Part Time" },
    { value: "Contract", label: "Contract" }
];

export const formattedExperience = [
    { value: "Fresher", label: "Fresher" },
    { value: "1-2 years", label: "1-2 years" },
    { value: "2-3 years", label: "2-3 years" },
    { value: "3-5 years", label: "3-5 years" },
    { value: "5+ years", label: "5+ years" }
];
export const formattedAgeRange = [
    { value: "18-25", label: "18-25" },
    { value: "25-30", label: "25-30" },
    { value: "30-45", label: "30-45" },
    { value: "45+", label: "45+" }
];
export const formattedGender = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" }
];

export const formattedLanguagesOptions = [
    { value: "Hindi", label: "Hindi" },
    { value: "Urdu", label: "Urdu" },
    { value: "English", label: "English" },
    { value: "Bengali", label: "Bengali" },
    { value: "Telugu", label: "Telugu" },
    { value: "Marathi", label: "Marathi" },
    { value: "Tamil", label: "Tamil" },
    { value: "Gujarati", label: "Gujarati" },
    { value: "Kannada", label: "Kannada" },
    { value: "Malayalam", label: "Malayalam" },
    { value: "Odia", label: "Odia" },
    { value: "Punjabi", label: "Punjabi" },
    { value: "Assamese", label: "Assamese" },
    { value: "Maithili", label: "Maithili" },
    { value: "Bhili/Bhilodi", label: "Bhili/Bhilodi" },
    { value: "Santali", label: "Santali" },
    { value: "Kashmiri", label: "Kashmiri" },
    { value: "Nepali", label: "Nepali" },
    { value: "Gondi", label: "Gondi" },
    { value: "Sindhi", label: "Sindhi" },
    { value: "Konkani", label: "Konkani" },
    { value: "Dogri", label: "Dogri" },
    { value: "Manipuri", label: "Manipuri" },
    { value: "Bodo", label: "Bodo" },
    { value: "Khasi", label: "Khasi" },
    { value: "Mizo", label: "Mizo" },
    { value: "Sanskrit", label: "Sanskrit" },
    { value: "Tulu", label: "Tulu" },
    { value: "Marwari", label: "Marwari" },
    { value: "Magahi", label: "Magahi" },
    { value: "Chhattisgarhi", label: "Chhattisgarhi" },
    { value: "Rajasthani", label: "Rajasthani" },
    { value: "Ho", label: "Ho" },
    { value: "Brahui", label: "Brahui" },
    { value: "Khandeshi", label: "Khandeshi" },
    { value: "Kurukh", label: "Kurukh" },
    { value: "Kodava", label: "Kodava" },
    { value: "Toda", label: "Toda" },
    { value: "Sourashtra", label: "Sourashtra" },
    { value: "Lepcha", label: "Lepcha" },
    { value: "Bhutia", label: "Bhutia" },
    { value: "Nicobarese", label: "Nicobarese" },
    { value: "Andamanese", label: "Andamanese" }
];

export const formattedProficiency = [
    { value: "Basic", label: "Basic" },
    { value: "Intermediate", label: "Intermediate" },
    { value: "Advance", label: "Advance" }
];

