import React, { useContext, useEffect, useState } from 'react';
import { errorPopup, successPopup } from '../../../../Utils/ToasterMessage';
import UserContext from '../../ContextApi/UserContext';
import { EventButton } from '../../ReUseComps/Button';
import { URLS } from '../../../../Utils/App_urls';
import { IoCloseOutline } from 'react-icons/io5';
import { Form, Spinner } from 'react-bootstrap';
import { FaAngleLeft } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import '../EditBasicInfo/EditBasicInfo.css'
import { FaPlus } from "react-icons/fa";
import Select from "react-select";
import axios from 'axios';

import './EditLanguage.css'

export default function EditLanguage() {
    const { editProfilePopUPToggle, setEditProfilePopUpToggle, profilModalEventType, setNeedToReload, profileModalData, setLoaderOn } = useContext(UserContext);
    const languagesOptions = ["Hindi", "English", "Bengali", "Telugu", "Marathi", "Tamil", "Gujarati", "Kannada", "Malayalam", "Odia", "Punjabi", "Urdu", "Assamese", "Maithili", "Bhili/Bhilodi", "Santali", "Kashmiri", "Nepali", "Gondi", "Sindhi", "Konkani", "Dogri", "Manipuri", "Bodo", "Khasi", "Mizo", "Sanskrit", "Tulu", "Marwari", "Magahi", "Chhattisgarhi", "Rajasthani", "Ho", "Brahui", "Khandeshi", "Kurukh", "Kodava", "Toda", "Sourashtra", "Lepcha", "Bhutia", "Nicobarese", "Andamanese"];
    const [moreLanguageOption, setMoreLanguageOption] = useState([{ languageFromMenu: '', proficiency: '' }]);
    const [error, setError] = useState({ errorMessage: '', errorStatus: true });
    const [loader, setLoader] = useState(false);
    const [languageLists, setLanguageLists] = useState([]);

    const proficiencyListOfLevel = [
        { value: 'Basic', label: 'Basic' },
        { value: 'Intermediate', label: 'Intermediate' },
        { value: 'Advance', label: 'Advance' }
    ]

    const handleClosePopUPModal = () => {
        setEditProfilePopUpToggle({ ...editProfilePopUPToggle, languageModal: false, mainModalToggle: false })
    }

    const handleAddMoreLagOpt = () => {
        setMoreLanguageOption(prev => [...prev, { languageFromMenu: '', proficiency: '' }]);
        setError({ errorMessage: '', errorStatus: false });

    };

    const handleRemoveLagOpt = (index) => {
        setMoreLanguageOption(prevOptions => prevOptions.filter((_, id) => id !== index));
    };

    const handleAddLangInArr = (e, type, idx) => {
        setMoreLanguageOption(moreLanguageOption =>
            moreLanguageOption.map((data, index) => {
                setError({ ...error, errorStatus: true, errorMessage: '' });
                if (index === idx) {
                    if (type === 'language') {
                        return {
                            ...data,
                            languageFromMenu: e.value
                        };
                    } else if (type === 'proficiency') {
                        return {
                            ...data,
                            proficiency: e.value
                        };
                    }
                }
                return data;
            })
        );
    }

    const handleSubmitLag = () => {
        console.log('moreLanguageOption.proficiency', moreLanguageOption[moreLanguageOption.length - 1]?.languageFromMenu)
        if (moreLanguageOption?.length === 0 ||
            moreLanguageOption[moreLanguageOption.length - 1]?.languageFromMenu === 'Select Language' ||
            !(moreLanguageOption[moreLanguageOption.length - 1]?.languageFromMenu)
        ) {
            setError({ errorMessage: 'Please select language.', errorStatus: true });
        } else if (moreLanguageOption.length === 0 ||
            moreLanguageOption[moreLanguageOption.length - 1]?.proficiency === 'Select Proficiency' ||
            !(moreLanguageOption[moreLanguageOption.length - 1]?.proficiency)

        ) {
            setError({ errorMessage: 'Please select language proficiency.', errorStatus: true });
        }
        else {
            console.log('moreLanguageOption', moreLanguageOption)
            updateLanguages()
        }
    };

    const updateLanguages = async () => {
        delete profileModalData['_id']
        delete profileModalData['userId']
        delete profileModalData['auth0UserId']
        delete profileModalData['createdAt']
        delete profileModalData['updatedAt']
        delete profileModalData['__v']
        try {
            setLoader(true)
            await axios.patch(`${URLS.createUserProfile}`, { ...profileModalData, languages: moreLanguageOption }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('userToken')}`
                }
            })
                .then((res) => {
                    setNeedToReload(true)
                    console.log('languageEdit::', res)
                    successPopup(res?.data?.message)
                    handleClosePopUPModal();
                    setLoader(false)
                })
                .catch((err) => {
                    console.log('basicinfoEditErr', err)
                    errorPopup(err?.response?.data?.message);
                    handleClosePopUPModal();
                    setLoader(false)
                })
        } catch (err) {
            console.error('err', err);
        }
    }

    const getAllLanguages = () => {
        axios.get(`${URLS.getLanguages}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('userToken')}`
            }
        })
            .then((res) => {
                console.log('language', res)
                const langaugeResList = res?.data?.data || [];
                const formattedLangList = langaugeResList.map(lang => ({ value: lang?.name, label: lang?.name }));
                setLanguageLists(formattedLangList)
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        setMoreLanguageOption(profileModalData?.languages);
        getAllLanguages();
        console.log('profilModalEventType', profilModalEventType)
        console.log('profileModalData', profileModalData)
    }, [])

    console.log(moreLanguageOption)

    return (
        <>
            <div className="modal-langauge-main d-flex flex-column">
                <div className="ModalTitle mb-2">
                    <h1>{profilModalEventType === 'add' ? <span>Add</span> : <span>Edit</span>} Language</h1>
                    <span onClick={handleClosePopUPModal}><IoCloseOutline fontSize={26} /></span>
                </div>
                <div className='langaage-menu-container'>
                    {
                        moreLanguageOption.map((data, index) => (
                            <div key={index} className='d-flex w-100 align-items-center justify-content-start gap-3'>
                                <div className='flex-grow-1 language-modal-menu'>
                                    <label htmlFor="languageSelect">Language</label>
                                    <div className='inbuild-selectOption'>
                                        <Select
                                            options={languageLists}
                                            onChange={(e) => handleAddLangInArr(e, 'language', index)}
                                            value={languageLists.find(option => option.value === data?.languageFromMenu)}
                                            label="Single select"
                                        />
                                    </div>
                                    {/* <Form.Select className='mt-2' onChange={(e) => handleAddLangInArr(e, 'language', index)} id='languageSelect' aria-label="Default select example">
                                        <option defaultValue={data?.languageFromMenu === ''} disabled selected>Select Language</option>
                                        {
                                            languagesOptions.map((lag, idx) => (
                                                <option key={idx} value={lag} selected={data?.languageFromMenu === lag}>{lag}</option>
                                            ))
                                        }
                                    </Form.Select> */}
                                </div>
                                <div className='flex-grow-1 language-modal-menu'>
                                    <label htmlFor="proficiencyLevel">Language’s Proficiency</label>
                                    <div className='inbuild-selectOption'>
                                        <Select
                                            options={proficiencyListOfLevel}
                                            onChange={(e) => handleAddLangInArr(e, 'proficiency', index)}
                                            // onChange={(e) => handleTypeSelect(e, index)}
                                            value={proficiencyListOfLevel.find(option => option.value === data?.proficiency)}
                                            label="Single select"
                                        />
                                    </div>
                                    {/* <Form.Select className='mt-2' onChange={(e) => handleAddLangInArr(e, 'proficiency', index)} id='proficiencyLevel' aria-label="Default select example">
                                        <option defaultValue={data.proficiency === ''} disabled selected >Select Proficiency</option>
                                        <option value="Basic" selected={data?.proficiency === "Basic"}>Basic</option>
                                        <option value="Intermediate" selected={data?.proficiency === "Intermediate"}>Intermediate</option>
                                        <option value="Advance" selected={data?.proficiency === "Advance"}>Advance</option>
                                    </Form.Select> */}
                                </div>
                                <div className={`d-flex align-items-center remove-optionIcon h-100 ${index === 0 && 'd_hide'}`}>
                                    <IoMdClose onClick={() => handleRemoveLagOpt(index)} className='mt-4' fontSize={16} />
                                </div>
                            </div>
                        ))
                    }
                </div>

                {profilModalEventType === 'add' && <div className="addMoreLang mt-3" onClick={handleAddMoreLagOpt}>
                    <span>Add more language</span>
                    <FaPlus fontSize={12} />
                </div>}
                {error.errorMessage && <p className='m-0 text-danger'>{error.errorMessage}</p>}
                <EventButton className='modal-update-btn mt-3' onClick={handleSubmitLag}>
                    Update
                    {loader && <Spinner className='m-0 ms-2' style={{ width: '18px', height: '18px', borderWidth: '3px' }} animation="border" />}
                </EventButton>
            </div>
        </>
    )
}
