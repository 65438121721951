import React, { useContext, useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { AuthFillButton } from '../../component/ReUseComps/Button';
import { SlLocationPin } from "react-icons/sl";
import UserContext from '../../component/ContextApi/UserContext';
import { BsCalendarFill } from "react-icons/bs";
import { CiMapPin } from "react-icons/ci";
import basicInfoImage from '../../../assets/basicInfoImage.png'
import './BasicInfo.css';

export default function BasicInfo() {
    const inputRef = useRef(null)
    const { step, setStep, userForm, setUserForm, userFormData } = useContext(UserContext);
    const [GPSAddress, setGPSAddress] = useState('');
    const [currentErrorField, setCurrentErrorField] = useState('');
    const [changeInputType, setChangeInputType] = useState('text')
    const [pickLocationToggle, setPickLocationToggle] = useState(false)
    const [error, setError] = useState({
        errorStatus: true,
        fullNameError: '',
        DOBError: '',
        genderError: '',
        addressError: '',
        mobileNoErr: '',
    })
    const [basicData, setBasicData] = useState({
        fullName: '',
        DOB: '',
        gender: '',
        address: '',
        mobileNo: '',
    })

    const handleFullName = (e) => {
        setBasicData({ ...basicData, fullName: e.target.value })
        setError({ ...error, errorStatus: false, fullNameError: '', DOBError: '', genderError: '', addressError: '' })
    }

    const handleDOB = (e) => {
        setBasicData({ ...basicData, DOB: e.target.value })
        setError({ ...error, errorStatus: false, fullNameError: '', DOBError: '', genderError: '', addressError: '' })

    }

    const handleGender = (e) => {
        const { id } = e.target;
        setBasicData({ ...basicData, gender: id })
        setError({ ...error, errorStatus: false, fullNameError: '', DOBError: '', genderError: '', addressError: '' })

    }

    const handleAddress = (e) => {
        setPickLocationToggle(false)
        setBasicData({ ...basicData, address: e.target.value })
        setError({ ...error, errorStatus: false, fullNameError: '', DOBError: '', genderError: '', addressError: '' })
    }

    const handleMobileNumberChange = (e) => {
        const regex = new RegExp(/^[0-9]*$/)
        regex.test(e.target.value) && setBasicData({ ...basicData, mobileNo: e.target.value })
        setError({ ...error, errorStatus: false, mobileNoErr: '', fullNameError: '', DOBError: '', genderError: '', addressError: '' })
    }

    const handleErrorCheckAndSubmit = () => {
        if (basicData.fullName === '' || !basicData.fullName) {
            return setError({
                ...error,
                errorStatus: true,
                fullNameError: 'Full Name is required.',
                DOBError: '',
                genderError: '',
                addressError: ''
            })
        } else if (!basicData?.mobileNo || basicData?.mobileNo === '') {
            return setError({
                ...error,
                errorStatus: true,
                mobileNoErr: 'Mobile number is required.'
            })
        } else if (basicData?.mobileNo?.length !== 10) {
            return setError({
                ...error,
                errorStatus: true,
                mobileNoErr: 'Mobile Number must be 10 digit required.'
            })
        } else if (basicData.DOB === '' || !basicData.DOB) {
            return setError({
                ...error,
                errorStatus: true,
                DOBError: 'DOB is required.',
                genderError: '',
                addressError: ''
            })
        } else if (basicData.gender === '' || !basicData.gender) {
            return setError({
                ...error,
                errorStatus: true,
                fullNameError: '',
                DOBError: '',
                genderError: 'Gender is required.',
                addressError: ''
            })
        } else if (basicData.address === '' || !basicData.address) {
            return setError({
                ...error,
                errorStatus: true,
                fullNameError: '',
                DOBError: '',
                genderError: '',
                addressError: 'Address is required.',
            })
        } else {
            setUserForm(userForm => ({ ...userForm, basicInfo: basicData }));
            setStep(step => step + 1)
        }
    }

    const pickLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success, errorGPS);
        } else {
            console.log("Geolocation not supported");
        }
    };

    function success(position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
        getCityName(latitude, longitude);
    }

    function errorGPS() {
        console.log("Unable to retrieve your location");
    }

    async function getCityName(latitude, longitude) {
        setPickLocationToggle(true)
        try {
            const response = await fetch(
                `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
            );
            const data = await response.json();
            const address = `City - ${data?.address?.city || ''} ${data?.address?.city && ','} Dist - ${data?.address?.state_district || ''} ${data?.address?.state_district && ','} State - ${data?.address?.state || ''}  ${data?.address?.state && ','} Pin - ${data?.address?.postcode || ''} ${data?.address?.postcode && ','} ${data?.address?.country_code || ''}`;
            setGPSAddress(address);
            setBasicData({ ...basicData, address: address })
        } catch (error) {
            return null;
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        if (userForm?.basicInfo) {
            setBasicData({
                ...basicData,
                fullName: userForm?.basicInfo?.fullName || '',
                DOB: userForm?.basicInfo?.DOB || '',
                gender: userForm?.basicInfo?.gender || '',
                address: userForm?.basicInfo?.address || '',
                mobileNo: userForm?.basicInfo?.mobileNo || ''
            })
        }

    }, [userForm?.basicInfo])

    console.log('userForm', userForm)
    console.log('BasicData::::', basicData)
    console.log('error::::', error)

    return (
        <>
            <div className='w-100 d-flex'>
                <div className='basicInfo responsive_cont basicDEtilsPage w-50 flex-grow-1'>
                    <h1 className='page-title-tag'>Basic Info</h1>
                    <Form onSubmit={handleSubmit}>
                        <div className='d-flex gap-2 flex-grow-1 justify-content-between'>
                            <Form.Group className="mb-3 flex-grow-1">
                                <Form.Label>Full Name*</Form.Label>
                                <Form.Control
                                    type="text"
                                    id='firstName'
                                    value={basicData.fullName}
                                    onChange={handleFullName}
                                    isInvalid={error.fullNameError}
                                    placeholder="Enter your full name" />
                                <Form.Control.Feedback type="invalid">
                                    {error.fullNameError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        <div className='d-flex gap-2 flex-grow-1 justify-content-between'>
                            <Form.Group className="mb-3 flex-grow-1">
                                <Form.Label>Mobile Number*</Form.Label>
                                <Form.Control
                                    type="text"
                                    id='mobilNumber'
                                    minLength="10"
                                    maxLength="10"
                                    value={basicData.mobileNo}
                                    onChange={handleMobileNumberChange}
                                    isInvalid={error.mobileNoErr}
                                    placeholder="Enter your mobile number" />
                                <Form.Control.Feedback type="invalid">
                                    {error.mobileNoErr}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        <Form.Group className="mb-3 flex-grow-1" >
                            <Form.Label>Date of Birth*</Form.Label>
                            <Form.Control
                                type={`date`}
                                id='DOB'
                                name="DOB"
                                ref={inputRef}
                                max={`2006-12-31`}
                                // onFocus={() => setChangeInputType('date')}
                                // onBlur={() => setChangeInputType('text')}
                                min='1900-01-01'
                                value={basicData.DOB}
                                onChange={handleDOB}
                                placeholder="Enter your Date of Birth"
                                isInvalid={error.DOBError}
                            />
                            <Form.Control.Feedback className='text-start' type="invalid">
                                {error.DOBError}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3 flex-grow-1 flex-column align-items-start select_gender" >
                            <div className='d-flex'>
                                <Form.Check
                                    inline
                                    label="Male"
                                    name="gender"
                                    type="radio"
                                    id="male"
                                    value="male"
                                    onChange={handleGender}
                                    checked={basicData.gender === 'male'}
                                    isInvalid={error.genderError}
                                />
                                <Form.Check
                                    inline
                                    label="Female"
                                    name="gender"
                                    type="radio"
                                    id="female"
                                    value="female"
                                    onChange={handleGender}
                                    checked={basicData.gender === 'female'}
                                    isInvalid={error.genderError}
                                />
                                <Form.Check
                                    inline
                                    label="Other"
                                    name="gender"
                                    type="radio"
                                    id="other"
                                    value="other"
                                    onChange={handleGender}
                                    checked={basicData.gender === 'other'}
                                    isInvalid={error.genderError}
                                />
                            </div>
                            <Form.Control.Feedback type="invalid">
                                {error.genderError}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlTextarea1">
                            <div className='d-flex justify-content-between align-items-end flex-wrap mb-2'>
                                <Form.Label className='m-0'>Address*</Form.Label>
                                <div className={`d-flex gap-3 d-none d-md-block locationFocus`}>
                                    <div
                                        className={`${pickLocationToggle && 'active_cuuLocation'} locationbtn px-3 py-2`}
                                        onClick={pickLocation}
                                        tabIndex="0">
                                        <CiMapPin fontSize={18} /> <span>Pick current location</span>
                                    </div>
                                </div>
                            </div>

                            <Form.Control as="textarea"
                                name="address"
                                value={basicData.address}
                                placeholder='Address line 1'
                                onChange={handleAddress}
                                rows={3}
                                isInvalid={error.addressError}
                            />
                            <div className={`d-flex gap-3 d-block d-md-none locationFocus adjsWidth mt-2`}>
                                <div
                                    className={`${pickLocationToggle && 'active_cuuLocation'} locationbtn px-3 py-2`}
                                    onClick={pickLocation}
                                    tabIndex="0">
                                    <CiMapPin fontSize={18} /> <span>Pick current location</span>
                                </div>
                            </div>
                            <Form.Control.Feedback type="invalid">
                                {error.addressError}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form>
                    <div className="navigation-buttons w-100 mt-3">
                        <AuthFillButton className='w-100' onClick={handleErrorCheckAndSubmit} disabled={step === 7}>Next</AuthFillButton>
                    </div>
                </div>
                <div className='steper-image-container-basicInfo hidebasicImage h-md-100'>
                    <img src={basicInfoImage} alt="basicInfoImage" />
                </div>

            </div>
        </>
    );
}
