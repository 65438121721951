// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.skillPicker{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 12px;

}

.skillPicker > input{
    width: 95%;
    height: 100%;
    padding: 8px 0px 8px 12px;
    border: none;
    outline: none;
    border-radius: inherit;
}

.inbuild-selectOption > div{
    border: 1px solid #D5E3F9;
    border-radius: 8px;
}
.inbuild-selectOption > div > div{
    border:unset;
    border-radius: inherit;
}

.skillPicker > svg{
    cursor: pointer;
}

.filter-city-name{
    position: relative;
    z-index: 1;
    width: 560px;
    max-height: 200px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow-y: auto;
    border-radius: 8px;
    padding: 8px 2px;
}
.filter-city-name > p{
    margin: 8px 0px 0px 10px;
    padding-left: 4px;
    cursor: pointer;
    border-radius: 3px;
    /* margin: 0; */
}
.filter-city-name > p:hover{
    background-color: #00000020;
}`, "",{"version":3,"sources":["webpack://./src/User/Pages/CreateProfile/JobCity.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;;AAEvB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,iBAAiB;IACjB,sBAAsB;IACtB,4CAA4C;IAC5C,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,wBAAwB;IACxB,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,2BAA2B;AAC/B","sourcesContent":["\n.skillPicker{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding-right: 12px;\n\n}\n\n.skillPicker > input{\n    width: 95%;\n    height: 100%;\n    padding: 8px 0px 8px 12px;\n    border: none;\n    outline: none;\n    border-radius: inherit;\n}\n\n.inbuild-selectOption > div{\n    border: 1px solid #D5E3F9;\n    border-radius: 8px;\n}\n.inbuild-selectOption > div > div{\n    border:unset;\n    border-radius: inherit;\n}\n\n.skillPicker > svg{\n    cursor: pointer;\n}\n\n.filter-city-name{\n    position: relative;\n    z-index: 1;\n    width: 560px;\n    max-height: 200px;\n    background-color: #fff;\n    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;\n    overflow-y: auto;\n    border-radius: 8px;\n    padding: 8px 2px;\n}\n.filter-city-name > p{\n    margin: 8px 0px 0px 10px;\n    padding-left: 4px;\n    cursor: pointer;\n    border-radius: 3px;\n    /* margin: 0; */\n}\n.filter-city-name > p:hover{\n    background-color: #00000020;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
