import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
// import { errorPopup, successPopup } from '../../../Utils/ToasterMessage';
import { errorPopup, successPopup } from '../../../Utils/ToasterMessage'
import forgotPassImage from '../../../assets/forgotPassImage.png'
import { COMPANY_URLS } from '../../../Utils/Company_App_Urls';
import kaamwalaLogo from '../../../assets/KAAMVALALogo.png'
import Loader from '../../../User/component/Loader/Loader';
import { TfiEmail } from "react-icons/tfi";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import './Auth.css'

export default function ForgotPassword() {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false)

    const formik = useFormik({
        initialValues: { email: '' },
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email address').required('Email is required'),
        }),
        onSubmit: async (values) => {
            setLoader(true);
            try {
                await axios.post(`${COMPANY_URLS.forgetPassword}`, values)
                    .then(res => {
                        successPopup(res?.data?.message)
                        setLoader(false)
                    })
                    .catch(err => {
                        errorPopup(err?.response?.data?.message);
                        setLoader(false)
                    })
            } catch (error) {
                setLoader(false)
            }
        },
    });

    return (
        <>
            {loader && <Loader />}
            <div className="main_login">
                <div className='left-color-box'>
                </div>
                <div className='right-image-box'>
                    {/* <div className='forgot-pass-box-com d-flex align-items-center justify-content-center'><img src={forgotPassImage} alt="forgotPass" /></div> */}
                </div>
                <div className='auth-box-adjust-gorgot d-flex justify-content-start align-items-center gap-5'>
                    <div className="login_box py-5">
                        <img className='mb-4' src={kaamwalaLogo} alt="kaamwalaLogo" />
                        <h1 className='AuthTitle mb-3'>Forgot Password</h1>
                        <form onSubmit={formik.handleSubmit} className='d-flex flex-column gap-1'>
                            <div className='mb-1'>
                                <label className='d-block inputName' htmlFor="email">
                                    <TfiEmail fontSize={18} />
                                    <span className='mx-1 align-middle'>Email</span>
                                </label>
                                <input
                                    className='form-input'
                                    id='email'
                                    type="email"
                                    placeholder='abc@gmail.com'
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.errors.email && formik.touched.email && (
                                    <div className="input-feedback text-danger auth-error text-start">{formik.errors.email}</div>
                                )}
                            </div>
                            <div className='d-flex justify-content-end'>
                            </div>
                            <button className='authbtn mt-3'>Submit</button>
                            <span className='forgotPassword text-center mt-3' onClick={() => navigate('/company/login')}>Back To Login</span>
                        </form>
                    </div>
                    <div className='forgot-pass-box-com fogot_img_res d-flex align-items-center justify-content-center'><img src={forgotPassImage} alt="forgotPass" /></div>
                </div>
            </div>
        </>
    )
}

