import React from 'react'
import featureImage from '../../../assets/featureImage.png'
import simple from '../../../assets/simpleHiring.png'
import recommendation from '../../../assets/recommendations.png'
import priority from '../../../assets/priority.png'
import './Home.css'

export default function Features() {

    const featurecard = [
        {
            image: simple,
            title: 'Simple Hiring',
            description: 'Recieve calls from qualified candidates in under an hour of posting a job'
        },
        {
            image: recommendation,
            title: 'Intelligent Recommendations',
            description: 'Only the best candidates are recommended by our ML as per your requirement'
        },
        {
            image: priority,
            title: 'Priority customer support',
            description: 'Prioritized customer support for the paid plan users'
        },
    ]

    return (
        <>
            <div className='features_main py-5'>
                <div className="stepsTitle">
                    <h1>What makes Kaamvala better ?</h1>
                    <div className='hlfbtnBorder' />
                </div>
                <div className='container d-flex flex-wrap align-items-center justify-content-around'>
                    <div className="featureImageBox">
                        <img src={featureImage} alt="featureImage" />
                    </div>
                    <div className="featureCardsGrp">
                        {
                            featurecard.map((data, index) => (
                                <div key={index} className="FeatureCards">
                                    <img src={data.image} alt="simple hire" />
                                    <div>
                                        <h1>{data.title} </h1>
                                        <p>{data.description}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <button className='postJobbtn'>Post a Job</button>
            </div>
        </>
    )
}
