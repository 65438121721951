import React from 'react'
import notCandiFound from '../../../assets/notCandiFound.png'
import './NoCandidateApplied.css'
import { useParams } from 'react-router-dom'

export default function NoCandidateApplied() {
    const params = useParams();

    console.log('params',params)

    const shareLink = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Kaamval job url',
                text: 'I found matched interesting  job link:',
                url: `https://webapp.kaamvala.com/user/find-job/job-details/${params.id}`,
            })
                .then((res) => { })
                .catch((error) => console.log('Error sharing:', error));
        } else {
            alert('Your browser does not support the Web Share API');
        }
    }

    return (
        <>
            <div className="yetToApply">
                <img src={notCandiFound} alt="notCandiFound" />
                <h1>No Application Yet!</h1> 
                <p>It seems that no candidates have applied for this job yet. Don't worry, quality candidates might just be around the corner!</p>
                <button className='invite_buttons' onClick={shareLink}>Invite Candidates</button>
            </div>
        </>
    )
}
