import React, { useContext, useEffect, useState } from 'react'
import BasicInfo from './BasicInfo'
import { Steps } from 'antd';
import { MdDone } from "react-icons/md";
import { LiaAngleRightSolid } from "react-icons/lia";
import './ProfileStepper.css'
import Education from './Education';
import Experience from './Experience';
import Language from './Language';
import Preferrence from './Preferrence';
import JobCity from './JobCity';
import ProfilePhoto from './ProfilePhoto';
import UserContext from '../../component/ContextApi/UserContext';
import Departments from './Departments';
import SuccessfulSubmit from './SuccessfulSubmit';
import useWindowDimension from '../../../Utils/useWindowDimension';
import Preview from './PreviewPage/Preview';
import { MdOutlineDone } from "react-icons/md";
import { Progress } from 'antd';


export default function ProfileStepper() {
    const { step, setStep } = useContext(UserContext)
    const [windowDimensions, setWindowDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
    const stepperName = ['Basic Info', 'Education', 'Experience', 'Language', 'Preferences', 'Job City', 'Department', 'Profile Photo', 'Preview']
    useEffect(() => {
        function handleResize() {
            setWindowDimensions({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const componentArr = [
        <BasicInfo key="basic-info" />,
        <Education key="education" />,
        <Experience key="Experience" />,
        <Language key="language" />,
        <Preferrence key="preferrence" />,
        <JobCity key="jobcity" />,
        <Departments key="departments" />,
        <ProfilePhoto key="profilephoto" />,
        <Preview key="preview" />,
        <SuccessfulSubmit key="successfulSubmit" />,
    ];
    console.log('step', step)

    const items = [
        {
            title:
                <p className={`steper-title-box`}>Basic Info {' '}
                    <span className={`${step > 0 ? ' d-block' : 'd-none'}`}><MdDone fontSize={24} /></span>
                    <span className={`${step < 1 ? ' d-block' : 'd-none'}`}><LiaAngleRightSolid fontSize={24} /></span>
                </p>,
            key: 1,
            icon: <div className={`icon-stepper-add ${step >= 0 && 'icon-bg-update'}`}>1</div>
        },
        {
            title:
                <p className={`steper-title-box`}>Education {' '}
                    <span className={`${step >= 2 ? ' d-block' : 'd-none'}`}><MdDone fontSize={24} /></span>
                    <span className={`${step === 1 ? ' d-block' : 'd-none'}`}><LiaAngleRightSolid fontSize={22} /></span>
                </p>,
            key: 2,
            icon: <div className={`icon-stepper-add ${step >= 1 && 'icon-bg-update'}`}>2</div>
        },
        {
            title:
                <p className={`steper-title-box`}>Experience {' '}
                    <span className={`${step > 2 ? ' d-block' : 'd-none'}`}><MdDone fontSize={24} /></span>
                    <span className={`${step === 2 ? ' d-block' : 'd-none'}`}><LiaAngleRightSolid fontSize={24} /></span>
                </p>,
            key: 3,
            icon: <div className={`icon-stepper-add ${step >= 2 && 'icon-bg-update'}`}>3</div>
        },
        {
            title:
                <p className={`steper-title-box`}>Language {' '}
                    <span className={`${step > 3 ? ' d-block' : 'd-none'}`}><MdDone fontSize={24} /></span>
                    <span className={`${step === 3 ? ' d-block' : 'd-none'}`}><LiaAngleRightSolid fontSize={24} /></span>
                </p>,
            key: 4,
            icon: <div className={`icon-stepper-add ${step >= 3 && 'icon-bg-update'}`}>4</div>
        },
        {
            title:
                <p className={`steper-title-box`}>Preferences {' '}
                    <span className={`${step > 4 ? ' d-block' : 'd-none'}`}><MdDone fontSize={24} /></span>
                    <span className={`${step === 4 ? ' d-block' : 'd-none'}`}><LiaAngleRightSolid fontSize={24} /></span>
                </p>,
            key: 5,
            icon: <div className={`icon-stepper-add ${step >= 4 && 'icon-bg-update'}`}>5</div>
        },
        {
            title:
                <p className={`steper-title-box`}>Job City {' '}
                    <span className={`${step > 5 ? ' d-block' : 'd-none'}`}><MdDone fontSize={24} /></span>
                    <span className={`${step === 5 ? ' d-block' : 'd-none'}`}><LiaAngleRightSolid fontSize={24} /></span>
                </p>,
            key: 6,
            icon: <div className={`icon-stepper-add ${step >= 5 && 'icon-bg-update'}`}>6</div>
        },
        {
            title:
                <p className={`steper-title-box`}>Department {' '}
                    <span className={`${step > 6 ? ' d-block' : 'd-none'}`}><MdDone fontSize={24} /></span>
                    <span className={`${step === 6 ? ' d-block' : 'd-none'}`}><LiaAngleRightSolid fontSize={24} /></span>
                </p>,
            key: 7,
            icon: <div className={`icon-stepper-add ${step >= 6 && 'icon-bg-update'}`}>7</div>
        },
        {
            title:
                <p className={`steper-title-box`}>Profile Photo {' '}
                    <span className={`${step > 7 ? ' d-block' : 'd-none'}`}><MdDone fontSize={24} /></span>
                    <span className={`${step === 7 ? ' d-block' : 'd-none'}`}><LiaAngleRightSolid fontSize={24} /></span>
                </p>,
            key: 8,
            icon: <div className={`icon-stepper-add ${step >= 7 && 'icon-bg-update'}`}>8</div>
        },
        {
            title:
                <p className={`steper-title-box`}>Preview {' '}
                    <span className={`${step > 8 ? ' d-block' : 'd-none'}`}><MdDone fontSize={24} /></span>
                    <span className={`${step === 8 ? ' d-block' : 'd-none'}`}><LiaAngleRightSolid fontSize={24} /></span>
                </p>,
            key: 8,
            icon: <div className={`icon-stepper-add ${step >= 8 && 'icon-bg-update'}`}>9</div>
        },
    ]

    useEffect(() => {
    }, [windowDimensions.width >= 800])

    return (
        <>
            <div className="profile_stepper flex-md-row flex-column">
                <div className='d-block d-md-none stpper_container_ProgessBar d-flex gap-3'>
                    <Progress
                        size={70}
                        type="circle"
                        percent={`${(step / 8) * 100}`}
                        format={() => (step <= 8 ? `${step + 1} of 9` : <MdOutlineDone fontSize={35}/>)}
                        strokeColor={'#ffffff'}
                        trailColor={'#FFFFFF30'}
                        strokeWidth={10}
                        style={{ color: '#ffffff' }}
                    />
                    <div className='stepper_title d-flex flex-column justify-content-center'>
                        <h1>{stepperName[step]}</h1>
                        {step + 1 <= 8 && <p>Next Step: {stepperName[step + 1]}</p>}
                    </div>
                </div>
                <div className={`stpper_container_box d-none d-md-block`}>
                    <Steps
                        direction={`vertical`}
                        current={step}
                        items={items}
                        key={items.key}
                        icon={items.key}
                    />
                </div>
                <div className='stepper_outlet_container'>
                    <div>
                        {componentArr[step]}
                    </div>
                </div>
            </div>
        </>
    )
}
