// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/whiteTick.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/selectMenuIcon.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: "Inria Sans";
  /* word-break: break-all; */
}

.form-check-input:checked[type="checkbox"] {
  --bs-form-check-bg-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: 10px 10px;
  background-color: #f5f5f5;
}

.form-select {
  --bs-form-select-bg-img: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  background-size: 10px 6px;
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track{
  width: 6px;
  background-color: rgb(244, 244, 244);
}

*::-webkit-scrollbar-thumb {
  background-color: #818B99;
  border: 2px solid transparent;
  border-radius: 3px;
  background-clip: content-box;
  width: 6px;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,2BAA2B;AAC7B;;AAEA;EACE,iEAAuD;EACvD,0BAA0B;EAC1B,yBAAyB;AAC3B;;AAEA;EACE,gEAA2D;EAC3D,yBAAyB;AAC3B;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,UAAU;EACV,oCAAoC;AACtC;;AAEA;EACE,yBAAyB;EACzB,6BAA6B;EAC7B,kBAAkB;EAClB,4BAA4B;EAC5B,UAAU;AACZ","sourcesContent":["* {\n  font-family: \"Inria Sans\";\n  /* word-break: break-all; */\n}\n\n.form-check-input:checked[type=\"checkbox\"] {\n  --bs-form-check-bg-image: url(\"./assets/whiteTick.png\");\n  background-size: 10px 10px;\n  background-color: #f5f5f5;\n}\n\n.form-select {\n  --bs-form-select-bg-img: url(\"./assets/selectMenuIcon.png\");\n  background-size: 10px 6px;\n}\n\n*::-webkit-scrollbar {\n  width: 10px;\n}\n\n*::-webkit-scrollbar-track{\n  width: 6px;\n  background-color: rgb(244, 244, 244);\n}\n\n*::-webkit-scrollbar-thumb {\n  background-color: #818B99;\n  border: 2px solid transparent;\n  border-radius: 3px;\n  background-clip: content-box;\n  width: 6px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
