import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import kaamwalaLogo from '../../../assets/KAAMVALALogo.png';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CiLogout } from "react-icons/ci";
import { FaUserAlt } from "react-icons/fa";
import { IoDocumentTextOutline, IoNotifications } from "react-icons/io5";
import { PiBagSimpleFill } from "react-icons/pi";
import { BsPeopleFill } from 'react-icons/bs';
import { errorPopup, successPopup } from '../../../Utils/ToasterMessage';
import CompanyContext from '../ContextAPI/CompanyContext';
import { URLS } from '../../../Utils/App_urls';
import './Navbar.css';
import { COMPANY_URLS } from '../../../Utils/Company_App_Urls';
// import { Badge } from 'react-bootstrap';


const notifications = [
    {
        title: "New Job Application Steps",
        message: "Stay updated on your applications! Visit the 'Applied Jobs'",
        date: "04 April, 2021",
        time: "04:00 PM"
    },
    {
        title: "Profile Update Reminder",
        message: "Your profile is incomplete. Complete it to get better job recommendations.",
        date: "05 April, 2021",
        time: "10:30 AM"
    },
    {
        title: "New Job Posting in Your Field",
        message: "Check out the latest job posting in your preferred category.",
        date: "06 April, 2021",
        time: "02:15 PM"
    },
    {
        title: "Interview Invitation",
        message: "You have been invited for an interview. Check your email for details.",
        date: "07 April, 2021",
        time: "09:00 AM"
    },
    {
        title: "Application Status Update",
        message: "Your application status has been updated. Visit 'Applied Jobs' for details.",
        date: "08 April, 2021",
        time: "03:45 PM"
    }
];


export default function NavBar() {
    const location = useLocation();
    const navigate = useNavigate();
    const { setLoader, isCompanyProfileComplate, needTorender, profileLogo, setProfileLogo } = useContext(CompanyContext);
    const [companyLogo, setCompanyLogo] = useState('')
    const [showDropdown, setShowDropdown] = useState(false);
    const [expandedNav, setExpandedNav] = useState(false);
    const [showBadge, setShowBadge] = useState(false)
    const handleToggle = () => {
        setShowDropdown(!showDropdown);
    };

    const handleBadgeToggle = () => {
        setShowBadge(prev => !prev)
    }
    const handleNotification=()=>{
        navigate('notification')
        setShowBadge(prev => !prev)
    }

    const getCompanyDetails = () => {
        axios.get(`${COMPANY_URLS.get_profile}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        })
            .then((res) => {
                setProfileLogo(res?.data?.data?.companylogo)
            })
            .catch((err) => { })
    }

    const handleLogOut = () => {
        setLoader(true);
        axios.post(`${COMPANY_URLS.logout}`, {
            "refreshToken": `${localStorage.getItem('acessToken')}`
        })
            .then(res => {
                successPopup(res?.data?.message);
                localStorage.removeItem('token');
                localStorage.removeItem('companyRole');
                localStorage.removeItem('isprofileComplete');
                localStorage.removeItem('registeredEmail');
                setLoader(false);
                navigate('/company/login');
            })
            .catch((err) => {
                errorPopup(err?.response?.data?.message);
                setLoader(false);
            });
    };

    const checkLocation = () => {
        const locationArr = location.pathname.split('/');
        return locationArr || '';
    };

    const handleHomeLogoClick = () => {
        window.location.reload()
        navigate('/company/job-post')
    }

    useEffect(() => {
        getCompanyDetails();
    }, [needTorender])

    useEffect(() => {
        checkLocation();
    }, []);
    console.log('needTorender::', needTorender)
    console.log('profileLogo::', profileLogo)

    return (
        <>
            <div className='main-navbar'>
                <Navbar collapseOnSelect expanded={expandedNav} expand="lg" className="bg-body-tertiary m-0 w-100">
                    <Container fluid>
                        <Navbar.Brand className={`${!isCompanyProfileComplate && 'disabled-navItems'}`}>
                            <img onClick={() => handleHomeLogoClick()} src={kaamwalaLogo} alt="kaamwalaLogo" />
                        </Navbar.Brand>
                        <div className='top-nav-bar'>
                            <div className='h-100 d-flex align-items-center gap-2'>
                                {/* <div className={`${!isCompanyProfileComplate && 'disabled-navItems'} login-btn user-avatar`}>
                                    <IoNotifications fontSize={24} />
                                    <Badge pill bg="primary">2</Badge>
                                </div> */}
                                <Dropdown show={showBadge} onToggle={handleBadgeToggle}>
                                    <div onClick={handleBadgeToggle} id="dropdown-basic" className={`${!isCompanyProfileComplate && 'disabled-navItems'} login-btn user-avatar`}>
                                        <IoNotifications fontSize={24} />
                                        <span className='badge-icon'>3</span>
                                    </div>
                                    <Dropdown.Menu className='notificaton_menu px-3 py-3'>
                                        <div>
                                            <h4 className='notification-title'>Notification</h4>
                                            <div className='notification-list-wrapper'>
                                                {notifications.map((notification, index) => (
                                                    <div key={index} className="notification-items-list mb-2">
                                                        <p>{notification.title}</p>
                                                        <p>{notification.message}</p>
                                                        <p>{notification.date} | {notification.time}</p>
                                                    </div>
                                                ))}
                                            </div>
                                            <span onClick={handleNotification} className='notification-title text-underline'>View All</span>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown className={`profile-drop-menu`} show={showDropdown} onToggle={handleToggle}>
                                    <div className={`login-btn user-avatar`} id="dropdown-basic" onClick={handleToggle}>
                                        {profileLogo ? <img className='profile-iconNav' src={profileLogo} alt="profile Image" />
                                            : <FaUserAlt fontSize={24} />}
                                    </div>
                                    <Dropdown.Menu className='profile-menu-items'>
                                        <Dropdown.Item className={`${!isCompanyProfileComplate && 'disabled-navItems'}`} onClick={() => navigate('profile')} ><FaUserAlt fontSize={16} />Profile</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item onClick={handleLogOut}><CiLogout fontSize={18} /> Log Out</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </Container>
                </Navbar>
            </div>
        </>
    );
}
