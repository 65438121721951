import React from 'react'
import kaamwalaLogo from '../../../assets/KAAMVALALogo.png'
import './Footer.css'
import { FaFacebookF, FaLinkedinIn, FaMapMarkerAlt, FaPhoneAlt, FaSkype } from 'react-icons/fa'
import { RiTwitterXLine } from 'react-icons/ri'
import { AiFillInstagram } from "react-icons/ai";
import { TfiEmail } from 'react-icons/tfi'


export default function Footer() {
    return (
        <>
            <div className='footer-section mt-3'>
                {/* <div className='py-5 d-flex justify-content-around flex-wrap gap-3'>
                    <div className='footer-partSection'>
                        <img src={kaamwalaLogo} alt="kaamwalaLogo" />
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <div className='d-flex gap-2'>
                            <div className="iconBox">
                                <FaFacebookF color='#fff' fontSize={25} />
                            </div>
                            <div className="iconBox">
                                <FaLinkedinIn color='#fff' fontSize={25} />
                            </div>
                            <div className="iconBox">
                                <FaSkype color='#fff' fontSize={25} />
                            </div>
                            <div className="iconBox">
                                <RiTwitterXLine color='#fff' fontSize={25} />
                            </div>
                            <div className="iconBox">
                                <AiFillInstagram color='#fff' fontSize={30} />
                            </div>
                        </div>
                    </div>
                    <div className='footer-partSection'>
                        <h1>User Links</h1>
                        <div className='footerList'>
                            <p>Candidate</p>
                            <p>Employer</p>
                            <p>Company</p>
                        </div>
                    </div>
                    <div className='footer-partSection'>
                        <h1>Get In Touch</h1>
                        <div className='footerList'>
                            <p className='d-flex gap-2'>
                                <FaMapMarkerAlt color='#3284FF' fontSize={30} />
                                <span> Lorem Ipsum is simplys</span>
                            </p>
                            <p className='d-flex gap-1'>
                                <FaPhoneAlt color='#3284FF' fontSize={30} />
                                <span>+91-90909xx01</span>
                            </p>
                            <p className='d-flex gap-1'>
                                <TfiEmail color='#3284FF' fontSize={30} />
                                <span>abc@gmail.com</span>
                            </p>
                        </div>
                    </div>
                </div> */}
                <div className='footer-main'>
                    <div className='container'>© {new Date().getFullYear()} Kaamvala Copy Right . All Rights Reserved.</div>
                </div>
            </div>
        </>
    )
}
