import React, {useContext} from 'react'
import CompanyContext from '../Company/Component/ContextAPI/CompanyContext'
import { Navigate, Outlet } from 'react-router-dom';

export default function CompanyProtecter(){

    const c_token = localStorage.getItem('token');

    if(c_token) return <Outlet/>
    return <Navigate to ="/company/login"/>
}