// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.experience_company_grp_card {
  background-color: #eef5ff;
  border-radius: 12px;
  padding: 16px;
}

.add-more-expe {
  border: 1px solid #3284ff;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid #3284ff;
  padding: 8px 16px;
  border-radius: 40px;
  color: #3284ff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.add-more-expe:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  cursor: pointer;
}

.experience_company_grp_card > h1 {
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.experienceOptions-title > svg:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.experience_title {
  font-size: 25px;
  font-weight: 700;
  color: #000000;
}

@media screen and (max-width:768px) {
  .experience_title{
    font-size: 18px;
  }
  .responsve_exp{
    padding: 20px 2rem;
    border-radius: 8px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/User/Pages/CreateProfile/Experience.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,uBAAkB;EAAlB,kBAAkB;EAClB,yBAAyB;EACzB,iBAAiB;EACjB,mBAAmB;EACnB,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,SAAS;AACX;;AAEA;EACE;wCACsC;EACtC,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf;wCACsC;AACxC;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE;IACE,eAAe;EACjB;EACA;IACE,kBAAkB;IAClB,kBAAkB;EACpB;AACF","sourcesContent":[".experience_company_grp_card {\n  background-color: #eef5ff;\n  border-radius: 12px;\n  padding: 16px;\n}\n\n.add-more-expe {\n  border: 1px solid #3284ff;\n  width: fit-content;\n  border: 1px solid #3284ff;\n  padding: 8px 16px;\n  border-radius: 40px;\n  color: #3284ff;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 10px;\n}\n\n.add-more-expe:hover {\n  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,\n    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;\n  cursor: pointer;\n}\n\n.experience_company_grp_card > h1 {\n  color: #1e1e1e;\n  font-size: 16px;\n  font-weight: 700;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.experienceOptions-title > svg:hover {\n  cursor: pointer;\n  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,\n    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;\n}\n\n.experience_title {\n  font-size: 25px;\n  font-weight: 700;\n  color: #000000;\n}\n\n@media screen and (max-width:768px) {\n  .experience_title{\n    font-size: 18px;\n  }\n  .responsve_exp{\n    padding: 20px 2rem;\n    border-radius: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
