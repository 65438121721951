import React, { useContext, useEffect, useState } from 'react'
import './EditSkills.css'
import '../EditBasicInfo/EditBasicInfo.css'
import { IoCloseOutline } from 'react-icons/io5'
import { FiPlus } from "react-icons/fi";
import { Form, Spinner } from 'react-bootstrap'
import { GoPlus } from 'react-icons/go'
import { EventButton } from '../../ReUseComps/Button'
import axios from 'axios'
import { URLS } from '../../../../Utils/App_urls'
import { errorPopup, successPopup } from '../../../../Utils/ToasterMessage'
import UserContext from '../../ContextApi/UserContext';
import Select from "react-select";


export default function EditSkills() {
    const { editProfilePopUPToggle, setEditProfilePopUpToggle, profileModalData, setNeedToReload, setLoaderOn } = useContext(UserContext)
    const [enterSkill, setEnterSkill] = useState('')
    const [skill, setSkill] = useState([])
    const [manualSkill, setManualSkill] = useState('')
    const [loader, setLoader] = useState(false);
    const [skillList, setSkillList] = useState([]);
    const [error, setError] = useState({
        errorStatus: true,
        errorMessage: ''
    })

    const handleClosePopUPModal = () => {
        setEditProfilePopUpToggle({ ...editProfilePopUPToggle, skillsModal: false, mainModalToggle: false })
    }

    const handleRemoveDepart = (index) => {
        setSkill(skill => skill.filter((_, id) => id !== index))
    }

    const handleInputChange = (e) => {
        setManualSkill(e.value)
        if (!skill.includes(e.value)) {
            setSkill(prevArr => [...prevArr, e.value])
        }
        setManualSkill('')
        setError({ ...error, errorStatus: false, errorMessage: '' })
    }

    const handleFormAddSkill = (e) => {
        e.preventDefault();
        if (manualSkill) {
            if (!skill.includes(manualSkill)) {
                setSkill(prevArr => [...prevArr, manualSkill])
                setError({ errorStatus: false, errorMessage: '' })
            }
            setManualSkill('')
        }
    }

    const handleSkillSubmit = () => {
        if (!skill.length || skill.length <= 0) {
            setError({ ...error, errorStatus: true, errorMessage: 'Skill is required.' })
        } else {
            updateSkills()
        }
    }

    const updateSkills = async () => {
        delete profileModalData['_id']
        delete profileModalData['userId']
        delete profileModalData['auth0UserId']
        delete profileModalData['createdAt']
        delete profileModalData['updatedAt']
        delete profileModalData['__v']
        delete profileModalData['skills']
        try {
            setLoader(true)
            await axios.patch(`${URLS.createUserProfile}`, { ...profileModalData, 'skills': skill }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('userToken')}`
                }
            })
                .then((res) => {
                    setNeedToReload(true)
                    handleClosePopUPModal();
                    successPopup(res?.data?.message)
                    setLoader(false)
                })
                .catch((err) => {
                    handleClosePopUPModal();
                    errorPopup(err?.response?.data?.message);
                    setLoader(false)
                })
        } catch (err) {
            console.error('err', err);
        }
    }

    const getAllSkillList = () => {
        axios.get(`${URLS.getSkillList}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`
          }
        })
          .then((res) => {
            const skillListRes = res?.data?.getList || [];
            const formattedJobs = skillListRes.map(skill => ({ value: skill?.name, label: skill?.name }));
            setSkillList(formattedJobs)
          })
          .catch((err) => { console.log('skillErr', err) })
      }

    useEffect(() => {
        setSkill(profileModalData?.skills);
        getAllSkillList();
    }, [])

    return (
        <>
            <div className="modal-skill-main d-flex flex-column">
                <div className="ModalTitle">
                    <h1>Edit Skills</h1>
                    <span onClick={handleClosePopUPModal}><IoCloseOutline fontSize={26} /></span>
                </div>
                <div className='skills-add-Form'>
                    <p className='m-0 mt-2 lksjdoai'>Add Skills*</p>
                    <span className='expSkillPara-modal'>Showcase your skills to get noticed</span>.
                    <div className='inbuild-selectOption'>
                        <Select
                            options={skillList}
                            // defaultValue={skillList.find(option => option.value === manualSkill)}
                            onChange={handleInputChange}
                            value={skillList.find(option => option.value === enterSkill)}
                            label="Single select"
                        />
                    </div>
                    {/* <form onSubmit={handleFormAddSkill}>
                        <div className='skills-select-input-group'>
                            <div>
                                <input type="text"
                                    value={manualSkill}
                                    onChange={handleInputChange}
                                />
                                <button type='submit' onClick={handleFormAddSkill}> <GoPlus fontSize={20} /> </button>
                            </div>
                        </div>
                    </form> */}
                    <div className='mt-3'>
                        <div className="selected_skills-box">
                            <div className='d-flex align-items-center flex-wrap gap-1'>
                                {
                                    skill?.map((data, index) => (
                                        <div key={index} className='selected-skills-items'>
                                            <span>{data}</span>
                                            <IoCloseOutline onClick={() => handleRemoveDepart(index)} fontSize={20} />
                                        </div>

                                    ))
                                }
                            </div>
                        </div>
                        {error?.errorMessage && <p className='text-danger m-0'>{error?.errorMessage}</p>}
                    </div>
                </div>
                <EventButton className='modal-update-btn mt-3 d-flex align-items-center justify-content-center' onClick={handleSkillSubmit}>
                    Update
                    {loader && <Spinner className='m-0 ms-2' style={{ width: '18px', height: '18px', borderWidth: '3px' }} animation="border" />}
                </EventButton>
            </div>
        </>
    )
}
