import React, { useContext, useEffect, useState } from 'react'
import useHtmlToText from '../../../Company/Component/CustomHooks/convertHtmlToText'
import { errorPopup, successPopup, warningPopup } from '../../../Utils/ToasterMessage'
import { EventButton, StepperBackButton } from '../../component/ReUseComps/Button'
import UserContext from '../../component/ContextApi/UserContext'
import dummyLogo from '../../../assets/dummyProfileImage.png'
import cashMultiple from '../../../assets/cash-multiple.png'
import { useNavigate, useParams } from 'react-router-dom'
import bagSolid from '../../../assets/bag-solid.png'
import { PiArrowUpRightBold } from 'react-icons/pi'
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaLocationDot } from 'react-icons/fa6'
import { URLS } from '../../../Utils/App_urls'
import { FiBookmark } from 'react-icons/fi'
import { IoMdShare } from 'react-icons/io'
import { Spinner } from 'react-bootstrap'

import axios from 'axios'
import './AppliedJob.css'

export default function SmallDescriptionCard() {
    const { singleAppliedJobDetails } = useContext(UserContext);
    const [appliedJobDEtails, setAppliedJobDetails] = useState([]);
    const [loader, setLoader] = useState(false);
    const [isSaveForLater, setIsSaveForLater] = useState(false)
    const navigate = useNavigate();
    const jobId = useParams();

    const getSingleJobDetails = () => {
        axios.get(`${URLS?.getSingleJob}`, {
            params: { id: jobId?.id },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('userToken')}`
            }
        })
            .then((res) => {
                console.log('appliedSingleJobDetails', res)
                setAppliedJobDetails(res?.data?.result?.[0])
                setIsSaveForLater(res?.data?.result?.[0]?.savedLaterJobs?.status)
            })
            .catch((err) => console.log(err))
    }

    const saveLaterJob = () => {
        if (!isSaveForLater) {
            setLoader(true)
            axios.post(`${URLS.saveLater}`,
                {
                    JobId: jobId?.id,
                    status: true
                }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` }
            })
                .then((res) => {
                    console.log('savaLaterJobs', res)
                    successPopup(res?.data?.message)
                    getSingleJobDetails()
                    setLoader(false)
                })
                .catch((err) => {
                    setLoader(false)
                    errorPopup(err?.response?.data?.message);
                })
        } else {
            warningPopup('Already saved.')
        }
    }

    const applyJob = () => {
        // setLoader(true)
        axios.post(`${URLS.userJobApplie}`,
            { JobId: jobId },
            {
                headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` }
            })
            .then((res) => {
                successPopup(res?.data?.message)
                getSingleJobDetails()
                // setLoader(false)
            })
            .catch((err) => {
                errorPopup(err?.response?.data?.message)
                // setLoader(false)
            })
    }


    const shareLink = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Kaamval job url',
                text: 'I found matched interesting  job link:',
                url: window.location.href,
            })
                .then((res) => { })
                .catch((error) => console.log('Error sharing:', error));
        } else {
            alert('Your browser does not support the Web Share API');
        }
    }


    useEffect(() => {
        getSingleJobDetails();
    }, []);

    console.log("singleAppliedJobDetails", singleAppliedJobDetails)

    return (
        <>
            <h1 className='page_to_saveJOb mb-3' onClick={() => navigate(-1)}><FaArrowLeftLong fontSize={20} /> Saved Job Detail</h1>
            <div className="smallDescriptionCard_main d-flex flex-column gap-3 py-4">
                {/* <button onClick={getSingleJobDetails}>CLick</button> */}
                <div className="applied-job-header d-flex align-items-cenetr gap-2">
                    <img src={singleAppliedJobDetails?.companyProfileDetail?.[0]?.companylogo || dummyLogo} alt="company logo" />
                    <div>
                        <h1>{appliedJobDEtails?.title}</h1>
                        <p className='text-start'>{appliedJobDEtails?.companyProfileDetail?.[0]?.companyName || 'N/A'}</p>
                    </div>
                </div>
                <div className='job_slry_lctn skill_type d-flex gap-md-4 gap-3'>
                    <div><img src={bagSolid} alt="bag solid" /> <span>{appliedJobDEtails?.experience || 'N/A'}</span> </div>
                    {
                        appliedJobDEtails?.salaryType === "Fixed Salary" &&
                        <div className='d-flex align-items-center gap-1'>
                            <img src={cashMultiple} alt="bag solid" />
                            <span>
                                {appliedJobDEtails?.FixedSalary || 'N/A'}
                                {appliedJobDEtails?.FixedSalary && 'k'}
                            </span>
                        </div>
                    }
                    {
                        appliedJobDEtails?.salaryType === "Salary Range" &&
                        <div className='d-flex align-items-center gap-1'><img src={cashMultiple} alt="bag solid" /> <span>100k - 200k</span> </div>
                    }
                    {
                        appliedJobDEtails?.salaryType === "Not Disclose" &&
                        <div className='d-flex align-items-center gap-1'><img src={cashMultiple} alt="bag solid" /> <span>100k - 200k</span> </div>
                    }
                    <div className='d-flex align-items-center gap-1'><FaLocationDot /> <span>{appliedJobDEtails?.workPlace || 'N/A'}</span></div>
                </div>
                <div className="employmentType_box d-flex gap-2 align-items-center">
                    <span>Employment Type :</span>
                    <div className='skill_type'>{appliedJobDEtails?.deparmentType}</div>
                </div>
                <div className="applied_skills_box d-flex gap-2 align-items-center">
                    <span>Skills :</span>
                    {
                        appliedJobDEtails?.skills?.map((skill, index) => (
                            <div key={index} className='skill_type'>{skill}</div>
                        ))
                    }
                </div>
                <div className="button-section-jobCard d-flex flex-wrap gap-3">
                    {!(appliedJobDEtails?.isUserApplied) ?
                        <EventButton onClick={applyJob}><span>Apply Now </span><PiArrowUpRightBold fontSize={22} /></EventButton>
                        : <EventButton className='applied-btn' disabled onClick={applyJob}><span>Applied</span></EventButton>}
                    {
                        isSaveForLater ?
                            <StepperBackButton disabled onClick={saveLaterJob}>Saved<FiBookmark fontSize={18} /> </StepperBackButton>
                            : <StepperBackButton onClick={saveLaterJob}>
                                {loader && <Spinner style={{ width: '15px', height: '15px', borderWidth: '2px', marginRight: '2px' }} animation="border" />}
                                Save for Later <FiBookmark fontSize={18} />
                            </StepperBackButton>
                    }
                    <StepperBackButton onClick={shareLink}>Share <IoMdShare fontSize={18} /> </StepperBackButton>
                </div>
                <div className="applied-job-descrip d-flex flex-column gap-3">
                    <div className="jobDescription d-flex flex-column">
                        <h3>Job Description</h3>
                        <p><div dangerouslySetInnerHTML={{ __html: useHtmlToText(appliedJobDEtails?.jobDescription) }} /></p>
                    </div>
                    <div className="jobDescription d-flex flex-row gap-1 align-items-center">
                        <h3>Number of Opening :</h3>
                        <p className='fs-5'>{appliedJobDEtails?.noOfOpening}</p>
                    </div>
                </div>
            </div>
        </>
    )
}
