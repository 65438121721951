import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaPlus } from "react-icons/fa";
import { FaAngleLeft } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import UserContext from '../../component/ContextApi/UserContext';
import AuthOutlineBtn, { AuthFillButton } from '../../component/ReUseComps/Button';
import languageImage from '../../../assets/addLanguage.png'
import { URLS } from '../../../Utils/App_urls';
import Select from "react-select";
import './Language.css';
import './BasicInfo.css';
import axios from 'axios';

export default function Language() {
    const { step, setStep, userForm, setUserForm } = useContext(UserContext);
    const [moreLanguageOption, setMoreLanguageOption] = useState([{ languageFromMenu: '', proficiency: '' }]);
    const [error, setError] = useState({ errorMessage: '', errorStatus: true });
    const [languageLists, setLanguageLists] = useState([])
    const [selectedOption, setSelectedOption] = useState("none");

    const proficiencyListOfLevel = [
        { value: 'Basic', label: 'Basic' },
        { value: 'Intermediate', label: 'Intermediate' },
        { value: 'Advance', label: 'Advance' }
    ]

    const handleAddMoreLagOpt = () => {
        setMoreLanguageOption(prev => [...prev, { languageFromMenu: '', proficiency: '' }]);
        setError({ errorMessage: '', errorStatus: false });
    };

    const handleRemoveLagOpt = (index) => {
        setMoreLanguageOption(prevOptions => prevOptions.filter((_, id) => id !== index));
    };

    const getAllLanguages = () => {
        axios.get(`${URLS.getLanguages}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('userToken')}`
            }
        })
            .then((res) => {
                console.log('language', res)
                const langaugeResList = res?.data?.data || [];
                const formattedLangList = langaugeResList.map(lang => ({ value: lang?.name, label: lang?.name }));
                setLanguageLists(formattedLangList)
            })
            .catch((err) => console.log(err))
    }

    const handleAddLangInArr = (e, type, idx) => {
        setMoreLanguageOption(moreLanguageOption =>
            moreLanguageOption.map((data, index) => {
                if (index === idx) {
                    if (type === 'language') {
                        return { ...data, languageFromMenu: e.target.value };
                    } else if (type === 'proficiency') {
                        return { ...data, proficiency: e.target.value };
                    }
                }
                return data;
            })
        );
        setError({ errorMessage: '', errorStatus: true });
    }
    const handleProficiencyChange = (e, idx) => {
        setMoreLanguageOption(moreLanguageOption =>
            moreLanguageOption.map((data, index) => {
                if (index === idx) {
                    return { ...data, proficiency: e.value };
                }
                return data;
            })
        );
        setError({ errorMessage: '', errorStatus: true });
    }

    const checkDuplicatesLang = () => {
        let languageArr = [];
        const duplicates = [];
        const uniqueElements = new Set();
        moreLanguageOption?.map((data, idx) => {
            languageArr.push(data?.languageFromMenu)
        })

        languageArr.forEach(element => {
            if (uniqueElements.has(element)) {
                duplicates.push(element);
            } else {
                uniqueElements.add(element);
            }
        });

        console.log('duplicates', duplicates);
        if (duplicates?.length !== 0) {
            setError({
                errorMessage: 'Duplicate language names are not allowed. Please ensure each language is unique.',
                errorStatus: true
            });
            return false
        } else {
            return true
        }
    }

    const handleSubmitLag = () => {
        console.log('moreLanguageOption.proficiency', moreLanguageOption[moreLanguageOption.length - 1]?.languageFromMenu)

        moreLanguageOption.map((langOpt, idx) => {
            if (langOpt.languageFromMenu === '') {
                setError({ errorMessage: 'Please select language.', errorStatus: true });
            }
        })
        moreLanguageOption.map((langOpt, idx) => {
            if (langOpt.proficiency === '') {
                setError({ errorMessage: 'Please select language proficiency.', errorStatus: true });
            }
        })

        if (moreLanguageOption.length === 0 ||
            moreLanguageOption[moreLanguageOption.length - 1]?.languageFromMenu === 'Select Language' ||
            moreLanguageOption[moreLanguageOption.length - 1]?.languageFromMenu === ''
        ) {
            setError({ errorMessage: 'Please select language.', errorStatus: true });
        } else if (moreLanguageOption.length === 0 ||
            moreLanguageOption[moreLanguageOption.length - 1]?.proficiency === 'Select Proficiency' ||
            moreLanguageOption[moreLanguageOption.length - 1]?.proficiency === ''
        ) {
            setError({ errorMessage: 'Please select language proficiency.', errorStatus: true });
        }
        else {
            if (checkDuplicatesLang()) {
                setUserForm(userForm => ({ ...userForm, language: moreLanguageOption }));
                setStep(step => step + 1);
            }
        }
    };

    console.log('error', error)
    const handleBackStep = () => {
        setUserForm(userForm => ({ ...userForm, language: moreLanguageOption }));
        setStep(prev => prev - 1)
    }

    const handleTypeSelect = (e, idx) => {
        setSelectedOption(e.value)
        setError({ errorMessage: '', errorStatus: true });
        setMoreLanguageOption(moreLanguageOption =>
            moreLanguageOption.map((data, index) => {
                if (index === idx) {
                    return {
                        ...data,
                        languageFromMenu: e.value
                    }
                }
                return data;
            })
        );
    }

    useEffect(() => {
        if (!(userForm?.language?.length === 0)) {
            setMoreLanguageOption([...userForm?.language]);
        } else {
            setMoreLanguageOption([{ languageFromMenu: '', proficiency: '' }]);
        }
    }, [userForm?.language]);

    useEffect(() => {
        getAllLanguages()
    }, [])

    console.log('userForm::', userForm)
    return (
        <>
            <div className='w-100 d-flex'>
                <div className="language_main responsive_cont w-50 pt-3 pt-md-5 flex-grow-1">
                    <h1 className='page-title-tag d-flex align-items-center m-0'>
                        <span onClick={() => setStep(prev => prev - 1)} className='d-flex'><FaAngleLeft /></span>
                        Add  Language
                    </h1>
                    {
                        moreLanguageOption?.map((data, index) => (
                            <div key={index} className='d-flex w-100 align-items-sm-center justify-content-start flex-sm-row flex-column flex-wrap gap-3'>
                                <div className='flex-grow-1'>
                                    <div className='d-flex align-items-center justify-content-between mb-2'>
                                        <label htmlFor="languageSelect">Language</label>
                                        <div className={`d-sm-none d-block d-flex align-items-center ${moreLanguageOption?.length === 1 ? 'd-none' : index === 0 && 'opacity-0 sdksoiwe'}`}>
                                            <IoMdClose onClick={() => handleRemoveLagOpt(index)} className='' fontSize={16} />
                                        </div>
                                    </div>
                                    <div className='inbuild-selectOption'>
                                        <Select
                                            options={languageLists}
                                            onChange={(e) => handleTypeSelect(e, index)}
                                            value={languageLists.find(option => option?.value === data?.languageFromMenu)}
                                            label="Single select"
                                        />
                                    </div>
                                </div>
                                <div className='flex-grow-1'>
                                    <div className='mb-2'>
                                        <label htmlFor="proficiencyLevel">Language’s Proficiency</label>
                                    </div>
                                    <div className='inbuild-selectOption'>
                                        <Select
                                            options={proficiencyListOfLevel}
                                            onChange={(e) => handleProficiencyChange(e, index)}
                                            // onChange={(e) => handleTypeSelect(e, index)}
                                            value={proficiencyListOfLevel?.find(option => option.value === data?.proficiency)}
                                            label="Single select"
                                        />
                                    </div>
                                </div>
                                <div className={`d-flex align-items-center remove-optionIcon h-100 ${moreLanguageOption?.length === 1 ? 'd-none' : index === 0 && 'opacity-0 sdksoiwe'}`}>
                                    <IoMdClose onClick={() => handleRemoveLagOpt(index)} className='mt-4' fontSize={16} />
                                </div>
                            </div>
                        ))
                    }

                    {moreLanguageOption?.length < 3 && <div className="addMoreLang" onClick={handleAddMoreLagOpt}>
                        <span>Add more language</span>
                        <FaPlus fontSize={12} />
                    </div>}
                    {error.errorMessage && <p className='m-0 text-danger'>{error.errorMessage}</p>}
                    <div className="navigation-buttons d-flex gap-3 mt-3">
                        <AuthOutlineBtn className={`${step === 0 && 'd-none'}`} onClick={handleBackStep} disabled={step === 0}>Back</AuthOutlineBtn>
                        <AuthFillButton className='' onClick={handleSubmitLag} disabled={step === 7}>Next</AuthFillButton>
                    </div>
                </div>
                <div className='steper-image-container lag-img h-md-100'>
                    <img src={languageImage} alt="languageImage" />
                </div>
            </div>
        </>
    );
}
