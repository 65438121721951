import React, { useContext } from 'react'
import NavBar from '../NavBar/Navbar'
import { Outlet } from 'react-router-dom'
import Footer from '../Footer/Footer'
import './Layout.css'
import Sidebar from '../Sidebar/Sidebar'
import Loader from '../Loader/Loader'
import UserContext from '../ContextApi/UserContext'

export default function Layout() {
  const { loaderOn, setLoaderOn } = useContext(UserContext)

  return (
    <>
      {loaderOn && <Loader />}
      <div className='layout-formate'>
        <div className='d-none'>
          <Sidebar />
        </div>
        <div className='outlet-main'>
          <NavBar />
          <div className='out_let_conatiner'>
            <Outlet />
          </div>
            <Footer />
        </div>
      </div>
    </>
  )
}
