import React from 'react'
import dummyProfileImg from '../../../assets/dummyProfileImg.png'
import { LiaIdCard } from "react-icons/lia";
import { GiNetworkBars } from "react-icons/gi";
import { SlGraduation } from "react-icons/sl";
import locationIcon from '../../../assets/Location.png'
import { SlLocationPin } from "react-icons/sl";
import lagLevel from '../../../assets/network.png'

import './Feed.css'
import Posts from './Posts';

export default function Feed() {
    return (
        <>
            <div className="feed_main">
                <div className="feed_section">
                    <div className="userDetailsCard">
                        <div><img src={dummyProfileImg} alt="dummyProfileImg" /></div>
                        <div className='idCardSection'>
                            <p><LiaIdCard fontSize={24} /> <span>Cameron Williamson</span></p>
                            <p><SlGraduation fontSize={24} /> <span> Lilypad Community School</span></p>
                            <p><GiNetworkBars fontSize={24} /> <span>Basic English</span></p>
                            <p><SlLocationPin fontSize={24} /> <span>Noida</span></p>
                        </div>
                    </div>
                </div>

                <div className="networksectionCard">
                    <div>
                        <h4>4</h4>
                        <p>Connections</p>
                    </div>
                    <div>
                        <h4>4</h4>
                        <p>Profile Visit</p>
                    </div>
                </div>
                <div className='compot-container'>
                    <Posts />
                </div>
            </div>
        </>
    )
}
