import React, { useContext, useEffect, useState } from 'react'
import './Index.css'
import EmptyJob from './EmptyJob'
import JobPostCards from './JobPostCards/JobPostCards'
import CompanyContext from '../../Component/ContextAPI/CompanyContext'
import axios from 'axios';
import { COMPANY_URLS } from '../../../Utils/Company_App_Urls';
import { Oval } from 'react-loader-spinner'


export default function Index() {
    const { isJobCreated, needTorender } = useContext(CompanyContext);
    const [isJob, setIsJob] = useState(1)
    const [loader, setLoader] = useState(false)

    const getAllJobPost = () => {
        setLoader(true)
        axios.get(`${COMPANY_URLS.getAllJobs}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            params:{
                page:1,
                limit:10
            }
        })
            .then((res) => {
                setLoader(false)
                setIsJob(res?.data?.data?.jobs?.length)
                console.log('companyJob', res?.data?.data?.jobs?.length)
            })
            .catch((err) => { setLoader(false) })
    }

    useEffect(() => {
        getAllJobPost()
    }, [isJobCreated, needTorender])

    return (
        <>
            <div className='job_post_main_index'>
                {/* {loader ? <div className='w-100 d-flex align-items-start justify-content-center'><Oval width="50" height="50px" color='#3284FF' /></div> : isJob > 0 && <JobPostCards />} */}
                {isJob > 0 ? <JobPostCards /> : <EmptyJob />}
                {/* {!loader && !isJob && <EmptyJob />} */}
            </div>
        </>
    )
}
