import React, { useCallback, useContext, useRef, useState } from 'react'
import '../EditBasicInfo/EditBasicInfo.css'
import './UpdateAvatar.css'
import { IoCloseOutline } from 'react-icons/io5'
import { FiCamera } from 'react-icons/fi'
import { AuthFillButton, EventButton, StepperBackButton } from '../../ReUseComps/Button'
import UserContext from '../../ContextApi/UserContext'
import Webcam from 'react-webcam'
import { errorPopup, successPopup } from '../../../../Utils/ToasterMessage'
import { URLS } from '../../../../Utils/App_urls'
import axios from 'axios'
import { Spinner } from 'react-bootstrap'

const videoConstraints = {
    width: '100%',
    height: '100%',
    facingMode: "user"
};

export default function UpdateAvatar() {
    const webcamRef = useRef(null)
    const { editProfilePopUPToggle, setEditProfilePopUpToggle, profileModalData, needToReload, setNeedToReload, setLoaderOn } = useContext(UserContext)
    const [openCamera, setOpenCamera] = useState(false);
    const [avatarUrl, setAvatarUrl] = useState('');
    const [previewUrl, setPreViewUrl] = useState('')
    const [loader, setLoader] = useState(false)

    const handleClosePopUPModal = () => {
        setEditProfilePopUpToggle({ ...editProfilePopUPToggle, updateAvatarModal: false, mainModalToggle: false })
    }

    const handleOpenCamera = useCallback(async () => {
        setOpenCamera(false)
        const imageSrc = webcamRef.current.getScreenshot();
        handleConvert(imageSrc)
        setPreViewUrl(imageSrc)
        setAvatarUrl(imageSrc)
    }, [webcamRef]);

    const handleConvert = (base64ImageSrc) => {
        const byteCharacters = atob(base64ImageSrc?.split(',')[1]);
        const byteNumbers = new Array(byteCharacters?.length);
        for (let i = 0; i < byteCharacters?.length; i++) {
            byteNumbers[i] = byteCharacters?.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/jpeg' });
        // setAvatarUrl(blob);
    };

    const handleCaptureImage = () => {
        setOpenCamera(true)
    }

    const uploadImageinS3 = async (data) => {
        setLoader(true)
        const formData = new FormData();
        formData.append('image', data);

        try {
            const res = await axios.post(`${URLS?.uploadImage}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('userToken')}`
                }
            });
            return res
        } catch (err) {
            console.error('ImageS3Err', err);
        }
    };

    const uploadProfileData = async () => {
        delete profileModalData['_id']
        delete profileModalData['userId']
        delete profileModalData['auth0UserId']
        delete profileModalData['createdAt']
        delete profileModalData['updatedAt']
        delete profileModalData['__v']

        const imageUploadRes = await uploadImageinS3(avatarUrl);
        const data = {
            ...profileModalData,
            "profile": imageUploadRes?.data?.data?.imageUrl
        }

        await axios.patch(`${URLS.createUserProfile}`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('userToken')}`
            }
        })
            .then((res) => {
                console.log(res)
                setNeedToReload(true)
                successPopup('Avatar Updated Successfully.');
                handleClosePopUPModal()
                setLoader(false)
            })
            .catch((err) => {
                console.log(err)
                setLoader(false)
            })

    };
    const handleUpdateAvatar = () => {
        uploadProfileData()
    }
    console.log('needToReloadAv', needToReload)


    return (
        <>
            <div className="update-avatar-main pb-2">
                <div className="ModalTitle mb-4">
                    <h1>Update Profile Image</h1>
                    <span onClick={handleClosePopUPModal}><IoCloseOutline fontSize={26} /></span>
                </div>
                <div className='web-cam-container d-flex flex-column align-items-center justify-content-center gap-4'>
                    {
                        openCamera ?
                            <div className='cameraBox'>
                                <Webcam
                                    audio={false}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    videoConstraints={videoConstraints}
                                />
                            </div> :
                            <>
                                {
                                    previewUrl ? <img src={previewUrl} alt="previewUrl" /> :
                                        <div>
                                            <FiCamera fontSize={60} />
                                        </div>
                                }
                            </>
                    }
                    {
                        openCamera ?
                            <StepperBackButton onClick={handleOpenCamera}>Open Camera</StepperBackButton> :
                            <StepperBackButton onClick={handleCaptureImage}>Capture Image</StepperBackButton>
                    }
                    <AuthFillButton onClick={handleUpdateAvatar}>
                        Update Image
                        {loader && <Spinner className='m-0 ms-2' style={{ width: '18px', height: '18px', borderWidth: '3px' }} animation="border" />}
                    </AuthFillButton>
                </div>
            </div>

        </>
    )
}
