import React, { useContext, useEffect, useRef, useState } from 'react'
import { errorPopup, successPopup } from '../../../../Utils/ToasterMessage';
import UserContext from '../../ContextApi/UserContext'
import { EventButton } from '../../ReUseComps/Button';
import '../../../Pages/CreateProfile/BasicInfo.css'
import { URLS } from '../../../../Utils/App_urls';
import { IoCloseOutline } from 'react-icons/io5'
import Form from 'react-bootstrap/Form';
import { Spinner } from 'react-bootstrap';
import '../EditBasicInfo/EditBasicInfo.css'
import '../EditBasicInfo/EditBasicInfo.css'
import './EditExperience.css'
import axios from 'axios';
import { RxCross2 } from 'react-icons/rx';
import { FiPlus } from 'react-icons/fi';

export default function EditExperience() {
    const { editProfilePopUPToggle, setEditProfilePopUpToggle, profilModalEventType, profileModalData, setNeedToReload, setLoaderOn } = useContext(UserContext)
    const [experienceStore, setExperienceStore] = useState([{ companyName: '', startDate: '', endDate: '' }])
    const [duration, setDuration] = useState({ startDate: '', endDate: '' });
    const [endTimeValidation, setEndTimeValidation] = useState('');
    const [experienceType, setExperienceType] = useState('')
    const [jobTitle, setJobTitle] = useState('');
    const [companyName, setCompanyName] = useState('')
    const [employmentType, setEmploymentType] = useState('')
    const [startDateLimit, setStartLimit] = useState('')
    const [expStoreError, setExpStoreErr] = useState({
        expCompanyNameError: '',
        expDurationError: '',
    })
    const [loader, setLoader] = useState(false)
    const [error, setError] = useState({
        jobTitleError: '',
        employmentTypeError: '',
        companyNameError: '',
        durationError: '',
        skillError: '',
        errorStatus: true
    })
    const handleJObTitle = (e) => {
        setJobTitle(e.target.value);
        setError({ errorStatus: false, jobTitleError: '' })
    }

    // const handleCompanyName = (e) => {
    //     setCompanyName(e.target.value)
    //     setError({ errorStatus: false, companyNameError: '' })
    // }

    const handleCompanyName = (e, idx) => {
        setExperienceStore(experienceStore =>
            experienceStore.map((data, index) => {
                if (index === idx) {
                    return { ...data, companyName: e.target.value };
                }
                return data;
            })
        );
        setExpStoreErr(prevState => ({ ...prevState, [`expCompanyNameError${idx}`]: '', }));
    }

    const handleDuration = (e, type, idx) => {
        if (type == 'startDate') {
            setExperienceStore(experienceStore =>
                experienceStore.map((data, index) => {
                    if (index === idx) {
                        return { ...data, startDate: e.target.value };
                    }
                    return data;
                })
            );
            setExpStoreErr(prevState => ({ ...prevState, [`expDurationError${idx}`]: '', }));
            setEndTimeValidation(e.target.value)
            setError({ errorStatus: false, durationError: '' })
        } else if (type === 'endDate') {

            setExperienceStore(experienceStore =>
                experienceStore.map((data, index) => {
                    if (index === idx) {
                        return { ...data, endDate: e.target.value };
                    }
                    return data;
                })
            );
            setExpStoreErr(prevState => ({ ...prevState, [`expDurationError${idx}`]: '', }));
        }
    }

    const handleEmploymentType = (e) => {
        const { id } = e.target;
        setEmploymentType(id)
        setError({ errorStatus: false, employmentTypeError: '' })
    }

    const handleExperienceType = (e) => {
        let { id, value } = e?.target
        setExperienceType(id)
    }

    const dateFormateRange = () => {
        let data = `${new Date().getFullYear()}`
        const month = (new Date().getMonth() + 1).toString().padStart(2, '0')
        data += `-${month}`
        const day = new Date().getDate().toString().padStart(2, '0')
        data += `-${day}`
        setStartLimit(data)
    }

    const handleSizeOfExpStore = (type, idx = -1) => {
        let lastIndex = (experienceStore?.length) - 1;
        if (type === 'increase') {
            if (isExperienceInputValid()) {
                return setExperienceStore(prev => [...prev, { companyName: '', startDate: '', endDate: '' }]);
            }
        }
        // if (type === 'increase') {
        //     if (!experienceStore?.[lastIndex]?.companyName || experienceStore?.companyName === '') {
        //         return setError({ errorStatus: true, companyNameError: 'Company name is required.' })
        //     }
        //     if (!experienceStore?.[lastIndex]?.startDate || !experienceStore?.[lastIndex]?.endDate) {
        //         return setError({ errorStatus: true, durationError: 'Duration is required.' })
        //     }
        //     return setExperienceStore(prev => [...prev, { companyName: '', startDate: '', endDate: '' }]);
        // }
        console.log('isExperienceInputValid()', isExperienceInputValid())
        type === 'decrease' && setExperienceStore(prev => prev?.filter((_, id) => id !== idx));
    }
    // const handleDuration = (e, type) => {
    //     if (type == 'startDate') {
    //         setDuration({ ...duration, startDate: e.target.value })
    //         setEndTimeValidation(e.target.value)
    //         setError({ errorStatus: false, durationError: '' })
    //     } else if (type === 'endDate') {
    //         setDuration({ ...duration, endDate: e.target.value })
    //         setError({ errorStatus: false, durationError: '' })
    //     }
    // }

    const isExperienceInputValid = () => {
        let isValid = true;
        experienceStore.forEach((exp, idx) => {
            if (!exp.companyName) {
                setExpStoreErr(prevState => ({ ...prevState, [`expCompanyNameError${idx}`]: `Company name is required.`, }));
                isValid = false
            }
        });
        experienceStore.forEach((exp, idx) => {
            if (!exp.endDate || !exp?.startDate) {
                setExpStoreErr(prevState => ({ ...prevState, [`expDurationError${idx}`]: `Duration name on is required.`, }));
                isValid = false
            }
        });
        return isValid;
    }

    const isValidForm = () => {
        let isValid = true
        if (jobTitle === '' || !jobTitle) {
            isValid = false
            return setError({ errorStatus: true, jobTitleError: 'Job title is required.' })
        }
        if (employmentType === '') {
            isValid = false
            return setError({ errorStatus: true, employmentTypeError: 'Employment type is required.' })
        }

        return isValid;
    }

    const handleSubmitExp = () => {
        // let isSubmitStatus = true
        // if (jobTitle === '' || !jobTitle) {
        //     isSubmitStatus = false
        //     return setError({ errorStatus: true, jobTitleError: 'Job title is required.' })
        // }
        // if (employmentType === '') {
        //     return setError({ errorStatus: true, employmentTypeError: 'Employment type is required.' })
        // }
        // experienceStore.forEach((exp, idx) => {
        //     if (!exp.companyName) {
        //         setExpStoreErr(prevState => ({ ...prevState, [`expCompanyNameError${idx}`]: `Company name is required.`, }));
        //     }
        //     isSubmitStatus = false
        // });
        // experienceStore.forEach((exp, idx) => {
        //     if (!exp.endDate || !exp?.startDate) {
        //         setExpStoreErr(prevState => ({ ...prevState, [`expDurationError${idx}`]: `Duration name on is required.`, }));
        //     }
        //     isSubmitStatus = false
        // });
        // if (experienceStore?.[0]?.companyName === '') {
        //     return setError({ errorStatus: true, companyNameError: 'Company name is required.' })
        // }
        // if (duration.startDate === '' || duration.endDate === '') {
        //     return setError({ errorStatus: true, durationError: 'Duration is required.' })
        // }
        if (isExperienceInputValid() && isValidForm()) {
            updateExperience()
        }
    }

    const handleClosePopUPModal = () => {
        setEditProfilePopUpToggle({ ...editProfilePopUPToggle, experienceModal: false, mainModalToggle: false })
    }

    const updateExperience = async () => {
        const expData = {
            'JobTitle': jobTitle,
            'companyName': experienceStore,
            'employeType': employmentType,
            'experience': experienceType
        }
        const frehserData = {
            'JobTitle': jobTitle,
            'employeType': employmentType,
            'companyName': [],
            'experience': experienceType
        }
        delete profileModalData['_id']
        delete profileModalData['userId']
        delete profileModalData['auth0UserId']
        delete profileModalData['createdAt']
        delete profileModalData['updatedAt']
        delete profileModalData['__v']
        delete profileModalData['companyName']

        if (experienceType === "experience") {
            Object.assign(profileModalData, expData);
        }
        if (experienceType === "fresher") {
            Object.assign(profileModalData, frehserData);
        }

        console.log('after::', profileModalData)
        try {
            setLoader(true)
            await axios.patch(`${URLS.createUserProfile}`, { ...profileModalData }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('userToken')}`
                }
            })
                .then((res) => {
                    setNeedToReload(true)
                    console.log('experienceUpdate::', res)
                    successPopup(res?.data?.message)
                    handleClosePopUPModal();
                    setLoader(false)
                })
                .catch((err) => {
                    console.log('basicinfoEditErr', err);
                    errorPopup(err?.response?.data?.message);
                    handleClosePopUPModal();
                    setLoader(false)
                })
        } catch (err) {
            console.error('err', err);
        }
    }

    useEffect(() => {
        dateFormateRange();
        setExperienceType(profileModalData?.experience);
        if (profileModalData?.companyName && profileModalData?.companyName.length > 0) {
            setExperienceStore(profileModalData.companyName);
        } else {
            setExperienceStore([{ companyName: '', startDate: '', endDate: '' }]);
        }
        setJobTitle(profileModalData?.JobTitle);
        setCompanyName(profileModalData?.experiece?.[0]?.companyName);
        setEmploymentType(profileModalData?.employeType)
        setDuration({ ...duration, startDate: profileModalData?.startMonth, endDate: profileModalData?.endMonth })
    }, [])
    // console.log('!experienceStore?.[lastIndex]?.companyName', !experienceStore?.[experienceStore?.length - 1]?.companyName)
    console.log('profileModalData', profileModalData)
    // console.log('Errr:::', error)
    return (
        <>
            <div className="experiece_main_modal d-flex flex-column">
                <div className="ModalTitle mb-3">
                    <h1>Edit Experience</h1>
                    <span onClick={handleClosePopUPModal}><IoCloseOutline fontSize={26} /></span>
                </div>
                {/* <div className="isExperience_options">
                    <p>Do you have any experience or fresher?</p>
                    <div className='experi_radio_button d-flex align-items-center justify-content-start gap-4 flex-wrap'>
                        <span>
                            <input type="radio" name="experience" id="fresher" />
                            <label htmlFor="fresher">Fresher</label>
                        </span>
                        <span>
                            <input type="radio" name="experience" id="isexperience" />
                            <label htmlFor="isexperience">Experience</label>
                        </span>
                    </div>
                </div> */}
                <div className='employment-type-input-group mb-2'>
                    <p className='m-0'>Do you have any experience or fresher?</p>
                    <div className='mt-1 emply-mnt-type'>
                        <span>
                            <input
                                onChange={handleExperienceType}
                                name='isexperience'
                                type='radio'
                                checked={experienceType === 'fresher'}
                                id={`fresher`}
                            />
                            <label htmlFor="fresher">Fresher</label>
                        </span>
                        <span>
                            <input
                                onChange={handleExperienceType}
                                name='isexperience'
                                type='radio'
                                checked={experienceType === 'experience'}
                                id={`experience`}
                            />
                            <label htmlFor="experience">Experience</label>
                        </span>
                    </div>
                    {error.employmentTypeError && <p className='m-0 text-danger'>{error.employmentTypeError}</p>}
                </div>
                <div>
                    <Form>
                        <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                            <Form.Label>Job Title</Form.Label>
                            <Form.Control
                                type="text"
                                className='exp-input'
                                onChange={handleJObTitle}
                                value={jobTitle}
                                placeholder='Enter your previous job tile' />
                            {error.jobTitleError && <p className='m-0 text-danger'>{error.jobTitleError}</p>}
                        </Form.Group>
                    </Form>
                </div>
                <div className='employment-type-input-group mb-2'>
                    <p className='m-0'>Employment Type*</p>
                    <div className='mt-1 emply-mnt-type'>
                        <span>
                            <input
                                onChange={handleEmploymentType}
                                name='jobType'
                                type='radio'
                                checked={employmentType === 'Full-Time'}
                                id={`Full-Time`}
                            />
                            <label htmlFor="Full-Time">Full-Time</label>
                        </span>
                        <span>
                            <input
                                onChange={handleEmploymentType}
                                name='jobType'
                                type='radio'
                                checked={employmentType === 'Part-Time'}
                                id={`Part-Time`}
                            />
                            <label htmlFor="Part-Time">Part-Time</label>
                        </span>
                        <span>
                            <input
                                onChange={handleEmploymentType}
                                name='jobType'
                                type='radio'
                                checked={employmentType === 'Contract'}
                                id={`Contract`}
                            />
                            <label htmlFor="Contract">Contract</label>
                        </span>
                    </div>
                    {error.employmentTypeError && <p className='m-0 text-danger'>{error.employmentTypeError}</p>}
                </div>
                {experienceType === 'experience' && <div className='expeStore_main mb-2'>
                    {
                        experienceStore?.map((expData, index) => (
                            <div className='expStore_child mb-3'>
                                <h1 className='d-flex align-items-center justify-content-between '>
                                    <span>Experience {experienceStore?.length > 0 && (index + 1)}</span>
                                    <RxCross2 className={`${index > 0 ? 'd-block' : 'd-none'}`} onClick={() => handleSizeOfExpStore('decrease', index)} fontSize={21} />
                                </h1>
                                <Form>
                                    <Form.Group className="mb-2" controlId="Enter your previous company name">
                                        <Form.Label>Company Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            className='exp-input'
                                            onChange={(e) => handleCompanyName(e, index)}
                                            value={expData?.companyName}
                                            placeholder='Enter your previous company name' />
                                        {expStoreError[`expCompanyNameError${index}`] && (
                                            <p className='m-0 text-danger'>
                                                {expStoreError[`expCompanyNameError${index}`]}
                                            </p>
                                        )}
                                        {expStoreError.expCompanyNameError && <p className='m-0 text-danger'>{expStoreError.expCompanyNameError}</p>}
                                    </Form.Group>
                                </Form>
                                <div className='input-date-picker'>
                                    <p className='m-0'>Duration</p>
                                    <div className='exp-duration-datePicker'>
                                        <input
                                            type="date"
                                            max={startDateLimit}
                                            placeholder='Start Date'
                                            onBlur={(e) => e.type = 'text'}
                                            onChange={(e) => handleDuration(e, 'startDate', index)}
                                            value={expData?.startDate}
                                        />
                                        <input
                                            type="date"
                                            onChange={(e) => handleDuration(e, 'endDate', index)}
                                            max={startDateLimit}
                                            min={endTimeValidation}
                                            value={expData?.endDate}
                                        />
                                    </div>
                                    {expStoreError[`expDurationError${index}`] && (
                                        <p className='m-0 text-danger'>
                                            {expStoreError[`expDurationError${index}`]}
                                        </p>
                                    )}
                                </div>
                            </div>
                        ))
                    }
                    {!(profilModalEventType === 'edit') &&
                        <div className={`addMoreExpeInStore mt-3 ${experienceStore?.length >= 3 && 'd-none'} `} onClick={() => handleSizeOfExpStore('increase')}>
                            <span>Add more Experience</span>
                            <FiPlus fontSize={20} />
                        </div>}
                </div>
                }
                <EventButton className='modal-update-btn mt-3' onClick={handleSubmitExp}>
                    Update
                    {loader && <Spinner className='m-0 ms-2' style={{ width: '18px', height: '18px', borderWidth: '3px' }} animation="border" />}
                </EventButton>
            </div>
        </>
    )
}
