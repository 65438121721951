import React, { useContext, useEffect, useState } from 'react'
import { RiSearch2Fill } from 'react-icons/ri';
import { Form, Spinner } from 'react-bootstrap';
import { FaAngleLeft, FaAngleRight, FaLocationDot, FaPlus } from 'react-icons/fa6';
import { GoArrowRight, GoPlug } from 'react-icons/go';
import bagSolid from '../../../../assets/bag-solid.png'
import cashMultiple from '../../../../assets/cash-multiple.png'
import dummyComapyLogo from '../../../../assets/dummyComapyLogo.png'
import { GoPlus } from "react-icons/go";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { COMPANY_URLS } from '../../../../Utils/Company_App_Urls';
import { errorPopup, successPopup, warningPopup } from '../../../../Utils/ToasterMessage';
import CompanyContext from '../../../Component/ContextAPI/CompanyContext';
import useHtmlToText from '../../../Component/CustomHooks/convertHtmlToText';
import './JobPostCards.css'
import { EventButton, PaginationBtn } from '../../../../User/component/ReUseComps/Button';
import { Oval } from 'react-loader-spinner';
import { IoSearch } from 'react-icons/io5';

export default function JobPostCards() {
    const navigate = useNavigate()
    const { setIsJobCreated, needTorender, isJobCreated, setNeedTorender, setCloseJobModalOpen } = useContext(CompanyContext)
    const [allJobs, setAllJobs] = useState([]);
    const filterBtn = ['All', 'Open', 'Close']
    const [filteredJobs, setFilteredJobs] = useState([])
    const [filterInputFiled, setFilterInputFiled] = useState('')
    const [postedDay, setPostedDay] = useState('')
    const [filterUsingInputField, setFilterUsingInputField] = useState([])
    const [clipArrLength, setClipArrLength] = useState({ min: 0, max: 10 })
    const [paginationBtnArr, setpaginationBtnArr] = useState([1, 2, 3, 4, 5])
    const [totalPage, setTotalPage] = useState(0)

    const [activeFilterBtn, setActiveFilterBtn] = useState({
        all: true,
        open: false,
        close: false,
    })

    const handlePaginationSize = (pgNo) => {
        let max = pgNo * 10;
        let min = max - 10 >= 0 ? max - 10 : 0;
        setClipArrLength({ min: min, max: max })
        getAllJobPost(pgNo)
    }

    const handleIncPagination = () => {
        setpaginationBtnArr(paginationBtnArr.map((item, _) => item + 1))
        if(totalPage < paginationBtnArr[4]){
            getAllJobPost(paginationBtnArr[4]+1)
        }else{
            getAllJobPost(totalPage)
        }
    }
    const handleDecPagination = () => {
        if (paginationBtnArr[0] > 1) {
            setpaginationBtnArr(paginationBtnArr.map((item, _) => item - 1));
            getAllJobPost(paginationBtnArr[0]-1);
        }else{
            getAllJobPost(paginationBtnArr[0]);
        }

    }

    const getAllJobPost = (pageNo = 1) => {
        axios.get(`${COMPANY_URLS.getAllJobs}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            params:{
                page:pageNo,
                limit:10
            }
        })
            .then((res) => {
                setAllJobs(res?.data?.data?.jobs)
                setFilteredJobs(res?.data?.data?.jobs)
                setFilterUsingInputField(res?.data?.data?.jobs)
                setIsJobCreated(true);
                postedDate(res?.data?.data?.createdAt?.split('T')?.[0])
                console.log('resAllJobs', res)
                setTotalPage(res?.data?.data?.totalPages)
            })
            .catch((err) => {
                console.log('err', err)
            })
    }

    const reDirectToDetailsPage = (id) => {
        navigate(`/company/job-post/job-details/${id}`, {
            state: { jobId: id }
        })
    }

    const closeJobOpening = (status, id) => {
        if (status === 'open') {
            setCloseJobModalOpen({ status: true, data: { id: id } })
        } else {
            warningPopup('This job has been already closed.')
        }
    }

    const convertHtmlToText = (htmlString) => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlString;
        return tempElement.textContent || tempElement.innerText || "";
    };

    const filterOptionChange = (option) => {
        option === 'all' && setActiveFilterBtn({ all: true, open: false, close: false })
        option === 'open' && setActiveFilterBtn({ open: true, all: false, close: false })
        option === 'close' && setActiveFilterBtn({ close: true, all: false, open: false })
        handleFilterJobPost(option)
    }

    const handleFilterJobPost = (opt) => {
        if (opt === 'all') {
            setFilteredJobs(allJobs)
            setFilterUsingInputField(allJobs)
        } else {
            const filteredJobs = allJobs?.filter((data) => {
                let status = data?.status?.toLowerCase();
                return status === opt.toLowerCase();
            });
            setFilteredJobs(filteredJobs);
            setFilterUsingInputField(filteredJobs)
        }
    }

    const handleFilterInputSearch = (e) => {
        let keyWord = e.target.value
        setFilterInputFiled(e.target.value);

        const filteredJob = filteredJobs?.filter((data) => {
            const title = data?.title?.toLowerCase();
            return title?.includes(keyWord);
        });
        setFilterUsingInputField(filteredJob);
    }

    // const postedDate = (data) => {
    //     const firstDate = new Date(data);
    //     const currentDate = new Date();
    //     const timeDifference = currentDate - firstDate;
    //     const daysDifference = Math.ceil(Math.abs(timeDifference / (1000 * 3600 * 24)));
    //     setPostedDay(daysDifference)
    // }

    const postedDate = (data) => {
        const firstDate = new Date(data);
        const currentDate = new Date();
        const timeDifference = currentDate - firstDate;
        const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
        return daysDifference >=1 ? `${daysDifference} Day${daysDifference > 1 ? 's' : ''} ago` : 'Posted Today';
    }

    useEffect(() => {
        getAllJobPost()
        handleFilterJobPost('all')
    }, [needTorender])

    return (
        <>
            <div className="job-post-card-container">
                <div className='w-100 d-flex align-items-center flex-row justify-content-between mb-3'>
                    <p className='job-post-vailable-para mb-3'>Total <span>{allJobs?.length}</span> Jobs posted</p>
                    <EventButton id='jopPostBtn' className='d-flex align-items-center justify-content-center gap-2' onClick={() => navigate('/company/create-job')}><FaPlus fontSize={16} />Create Job</EventButton>
                </div>
                {/* Job cards */}
                <div className="filterOption_postedJob mb-3 d-flex align-items-center gap-2 justify-content-between flex-wrap">
                    <div className="jobFilterBtnGrp d-flex align-items-center gap-1">
                        <button onClick={() => filterOptionChange('all')} className={`${activeFilterBtn.all && 'jobPostFilterButton'}`}>All</button>
                        <button onClick={() => filterOptionChange('open')} className={`${activeFilterBtn.open && 'jobPostFilterButton'}`}>Open</button>
                        <button onClick={() => filterOptionChange('close')} className={`${activeFilterBtn.close && 'jobPostFilterButton'}`}>Close</button>
                    </div>
                    <div className='filterInputGrp d-flex align-items-center justify-content-between gap-2'>
                        <IoSearch fontSize={20} />
                        <Form.Control
                            placeholder="Job Title"
                            aria-label="Job Title"
                            aria-describedby="basic-addon1"
                            value={filterInputFiled}
                            onChange={handleFilterInputSearch}
                        />
                    </div>
                </div>
                <div>
                    {
                        filterUsingInputField?.length > 0 ?
                    filterUsingInputField?.map((job, index) => (
                        (index >= clipArrLength.min && index < clipArrLength.max) &&
                        <div className='job-post-search-body mb-3' key={index + ('s' + 1)}>
                            <div className="job-post-cards d-flex flex-wrap">
                                <div className="job-post-right-container d-flex flex-column gap-3 flex-grow-1">
                                    <div className="job-post-card-header d-flex align-items-start justify-content-start flex-wrap gap-4">
                                        <div className="job-post-company-title d-flex align-items-start gap-3">
                                            <div><img src={job?.companyProfileDetail?.[0]?.companylogo || dummyComapyLogo} alt={job?.company} /></div>
                                            <div className='job-post-compay-name mb-2'>
                                                <h1 className='m-0'>{job?.title}</h1>
                                                <p className='m-0'>{job?.companyProfileDetail?.[0]?.companyName}</p>
                                            </div>
                                            {
                                                job?.status === 'open' ?
                                                    <span className='active-status closed-status ms-2'><div className='green_dot' /> <span>Open</span></span> :
                                                    <span className='closed-status ms-2'> <div className='red_dot' /> <span>Closed</span></span>
                                            }
                                        </div>
                                        <p className='m-0 d-flex flex-column posted_date'>
                                            {/* <span>{postedDay > 0 ? `Posted ${postedDay} Day ago` : 'Posted Today'}</span> */}
                                            <span>{postedDate(job?.createdAt?.split('T'))}</span>
                                        </p>
                                    </div>
                                    <div className='job-post-conadtion-box'>
                                        <div><img src={bagSolid} alt="bag solid" /> <span>{job?.experience}</span></div>
                                        {
                                            job?.salaryType == "Fixed Salary" && <div><img src={cashMultiple} alt="cash multiple" /> <span>{job?.FixedSalary} LPA</span></div>
                                        }
                                        {
                                            job?.salaryType == "Salary Range" && <div><img src={cashMultiple} alt="cash multiple" /> <span>{job?.minSalary} - {job?.maxSalary} LPA</span></div>
                                        }
                                        {
                                            job?.salaryType == "Not Disclose" && <div><img src={cashMultiple} alt="cash multiple" /> <span>{job?.salaryType}</span></div>
                                        }
                                        <div><FaLocationDot /> <span className='text-capitalize'>{job?.jobLocations || 'N/A'}</span></div>
                                    </div>

                                    <div className="job-post-preferences d-flex gap-1 gap-sm-3 flex-wrap">
                                        <span key={index}>{job?.deparmentType}</span>
                                        <span key={index}>{job?.educationRequiement} {' '}</span>
                                        <span key={index}>{job?.noOfOpening ? `${job?.noOfOpening} Openings` : 'N/A'}</span>
                                    </div>

                                    <div className='job-post-desp-inCard'>
                                        {/* <div>{job?.jobSummary?.slice(0, 430) + '...'}</div> */}
                                        <div>{job?.jobSummary}</div>
                                    </div>
                                    <div className="job-post-company-req-skills d-flex flex-wrap gap-2 gap-sm-3">
                                        {job?.skills?.map((skill, index) => (
                                           !(index > 4) &&  <span key={index}>{skill}</span>
                                        ))}
                                        {job?.skills?.length > 4 && <span>+{job?.skills?.length-4} more</span>}
                                    </div>
                                </div>
                                <div className="job-post-cards-btn-box flex-row flex-sm-column flex-wrap">
                                    <button className='job-post-search-btn' onClick={() => reDirectToDetailsPage(job?._id)}>View <GoArrowRight fontSize={20} /></button>
                                    <button
                                        disabled={job?.status === 'Close'}
                                        className={`job-post-outline-btn ${job?.status === 'Close' && 'closed_hiring'}`}
                                        onClick={() => closeJobOpening(job?.status, job?._id)}>
                                        {job?.status === 'Close' ? 'Closed' : 'Close Hiring'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )):
                    <p className='text-center'>No Job Found</p>
                }
                    {/* {filterUsingInputField?.length === 0 && <p className='text-center'>No Data Found</p>}
                    {filterUsingInputField?.length <= 0 && <div className='d-flex align-items-center justify-content-center'><Oval width="50" height="50px" color='#3284FF' /></div>} */}
                    {/* {filterUsingInputField?.length > 0 &&  */}
                    <div className="footer-pagination d-flex align-items-center justify-content-center pb-3">
                        <div className='d-flex align-items-center gap-2'>
                            <PaginationBtn 
                                // disabled={(paginationBtnArr[paginationBtnArr?.length - 1] / 10 < 1)} 
                                disabled={paginationBtnArr[0] === 1} 
                                className={`pagination-btn ${(paginationBtnArr[paginationBtnArr?.length - 1] / 10 < 1) && 'shadow-none' } `} 
                                onClick={handleDecPagination}>
                                    <FaAngleLeft fontSize={20} /> 
                                    Previous
                            </PaginationBtn>
                            {
                                paginationBtnArr.map((data, idx) => (
                                    <PaginationBtn
                                        key={idx}
                                        disabled={totalPage <= data}
                                        onClick={() => handlePaginationSize(data)}
                                        className={`pagination-btn ${(clipArrLength.max === data * 10 && 'pagination-active')} 
                                        `}
                                        // ${filterUsingInputField?.length < (data * 10) && idx > 0 && 'd-none'}
                                    >
                                        {data}
                                    </PaginationBtn>
                                ))
                            }
                            <PaginationBtn 
                                // disabled={filterUsingInputField?.length < (paginationBtnArr[paginationBtnArr?.length - 1] * 10)} 
                                disabled={totalPage < paginationBtnArr[4]} 
                                className={`pagination-btn ${(filterUsingInputField?.length < (paginationBtnArr[paginationBtnArr?.length - 1] * 10)) && 'shadow-none'}`} onClick={handleIncPagination}>
                                Next
                                <FaAngleRight fontSize={20} />
                            </PaginationBtn>
                        </div>
                    </div>
                    {/* } */}
                </div>
            </div>
        </>
    )
}
