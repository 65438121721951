import React, { useEffect, useState } from "react";

export default function useWindowDimension() {
  const [windowDimensions, setWindowDimensions] = useState({
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  console.log('xxxxxx',windowDimensions)
  return windowDimensions;
}
