// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not_interseted_main{
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 8px;
    padding: 24px 24px;
}

.not_interseted_main > h1{
    color: #3284FF;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    margin-bottom: 1.6rem;
}

.warning-none{
    font-size: 24px;
    text-align: center;
    font-weight: 600;
}

.archive_parents{
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
}

.page-table-typo_archive {
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/User/Pages/NotInterested/NotInterested.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,wBAAmB;IAAnB,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,wBAAmB;IAAnB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,SAAS;AACb","sourcesContent":[".not_interseted_main{\n    width: 100%;\n    height: fit-content;\n    border-radius: 8px;\n    padding: 24px 24px;\n}\n\n.not_interseted_main > h1{\n    color: #3284FF;\n    font-size: 20px;\n    font-weight: 700;\n    cursor: pointer;\n    margin-bottom: 1.6rem;\n}\n\n.warning-none{\n    font-size: 24px;\n    text-align: center;\n    font-weight: 600;\n}\n\n.archive_parents{\n    width: 100%;\n    height: fit-content;\n}\n\n.page-table-typo_archive {\n    font-size: 16px;\n    font-weight: 400;\n    color: #000000;\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
