import React, { useContext, useEffect, useState } from 'react'
import { EventButton, StepperBackButton } from '../../../User/component/ReUseComps/Button'
import { errorPopup, successPopup, warningPopup } from '../../../Utils/ToasterMessage'
import useHtmlToText from '../../Component/CustomHooks/convertHtmlToText'
import dummyCompanyLogo from '../../../assets/unknownLogo.png'
import { COMPANY_URLS } from '../../../Utils/Company_App_Urls'
import cashMultiple from '../../../assets/cash-multiple.png'
import { useLocation, useParams } from 'react-router-dom'
import bagImage from '../../../assets/bag-solid.png'
import { TiLocation } from "react-icons/ti";
import { IoMdShare } from "react-icons/io";
import './PostedJobDetails.css'
import axios from 'axios'
import { Spinner } from 'react-bootstrap'
import CompanyContext from '../../Component/ContextAPI/CompanyContext'
import { Oval } from 'react-loader-spinner'
import { Skeleton } from 'antd'

export default function JobDetails({ singleJobDetails }) {
  const { setCloseJobModalOpen, needTorender, } = useContext(CompanyContext)
  const jobId = useParams()
  const location = useLocation()
  const [postedDay, setPostedDay] = useState('')
  const [spinners, setSpinners] = useState(false)

  // const getSingleJobDetails = async () => {
  //   await axios.get(`${COMPANY_URLS.getSingleJob}`, {
  //     params: {
  //       id: jobId?.id
  //     },
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem('token')}`
  //     }
  //   })
  //     .then((res) => {
  //       const result = res?.data?.result?.[0] || [];
  //       // setSingleJobDetails(result || {});
  //       postedDate(result?.createdAt?.split('T')?.[0])
  //       console.log('result',result?.createdAt?.split('T')?.[0])
  //       // setGetAllAppliedCandidates(result?.appliedUserProfile)
  //     })
  //     .catch((err) => { console.log(err) })
  // }

  const shareLink = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Kaamval job url',
        text: 'I found matched interesting  job link:',
        url: window.location.href,
      })
        .then((res) => {
          successPopup(res?.data?.message)
        })
        .catch((error) => console.log('Error sharing:', error));
    } else {
      alert('Your browser does not support the Web Share.');
    }
  }

  const postedDate = (data) => {
    const firstDate = new Date(data);
    const currentDate = new Date();
    const timeDifference = currentDate - firstDate;
    const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
    return daysDifference >= 1 ? `${daysDifference} Day${daysDifference > 1 ? 's' : ''} ago` : 'Posted Today';
  }

  // const postedDate = (data) => {
  //   const firstDate = new Date(data);
  //   const currentDate = new Date();
  //   const timeDifference = currentDate - firstDate;
  //   const daysDifference = Math.ceil(Math.abs(timeDifference / (1000 * 3600 * 24)));
  //   setPostedDay(daysDifference)
  // }

  const convertHtmlToText = (htmlString) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;
    return tempElement.textContent || tempElement.innerText || "";
  };

  const closeJobOpening = (status, id) => {
    if (status === 'open') {
      setCloseJobModalOpen({ status: true, data: { id: id } })
    } else {
      warningPopup('This job has been already closed.')
    }
  }

  useEffect(() => {
    postedDate(singleJobDetails?.createdAt?.split('T')?.[0])
  }, [needTorender])

  return (
    <>
      <div className="job-details-main">
        {singleJobDetails?.title ?
          <div className="similar-job-card d-flex flex-column gap-3">
            <div className="similar-job-header d-flex flex-sm-row flex-column-reverse align-items-start justify-content-between flex-wrap gap-2 mb-3">
              <div className='d-flex align-items-center gap-3'>
                <div className="compy-logo-box">
                  <img src={singleJobDetails?.companyProfileDetail?.[0]?.companylogo || dummyCompanyLogo} alt="dummyCompanyLogo" />
                </div>
                <div className='compy-title-descr'>
                  <h1 className='d-flex align-items-center gap-2'>{singleJobDetails?.title}
                    {
                      singleJobDetails?.status === 'open' ?
                        <span className='active-status closed-status ms-2'><div className='green_dot' /> Open</span> :
                        <span className='closed-status ms-2'><div className='red_dot' />Closed</span>
                    }
                  </h1>
                  <p>{singleJobDetails?.companyProfileDetail?.[0]?.companyName}</p>
                  <a className='text-break' href={singleJobDetails?.companyProfileDetail?.[0]?.weburl} target='_blank'>{singleJobDetails?.companyProfileDetail?.[0]?.weburl}</a>
                </div>
              </div>
              <p className='m-0 d-flex flex-column'>
                {/* <span>{postedDay > 0 ? `Posted ${postedDay} Day ago` : 'Posted Today'}</span> */}
                <span>{postedDate(singleJobDetails?.createdAt?.split('T'))}</span>
              </p>
            </div>
            <div className='compay-benif d-flex align-items-start justify-content-start flex-wrap gap-3 mb-2'>
              <div> <img src={bagImage} alt="bag solid image" /> <span>{singleJobDetails?.experience || 'N/A'}</span></div>
              {singleJobDetails?.salaryType === "Fixed Salary" && <div><img src={cashMultiple} alt="cash multiple" /> <span>{singleJobDetails?.FixedSalary} LPA</span></div>}
              {singleJobDetails?.salaryType == "Salary Range" && <div><img src={cashMultiple} alt="cash multiple" /> <span>{singleJobDetails?.minSalary} LPA - {singleJobDetails?.maxSalary} LPA</span></div>}
              {singleJobDetails?.salaryType == "Not Disclose" && <div><img src={cashMultiple} alt="cash multiple" /> <span>{singleJobDetails?.salaryType}</span></div>}
              <div><TiLocation color='#4B4B4B' fontSize={24} /><span>{singleJobDetails?.workPlace}</span></div>
            </div>

            <div className="highlight-skills d-flex align-items-center flex-wrap gap-3 mb-2">
              {/* <span>Employenment Type :</span> */}
              <div>{singleJobDetails?.deparmentType || 'N/A'}</div>
              <div>{singleJobDetails?.educationRequiement || 'N/A'}</div>
              <div>{singleJobDetails?.noOfOpening ? `${singleJobDetails?.noOfOpening} Openings` : 'N/A'}</div>
            </div>
            <div className="skill-section-job-card d-flex align-items-center flex-wrap gap-2">
              {/* <span>Skills :</span> */}
              {
                singleJobDetails?.skills?.map((data, index) => (
                  <div key={index}>{data}</div>
                ))
              }
            </div>
            <div className="button-section-jobCard d-flex flex-wrap gap-3 mt-3">
              {
                singleJobDetails?.status === 'open' ?
                  <EventButton
                    onClick={() => closeJobOpening(singleJobDetails?.status, singleJobDetails?._id)}
                  >
                    <span>Close Hiring</span> {spinners && <Spinner animation="border" role="status" />}</EventButton>
                  : <button className='blosed-job-btn py-2'><span>Closed</span> {spinners && <Spinner animation="border" role="status" />}</button>
              }
              <StepperBackButton onClick={shareLink}>Share <IoMdShare fontSize={18} /> </StepperBackButton>
            </div>
            <div className="job-description-container mt-3">
              <div><h1>Job Description</h1></div>
              <div dangerouslySetInnerHTML={{ __html: convertHtmlToText(singleJobDetails?.jobDescription) }} />
            </div>
          </div>
          :
          <Skeleton
            avatar
            paragraph={{
              rows: 5,
            }}
            />
        }
      </div>
    </>
  )
}
