import React from 'react'
import { FaArrowLeftLong } from "react-icons/fa6";
import pageNotFound from '../assets/404.jpg'
import './FallbackPage.css'
import { useNavigate } from 'react-router-dom';


export default function FallbackPage() {
    const navigate = useNavigate();
    return (
        <>
            <div className="fallback_container d-flex flex-column align-items-center justify-content-center">
                <img src={pageNotFound} alt="pageNotFound" />
                <h1>Page Not Found</h1>
                <div className='d-flex align-items-center gap-2 justify-content-center'> <FaArrowLeftLong onClick={()=>navigate(-1)}/> <span>Back</span></div>
            </div>
        </>
    )
}
