import { useMemo } from 'react';

const useHtmlToText = (htmlString='N/A') => {
    return useMemo(() => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlString;
        return tempElement.textContent || tempElement.innerText || "";
    }, [htmlString]);
};

export default useHtmlToText;

