import React, { useContext, useEffect, useRef, useState } from 'react';
import { AuthFillButton, StepperBackButton } from '../../component/ReUseComps/Button';
import UserContext from '../../component/ContextApi/UserContext';
import jobCityImage from '../../../assets/cityImage.png'
import { URLS } from '../../../Utils/App_urls';
import { GrLocationPin } from 'react-icons/gr';
import { FaAngleLeft } from 'react-icons/fa6';
import { CiSearch } from "react-icons/ci";
import { FaPlus } from 'react-icons/fa';
import { Form } from 'react-bootstrap';
import Select from "react-select";
import axios from 'axios';
import './Language.css';
import './JobCity.css'
import './BasicInfo.css';


export default function JobCity() {
    const [selectedOption, setSelectedOption] = useState("none");
    const { step, setStep, userForm, setUserForm } = useContext(UserContext);
    const [enterCity, setEnterCity] = useState('');
    const [addCities, setAddCities] = useState([]);
    const [city, setCity] = useState([]);
    const [dummyName, setDummyName] = useState([])
    const [error, setError] = useState({
        errorMessage: '',
        errorStatus: true
    });

    const suggestedCity = ['Faridabad', 'Meerut', 'Agra', 'New Delhi', 'Jaipur'];
    const AllLiveCities = ['Indore', 'Meerut', 'Jaipur', 'Indore', 'Meerut', 'Jaipur'];

    const handleAddMoreCity = (data, id) => {
        setCity((prev) => [...prev, data]);
        setError(error => ({ ...error, errorMessage: '', errorStatus: false }));
        updateCityStyles(id);
    };

    const updateCityStyles = (id) => {
        let lagbtn = document.getElementById(`sugest-city-${id}`);
        if (lagbtn) lagbtn.classList.add('lagSelect');

        let plusIcon = document.getElementById(`plushIcon-${id}`);
        if (plusIcon) plusIcon.classList.add('iconRemove');

        let cressIcon = document.getElementById(`crossIcons-${id}`);
        if (cressIcon) cressIcon.classList.add('d-block');
    };

    const handleRemoveCity = (data, id) => {
        setCity(prevArr => prevArr.filter((items) => items !== data));
        updateCityStylesRemoval(id);
    };

    const updateCityStylesRemoval = (id) => {
        let lagbtn = document.getElementById(`sugest-city-${id}`);
        if (lagbtn) lagbtn.classList.remove('lagSelect');

        let cressIcon = document.getElementById(`crossIcons-${id}`);
        if (cressIcon) cressIcon.classList.remove('d-block');

        let plusIcon = document.getElementById(`plushIcon-${id}`);
        if (plusIcon) plusIcon.classList.remove('iconRemove');
    };

    const handleRemoveCityFromSearchBar = (data) => {
        setCity(prevArr => prevArr.filter((items) => items !== data));
        setAddCities(prevArr => prevArr.filter((items) => items !== data));
        setError(error => ({ ...error, errorMessage: '', errorStatus: false }));
    };

    const handleEnterCity = (e) => {
        const value = e;
        if (!city.includes(value)) {
            setCity(prevSkills => [...prevSkills, value]);
        }
        if (!addCities.includes(value)) {
            setAddCities(prevSkills => [...prevSkills, value]);
        }
        setError(error => ({ ...error, errorMessage: '', errorStatus: false }));
    };

    const pickLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success, errorFn);
        } else {
            console.log("Geolocation not supported");
        }
    };

    function success(position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        getCityName(latitude, longitude);
    }

    function errorFn() {
        console.log("Unable to retrieve your location");
    }

    async function getCityName(latitude, longitude) {
        try {
            const response = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`);
            const data = await response.json();
            let newCity = data?.address?.city;
            if (!city.includes(newCity)) {
                setCity(prev => [...prev, data?.address?.city])
            }

        } catch (error) {
            console.error('Error fetching city name:', error);
            return null;
        }
    }

    const handleSubmitCity = () => {
        if (city?.length === 0) {
            setError(error => ({ ...error, errorMessage: 'City is required.', errorStatus: true }));
        } else {
            setError(error => ({ ...error, errorMessage: '', errorStatus: false }));
            setUserForm(userForm => ({ ...userForm, jobCity: city }));
            setStep(step => step + 1);
        }
    };

    const getCity = () => {
        axios.get(`${URLS.getcities}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('userToken')}`
            }
        })
            .then((res) => {
                const citiesData = res?.data?.data || [];
                const formattedCities = citiesData.map(city => ({ value: city, label: city }));
                setDummyName(formattedCities)
            })
            .catch((err) => console.log(err))
    }

    const handleBackStep = () => {
        setUserForm(userForm => ({ ...userForm, jobCity: city }));
        setStep(prev => prev - 1);
    }
    const handleTypeSelect = (e) => {
        setSelectedOption(e.value);
        handleEnterCity(e.value)
    };

    // console.log('City::::::>>', city);
    // console.log('citiesName====', citiesName)
    // console.log('userForm', userForm.jobCity);
    console.log('enterCity:::', dummyName);

    useEffect(() => {
        if (userForm.jobCity) {
            userForm.jobCity.forEach((item) => {
                if (suggestedCity.includes(item)) {
                    handleAddMoreCity(item, `${suggestedCity.indexOf(item)}`);
                } else if (AllLiveCities.includes(item)) {
                    handleAddMoreCity(item, `${AllLiveCities.indexOf(item) + 'a'}`);
                } else {
                    setAddCities((prev) => {
                        if (!prev.includes(item)) {
                            return [...prev, item];
                        }
                        return prev;
                    });
                }
            });
            setCity(userForm.jobCity);
        }
    }, [userForm.jobCity]);

    useEffect(() => {
        getCity()
    }, [])

    return (
        <>
            <div className='d-flex'>
                <div className="jobCity_main responsive_cont basicInfo pt-2 w-50 flex-grow-1">
                    <h1 className='page-title-tag d-flex align-items-center mt-2 mb-2 mt-md-4'>
                        <span onClick={() => setStep(prev => prev - 1)} className='d-flex'><FaAngleLeft /></span>
                        <span>Job City</span>
                    </h1>
                    <div>
                        <div className='d-flex justify-content-between align-items-center flex-wrap mt-3 mb-2'>
                            <p className='m-0 lksjdoai'>Preferred Job Location*</p>
                            <div onClick={() => pickLocation()} className='pickLocationGPS'> <GrLocationPin fontSize={18} /> Pick Current Location</div>
                        </div>
                        <div className='inbuild-selectOption'>
                            <Select
                                options={dummyName}
                                onChange={handleTypeSelect}
                                value={dummyName.find(option => option.value === selectedOption)}
                                label="Single select"
                            />
                        </div>
                        <p className='m-0 text-danger'>{error.errorMessage}</p>
                        <div className='d-flex gap-2 my-2 flex-wrap'>
                            {
                                city?.map((city, index) => (
                                    <p key={index} className='skillCollections m-0'>{city} <span onClick={() => handleRemoveCityFromSearchBar(city)}>x</span></p>
                                ))
                            }
                        </div>
                    </div>
                    <div className='moreLanguageOptionBox mt-4'>
                        <h2>Suggested cities</h2>
                        <div className='d-flex gap-2 flex-wrap'>
                            {
                                suggestedCity.map((lag, index) => (
                                    <button
                                        key={index}
                                        className="otrLgnOptbx"
                                        id={`sugest-city-${index}`}>
                                        <span>{lag}</span>
                                        <FaPlus onClick={() => handleAddMoreCity(lag, index)} className='addLagIcon' id={`plushIcon-${index}`} color='#000000' fontSize={12} />
                                        <span className='removeLagIcon' onClick={() => handleRemoveCity(lag, index)} id={`crossIcons-${index}`}>X</span>
                                    </button>
                                ))
                            }
                        </div>
                    </div>
                    <div className="navigation-buttons d-flex gap-3 mt-3">
                        <StepperBackButton className={`${step === 0 && 'd-none'}`} onClick={handleBackStep} disabled={step === 0}>Back</StepperBackButton>
                        <AuthFillButton className='' onClick={handleSubmitCity} disabled={step === 7}>Next</AuthFillButton>
                    </div>
                </div>
                <div className='steper-image-container h-md-100'>
                    <img src={jobCityImage} alt="job city" />
                </div>
            </div>
        </>
    );
}
