// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.profile-modal-box{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    background: #00000080;
    z-index: 999;
    overflow-y: auto;
    padding-top: 2rem;
    padding-bottom: 1rem;
}

.modals_container{
    width: 570px;
    height: -moz-fit-content;
    height: fit-content;
    padding: 32px 32px;
    border-radius: 12px;
    background-color: #fff;
}

@media screen and (max-width:576px) {
    .modals_container{
        width: 95%;
        margin: 0 auto;
    }
}`, "",{"version":3,"sources":["webpack://./src/User/component/EditProfilePopUps/ModalBox.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,MAAM;IACN,OAAO;IACP,aAAa;IACb,yBAAyB;IACzB,uBAAuB;IACvB,qBAAqB;IACrB,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,YAAY;IACZ,wBAAmB;IAAnB,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI;QACI,UAAU;QACV,cAAc;IAClB;AACJ","sourcesContent":["\n.profile-modal-box{\n    width: 100%;\n    height: 100%;\n    position: fixed;\n    top: 0;\n    left: 0;\n    display: flex;\n    /* align-items: center; */\n    justify-content: center;\n    background: #00000080;\n    z-index: 999;\n    overflow-y: auto;\n    padding-top: 2rem;\n    padding-bottom: 1rem;\n}\n\n.modals_container{\n    width: 570px;\n    height: fit-content;\n    padding: 32px 32px;\n    border-radius: 12px;\n    background-color: #fff;\n}\n\n@media screen and (max-width:576px) {\n    .modals_container{\n        width: 95%;\n        margin: 0 auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
