import React, { useEffect, useState } from 'react'
import UserContext from './UserContext'
import axios from 'axios';
import { URLS } from '../../../Utils/App_urls';


export default function UserContextProvider({ children }) {

  const [loginModal, setLoginModal] = useState(false);
  const [loaderOn, setLoaderOn] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(false);
  const [userProfileComaplete, setUserprofileComplete] = useState(false);
  const [profileModalData, setProfileModalData] = useState(null)
  const [profilModalEventType, setProfileModalEventType] = useState(null)
  const [needToReload, setNeedToReload] = useState(false);
  const [allJobAfterFilter, setAllAfterFilter] = useState([]);
  const [setLoader, loader] = useState(false)
  const [pureSavedJobs, setPureSavedJobs] = useState([]);
  const [editProfilePopUPToggle, setEditProfilePopUpToggle] = useState({
    mainModalToggle: false,
    basicInfoModal: false,
    educationModal: false,
    experienceModal: false,
    departmentModal: false,
    skillsModal: false,
    languageModal: false,
    preferenceModal: false,
    updateAvatarModal:false,
  })
  const [step, setStep] = useState(0)
  const [userForm, setUserForm] = useState({ basicInfo: {}, education: [], experience: [], language: [], preference: [], jobCity: [], department: [], profilePhoto: '', availability:''});
  const userFormData = new FormData();

  const getUserDetails = async () => {
    axios.get(`${URLS.userDetails}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('userToken')}`
      }
    })
      .then((res) => {
        setUserprofileComplete(res?.data?.data?.profileComplete)
        localStorage.setItem('isprofileUserComplete',res?.data?.data?.profileComplete)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const [profileImageUrl, setProfileImageUrl] = useState(''); // for updating profile image url set in nevbar
  const [singleAppliedJobDetails, setSingleAppliedJobDetails] = useState({})

  useEffect(() => {
    getUserDetails()
  }, [userProfileComaplete])

  return (
    <UserContext.Provider
      value={{
        loginModal, setLoginModal,
        step, setStep,
        userForm, setUserForm,
        userFormData,
        openDrawer, setOpenDrawer,
        userProfileComaplete, setUserprofileComplete,
        editProfilePopUPToggle, setEditProfilePopUpToggle,
        profileModalData, setProfileModalData, // for forwaring data from profile to pop-up modal
        profilModalEventType, setProfileModalEventType,  // for giving instruction to pop-up modal is edit or Update
        needToReload, setNeedToReload, // for reloading page when popup modal update user-profile
        profileImageUrl, setProfileImageUrl,
        loaderOn, setLoaderOn,
        getUserDetails,
        setLoader, loader,
        singleAppliedJobDetails, setSingleAppliedJobDetails,
        allJobAfterFilter, setAllAfterFilter, // Adding allJobs after filter on Find job page.
        pureSavedJobs, setPureSavedJobs,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
