import React from 'react'
import postJob from '../../../assets/postJob.png'
import getVerify from '../../../assets/getVerified.png'
import callsHire from '../../../assets/callsHire.png'
import stepArrow from '../../../assets/stepArrow.png'
import './Home.css'

export default function ApplySteps() {

    const jobSteps = [
        {
            image: postJob,
            title: 'Post a Job',
            description: 'Tell us what you need in a candidate in just 5-minutes.'
        },
        {
            image: getVerify,
            title: 'Get Verified',
            description: 'Our team will call to verify your employer account'
        },
        {
            image: callsHire,
            title: 'Get calls. Hire.',
            description: 'You will get calls from relevant candidates within one hour or call them directly from our candidate database.'
        },
    ]

    return (
        <>
            <div className="applySteps_main mb-5">
                <div className="stepsTitle">
                    <h1>Get started in 3 easy steps</h1>
                    <div className='hlfbtnBorder' />
                </div>
                <div className='d-flex flex-wrap justify-content-center flex-wrap align-items-start gap-2'>
                    {
                        jobSteps.map((data, index) => (
                            <div className='d-flex align-items-center flex-wrap justify-content-center gap-2'>
                                <div key={index} className="jobAplyCrds">
                                    <img src={data.image} alt="postJob" />
                                    <h1>{data.title} </h1>
                                    <p>{data.description}</p>
                                </div>
                                <img className={`steps-arrowIcon ${jobSteps.length - 1 === index && 'd-none' } d-md-block d-none`} src={stepArrow} alt="stepArrow" />
                            </div>
                        ))
                    }
                </div>
                <button className='postJobbtn'>Post a Job</button>
            </div>
        </>
    )
}
