// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.search_main{
    width: 100%;
    margin-top: 2rem;
    text-align: center;
    font-size: 25px;
    font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/User/Pages/SearchJob/SearchJob.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;AACpB","sourcesContent":["\n.search_main{\n    width: 100%;\n    margin-top: 2rem;\n    text-align: center;\n    font-size: 25px;\n    font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
