import React, { useEffect, useState } from 'react'
import { MdLock } from "react-icons/md";
import { IoMdNotifications } from "react-icons/io";
import './Setting.css'
import ResetPassword from './ResetPassword';
import { useLocation, useNavigate } from 'react-router-dom';
import ResetNotification from './ResetNotification';

export default function Setting() {
    const navigate = useNavigate()
    const location = useLocation()
    const [activeComp, setActiveComp] = useState('changePassword');

    const handleRouteChange = (value) => {
        setActiveComp(value)
        navigate(`#${value}`)
    }

    useEffect(()=>{
        navigate(`#changePassword`)
    },[])

    console.log('location', location.hash)

    return (
        <>
            <h1 className='setting_title'>Setting</h1>
            <div className="setting_main d-flex flex-lg-row flex-column gap-md-4 gap-2 mt-2">
                <div className="sub_router_box d-flex flex-lg-column flex-row gap-4 flex-wrap">
                    <div
                        onClick={() => handleRouteChange('changePassword')}
                        className={`${location?.hash?.includes('changePassword') && 'active-subrouter'}`}>
                            <MdLock />
                            Change Password
                    </div>
                    <div
                        onClick={() => handleRouteChange('notification')}
                        className={`${location?.hash?.includes('notification') && 'active-subrouter'}`}>
                            <IoMdNotifications/>
                            Notification
                    </div>
                </div>
                <div className="sub_router_comp">
                   {location?.hash?.includes('notification') ? <ResetNotification/> : <ResetPassword/>}
                </div>
            </div>
        </>
    )
}
