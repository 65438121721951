import React, { useContext, useEffect } from 'react'
import { AuthFillButton, StepperBackButton } from '../../../component/ReUseComps/Button'
import { errorPopup, successPopup } from '../../../../Utils/ToasterMessage'
import calendarIcon from '../../../../assets/solar_calendar-linear.png'
import dummyprofileImage from '../../../../assets/boys-profile.png'
import UserContext from '../../../component/ContextApi/UserContext'
import genderIcon from '../../../../assets/icons8_gender.png'
import { URLS } from '../../../../Utils/App_urls'
import { IoCallOutline } from "react-icons/io5";
import { PiMapPinLight } from "react-icons/pi";
import { Form } from 'react-bootstrap'
import axios from 'axios'
import './Preview.css'


export default function Preview() {
    const { step, setStep, userForm, setProfileImageUrl, setUserprofileComplete } = useContext(UserContext)
    const handleBackStep = () => {
        setStep(prev => prev - 1)
    }

    const handlleSubmit = async () => {
        await uploadProfileData();
    }

    const uploadProfileData = async () => {
        const isExperience = {
            "companyName": userForm?.experience?.experienceStore,
        }

        const userData = {
            "name": userForm?.basicInfo?.fullName,
            "DOB": userForm?.basicInfo?.DOB,
            "address": userForm?.basicInfo?.address,
            "gender": userForm?.basicInfo?.gender,
            "education": userForm?.education,
            "experience": userForm?.experience?.experience,
            "JobTitle": userForm?.experience?.jobTitle,
            "employeType": userForm?.experience?.employmentType,
            "skills": userForm?.experience?.skills,
            "languages": userForm?.language,
            "workPlace": userForm?.preference.find(data => data?.category === "Preferred workplace")?.selectedOptions,
            "shift": userForm?.preference.find(data => data?.category === "Preferred shift")?.selectedOptions,
            "city": userForm?.jobCity,
            "department": userForm?.department,
            "profile": userForm?.profilePhoto,
            "mobileNo": userForm?.basicInfo?.mobileNo,
            "startMonth":[userForm?.availability],
            "availablity":userForm?.availability
        };
        if (userForm?.experience?.experience === "experience") {
            Object.assign(userData, isExperience);
        }
        console.log('userData2', userData)


        await axios.patch(`${URLS.createUserProfile}`, userData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('userToken')}`
            }
        }).then((res) => {
            console.log('previewSubmit::', res);
            successPopup('Profile Created Successfully.');
            setStep(prev => prev + 1);
            setProfileImageUrl(res?.data?.data?.profile);
            setUserprofileComplete(true);
        })
            .catch((err) => {
                console.error('err', err);
                errorPopup(err?.response?.data?.message);

            })
    };

    const formatDate = (data) => {
        const [year, month, day] = data.split('-');
        return `${day}/${month}/${year}`;
    }
    useEffect(() => {

    }, [])
    console.log('userFormPreview', userForm)
    console.log('userForm?.experience?.skills', userForm?.experience?.skills)
    return (
        <>
            <Form className="preview_main responsive_conts px-3 ps-sm-0 pe-sm-4 pb-4 pt-3 d-flex flex-column gap-2">
                <h1>Preview</h1>
                <div className="preview_section small_margin d-flex align-items-center justify-content-between gap-3 flex-wrap">
                    <div className="profileImgName d-flex align-items-center justify-content-start gap-3">
                        <img src={userForm?.profilePhoto || dummyprofileImage} alt="dummyprofileImage" />
                        <div className='d-flex flex-column gap-2'>
                            <p>{userForm?.basicInfo?.fullName || 'N/A'}</p>
                            <p className='d-flex gap-2'>{userForm?.experience?.jobTitle || 'N/A'}</p>
                        </div>
                    </div>
                    <div className="basic-preview-details">
                        <div className="nO-DOB-Gen d-flex align-items-center justify-content-between gap-3 mb-2 flex-wrap">
                            <div><IoCallOutline fontSize={20} /> <span>{userForm?.basicInfo?.mobileNo}</span></div>
                            <div><img src={calendarIcon} alt="calendarIcon" /> <span>{formatDate(userForm?.basicInfo?.DOB)}</span></div>
                            <div><img src={genderIcon} alt="genderIcon" /> <span>{userForm?.basicInfo?.gender || 'N/A'}</span></div>
                        </div>
                        <div className="nO-DOB-Gen d-flex align-items-center justify-content-between gap-3">
                            <div><PiMapPinLight fontSize={20} /> <span>{userForm?.basicInfo?.address || 'N/A'}</span></div>
                        </div>
                    </div>
                </div>
                <div className="preview_section-box">
                    <h1>Work Status</h1>
                    <div>
                        <div className='d-flex flex-wrap preview-input-group gap-2'>
                            <Form.Group className="mb-0 mb-sm-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Experience</Form.Label>
                                <Form.Control type="text" disabled defaultValue={userForm?.experience?.experience || 'N/A'} placeholder="XYZ Pvt Ltd" />
                            </Form.Group>
                            <Form.Group className="mb-0 mb-sm-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Job Title</Form.Label>
                                <Form.Control type="text" disabled defaultValue={userForm?.experience?.jobTitle || 'N/A'} placeholder="UI/UX Designer" />
                            </Form.Group>
                        </div>

                        {
                            userForm?.experience?.experience === 'experience' &&
                            <div className='expStore_preview mt-sm-0 mt-3'>
                                {
                                    userForm?.experience?.experienceStore?.map((expStore, idx) => (
                                        <div className='d-flex flex-wrap preview-input-group gap-2'>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                <Form.Label>Company Name</Form.Label>
                                                <Form.Control type="text" disabled defaultValue={expStore?.companyName} placeholder="XYZ Pvt Ltd" />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Start Date</Form.Label>
                                                <Form.Control type="date" disabled defaultValue={expStore.startDate} placeholder="01/01/2010" />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                <Form.Label>End Date</Form.Label>
                                                <Form.Control type="date" disabled defaultValue={expStore.endDate} placeholder="21/08/2011" />
                                            </Form.Group>
                                        </div>
                                    ))
                                }
                            </div>
                        }
                    </div>
                </div>
                <div className="preview_section-box">
                    <h1>Education & Availability</h1>
                    <div>
                        <div className='d-flex flex-wrap preview-input-group gap-2'>
                            <Form.Group className="mb-0 mb-sm-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Highest Education</Form.Label>
                                <Form.Control disabled type="text" defaultValue={userForm?.education || 'N/A'} placeholder="12th Pass" />
                            </Form.Group>
                            <Form.Group className="mb-0 mb-sm-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Availability</Form.Label>
                                <Form.Control disabled type="text" defaultValue={userForm?.availability} placeholder="Immediate" />
                            </Form.Group>
                        </div>
                    </div>
                </div>
                <div className="preview_section-box">
                    <h1>Language</h1>
                    <table className='preview_table border text-center'>
                        <thead>
                            <tr>
                                <th className='my-2'>Language</th> <th className='my-2'>Proficiency Level</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                userForm?.language?.map((data, index) => (
                                    <tr className='border-top' key={index}>
                                        <td>{data?.languageFromMenu}</td>
                                        <td>{data?.proficiency}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                <div className="preview_section-box">
                    <h1>Skill & Preference</h1>
                    <div className='d-flex align-items-center justify-content-between flex-wrap gap-3'>
                        <div className='preview-achievement-bx  flex-grow-1'>
                            <h2>Skills</h2>
                            <div className='skillETCBox d-flex align-items-center justify-content-start flex-wrap gap-1'>
                                {
                                    userForm?.experience?.skills?.map((data, idx) => (
                                        <span key={idx}>{data}</span>
                                    ))
                                }
                            </div>
                        </div>
                        <div className='preview-achievement-bx  flex-grow-1'>
                            <h2>Employment Type</h2>
                            <div className='skillETCBox d-flex align-items-center justify-content-start gap-1'>
                                <span>{userForm?.experience?.employmentType}</span>
                            </div>
                        </div>
                        <div className='preview-achievement-bx  flex-grow-1'>
                            <h2>Preferred Shift</h2>
                            <div className='skillETCBox d-flex align-items-center justify-content-start gap-1'>
                                {
                                    userForm?.preference.find(data => data?.category === "Preferred shift")?.selectedOptions?.map((data, idx) => (
                                        <span key={idx}>{data}</span>
                                    ))
                                }
                            </div>
                        </div>
                        <div className='preview-achievement-bx  flex-grow-1'>
                            <h2>Preferred Workplace</h2>
                            <div className='skillETCBox d-flex align-items-center justify-content-start gap-1'>
                                {
                                    userForm?.preference.find(data => data?.category === "Preferred workplace")?.selectedOptions?.map((data, idx) => (
                                        <span key={idx}>{data}</span>
                                    ))
                                }
                            </div>
                        </div>
                        <div className='preview-achievement-bx  flex-grow-1'>
                            <h2>Job City</h2>
                            <div className='skillETCBox d-flex align-items-center flex-wrap justify-content-start gap-1'>
                                {
                                    userForm?.jobCity?.map((data, idex) => (<span key={idex}>{data}</span>))
                                }
                            </div>
                        </div>
                        <div className='preview-achievement-bx  flex-grow-1'>
                            <h2>Department</h2>
                            <div className='skillETCBox d-flex flex-wrap align-items-center justify-content-start gap-1'>
                                {
                                    userForm?.department?.map((data, idex) => (<span key={idex}>{data}</span>))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
            <div className="navigation-buttons preview-cntr-btn d-flex gap-3 mt-3 mb-3">
                <StepperBackButton className={`${step === 0 && 'd-none'}`} onClick={handleBackStep} disabled={step === 0}>Back</StepperBackButton>
                <AuthFillButton className='' onClick={handlleSubmit} disabled={step === 7}>Final Submit</AuthFillButton>
            </div>
        </>
    )
}
