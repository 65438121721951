import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import blankProfile from '../../../assets/blank-profile.png'
import { RiEditFill } from "react-icons/ri";
import { Progress } from 'antd';
import './Profile.css'
import { EventButton } from '../../component/ReUseComps/Button';
import { BiSolidFilePdf } from "react-icons/bi";
import { BsFiletypeDocx } from "react-icons/bs";
import { TbFileTypeDocx } from "react-icons/tb";
import axios from 'axios';
import { URLS } from '../../../Utils/App_urls';
import Loader from '../../component/Loader/Loader'
import ProfileModalBox from '../../component/EditProfilePopUps/ProfileModalBox';
import UserContext from '../../component/ContextApi/UserContext';
import { Dropdown } from 'react-bootstrap';
import { errorPopup, successPopup } from '../../../Utils/ToasterMessage';
import { IoMdCall } from "react-icons/io";


export default function ProfileBasicInfo() {
    const fileInputRef = useRef(null)
    const {
        editProfilePopUPToggle,
        setEditProfilePopUpToggle,
        setNeedToReload,
        needToReload,
        setProfileModalData,
        setProfileImageUrl
    } = useContext(UserContext)
    const [profileUpatePercentage, setProfilUpdatePercentage] = useState(90)
    const fileRef = useRef();
    const [fileUrl, setFileUrl] = useState(null)
    const [fileName, setFileName] = useState('');
    const [fileExtension, setFileExtension] = useState('')
    const [showDropdown, setShowDropdown] = useState(false);
    const [loader, setLoader] = useState(false)
    const [newImageFile, setNewImageFile] = useState({})
    const [userEmail, setUserEmail] = useState('')
    const [error, setError] = useState({
        errorStatus: false,
        errorMessage: '',
    })
    const [userDetails, setUserDetails] = useState({})

    const handleToggle = () => {
        setShowDropdown(!showDropdown);
    };

    const resumeFileExtension = (resumeName) => {
        setFileName(resumeName)
        let fileExtension = resumeName?.split('.');
        let ext = fileExtension?.[fileExtension?.length - 1]
        ext && setFileExtension(ext)
    }

    const handleFileUpload = (e) => {
        let files = e.target.files;
        files?.length > 0 && setFileUrl(URL?.createObjectURL(files[0]))
        files?.length > 0 && setFileName(files[0])
        resumeFileExtension(files[0]?.name)
        uploadResume(files[0])
    }

    const handleClickFileInput = () => {
        fileRef.current.click();
    }

    const getUserProfile = () => {
        setLoader(true)
        axios.get(`${URLS.get_userProfile}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('userToken')}`
            }
        })
            .then((res) => {
                setUserDetails(res?.data?.data)
                extractResumeName(res?.data?.data?.resume)
                setProfileImageUrl(res?.data?.data?.profile)
                setLoader(false)
                setNeedToReload(false)
            })
            .catch((err) => {
                setLoader(false);
                setNeedToReload(false)
            })
    }

    const uploadResume = async (data) => {
        const formData = new FormData();
        formData.append('file', data);
        axios.post(`${URLS.uploadUserResume}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('userToken')}`
            }
        })
            .then(async (res) => {
                await uploadProfileData(res?.data?.data?.imageUrl)
                successPopup(res?.data?.message);
            })
            .catch((err) => {
                errorPopup(err?.response?.data?.message);
            })
    }

    const uploadProfileData = async (resumeUrl) => {

        delete userDetails['_id']
        delete userDetails['userId']
        delete userDetails['auth0UserId']
        delete userDetails['createdAt']
        delete userDetails['updatedAt']
        delete userDetails['__v']

        try {
            const res = await axios.patch(`${URLS.createUserProfile}`, { ...userDetails, "resume": resumeUrl }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('userToken')}`
                }
            });
            setUserDetails(res?.data?.data)
            extractResumeName(res?.data?.data?.resume)
            getUserProfile()
        } catch (err) {
            console.error('err', err);
        }
    };

    const convertDate = (dateString = '') => {
        const [year, month, day] = dateString?.split('-');
        return `${day}/${month}/${year}`;
    }

    const extractResumeName = (resumeUrl) => {
        setFileUrl(resumeUrl)
        const decodeUrl = decodeURIComponent(resumeUrl)
        const urlIntoArr = decodeUrl?.split('/')
        const fileArr = urlIntoArr[urlIntoArr?.length - 1]?.split('-')
        const fileName = fileArr[0]
        resumeFileExtension(fileName)
        return fileArr[0]
    }

    const handleOpenEditModal = () => {
        setEditProfilePopUpToggle({ ...editProfilePopUPToggle, basicInfoModal: true, mainModalToggle: true })
        setProfileModalData(userDetails)
    }

    const handleOpenEditModalSkill = () => {
        setEditProfilePopUpToggle({ ...editProfilePopUPToggle, skillsModal: true, mainModalToggle: true })
        setProfileModalData(userDetails)
    }

    const handleOpenUpdateAvatarModal = () => {
        setEditProfilePopUpToggle({ ...editProfilePopUPToggle, updateAvatarModal: true, mainModalToggle: true })
        setProfileModalData(userDetails)
    }

    const handleImageInputChange = async (e) => {
        const file = await e?.target?.files[0];
        setNewImageFile(file)
        if (file) {
            await uploadImageinS3(file)
        }
    };

    const uploadImageinS3 = async (file) => {
        const formData = new FormData();
        formData.append('image', file);

        try {
            const res = await axios.post(`${URLS?.uploadImage}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('userToken')}`
                }
            });
            updateProfileImage(res?.data?.data?.imageUrl);
            return res;
        } catch (err) {
            console.error('ImageS3Err', err);
        }
    };

    const updateProfileImage = async (imageUploadRes) => {
        delete userDetails['_id']
        delete userDetails['userId']
        delete userDetails['auth0UserId']
        delete userDetails['createdAt']
        delete userDetails['updatedAt']
        delete userDetails['__v']

        try {
            const res = await axios.patch(`${URLS.createUserProfile}`, { ...userDetails, "profile": imageUploadRes }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('userToken')}`
                }
            });
            successPopup("Image updated successfully.");
            getUserProfile()
        } catch (err) {
            console.error('err', err);
        }
    };

    const getUserEmail = () => {
        axios.get(`${URLS?.userDetails}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('userToken')}`
            }
        })
            .then((res) => {
                setUserEmail(res?.data?.data?.email)
            })
            .catch((err) => { })
    }

    useEffect(() => {
        getUserProfile();
        getUserEmail()
    }, [needToReload])

    // useEffect(() => {
    //     getUserProfile();
    // }, [])
    return (
        <>
            {editProfilePopUPToggle?.basicInfoModal && <ProfileModalBox />}
            {/* {loader && <Loader />} */}
            <div className='profileBasicInfo_main d-flex flex-column gap-3'>
                <div className="indentity_card">
                    <div className="id_imgTitle">
                        <div>
                            {userDetails?.profile ? <img src={userDetails?.profile} alt="boysProfile" /> :
                                <img src={blankProfile} alt="boysProfile" />}
                            <Dropdown show={showDropdown} onToggle={handleToggle}>
                                <div className='edit-pen-box' id="dropdown-basic" onClick={handleToggle}>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => fileInputRef.current.click()} >Upload from Device</Dropdown.Item>
                                        <input className='d-none' ref={fileInputRef} type="file" onChange={handleImageInputChange} />
                                        <Dropdown.Item onClick={handleOpenUpdateAvatarModal}>Open Camera</Dropdown.Item>
                                    </Dropdown.Menu>
                                    <RiEditFill color='#3283FC' fontSize={14} />
                                </div>
                            </Dropdown>
                        </div>
                        <div className='id_title'>
                            <Progress
                                strokeColor={`${profileUpatePercentage === 100 ? 'green' : '#ED936D'}`}
                                trailColor='#ED936D1F' className='progress_bar' type="circle"
                                percent={profileUpatePercentage} />
                            <h1 className='m-0'>Your Profile is  {profileUpatePercentage}% Updated</h1>
                            <p className='m-0'>Update your profile to 100% to get better Job Opportunity</p>
                        </div>
                    </div>
                    <div className='cross-divider-line mb-3' />
                    <div className="idcard">
                        <div className="title_descrip d-flex flex-column gap-1">
                            <p><span>Name</span> <span onClick={handleOpenEditModal}><RiEditFill fontSize={18} />Edit</span> </p>
                            <h3>{userDetails?.name || 'N/A'}</h3>
                        </div>
                        <div className="title_descrip d-flex flex-column gap-1">
                            <p>Mobile Number</p>
                            {/* <h3>{convertDate(userDetails?.DOB?.split('T')[0] || '')}</h3> */}
                            <h3>{userDetails?.mobileNo}</h3>
                        </div>
                        <div className="title_descrip d-flex flex-column gap-1">
                            <p>Email</p>
                            {/* <h3>{convertDate(userDetails?.DOB?.split('T')[0] || '')}</h3> */}
                            <h3 className='text-lowercase'>{userEmail || 'N/A'}</h3>
                        </div>
                        <div className="title_descrip d-flex flex-column gap-1">
                            <p>Date of Birth</p>
                            <h3>{convertDate(userDetails?.DOB?.split('T')[0] || '')}</h3>
                        </div>
                        <div className="title_descrip d-flex flex-column gap-1">
                            <p>Gender </p>
                            <h3>{userDetails?.gender || 'N/A'}</h3>
                        </div>
                        <div className="title_descrip d-flex flex-column gap-1">
                            <p>Address </p>
                            <h3>{userDetails?.address || 'N/A'}</h3>
                        </div>
                    </div>
                </div>
                <div className="upload_doc_container indentity_card">
                    <h1>Resume</h1>
                    <div className="doc-inner-conatiner d-flex align-items-center justify-content-between flex-wrap gap-2">
                        {
                            !fileUrl ?
                                <div className='doc-notice-box'>
                                    <p>Upload/Drop Your Resume Here...</p>
                                    <p>Supported Formats: doc, docx, rtf, pdf, upto 2 MB</p>
                                </div> :
                                <a target='_blank' href={fileUrl}>
                                    <span className='me-2'>
                                        {
                                            fileExtension === 'pdf' && <BiSolidFilePdf fontSize={24} color='red' /> ||
                                            (fileExtension === 'docx' || fileExtension === 'doc') && <TbFileTypeDocx fontSize={24} color='#2D98DB' />
                                        }
                                    </span>
                                    {fileName}
                                </a>
                        }
                        <input className='d-none' ref={fileRef} type="file" onChange={handleFileUpload} accept='.pdf' />
                        <EventButton onClick={handleClickFileInput}>{fileUrl ? 'Update Resume' : 'Upload Resume'}</EventButton>
                    </div>
                    {error?.errorMessage && <p className='text-danger'>{error?.errorMessage}</p>}
                </div>
                {/* Skill */}
                <div className="general-info-card">
                    <div><h1>Skills</h1><span onClick={() => handleOpenEditModalSkill()}><RiEditFill fontSize={18} />Edit</span></div>
                    <div className='d-flex align-items-center justify-content-start gap-2 flex-wrap mt-3'>
                        {
                            userDetails?.skills?.map((data, index) => (
                                <div className='skill-sticker' key={index}>{data}</div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
