import React, { useContext, useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import './FilterJob.css'
import UserContext from '../../../component/ContextApi/UserContext';
import { URLS } from '../../../../Utils/App_urls';
import axios from 'axios';
import { RiSearch2Fill } from 'react-icons/ri';

export default function FilterJob() {

    const { setAllAfterFilter } = useContext(UserContext)
    const [jobsForFilter, setJobsForFilter] = useState([])
    const [jobTitle, setJobTitle] = useState('');
    const [masterFilter, setMasterFilter] = useState({
        layer1: [],
        layer2: [],
        layer3: [],
        layer4: [],
        layer5: [],
        layer6: [],
        layer7: []
    })

    const [filterJob, setFilterJob] = useState({
        sortByTime: 'all',
        workExperience: '',
        preferenceWorkPlace: '',
        preferredShift: '',
        EmploymentType: '',
    })

    const handleSortByTimeChange = (e, type) => {
        const { id, value } = e.target;
        let newFilterJob = { ...filterJob };

        switch (type) {
            case 'sortByTime':
                newFilterJob.sortByTime = id;
                // filterJobByRecent(id);
                getAllJobs('sortBy', id)
                break;
            case 'workExp':
                newFilterJob.workExperience = value;
                // filterJobByWorkExp(value);
                getAllJobs('experience', value)
                break;
            case 'workPlace':
                newFilterJob.preferenceWorkPlace = id;
                // filterJobByWorkPlaces(id);
                getAllJobs('workPlace', id)
                break;
            case 'preferredShift':
                newFilterJob.preferredShift = id;
                // filterJobsByShift(id);
                getAllJobs('shift', id)
                break;
            case 'employmentType':
                newFilterJob.EmploymentType = id;
                // filterJobsByEmploymentType(id);
                getAllJobs('deparmentType',id)
                break;
            default:
                break;
        }

        setFilterJob(newFilterJob);
        // getAllJobs()
    };

    const handleResetFilter = () => {
        const resetFilterJob = {
            sortByTime: 'all',
            workExperience: '',
            preferenceWorkPlace: '',
            preferredShift: '',
            EmploymentType: '',
        };
        setFilterJob(resetFilterJob);
        // setMasterFilter({
        //     layer1: jobsForFilter,
        //     layer2: jobsForFilter,
        //     layer3: jobsForFilter,
        //     layer4: jobsForFilter,
        //     layer5: jobsForFilter,
        //     layer6: jobsForFilter,
        //     layer7: jobsForFilter
        // });
        // setAllAfterFilter(jobsForFilter)
        getAllJobs()
    };

    const [filterOption, setFilterOption] = useState({
        workPlace:'',
        experience:'',
        sortBy:'',
        deparmentType:'',
        title:'',
        shift:''
    })
    
    console.log('filterJob',filterJob)

    const getAllJobs = (type='reset', option='') => {

        const paramsOpt = {
            page: 1,
            limit: 10,
            ...filterOption
        }

        if(type === 'workPlace'){
            paramsOpt['workPlace'] = option
        }else{
            paramsOpt['workPlace'] = filterJob?.preferenceWorkPlace
        }

        if(type === 'experience' && option){
            paramsOpt['experience'] = option
        }else{
            paramsOpt['experience'] = filterJob?.workExperience
        }

        if(type === 'sortBy' && option){
            paramsOpt['sortBy'] = option
        }else{
            paramsOpt['sortBy'] = filterJob?.sortByTime
        }

        if(type === 'deparmentType' && option){
            paramsOpt['deparmentType'] = option
        }else{
            paramsOpt['deparmentType'] = filterJob?.EmploymentType
        }

        if(type === 'title' && option){
            paramsOpt['title'] = option
        }else{
            paramsOpt['title'] = jobTitle;
        }
        if(type === 'shift' && option){
            paramsOpt['shift'] = option
        }else{
            paramsOpt['shift'] = filterJob?.preferredShift
        }

        Object.keys(paramsOpt).forEach(key => {
            if (!paramsOpt[key]) {
                delete paramsOpt[key];
            }
        });

        console.log('paramsOpt',paramsOpt)

        axios.get(`${URLS.getAllJob}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` },
            params: type==='reset' ?{
                page: 1,
                limit: 10
            } :paramsOpt
        })
            .then((res) => {
                console.log('getAllJObInFilter', res)
                const jobs = res?.data?.result || [];
                setJobsForFilter(jobs);
                setAllAfterFilter(jobs);
                // setMasterFilter(prev => ({
                //     ...prev,
                //     layer1: res?.data?.result || [],
                //     layer2: res?.data?.result || [],
                //     layer3: res?.data?.result || [],
                //     layer4: res?.data?.result || [],
                //     layer5: res?.data?.result || [],
                //     layer6: res?.data?.result || [],
                //     layer7: res?.data?.result || []
                // }))
            })
            .catch((err) => { console.log('err', err) })
    }

    const handleFilterJobsByTitle = (e) => {
        const { value } = e.target;
        getAllJobs('title', value)
        // let filterJobs = jobsForFilter?.filter((data) => {
        //     const title = data?.title?.toLowerCase();
        //     return title?.includes(value.toLowerCase())
        // })
        // setMasterFilter(prev => ({ ...prev, layer1: filterJobs, layer2: filterJobs, layer3: filterJobs, layer4: filterJobs, layer5: filterJobs, layer6: filterJobs }));
        setJobTitle(value)
        // setAllAfterFilter(filterJobs)
    }

    const filterJobByRecent = (type) => {
        const currentDate = new Date();
        // getAllJobs('sortBy', type)
        // const filterJobs = (daysLimit) => {
        //     return masterFilter?.layer1?.filter(({ createdAt }) => {
        //         const jobDate = new Date(createdAt.split('T')[0]);
        //         const diffInDays = (currentDate - jobDate) / (1000 * 60 * 60 * 24);
        //         return diffInDays <= daysLimit;
        //     });
        // };

        // switch (type) {
        //     case 'recent':
        //         setAllAfterFilter([...masterFilter?.layer1].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
        //         const newArr = [...masterFilter?.layer1].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        //         setMasterFilter(prev => ({ ...prev, layer2: newArr, layer3: newArr, layer4: newArr, layer5: newArr, layer6: newArr }));
        //         break;
        //     case 'all':
        //         setAllAfterFilter(masterFilter.layer1);
        //         setMasterFilter(prev => ({ ...prev, layer2: masterFilter?.layer1, layer3: masterFilter?.layer1, layer4: masterFilter?.layer1, layer5: masterFilter?.layer1, layer6: masterFilter?.layer1 }));
        //         break;
        //     case 'last week':
        //         setAllAfterFilter(filterJobs(7));
        //         let lastWeek = filterJobs(7)
        //         setMasterFilter(prev => ({ ...prev, layer2: lastWeek, layer3: lastWeek, layer4: lastWeek, layer5: lastWeek, layer6: lastWeek }));
        //         break;
        //     case 'last month':
        //         setAllAfterFilter(filterJobs(30));
        //         const lastMonth = filterJobs(30)
        //         setMasterFilter(prev => ({ ...prev, layer2: lastMonth, layer3: lastMonth, layer4: lastMonth, layer5: lastMonth, layer6: lastMonth }));
        //         break;
        //     default:
        //         setAllAfterFilter(masterFilter?.layer1);
        //         setMasterFilter(prev => ({ ...prev, layer2: masterFilter?.layer1 }));
        //         break;
        // }
    };

    const filterJobByWorkExp = (type) => {
        // const filterByExperience = masterFilter?.layer2?.filter((data) => {
        //     const experience = data?.experience?.toLowerCase();
        //     return experience?.includes(type?.toLowerCase())
        // })
        // setAllAfterFilter(filterByExperience)
        // setMasterFilter(prev => ({ ...prev, layer3: filterByExperience, layer4: filterByExperience, layer5: filterByExperience, layer6: filterByExperience }));
    }

    const filterJobByWorkPlaces = (type) => {
        // const filterByWorkPlace = masterFilter?.layer3?.filter((data) => {
        //     const workPlace = data?.workPlace?.toLowerCase();
        //     return workPlace?.includes(type?.toLowerCase())
        // })
        // setAllAfterFilter(filterByWorkPlace)
        // setMasterFilter(prev => ({ ...prev, layer4: filterByWorkPlace, layer5:filterByWorkPlace, layer6:filterByWorkPlace }));
    }

    const filterJobsByShift = (type) => {
        // const filterByShift = masterFilter?.layer4?.filter((data) => {
        //     const shift = data?.shift?.toLowerCase();
        //     return shift?.includes(type?.toLowerCase())
        // })
        // setAllAfterFilter(filterByShift)
        // setMasterFilter(prev => ({ ...prev, layer5: filterByShift, layer6:filterByShift }));
    }


    const filterJobsByEmploymentType = (type) => {
        // const filterByWorkPlace = masterFilter?.layer5?.filter((data) => {
        //     const employmentType = data?.deparmentType?.toLowerCase();
        //     return employmentType?.includes(type?.toLowerCase())
        // })
        // setAllAfterFilter(filterByWorkPlace)
        // setMasterFilter(prev => ({ ...prev, layer6: filterByWorkPlace }));
    }

    const handleSubmitForm = (e) => {
        e.preventDefault();
    }

    useEffect(() => {
        getAllJobs()
    }, [])

    return (
        <>
            <div className="filter-job-main d-flex flex-column gap-3">
                <div className="filter-header d-flex align-items-center justify-content-between">
                    <h1>Filter</h1>
                    <p onClick={handleResetFilter}>Reset</p>
                </div>
                <div className="cross-line-dividor" />
                <div className="job-search-header d-flex gap-1 mb-3 p-0">
                    <Form onSubmit={handleSubmitForm} className='d-flex gap-1 justify-content-sm-between justify-content-center flex-wrap'>
                        <div className="serch-job-input d-flex align-items-center justify-content-between">
                            <RiSearch2Fill fontSize={20} />
                            <Form.Control
                                placeholder="Job Title"
                                aria-label="Job Title"
                                aria-describedby="basic-addon1"
                                value={jobTitle}
                                onChange={(e) => handleFilterJobsByTitle(e)}
                            />
                        </div>
                    </Form>
                </div>
                {/* Sort By Time */}
                <div className="sort-container">
                    <p>Sort By</p>
                    <span>
                        <input
                            type="radio"
                            name='sort-by-time'
                            id='all'
                            value={'all'}
                            checked={filterJob?.sortByTime === 'all'}
                            onChange={(e) => handleSortByTimeChange(e, 'sortByTime')}
                        />
                        <label htmlFor="all">All</label>
                    </span>
                    <span>
                        <input
                            type="radio"
                            name='sort-by-time'
                            id='recent'
                            value={'recent'}
                            checked={filterJob?.sortByTime === 'recent'}
                            onChange={(e) => handleSortByTimeChange(e, 'sortByTime')}
                        />
                        <label htmlFor="recent">Recent</label>
                    </span>
                    <span>
                        <input
                            type="radio"
                            name='sort-by-time'
                            id='lastWeek'
                            value={'last week'}
                            checked={filterJob?.sortByTime === 'lastWeek'}
                            onChange={(e) => handleSortByTimeChange(e, 'sortByTime')}
                        />
                        <label htmlFor="lastWeek">Last Week</label>
                    </span>
                    <span>
                        <input
                            type="radio"
                            name='sort-by-time'
                            id='lastMonth'
                            value={'last month'}
                            checked={filterJob?.sortByTime === 'lastMonth'}
                            onChange={(e) => handleSortByTimeChange(e, 'sortByTime')}
                        />
                        <label htmlFor="lastMonth">Last 30 Days</label>
                    </span>
                </div>
                <div className="cross-line-dividor" />

                {/* Work Experience */}
                <div className='work-experience-menu d-flex flex-column gap-2'>
                    <h1>Work Experience</h1>
                    <Form.Select
                        value={filterJob.workExperience || ''}
                        onChange={(e) => handleSortByTimeChange(e, 'workExp')}
                        aria-label="Default select example">
                        <option value={""} disabled selected>Select Experience</option>
                        <option value="Fresher">Fresher</option>
                        <option value="1-2 years">1-2 years</option>
                        <option value="2-3 years">2-3 years</option>
                        <option value="3-5 years">3-5 years</option>
                        <option value="5+ years">5 + years</option>
                        {/* <option selected={filterJob.workExperience === 'Fresher'} value="Fresher">Fresher</option>
                        <option selected={filterJob.workExperience === '1 Year'} value="1 Year">1 Year</option>
                        <option selected={filterJob.workExperience === '2 Year'} value="2 Year">2 Year</option>
                        <option selected={filterJob.workExperience === '3 Year'} value="3 Year">3 Year</option> */}
                    </Form.Select>
                </div>
                <div className="cross-line-dividor" />

                {/* Preferred  Workplace */}
                <div className="sort-container">
                    <p>Preferred  Workplace</p>
                    <span>
                        <input
                            type="radio"
                            name='preferred-workplace'
                            id='On-site'
                            value={'On-site'}
                            checked={filterJob?.preferenceWorkPlace === 'On-site'}
                            onChange={(e) => handleSortByTimeChange(e, 'workPlace')}
                        />
                        <label htmlFor="On-site">On-site</label>
                    </span>
                    <span>
                        <input
                            type="radio"
                            name='preferred-workplace'
                            id='Off-site'
                            value={'Off-site'}
                            checked={filterJob?.preferenceWorkPlace === 'Off-site'}
                            onChange={(e) => handleSortByTimeChange(e, 'workPlace')}
                        />
                        <label htmlFor="Work From Home">Off-site</label>
                    </span>
                    <span>
                        <input
                            type="radio"
                            name='preferred-workplace'
                            id='Hybrid'
                            value={'Hybrid'}
                            checked={filterJob.preferenceWorkPlace === 'Hybrid'}
                            onChange={(e) => handleSortByTimeChange(e, 'workPlace')}
                        />
                        <label htmlFor="Hybrid">Hybrid</label>
                    </span>
                </div>
                <div className="cross-line-dividor" />

                {/* Preferred  Shift */}
                <div className="sort-container">
                    <p>Preferred Shift</p>
                    <span>
                        <input
                            type="radio"
                            name='Preferred-Shift'
                            id='Day Shift'
                            value={'Day Shift'}
                            checked={filterJob.preferredShift === 'Day Shift'}
                            onChange={(e) => handleSortByTimeChange(e, 'preferredShift')}
                        />
                        <label htmlFor="Day Shift">Day Shift</label>
                    </span>
                    <span>
                        <input
                            type="radio"
                            name='Preferred-Shift'
                            id='Night Shift'
                            value={'Night Shift'}
                            checked={filterJob.preferredShift === 'Night Shift'}
                            onChange={(e) => handleSortByTimeChange(e, 'preferredShift')}
                        />
                        <label htmlFor="Night Shift">Night Shift</label>
                    </span>
                </div>
                <div className="cross-line-dividor" />

                {/* Employenment Type */}
                <div className="sort-container">
                    <p>Employenment Type</p>
                    <span>
                        <input
                            type="radio"
                            name='Employenment-Type'
                            id='Full Time'
                            value={'Full Time'}
                            checked={filterJob.EmploymentType === 'Full Time'}
                            onChange={(e) => handleSortByTimeChange(e, 'employmentType')}
                        />
                        <label htmlFor="Full Time">Full Time</label>
                    </span>
                    <span>
                        <input
                            type="radio"
                            name='Employenment-Type'
                            id='Part Time'
                            value={'Part Time'}
                            checked={filterJob.EmploymentType === 'Part Time'}
                            onChange={(e) => handleSortByTimeChange(e, 'employmentType')}
                        />
                        <label htmlFor="Part Time">Part Time</label>
                    </span>
                    <span>
                        <input
                            type="radio"
                            name='Employenment-Type'
                            id='contract'
                            value={'contract'}
                            checked={filterJob.EmploymentType === 'contract'}
                            onChange={(e) => handleSortByTimeChange(e, 'employmentType')}
                        />
                        <label htmlFor="contract">Contract</label>
                    </span>
                </div>
            </div>
        </>
    )
}
