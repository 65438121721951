import React, { act, useState } from 'react'
import ArticlePost from './ArticlePost';
import Information from './Information';
import galleryIcon from '../../../assets/galleryIcon.png'
import './Posts.css'

export default function Posts() {
    const [active, setActive] = useState(false);
    const [activePostType, setActivePostType] = useState(true)

    const handleToggleButton = (booleanValue) => {
        setActive(booleanValue)
    }

    return (
        <>
            <div className='corrosLIneBorder' />
            <div className="post_main pb-4">
                <div className="postSelect-button">
                    <button onClick={() => handleToggleButton(false)} className={`${!active && 'active-button'}`}>Information</button>
                    <button onClick={() => handleToggleButton(true)} className={`${active && 'active-button'}`}>Post</button>
                </div>
                {
                    active ?
                        <>
                            <textarea placeholder='Write Something....' rows={6} name="blog" id="blog"></textarea>

                            <div className="blogType-button">
                                <button onClick={() => setActivePostType(true)} className={`${activePostType && 'active-button'}`}>Post</button>
                                <button
                                    onClick={() => setActivePostType(false)}
                                    className={`${!activePostType && 'active-button'} d-flex gap-3 justify-content-center align-items-center`}
                                >
                                    <img className='sdniowe' src={galleryIcon} alt="galleryIcon" />
                                    Image
                                </button>
                            </div>
                            <div className='horizontalLine' />
                            <ArticlePost />
                        </>
                        : <Information />
                }
            </div>
        </>
    )
}
