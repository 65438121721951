import React, { useState } from 'react'
import { Alert } from 'react-bootstrap';
import openEye from '../../../assets/openEye.png';
import closeEye from '../../../assets/closeEye.png';
import { COMPANY_URLS } from '../../../Utils/Company_App_Urls';
import axios from 'axios';
import './Setting.css'

export default function ResetPassword() {

    const [eyeToggle, setEyeToggle] = useState({ oldPass: false, newPass: false, confirmPass: false })
    const [password, setPassword] = useState({ currentPass: '', newPass: '', confirmPass: '' })
    const [error, setError] = useState({ currentPass: '', newPass: '', confirmPass: '' })

    const handleEyeToggle = (type) => {
        type === 'oldPass' && setEyeToggle((prev) => ({ ...prev, [type]: !prev[type] }));
        type === 'newPass' && setEyeToggle((prev) => ({ ...prev, [type]: !prev[type] }));
        type === 'confirmPass' && setEyeToggle((prev) => ({ ...prev, [type]: !prev[type] }));
    }

    const handlePasswordChange = (type, e) => {
        setPassword((prev) => ({ ...prev, [type]: e.target.value }))
        setError({ ...error, newPass: '', currentPass: '', confirmPass: '' })
    }

    const handleReset = () => {
        setPassword({ currentPass: '', newPass: '', confirmPass: '' })
    }

    function validateNewAndConfirmPass(password) {
        const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/;

        if (!passwordRegex.test(password?.newPass)) {
            setError(prev => ({ ...prev, newPass: 'New password must have at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.', confirmPass: '' }))
            return false;
        }

        if (password?.newPass !== password?.confirmPass) {
            setError(prev => ({ ...prev, confirmPass: 'Confirm password must match the new password.', newPass: '' }))
            return false;
        }
        return true;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    
        if (validateNewAndConfirmPass(password)) {
            console.log('Success');
    
            axios.post(
                `${COMPANY_URLS.change_password}`,
                {
                    password: password?.currentPass, 
                    confirmPassword: password?.newPass
                },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                }
            )
            .then((res) => {
                console.log('res', res);
            })
            .catch((err) => console.log(err));
        }
    };
    

    return (
        <>
            <div>
                <div className='reset-pass-title'>
                    <h1>Change Password</h1>
                    <p>Change your password for security purposes</p>
                </div>
                {/* <Alert className='mt-3 mb-0' variant='danger'>This is a danger alert—check it out!</Alert> */}
                <form onSubmit={handleSubmit} className="form-wrapper">
                    <label htmlFor="oldPassword" className='mt-3 mb-1'>Current Password</label>
                    <div>
                        <input
                            type={eyeToggle?.oldPass ? 'password' : 'text'}
                            placeholder='Enter your Current Password'
                            value={password?.currentPass}
                            onChange={(e) => handlePasswordChange('currentPass', e)}
                            id='oldPassword' />
                        <img onClick={() => handleEyeToggle('oldPass')} src={eyeToggle?.oldPass ? closeEye : openEye} alt="toggleEye" />
                    </div>
                    <label htmlFor="oldPassword" className='mt-3 mb-1'>New Password</label>
                    <div>
                        <input
                            type={eyeToggle?.newPass ? 'password' : 'text'}
                            placeholder='Enter your New Password'
                            value={password?.newPass}
                            onChange={(e) => handlePasswordChange('newPass', e)}
                            id='oldPassword' />
                        <img onClick={() => handleEyeToggle('newPass')} src={eyeToggle?.newPass ? closeEye : openEye} alt="toggleEye" />
                    </div>
                    {error?.newPass && <p className='text-danger m-0'>{error?.newPass}</p>}
                    <label htmlFor="oldPassword" className='mt-3 mb-1'>Confirm Password</label>
                    <div>
                        <input
                            type={eyeToggle?.confirmPass ? 'password' : 'text'}
                            placeholder='Enter your New Password Again'
                            value={password?.confirmPass}
                            onChange={(e) => handlePasswordChange('confirmPass', e)}
                            id='oldPassword' />
                        <img onClick={() => handleEyeToggle('confirmPass')} src={eyeToggle?.confirmPass ? closeEye : openEye} alt="toggleEye" />
                    </div>
                    {error?.confirmPass && <p className='text-danger m-0'>{error?.confirmPass}</p>}
                </form>
                <div className='btn_group_reset d-flex gap-5 justify-content-between align-items-center mt-5 flex-wrap border-0 p-0'>
                    <button onClick={handleReset}>Cancle</button>
                    <button onClick={handleSubmit}>Save Changes</button>
                </div>
            </div>
        </>
    )
}
