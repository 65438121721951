import React, { useContext, useEffect, useRef, useState } from 'react'
import AuthOutlineBtn, { AuthFillButton, StepperBackButton } from '../../component/ReUseComps/Button'
import UserContext from '../../component/ContextApi/UserContext'
import experienceImage from '../../../assets/experienceImgae.svg'
import { FaAngleLeft } from 'react-icons/fa6'
import { IoIosClose } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { URLS } from '../../../Utils/App_urls';
import { FiPlus } from "react-icons/fi";
import Form from 'react-bootstrap/Form';
import Select from "react-select";
import axios from 'axios';
import './Experience.css'
import './BasicInfo.css'


export default function Experience() {
  const { step, setStep, userForm, setUserForm } = useContext(UserContext)
  const [enterSkill, setEnterSkill] = useState('')
  const [addSkills, setAddSkills] = useState([])
  const [duration, setDuration] = useState({ startDate: '', endDate: '' });
  const [endTimeValidation, setEndTimeValidation] = useState('')
  const [jobTitle, setJobTitle] = useState('');
  const [companyName, setCompanyName] = useState('')
  const [employmentType, setEmploymentType] = useState('')
  const [startDateLimit, setStartLimit] = useState('')
  const [experience, setExperience] = useState('fresher')
  const [selectedOption, setSelectedOption] = useState("none");
  const [skillList, setSkillList] = useState([]);
  const [allJObsList, setAllJobList] = useState([])
  const [fresherJobTitle, setFresherJobTitle] = useState('');
  const [experienceStore, setExperienceStore] = useState([{ companyName: '', startDate: '', endDate: '' }])
  const [fresherError, setFresherError] = useState({
    errorMessage: ''
  })
  const [error, setError] = useState({
    jobTitleError: '',
    employmentTypeError: '',
    companyNameError: '',
    durationError: '',
    skillError: '',
    experienceStoreError: '',
    errorStatus: true
  })

  const removeSkill = (index) => {
    setAddSkills(prevSkills => prevSkills.filter((_, i) => i !== index))
  }

  const validateFields = () => {
    let isValid = true;
    let newErrors = { ...error };

    // Validate job title
    if (!jobTitle) {
      newErrors.jobTitleError = 'Job title is required.';
      isValid = false;
    }

    // Validate company names in experience
    experienceStore.forEach((exp, idx) => {
      if (!exp.companyName) {
        newErrors.companyNameError = `Company name for experience ${idx + 1} is required.`;
        isValid = false;
      }
    });

    // Validate employment type
    if (!employmentType) {
      newErrors.employmentTypeError = 'Employment type is required.';
      isValid = false;
    }

    // Validate duration
    experienceStore.forEach((exp, idx) => {
      if (!exp.startDate || !exp.endDate) {
        newErrors.durationError = `Duration for experience ${idx + 1} is required.`;
        isValid = false;
      }
    });

    // Validate skills
    if (addSkills.length === 0) {
      newErrors.skillError = 'At least one skill is required.';
      isValid = false;
    }

    setError(newErrors);
    return isValid;
  };

  const validateFieldsForFresher = () => {
    let isValid = true;
    let newErrors = { ...error };

    // Validate job title
    if (!jobTitle) {
      newErrors.jobTitleError = 'Job title is required.';
      isValid = false;
    }

    // Validate employment type
    if (!employmentType) {
      newErrors.employmentTypeError = 'Employment type is required.';
      isValid = false;
    }

    // Validate skills
    if (addSkills.length === 0) {
      newErrors.skillError = 'At least one skill is required.';
      isValid = false;
    }

    setError(newErrors);
    return isValid;
  };

  const handleSubmitExp = () => {
    if (experience === 'experience') {
      if (validateFields()) {
        // Proceed with form submission
        setUserForm(userForm => ({
          ...userForm,
          experience: {
            skills: addSkills,
            jobTitle: jobTitle || fresherJobTitle,
            experienceStore: experienceStore,
            employmentType: employmentType,
            experience: experience,
          },
        }));
        setStep(step => step + 1);
      }
    }
    if (experience === 'fresher') {
      if (validateFieldsForFresher()) {
        // Proceed with form submission
        setUserForm(userForm => ({
          ...userForm,
          experience: {
            skills: addSkills,
            jobTitle: jobTitle || fresherJobTitle,
            employmentType: employmentType,
            experience: experience,
          },
        }));
        setStep(step => step + 1);
      }
    }
  };

  const handleCompanyName = (e, idx) => {
    setExperienceStore(experienceStore =>
      experienceStore.map((data, index) => {
        if (index === idx) {
          return { ...data, companyName: e.target.value };
        }
        return data;
      })
    );
    setError({ errorStatus: false, companyNameError: '' })
  }

  const handleEmploymentType = (e) => {
    const { id } = e.target;
    setEmploymentType(id)
    setError({ errorStatus: false, employmentTypeError: '', })
  }

  const handleDuration = (e, type, idx) => {
    if (type == 'startDate') {
      setExperienceStore(experienceStore =>
        experienceStore.map((data, index) => {
          if (index === idx) {
            return { ...data, startDate: e.target.value };
          }
          return data;
        })
      );
      setEndTimeValidation(e.target.value)
      setError({ errorStatus: false, durationError: '' })
    } else if (type === 'endDate') {

      setExperienceStore(experienceStore =>
        experienceStore.map((data, index) => {
          if (index === idx) {
            return { ...data, endDate: e.target.value };
          }
          return data;
        })
      );
      setError({ errorStatus: false, durationError: '' })
    }
  }

  const handleAddSkill = (e) => {
    const value = e.value;
    if (!addSkills?.includes(value)) {
      setAddSkills(prevSkills => [...prevSkills, value])
      setEnterSkill(value)
    }
    setError({ errorStatus: false, skillError: '' })
  }

  const handleInitialValue = () => {
    if (userForm?.experience?.experience) {
      setExperience(userForm?.experience?.experience)
    }
    if (userForm?.experience?.experience === 'fresher') {
      setFresherJobTitle(userForm?.experience?.jobTitle || '')
      setSelectedOption(allJObsList.find(option => option.value === userForm?.experience?.jobTitle));
    }
    if (userForm?.experience?.jobTitle) {
      setJobTitle(userForm?.experience?.jobTitle)
      userForm?.experience?.jobTitle && setError({ errorStatus: false, jobTitleError: '' })

      setCompanyName(userForm?.experience?.companyName)
      userForm?.experience?.companyName && setError({ errorStatus: false, companyNameError: '' })

      setEmploymentType(userForm?.experience?.employmentType)
      userForm?.experience?.employmentType && setError({ errorStatus: false, employmentTypeError: '' })

      if (userForm?.experience?.experienceStore) {
        setExperienceStore(userForm?.experience?.experienceStore)
      }
      setDuration({
        startDate: userForm?.experience?.duration?.startDate,
        endDate: userForm?.experience?.duration?.endDate
      })

      setAddSkills(userForm?.experience?.skills || [])
      userForm?.experience?.skills?.length === 0 && setError({ errorStatus: false, skillError: '' })
      userForm?.experience?.duration?.startDate && setError({ errorStatus: false, durationError: '' })
    }
  }

  const handleFresherJobTitle = (e) => {
    setFresherJobTitle(e.target.value)
    setFresherError({ errorMessage: '' })
  }

  const dateFormateRange = () => {
    let data = `${new Date().getFullYear()}`
    const month = (new Date().getMonth() + 1).toString().padStart(2, '0')
    data += `-${month}`
    const day = new Date().getDate().toString().padStart(2, '0')
    data += `-${day}`
    setStartLimit(data)
  }

  const handleBackStep = () => {
    setUserForm(userForm => ({
      ...userForm, experience: {
        skills: addSkills,
        jobTitle: jobTitle,
        experienceStore: experienceStore,
        employmentType: employmentType,
        experience: experience
      }
    }));
    setStep(prev => prev - 1);
  }

  const handleIsExprience = (e) => {
    const { id, value } = e.target;
    setExperience(id)
  }

  const getAllSkillList = () => {
    axios.get(`${URLS.getSkillList}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('userToken')}`
      }
    })
      .then((res) => {
        const skillListRes = res?.data?.getList || [];
        const formattedJobs = skillListRes.map(skill => ({ value: skill?.name, label: skill?.name }));
        setSkillList(formattedJobs)
      })
      .catch((err) => { console.log('skillErr', err) })
  }

  const getJobList = () => {
    axios.get(`${URLS.getJoblist}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('userToken')}`
      }
    })
      .then((res) => {
        const allJobs = res?.data?.getList || [];
        const formattedJobs = allJobs.map(jobs => ({ value: jobs?.name, label: jobs?.name }));
        setAllJobList(formattedJobs)
        setSelectedOption(formattedJobs.find(option => option.value === userForm?.experience?.jobTitle)); // Set selected option for fresher
      })
      .catch((err) => console.log(err))
  }

  const handleTypeSelect = (e) => {
    setSelectedOption(e.value);
    setFresherJobTitle(e.value)
    setJobTitle(e.value)
    setError({ errorStatus: true, jobTitleError: '' })
    setFresherError({ errorMessage: '' })
  };

  const handleSizeOfExpStore = (type, idx = -1) => {
    let lastIndex = (experienceStore?.length) - 1;
    if (type === 'increase') {
      if (!experienceStore?.[lastIndex]?.companyName || experienceStore?.companyName === '') {
        return setError({ errorStatus: true, companyNameError: 'Company name is required.' })
      }
      if (!experienceStore?.[lastIndex]?.startDate || !experienceStore?.[lastIndex]?.endDate) {
        return setError({ errorStatus: true, durationError: 'Duration is required.' })
      }
      return setExperienceStore(prev => [...prev, { companyName: '', startDate: '', endDate: '' }]);
    }
    type === 'decrease' && setExperienceStore(prev => prev?.filter((_, id) => id !== idx));
  }

  useEffect(() => {
    handleInitialValue();
    dateFormateRange();
    getAllSkillList();
    getJobList();
  }, [userForm?.experience])

  console.log("experienceStore", experienceStore)
  // console.log("userFormExp", userForm)
  // console.log('error', error)

  return (
    <>
      <div className='w-100 d-flex'>
        <div className='w-50 pb-4 flex-grow-1 responsve_exp responsive_cont'>
          <div className="experience_main pb-3 pt-md-5 p-0 basicInfo">
            <h1 className='experience_title d-flex align-items-center m-0'>
              <span onClick={() => setStep(prev => prev - 1)} className='d-flex'><FaAngleLeft /></span>Experience</h1>
            <div className='employment-type-input-group'>
              <p>Do you have any experience or fresher?</p>
              <div className='mt-1 emply-mnt-type'>
                <span>
                  <input
                    onChange={handleIsExprience}
                    name='fresher'
                    type='radio'
                    checked={experience === 'fresher'}
                    id={`fresher`}
                  />
                  <label htmlFor="fresher">Fresher</label>
                </span>
                <span>
                  <input
                    onChange={handleIsExprience}
                    name='experience'
                    type='radio'
                    checked={experience === 'experience'}
                    id={`experience`}
                  />
                  <label htmlFor="experience">Experience</label>
                </span>
              </div>
              {/* {error.employmentTypeError && <p className='m-0 text-danger'>{error.employmentTypeError}</p>} */}
            </div>
            {experience === 'fresher' &&
              <Form>
                <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                  <Form.Label>Job Title</Form.Label>
                  <div className='inbuild-selectOption'>
                    <Select
                      options={allJObsList}
                      defaultValue={allJObsList.find(option => option.value === fresherJobTitle)}
                      onChange={handleTypeSelect}
                      value={allJObsList.find(option => option.value === selectedOption?.value)}
                      label="Single select"
                    />
                  </div>
                  {fresherError.errorMessage && <p className='m-0 text-danger'>{fresherError.errorMessage}</p>}
                </Form.Group>
              </Form>}
            {experience === 'experience' &&
              <>
                <div>
                  <Form>
                    <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                      <Form.Label>Job Title</Form.Label>
                      <div className='inbuild-selectOption'>
                        <Select
                          options={allJObsList}
                          defaultValue={allJObsList.find(option => option.value === fresherJobTitle)}
                          onChange={handleTypeSelect}
                          value={allJObsList.find(option => option.value === selectedOption?.value)}
                          label="Single select"
                        />
                      </div>
                      {error.jobTitleError && <p className='m-0 text-danger'>{error.jobTitleError}</p>}
                    </Form.Group>
                  </Form>
                </div>
                <div className='experience_company_grp_card'>
                  {
                    experienceStore?.map((exp, index) => (
                      <>
                        <h1 className={`experienceOptions-title ${index > 0 && 'mt-3'}`}>
                          <span>Experience {experienceStore?.length > 0 && (index + 1)}</span>
                          <RxCross2 className={`${index > 0 ? 'd-block' : 'd-none'}`} onClick={() => handleSizeOfExpStore('decrease', index)} fontSize={23} />
                        </h1>
                        <div>
                          <Form.Group className="mb-2" controlId="Enter your previous company name">
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control
                              type="text"
                              className='exp-input'
                              onChange={(e) => handleCompanyName(e, index)}
                              value={exp.companyName}
                              placeholder='Enter your previous company name' />
                            {
                              !error.companyNameError?.includes(`experience`) &&
                              error.companyNameError && <p className='m-0 text-danger'>{error.companyNameError}</p>
                            }
                          </Form.Group>
                        </div>
                        <div className='input-date-picker'>
                          <p className='mb-2'>Duration</p>
                          <div className='exp-duration-datePicker'>
                            <input
                              type="date"
                              max={startDateLimit}
                              placeholder='Start Date'
                              onBlur={(e) => e.type = 'text'}
                              onChange={(e) => handleDuration(e, 'startDate', index)}
                              value={exp.startDate}
                            />
                            <input
                              type="date"
                              onChange={(e) => handleDuration(e, 'endDate', index)}
                              max={startDateLimit}
                              min={endTimeValidation}
                              value={exp.endDate}
                            />
                          </div>
                          {
                            !error.durationError?.includes(`experience`) &&
                            error.durationError && <p className='m-0 text-danger'>{error.durationError}</p>
                          }
                        </div>
                        <div className='mt-3'>
                          {error.companyNameError && error.companyNameError?.includes(`experience ${index + 1}`) && <p className='m-0 text-danger'>{error.companyNameError}</p>}
                          {error.durationError && error.durationError?.includes(`experience ${index + 1}`) && <p className='m-0 text-danger'>{error.durationError}</p>}
                        </div>

                      </>
                    ))
                  }

                  <div className={`add-more-expe mt-3 ${experienceStore?.length >= 3 && 'd-none'} `} onClick={() => handleSizeOfExpStore('increase')}>
                    <span>Add more Experience</span>
                    <FiPlus fontSize={20} />
                  </div>
                </div>
              </>}
            <div className='employment-type-input-group'>
              <p>Employment Type*</p>
              <div className='mt-1 emply-mnt-type gap-2 gap-md-4'>
                <span>
                  <input
                    onChange={handleEmploymentType}
                    name='jobType'
                    type='radio'
                    checked={employmentType === 'Full-Time'}
                    id={`Full-Time`}
                  />
                  <label htmlFor="Full-Time">Full-Time</label>
                </span>
                <span>
                  <input
                    onChange={handleEmploymentType}
                    name='jobType'
                    type='radio'
                    checked={employmentType === 'Part-Time'}
                    id={`Part-Time`}
                  />
                  <label htmlFor="Part-Time">Part-Time</label>
                </span>
                <span>
                  <input
                    onChange={handleEmploymentType}
                    name='jobType'
                    type='radio'
                    checked={employmentType === 'Contract'}
                    id={`Contract`}
                  />
                  <label htmlFor="Contract">Contract</label>
                </span>
              </div>
              {error.employmentTypeError && <p className='m-0 text-danger'>{error.employmentTypeError}</p>}
            </div>
            <div>
              <p className='m-0 lksjdoai'>Add Skills*</p>
              <span className='expSkillPara'>Showcase your skills to get noticed</span>
              <div className='inbuild-selectOption'>
                <Select
                  options={skillList}
                  defaultValue={skillList.find(option => option.value === fresherJobTitle)}
                  onChange={handleAddSkill}
                  value={skillList.find(option => option.value === enterSkill)}
                  label="Single select"
                />
              </div>
              <p className='m-0 text-danger'>{error.skillError}</p>
              <button className='d-none' type='submit'></button>
            </div>
            <div className="autoSuggetionBox mt-0">
              <p className='d-block w-100 m-0'>Selected skills </p>
              <div className='d-flex gap-2 my-2 flex-wrap'>
                {
                  addSkills?.map((skill, index) => (
                    skill && <p key={index} className='skillCollections m-0'>{skill}
                      <span onClick={() => removeSkill(index)}><IoIosClose fontSize={26} /></span></p>
                  ))
                }
              </div>
            </div>
          </div>
          <div className="navigation-buttons d-flex gap-3 mt-3">
            <StepperBackButton className={`${step === 0 && 'd-none'}`} onClick={handleBackStep} disabled={step === 0}>Back</StepperBackButton>
            <AuthFillButton onClick={handleSubmitExp}>Next</AuthFillButton>
          </div>
        </div>
        <div className='steper-image-container w-50 flex-grow-md-1 h-md-100'>
          <img src={experienceImage} alt="experienceImage" />
        </div>
      </div>
    </>
  )
}
