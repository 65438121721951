
import React, { useContext, useEffect, useState } from 'react'
import { RiSearch2Fill } from "react-icons/ri";
import Form from 'react-bootstrap/Form';
import { GoArrowRight } from "react-icons/go";
import dummyComapyLogo from '../../../assets/dummyProfileImage.png'
import { FaLocationDot } from "react-icons/fa6";
import { EventButton, PaginationBtn } from '../../component/ReUseComps/Button';
import cashMultiple from '../../../assets/cash-multiple.png'
import bagSolid from '../../../assets/bag-solid.png'

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { URLS } from '../../../Utils/App_urls';
import UserContext from '../../component/ContextApi/UserContext';
import { Oval } from 'react-loader-spinner';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import '../ApplyJob/JobCards/JobCards.css'
import './AppliedJob.css'

export default function JobCards() {
  const { setSingleAppliedJobDetails, singleAppliedJobDetails } = useContext(UserContext)
  const navigate = useNavigate()
  const [appliedJobDetails, setAppliedJobDetails] = useState([]);
  const [filterJobsArr, setFilterJobsArr] = useState([])
  const [jobTitle, setJobTitle] = useState('')
  const [jobLocation, setJobLocation] = useState('');
  const [getJobLoader, setGetJobLoader] = useState(false);
  const [paginationBtnArr, setpaginationBtnArr] = useState([1, 2, 3, 4, 5]);
  const [clipArrLength, setClipArrLength] = useState({ min: 0, max: 10 })

  const handleSubmitForm = (e) => {
    e.preventDefault();
  }

  const handlePaginationSize = (pgNo) => {
    let max = pgNo * 10;
    let min = max - 10 >= 0 ? max - 10 : 0;
    setClipArrLength({ min: min, max: max })
  }

  const handleIncPagination = () => {
    setpaginationBtnArr(paginationBtnArr.map((item, _) => item + 1))
    setClipArrLength({ min: (paginationBtnArr[paginationBtnArr.length - 1] * 10) - 10, max: paginationBtnArr[paginationBtnArr.length - 1] * 10 })
  }
  const handleDecPagination = () => {
    if (paginationBtnArr[0] > 1) {
      setpaginationBtnArr(paginationBtnArr.map((item, _) => item - 1))
    }
    paginationBtnArr[0] >= 0 && setClipArrLength({ min: (paginationBtnArr[0] * 10) - 10, max: paginationBtnArr[0] * 10 })
  }

  const getAllJobs = () => {
    setGetJobLoader(true);
    axios.get(`${URLS.getAllAppliedJobs}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` }
    })
      .then((res) => {
        console.log('appliedJobsRes->', res)
        setAppliedJobDetails(res?.data?.result)
        setFilterJobsArr(res?.data?.result)
        setSingleAppliedJobDetails(res?.data?.result)
        setGetJobLoader(false);
      })
      .catch((err) => {
        setGetJobLoader(false);
      })
  }

  const redirectTojobDetails = (job, id) => {
    navigate(`/user/applied-job/job-details/${id}`, {
      state: { DATA: id }
    })
    setSingleAppliedJobDetails(job)
  }

  const handleFilterAppliedJob = (e) => {
    let enterTitle = e.target.value;
    setJobTitle(enterTitle);
    enterTitle?.length === 0 && filterOnSearchBtn();

    // -----------
    let filterJobs = filterJobsArr?.filter((data) => {
      const title = data?.title?.toLowerCase();
      return title?.includes(enterTitle?.toLowerCase())

    })

    filterJobs = filterJobs?.filter((data) => {
      const location = data?.workPlace?.toLowerCase();
      return location?.includes(jobLocation?.toLowerCase())

    })
    setAppliedJobDetails(filterJobs)
  }

  const filterOnSearchBtn = () => {
    let filterJobs = filterJobsArr?.filter((data) => {
      const title = data?.title?.toLowerCase();
      return title?.includes(jobTitle?.toLowerCase())

    })

    filterJobs = filterJobs?.filter((data) => {
      const location = data?.workPlace?.toLowerCase();
      return location?.includes(jobLocation?.toLowerCase())

    })
    setAppliedJobDetails(filterJobs)
  }

  const convertHtmlToText = (htmlString) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;
    return tempElement.textContent || tempElement.innerText || "";
  };

  const postedDate = (data) => {
    const firstDate = new Date(data);
    const currentDate = new Date();
    const timeDifference = currentDate - firstDate;
    const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
    return daysDifference > 0 ? `${daysDifference} Day${daysDifference > 1 ? 's' : ''} ago` : 'Posted Today';
}

  useEffect(() => {
    getAllJobs();
  }, [])

  console.log('appliedJobDetails', appliedJobDetails)

  return (
    <>
      <div className="job-card-container applied_job_cards_contaner pb-0">
        <div className="appliedJobStatus d-flex align-items-center justify-content-between flex-wrap mb-3">
          <p>Applied <span className='totaldataspan'>{appliedJobDetails?.length}</span> Jobs</p>
          <div className="serch-job-input d-flex align-items-center justify-content-between">
            <RiSearch2Fill fontSize={20} />
            <Form.Control
              placeholder="Job Title"
              aria-label="Job Title"
              aria-describedby="basic-addon1"
              onChange={(e) => handleFilterAppliedJob(e)}
              value={jobTitle}
            />
          </div>
        </div>
        {/* Job cards */}
        <div>
          {(appliedJobDetails?.length > 0) ? appliedJobDetails?.map((job, index) => (
            (index >= clipArrLength.min && index < clipArrLength.max) &&
            <div className='job-search-body cards_shadow mb-3' onClick={() => redirectTojobDetails(job, job?._id)} key={index}>
              <div className={`job-cards d-flex flex-wrap active-box-bdr ${job?._id === singleAppliedJobDetails?._id && 'active-box-desig'}`}>
                <div className="job-right-container d-flex flex-column px-0 gap-3 flex-grow-1">
                  <div className="job-card-header d-flex align-items-start justify-content-between flex-wrap gap-2">
                    <div className="company-title d-flex align-items-start gap-3">
                      <div><img src={job?.companyProfileDetail?.[0]?.companylogo || dummyComapyLogo} alt={job?.company} /></div>
                      <div className='compay-name'>
                        <h1 className='m-0'>{job?.title}</h1>
                        <p className='m-0'>{job?.companyProfileDetail?.[0]?.companyName || 'N/A'}</p>
                      </div>
                    </div>
                    <span className="post-date created_time">{postedDate(job?.createdAt?.split('T')[0])}</span>
                  </div>
                  <div className='job-conadtion-box'>
                    <div><img src={bagSolid} alt="bag solid" /> <span>{job?.experience}</span></div>
                    {
                      job?.salaryType === "Fixed Salary" &&
                      <div><img src={cashMultiple} alt="cash multiple" /> <span>{job?.FixedSalary}</span></div>
                    }
                    {
                      job?.salaryType === "Salary Range" &&
                      <div><img src={cashMultiple} alt="cash multiple" /> <span>{job?.minSalary} - {job?.maxSalary}</span></div>
                    }
                    {
                      job?.salaryType === "Not Disclose" &&
                      <div><img src={cashMultiple} alt="cash multiple" /> <span>{job?.salaryType}</span></div>
                    }
                    <div><FaLocationDot /> <span>{job?.workPlace}</span></div>
                  </div>

                  <div className="job-preferences d-flex gap-1 gap-sm-3 flex-wrap">
                    <span key={index}>{job?.deparmentType}</span>
                    <span key={index}>{job?.workPlace}</span>
                    <span key={index}>{job?.shift}</span>
                  </div>

                  <div className='job-desp-inCard skill_type job_description'>
                    {job?.jobSummary?.slice(0, 550)}
                    {job?.jobSummary?.length > 600 && '...'}
                    {/* <div dangerouslySetInnerHTML={{ __html: convertHtmlToText(job?.jobDescription).slice(0, 300) + '...' }} /> */}
                  </div>
                  <div className="company-req-skills skill_type d-flex flex-wrap gap-2 gap-sm-3">
                    {job?.skills?.slice(0, 3)?.map((skill, index) => (
                      <span key={index}>{skill}</span>
                    ))}
                    {job?.skills?.length > 3 && <span>{job?.skills?.length - 3}+ more</span>}
                  </div>
                </div>
                <div className="cards-btn-box flex-row flex-sm-column flex-wrap">
                  <button className={`job-search-btn`} onClick={() => redirectTojobDetails(job)}>View <GoArrowRight fontSize={20} /></button>
                  <div className={`job_staus_btn_saved job_staus_rejected_btn_saved ${index % 2 === 0 && 'd-none'}`}>Rejected</div>
                  <div className={`job_staus_btn_saved job_staus_shortlisted_btn_saved ${index % 2 != 0 && 'd-none'}`}>Shortlisted</div>
                  <div className="job_staus_btn_saved job_staus_hired_btn_saved d-none">Hired</div>
                  {/* <button className='job-search-outline-btn'>Not Interested</button> */}
                </div>
              </div>
            </div>
          ))
            :
            <>
              <p>No Job Applied</p>
            </>
          }
          {appliedJobDetails?.length > 0 && <div className="footer-pagination d-flex align-items-center flex-wrap justify-content-center gap-2 pb-3">
            <p className='vailable-job-para page-table-typo'>Page No. {clipArrLength?.max / 10} / {Math.ceil(appliedJobDetails?.length / 10)}</p>
            <div className='d-flex align-items-center gap-2'>
              <PaginationBtn style={{ width: 'fit-content' }} className='pagination-btn edgePageBtn'
                onClick={handleDecPagination}
                disabled={paginationBtnArr[0] === 1}>
                <FaAngleLeft fontSize={23} />
                Previous
              </PaginationBtn>
              {
                paginationBtnArr.map((data, idx) => (
                  <PaginationBtn
                    className={`pagination-btn ${clipArrLength?.max / 10 === data && 'activePageBtn'}`}
                    onClick={() => handlePaginationSize(data)}
                    disabled={Math.ceil(data > Math.ceil(appliedJobDetails?.length / 10))}
                  >
                    {data}
                  </PaginationBtn>
                ))
              }
              <PaginationBtn className={`pagination-btn ${appliedJobDetails?.length <= 60 && 'd-none'}`} disabled>...</PaginationBtn>
              <PaginationBtn
                style={{ width: 'fit-content' }}
                className='pagination-btn edgePageBtn'
                onClick={handleIncPagination}
                disabled={appliedJobDetails?.length < (paginationBtnArr[paginationBtnArr?.length - 1] * 10)}
              >
                Next
                <FaAngleRight fontSize={23} />
              </PaginationBtn>
            </div>

          </div>}
          {getJobLoader &&
            <div className='oval_container'>
              <Oval width="50" height="50px" color='#3284FF' />
            </div>
          }
        </div>
      </div>
    </>
  )
}
