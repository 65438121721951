// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.job-detais-container{
    height: 86.5vh;
}

.job-left-container{
    width: 430px;
    /* border: 1px solid red; */
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
}

.similarJob-right-container{
    width: 60%;
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
    /* border: 1px solid green; */
}
.main-unknown{
    margin-top: 6rem;
}

.back-btn-onJob{
    color: #3284FF;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}
.back-btn-onJob > svg:hover{
    cursor: pointer;
}
.back-btn-onJob > span:hover{
    cursor: pointer;
}

.job-left-container::-webkit-scrollbar, .similarJob-right-container::-webkit-scrollbar {
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/User/Pages/SingleJobDetails/Index.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,2BAA2B;IAC3B,YAAY;IACZ,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,6BAA6B;AACjC;AACA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;IACnB,QAAQ;AACZ;AACA;IACI,eAAe;AACnB;AACA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".job-detais-container{\n    height: 86.5vh;\n}\n\n.job-left-container{\n    width: 430px;\n    /* border: 1px solid red; */\n    flex-grow: 1;\n    height: 100%;\n    overflow-y: auto;\n}\n\n.similarJob-right-container{\n    width: 60%;\n    flex-grow: 1;\n    height: 100%;\n    overflow-y: auto;\n    /* border: 1px solid green; */\n}\n.main-unknown{\n    margin-top: 6rem;\n}\n\n.back-btn-onJob{\n    color: #3284FF;\n    font-size: 16px;\n    font-weight: 700;\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    gap: 8px;\n}\n.back-btn-onJob > svg:hover{\n    cursor: pointer;\n}\n.back-btn-onJob > span:hover{\n    cursor: pointer;\n}\n\n.job-left-container::-webkit-scrollbar, .similarJob-right-container::-webkit-scrollbar {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
