import React, { useContext } from 'react'
import './ModalBox.css'
import UserContext from '../ContextApi/UserContext'
import EditBasicInfo from './EditBasicInfo/EditBasicInfo'
import EditEducation from './EditEducation/EditEducation'
import EditExperience from './EditExperience/EditExperience'
import EditSkills from './EditSkills/EditSkills'
import EditLanguage from './EditLanguage/EditLanguage'
import EditPreferences from './EditPreferences/EditPreferences'
import EditDepartment from './EditDepartment/EditDepartment'
import UpdateAvatar from './UploadAvatar/UpdateAvatar'

export default function ProfileModalBox() {
  const { editProfilePopUPToggle, setEditProfilePopUpToggle } = useContext(UserContext)

  return (
    <>
      <div className={`${editProfilePopUPToggle?.mainModalToggle ? 'd-block' : 'd-none'}`}>
        <div className={`profile-modal-box`}>
          <div className='modals_container'>
            {editProfilePopUPToggle?.basicInfoModal && <EditBasicInfo />}
            {editProfilePopUPToggle?.educationModal && <EditEducation />}
            {editProfilePopUPToggle?.experienceModal && <EditExperience />}
            {/* {editProfilePopUPToggle?.experienceModal && <EditExperience />} */}
            {editProfilePopUPToggle?.skillsModal && <EditSkills />}
            {editProfilePopUPToggle?.languageModal && <EditLanguage />}
            {editProfilePopUPToggle?.preferenceModal && <EditPreferences />}
            {editProfilePopUPToggle?.departmentModal && <EditDepartment />}
            {editProfilePopUPToggle?.updateAvatarModal && <UpdateAvatar />}
          </div>
        </div>
      </div>
    </>
  )
}
