import React, { useContext, useState } from 'react'
import { VscClose } from "react-icons/vsc";
import dummyProfileImg from '../../../../assets/dummyComapyLogo.png'
import { FiPhone } from "react-icons/fi";
import { CiLocationOn, CiMail } from "react-icons/ci";
import { RxDownload } from "react-icons/rx";
import { FcCollapse } from "react-icons/fc";
import teenyicons_calendar from '../../../../assets/teenyicons_calendar-tick-outline.png'
import './CandaidateProfileView.css'
import { Accordion } from 'react-bootstrap';
import CompanyContext from '../../ContextAPI/CompanyContext';
import { IoMdCall } from 'react-icons/io';
import { MdOutlineMail } from "react-icons/md";
import { saveAs } from 'file-saver';
import axios from 'axios';
import { COMPANY_URLS } from '../../../../Utils/Company_App_Urls';
import { errorPopup, successPopup } from '../../../../Utils/ToasterMessage';
import Loader from '../../Loader/Loader';


export default function CandaidateProfileView() {
    const { appliedCandidateModalOpen, setAppliedCandidateModalOpen, appliedCandidateDetails, setNeedTorender, setAppliedCandidateDetails } = useContext(CompanyContext)
    const [open, setOpen] = useState(false);
    const [statusUpdateLoader, setStatusUpdateLoader] = useState(false)

    const handleToggleModalBox = () => {
        setAppliedCandidateModalOpen(prev => !prev)
        setOpen(false)
    }

    const updateCandidatesAppliedStatus = (userId, status) => {
        setStatusUpdateLoader(true)
        axios.patch(`${COMPANY_URLS.candidatesAppliedStatus}`,
            {
                userId: userId,
                status: status
            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
            .then((res) => {
                console.log('appliedStatus', res);
                successPopup(res?.data?.message);
                setStatusUpdateLoader(false);
                setNeedTorender(prev => !prev)
            })
            .catch((err) => {
                console.log('Error updating status:', err);
                errorPopup(err?.response?.data?.message || 'Something went wrong');
                setStatusUpdateLoader(false);
            });
    };


    const downloadUrl = 'https://devkaam.s3.ap-south-1.amazonaws.com/fxml%20file.pdf-1726127113558-43523926';
    // const downloadUrl ='https://d3jy31tj1gt845.cloudfront.net/reactdigitalgarden/json/menu.json';

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = 'file.json';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Correctly remove the link element after the download
    };

    const downloadResume = () => {
        axios.get(`${COMPANY_URLS.download_resume}?file=https://devkaam.s3.ap-south-1.amazonaws.com/fxml%20file.pdf-1726127113558-43523926`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then((res) => {
                console.log('downloadResume', res)
            })
        .catch((err) => console.log(err))
    }

    console.log('appliedCandidateDetailsModal::', appliedCandidateDetails)

    return (
        <>
            {statusUpdateLoader && <Loader />}
            <div className={`${appliedCandidateModalOpen ? 'd-block' : 'd-none'}`}>
                <div className={`candidates_profile_main`}>
                    <div className="modal_box_profile d-flex flex-column gap-3">
                        <div className="midal-title">
                            <span>Candidate Profile</span>
                            <VscClose onClick={handleToggleModalBox} color='#FF3232' fontSize={25} />
                        </div>
                        <div className='d-flex align-items-center justify-content-between flex-wrap'>
                            <div className="profile_headers d-flex align-items-center justify-content-center gap-ms-0 gap-2 mb-3 justify-content-sm-between flex-wrap">
                                <img src={appliedCandidateDetails?.profile || dummyProfileImg} alt="dummyProfileImg" />
                                <div className='candi-name text-capitalize d-flex flex-column'>
                                    <h1>{appliedCandidateDetails?.name}</h1>
                                    <p>{appliedCandidateDetails?.JobTitle}</p>
                                </div>
                                <div className='join-time'>
                                    <img src={teenyicons_calendar} alt="teenyicons_calendar" />
                                    <span>Availability :</span> {appliedCandidateDetails?.startMonth?.[0] || 'Immediate Joiner'}
                                </div>
                            </div>
                            <div className="candi-contact d-flex align-items-center flex-column gap-2">
                                <div className='d-flex gap-3 align-items-start w-100 justify-content-between'>
                                    <a className='call_text_typo' href={`tel:${appliedCandidateDetails?.mobileNo}`}><IoMdCall fontSize={20} /> {appliedCandidateDetails?.mobileNo || 'N/A'}</a>
                                    <a className='call_text_typo' href={`mailto:rahul54545@gmail.com`}><MdOutlineMail fontSize={20} />fakemail@yopmail.com</a>
                                    {/* <span> <FiPhone fontSize={16} /> 76677676767</span> */}
                                    {/* <span><CiMail fontSize={20} /> rahul54545@gmail.com</span> */}
                                </div>
                                <div className='d-flex gap-1 align-items-start'>
                                    <CiLocationOn fontSize={40} />
                                    <span> {appliedCandidateDetails?.address}</span>
                                </div>
                            </div>
                        </div>
                        <div className="skill_candi">
                            <h1>Skills</h1>
                            <div className='d-flex align-items-center flex-wrap gap-2'>
                                {
                                    appliedCandidateDetails?.skills?.map((skill, index) => (
                                        <span key={index}>{skill}</span>
                                    ))
                                }
                            </div>
                        </div>
                        <div className={`${open ? 'openResume' : 'resumeClose'} resume_view`} id='resumeSize'>
                            <div className='candi_header d-flex flex-wrap gap-1 align-items-center justify-content-between w-100'>
                                <h1>Resume</h1>
                                <div className='d-flex align-items-center gap-4'>
                                    {/* <button onClick={downloadResume}>click</button> */}
                                    {/* <div onClick={handleDownload} className='download-btn d-flex align-items-center gap-2'> */}
                                    <div className='download-btn d-flex align-items-center gap-2'>
                                      {appliedCandidateDetails?.resume ?  <a className='SAKLDNAKWK' href={appliedCandidateDetails?.resume} target="_blank">
                                            Download Resume <RxDownload fontSize={18} />
                                        </a>
                                        :'Resume not available'
                                        }
                                    </div>
                                    <div onClick={() => setOpen(prev => !prev)} className='preview-btn d-flex align-items-center gap-2'><span>Preview</span><FcCollapse className={`${!open && 'rotation'}`} fontSize={18} /></div>
                                </div>
                            </div>
                            <div className={`${open ? 'd-block' : 'd-none'}`}>
                                {!appliedCandidateDetails?.resume ? <p>Resume Not Available</p> 
                               : <iframe
                                    src={appliedCandidateDetails?.resume}
                                    style={{ width: '100%', height: '600px' }}
                                />
                                }
                            </div>
                        </div>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className='candi_extra_items'>
                                            <p>Education</p>
                                            <h1>{appliedCandidateDetails?.education}</h1>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='candi_extra_items'>
                                            <p>Experience</p>
                                            <h1>{appliedCandidateDetails?.experience}</h1>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='candi_extra_items'>
                                            <p>Language</p>
                                            {
                                                appliedCandidateDetails?.languages?.map((data, index) => (
                                                    <h1 key={index}>{data?.languageFromMenu} | {data?.proficiency}</h1>
                                                ))
                                            }
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='d-flex align-items-center justify-content-between gap-3 flex-wrap'>
                            <div className="job_staus_btn job_staus_rejected_btn" onClick={() => updateCandidatesAppliedStatus(appliedCandidateDetails?.userId, 'Rejected')}>Rejected</div>
                            <div className="job_staus_btn job_staus_shortlisted_btn" onClick={() => updateCandidatesAppliedStatus(appliedCandidateDetails?.userId, 'Shortlisted')}>Shortlisted</div>
                            <div className="job_staus_btn job_staus_hired_btn" onClick={() => updateCandidatesAppliedStatus(appliedCandidateDetails?.userId, 'Hired')}>Hired</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
