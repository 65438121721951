import React from 'react'
import dpImages from '../../../assets/dp-images.jpeg'
import './Chat.css'

export default function UserContactList() {
  const dummyUser = [
    {
      name: 'Gyan',
      Image: 'Im'
    },
    {
      name: 'Manish',
      image: 'Im'
    },
    {
      name: 'Abhishek',
      image: 'Im'
    },
    {
      name: 'Abhishek',
      image: 'Im'
    },
    {
      name: 'Abhishek',
      image: 'Im'
    },
    {
      name: 'Abhishek',
      image: 'Im'
    },
    {
      name: 'Abhishek',
      image: 'Im'
    },
    {
      name: 'Abhishek',
      image: 'Im'
    },
    {
      name: 'Abhishek',
      image: 'Im'
    },
    {
      name: 'Abhishek',
      image: 'Im'
    },
    {
      name: 'Abhishek',
      image: 'Im'
    },
  ]
  return (
    <>
      <div className="user_contact_list">
        <div className="chat-header">
          <h1>Message</h1>
        </div>
        <div className='dividorLine' />
        <div className="chat-userList">
          {
            dummyUser.map((data, index) => (
              <div className='list_card'>
                <div key={index}>
                  <img src={dpImages} alt="dp-images" />
                </div>
                <div>{data.name}</div>
              </div>
            ))
          }
        </div>
      </div>
    </>
  )
}
