import React, { useContext, useEffect, useState } from 'react'
import { AuthFillButton, StepperBackButton } from '../../component/ReUseComps/Button';
import UserContext from '../../component/ContextApi/UserContext';
import departmentImage from '../../../assets/departmentImage.png'
import { IoCloseOutline } from "react-icons/io5";
import { URLS } from '../../../Utils/App_urls';
import Form from 'react-bootstrap/Form';
import { GoPlus } from "react-icons/go";
import Select from "react-select";
import './Department.css'
import axios from 'axios';


export default function Departments() {
    const { step, setStep, userForm, setUserForm } = useContext(UserContext);
    const [departmentList, setDepartmentList] = useState([])
    const [selectedOption, setSelectedOption] = useState("none");
    const [department, setDepartment] = useState([])
    const [manualDepartment, setManualDepartment] = useState('')
    const [error, setError] = useState({
        errorStatus: true,
        errorMessage: ''
    })
    const departArr = ['Accounting', 'Arts & Design', 'Advertising', 'Data Science & Analytics', 'Finance', 'Human Resources', 'Consulting']

    const handleSubmitDepart = (e) => {
        e.preventDefault();
        if (manualDepartment) {
            if (!department.includes(manualDepartment)) {
                setDepartment(prevArr => [...prevArr, manualDepartment])
                setError({ errorStatus: false, errorMessage: '' })
            }
            setManualDepartment('')
        }
    }

    const handleMenuOptions = (e) => {
        // const optionValue = e.target.value
        const optionValue = e
        setDepartment(department => [...department, optionValue])
        setError({ errorStatus: false, errorMessage: '' })
    }

    const handleRemoveDepart = (index) => {
        setDepartment(department => department.filter((_, id) => id !== index))
    }

    const handleNextStep = () => {
        if (!department.length <= 0 && !error.errorStatus) {
            setUserForm({ ...userForm, department: department })
            setStep(step => step + 1)
        } else {
            setError({ errorStatus: true, errorMessage: 'Department is required' })
        }
    }

    const handleBackStep = () => {
        setUserForm({ ...userForm, department: department })
        setStep(step => step - 1)
    }
    
    const handleTypeSelect = e => {
        if (!department.includes(e.value)) {
            handleMenuOptions(e.value)
        }
        setSelectedOption(e.value);
    };


    const getDepartmentList=()=>{
        axios.get(`${URLS.getJoblist}`,{
            headers:{
                Authorization:`Bearer ${localStorage.getItem('userToken')}`
            }
        })
        .then((res)=>{
            const citiesData = res?.data?.getList || [];
            const formattedCities = citiesData.map((dep,_) => ({ value: dep.name, label: dep.name }));
            setDepartmentList(formattedCities);
        })
        .catch((err)=>{console.log('skill', err)})
    }

    useEffect(() => {
        setDepartment(userForm?.department)
        if (userForm?.department.length >= 0) {
            setError({ errorStatus: false, errorMessage: '' })
        }
        getDepartmentList();
    }, [userForm.department])

    // console.log(error)
    // console.log('department', department)
    // console.log(userForm)

    return (
        <>
            <div className='d-flex'>
                <div className="departments_mains responsive_cont w-50 flex-grow-1">
                    <h1>Department</h1>
                    <div className='department-select-option'>
                        <p className='m-0 mb-2'>Suggested Departments</p>
                        {/* <Form.Select onChange={handleMenuOptions} aria-label="Default select example">
                            <option value="" disabled selected>Select Department</option>
                            {
                                departArr.map((data, index) => (
                                    <option key={index} value={data}>{data}</option>
                                ))
                            }
                        </Form.Select> */}
                        <div className='inbuild-selectOption'>
                            <Select
                                options={departmentList}
                                placeholder='Search Department'
                                onChange={handleTypeSelect}
                                value={departmentList.find(option => option.value === selectedOption) || null}
                                label="Single select"
                            />
                        </div>
                    </div>

                    {/* <form onSubmit={handleSubmitDepart}>
                        <div className='manual-select-input-group'>
                            <p className='m-0 mb-2'>Add Department Manually</p>
                            <div>
                                <input type="text"
                                    value={manualDepartment}
                                    onChange={(e) => setManualDepartment(e.target.value)}
                                />
                                <button type='submit' onClick={handleSubmitDepart}> <GoPlus fontSize={20} /> </button>
                            </div>
                        </div>
                    </form> */}
                    <div>
                        <div className="selected_deprt_stock">
                            <p>Your Selected Departments</p>
                            <div className='d-flex text-capitalize align-items-center flex-wrap gap-1'>
                                {
                                    department?.map((data, index) => (
                                        <div key={index} className='selected-deprt-items'>
                                            <span>{data}</span>
                                            <IoCloseOutline onClick={() => handleRemoveDepart(index)} fontSize={20} />
                                        </div>

                                    ))
                                }
                            </div>
                        </div>
                        {error?.errorMessage && <p className='text-danger m-0'>{error?.errorMessage}</p>}
                    </div>
                    <div className='steper-buttonBox'>
                        <StepperBackButton onClick={handleBackStep}>Back</StepperBackButton>
                        <AuthFillButton onClick={handleNextStep}>Next</AuthFillButton>
                    </div>
                </div>
                <div className='steper-image-container h-md-100'>
                    <img src={departmentImage} alt="department image" />
                </div>
            </div>
        </>
    )
}
