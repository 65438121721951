import React from 'react'
import JobCards from './JobCards'
import './AppliedJob.css'
import SmallDescriptionCard from './SmallDescriptionCard'

export default function AppliedJob() {
    return (
        <>
            <div className='appliendJob_main'>
                <div className='applied-job-cards'>
                    <JobCards />
                </div>
                {/* <div className='applied-job-details-card'>
                    <SmallDescriptionCard />
                </div> */}
            </div>
        </>
    )
}
