import React, { useState } from 'react'
import './ArticlePost.css'
import { GiTargetDummy } from 'react-icons/gi'
import dummyImage from '../../../assets/dummyProfileImg.png'
import { FaRegShareSquare } from "react-icons/fa";
import { IoIosTrendingUp } from "react-icons/io";
import likeIcon from '../../../assets/likeIcon.png'
import { LuThumbsUp } from "react-icons/lu";
import { FaRegCommentDots } from "react-icons/fa6";
import { BiSolidLike } from "react-icons/bi";
import { BiLike } from "react-icons/bi";

export default function ArticlePost() {
    const [replyChat, setreplyChat] = useState('')
    const [addLike, setAddLike] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(replyChat)
        setreplyChat('')
    }

    const handleLikePost = () => {
        setAddLike(prev => !prev)
    }

    return (
        <>
            <div className="article_main d-flex">
                <img src={dummyImage} alt="profile-image" />
                <div className="articlepostBox w-100">
                    <div className="post-header d-flex justify-content-between">
                        <div className='d-flex gap-3'>
                            <div className="sender-name">
                                <h1>Cameron Williamson <span>+ Network</span></h1>
                                <p className='m-0'>Sales Executive</p>
                            </div>
                        </div>
                        <div className='share-target'>
                            <span>34</span><FaRegShareSquare className='icon-hover' fontSize={40} color='#777777' />
                        </div>
                    </div>
                    <div className='blog-text'>
                        <span>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con....</span>
                        <p>Read More</p>
                    </div>
                    <div className='post-trending'>
                        <IoIosTrendingUp className='icon-hover' fontSize={35} />
                        <span>Suggested post for you </span>
                    </div>
                    <div className='activityOnPost'>
                        <div className='d-flex align-items-center gap-1'>
                            <BiLike onClick={handleLikePost} className={`icon-hover ${addLike && 'd-none'}`} id='likePost' fontSize={35} />
                            <BiSolidLike onClick={handleLikePost} className={`icon-hover ${!addLike && 'd-none'}`} id='likePost' fontSize={35} />
                            <span className='likeNumber'>{addLike ? 2135 + 1 : 2135}</span>
                        </div>
                        <div className='d-flex align-items-center gap-1'>
                            <FaRegCommentDots className='icon-hover' fontSize={35} />
                            <span className=''>2135</span>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className='reply-comment'>
                            <img src={dummyImage} alt="dummy image" />
                            <input type="text"
                                onChange={(e) => setreplyChat(e.target.value)}
                                value={replyChat}
                                placeholder='Reply Here...'
                            />
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
