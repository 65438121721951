// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.successfulSubmit_main {
  margin-top: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.suceessBox_card {
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  padding: 4rem  2rem 3rem 2rem;
  font-family: "Inria Sans";
  border-radius: 5px;
  width: 600px;
  height: -moz-fit-content;
  height: fit-content;

  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.succ-icon-img>img{
  width: 200px;
  height: 200px;
}

.suceessBox_card > div > h1{
    font-size: 28px;
    color: #383838;
    font-weight: 400;
    margin: 0;
}
.suceessBox_card > div > p{
    font-size: 16px;
    color: #7E7E7E;
    font-weight: 400;
    margin: 0;
}
.suceessBox_card > div:last-child > p{
    font-size: 18px;
    color: #383838;
    font-weight: 400;
    margin-bottom:12px;
}

.steper-image-container {
  width: 40%;
  /* height: fit-content; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.doc-inner-conatiner > a{
  text-decoration: none;
  color: #000000;
}

.steper-image-container > img {
  width: 70%;
  height: 90%;
  margin: 0 auto;
}

@media screen and (max-width: 992px) {
  .steper-image-container {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/User/Pages/CreateProfile/SuccessfulSubmit.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,kDAAkD;EAClD,6BAA6B;EAC7B,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,wBAAmB;EAAnB,mBAAmB;;EAEnB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,SAAS;AACb;AACA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,SAAS;AACb;AACA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;EACE,UAAU;EACV,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,cAAc;AAChB;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".successfulSubmit_main {\n  margin-top: 6rem;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.suceessBox_card {\n  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */\n  padding: 4rem  2rem 3rem 2rem;\n  font-family: \"Inria Sans\";\n  border-radius: 5px;\n  width: 600px;\n  height: fit-content;\n\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.succ-icon-img>img{\n  width: 200px;\n  height: 200px;\n}\n\n.suceessBox_card > div > h1{\n    font-size: 28px;\n    color: #383838;\n    font-weight: 400;\n    margin: 0;\n}\n.suceessBox_card > div > p{\n    font-size: 16px;\n    color: #7E7E7E;\n    font-weight: 400;\n    margin: 0;\n}\n.suceessBox_card > div:last-child > p{\n    font-size: 18px;\n    color: #383838;\n    font-weight: 400;\n    margin-bottom:12px;\n}\n\n.steper-image-container {\n  width: 40%;\n  /* height: fit-content; */\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-grow: 1;\n}\n\n.doc-inner-conatiner > a{\n  text-decoration: none;\n  color: #000000;\n}\n\n.steper-image-container > img {\n  width: 70%;\n  height: 90%;\n  margin: 0 auto;\n}\n\n@media screen and (max-width: 992px) {\n  .steper-image-container {\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
