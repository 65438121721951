import React from 'react'

export default function ChatDisplay() {
    const dummyUser1 = ['Gyan', 'Anwar', 'Shakir', 'Shubham','Manish']
    
    return (
        <>
            <div className="chat_display">
            </div>
        </>
    )
}
