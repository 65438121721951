import React, { useContext, useEffect, useState } from 'react'
import { RiSearch2Fill } from "react-icons/ri";
import Form from 'react-bootstrap/Form';
import { GoArrowRight } from "react-icons/go";
import dummyComapyLogo from '../../../assets/dummyComapyLogo.png'
import { FaAngleLeft, FaAngleRight, FaLocationDot } from "react-icons/fa6";
import { EventButton, PaginationBtn } from '../../component/ReUseComps/Button';
import UserContextProvider from '../../component/ContextApi/UserContextProvider';
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import cashMultiple from '../../../assets/cash-multiple.png'
import bagSolid from '../../../assets/bag-solid.png'
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../../Utils/App_urls';
import { Oval } from 'react-loader-spinner';
import axios from 'axios';

import '../ApplyJob/JobCards/JobCards.css'
import './NotInterested.css'

export default function NotInterested() {
    const [allJobAfterFilter, setAllAfterFilter] = useState([])
    const navigate = useNavigate()
    const [allJobs, setAllJobs] = useState([])
    const [paginationBtnArr, setpaginationBtnArr] = useState([1, 2, 3, 4, 5]);
    const [clipArrLength, setClipArrLength] = useState({ min: 0, max: 10 })
    const [filterInputOpt, setFilterInputOpt] = useState({ jobTitle: '', jobLocation: '' });
    const [filterJobsArr, setFilterJobsArr] = useState([])


    const handleSubmitForm = (e) => {
        e.preventDefault();
    }

    const getAllJobs = () => {
        axios.get(`${URLS.getNotInterestedJob}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('userToken')}` }
        })
            .then((res) => {
                console.log('AllNotInte', res)
                // setAllJobs(res?.data?.result);
                // setAllAfterFilter(res?.data?.result);
                // setFilterJobsArr(res?.data?.result);
                setAllAfterFilter(res?.data?.result);
            })
            .catch((err) => { console.log('err', err) })
    }

    const redirectTojobDetails = (id) => {
        navigate(`/user/notIntested-jobs/job-details/${id}`, {
            state: { DATA: id }
        })
    }

    const handlePaginationSize = (pgNo) => {
        let max = pgNo * 10;
        let min = max - 10 >= 0 ? max - 10 : 0;
        setClipArrLength({ min: min, max: max })
    }

    const handleIncPagination = () => {
        setpaginationBtnArr(paginationBtnArr.map((item, _) => item + 1))
        setClipArrLength({ min: (paginationBtnArr[paginationBtnArr.length - 1] * 10) - 10, max: paginationBtnArr[paginationBtnArr.length - 1] * 10 })
    }
    const handleDecPagination = () => {
        if (paginationBtnArr[0] > 1) {
            setpaginationBtnArr(paginationBtnArr.map((item, _) => item - 1))
        }
        paginationBtnArr[0] >= 0 && setClipArrLength({ min: (paginationBtnArr[0] * 10) - 10, max: paginationBtnArr[0] * 10 })
    }

    const filterOnSearchBtn = () => {
        let filterJobs = filterJobsArr?.filter((data) => {
            const title = data?.title?.toLowerCase();
            return title?.includes(filterInputOpt?.jobTitle?.toLowerCase())

        })

        filterJobs = filterJobs?.filter((data) => {
            const location = data?.workPlace?.toLowerCase();
            return location?.includes(filterInputOpt?.jobLocation?.toLowerCase())

        })
        setAllAfterFilter(filterJobs)
    }

    const postedDate = (data) => {
        console.log('data', data)
        const firstDate = new Date(data);
        const currentDate = new Date();
        const timeDifference = currentDate - firstDate;
        const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
        return daysDifference > 0 ? `${daysDifference} Day${daysDifference > 1 ? 's' : ''} ago` : 'Posted Today';
    }

    useEffect(() => {
        getAllJobs();
    }, [])

    console.log('allJobAfterFilter', allJobAfterFilter)
    return (
        <>
            <div className="not_interseted_main">
                <h1 className='d-flex align-items-center gap-1' onClick={() => navigate(-1)}><MdOutlineKeyboardBackspace fontSize={26} />Archives Job Details</h1>
                <div className='archive_parents'>
                    {allJobAfterFilter?.map((job, index) => (
                        (index >= clipArrLength.min && index < clipArrLength.max) &&
                        <div className='job-search-body mb-3' key={index}>
                            <div className="job-cards d-flex flex-wrap">
                                <div className="job-right-container d-flex flex-column gap-2 flex-grow-1">
                                    <div className="job-card-header d-flex align-items-start justify-content-between flex-wrap gap-2">
                                        <div className="company-title d-flex align-items-start gap-3 mb-2">
                                            <div><img src={job?.companyProfileDetail?.companylogo || dummyComapyLogo} alt={job?.company} /></div>
                                            <div className='compay-name'>
                                                <h1 className='m-0'>{job?.userNotIntrestedJobs?.title}</h1>
                                                <p className='m-0'>{job?.companyProfileDetail?.companyName || 'ABC pvt. Ltd.'}</p>
                                            </div>
                                        </div>
                                        <p className='m-0 d-flex flex-column'>
                                            <span>{postedDate(job?.userNotIntrestedJobs?.createdAt?.split('T')[0])}</span>
                                        </p>
                                        {/* <span className="post-date">{job?.postDate}lskdjlsakdl</span> */}
                                    </div>
                                    <div className='job-conadtion-box'>
                                        <div><img src={bagSolid} alt="bag solid" /> <span>{job?.userNotIntrestedJobs?.experience}</span></div>
                                        {
                                            job?.userNotIntrestedJobs?.salaryType === "Fixed Salary" &&
                                            <div><img src={cashMultiple} alt="cash multiple" /> <span>{job?.userNotIntrestedJobs?.FixedSalary}k</span></div>
                                        }
                                        {
                                            job?.userNotIntrestedJobs?.salaryType === "Salary Range" &&
                                            <div><img src={cashMultiple} alt="cash multiple" /> <span>{job?.userNotIntrestedJobs?.minSalary}k - {job?.maxSalary}k</span></div>
                                        }
                                        {
                                            job?.userNotIntrestedJobs?.salaryType === "Not Disclose" &&
                                            <div><img src={cashMultiple} alt="cash multiple" /> <span>{job?.userNotIntrestedJobs?.salaryType}</span></div>
                                        }
                                        <div><FaLocationDot /> <span>{job?.userNotIntrestedJobs?.workPlace}</span></div>
                                    </div>

                                    <div className="job-preferences d-flex gap-1 gap-sm-3 flex-wrap">
                                        <span key={index}>{job?.userNotIntrestedJobs?.deparmentType}</span>
                                        <span key={index}>{job?.userNotIntrestedJobs?.workPlace}</span>
                                        <span key={index}>{job?.userNotIntrestedJobs?.shift}</span>
                                        {/* {job?.preferences?.map((preference, index) => ())} */}
                                    </div>
                                    {/* <div dangerouslySetInnerHTML={{ __html: convertHtmlToText(job?.userNotIntrestedJobs?.jobDescription)?.slice(0, 300) + '...' }} /> */}
                                    <div>
                                        {job?.userNotIntrestedJobs?.jobSummary?.slice(0, 400)}
                                        {job?.userNotIntrestedJobs?.jobSummary?.length > 400 && '...'}
                                    </div>
                                    <div className="company-req-skills d-flex flex-wrap gap-2 gap-sm-3">
                                        {job?.userNotIntrestedJobs?.skills?.map((skill, index) => (
                                            <span key={index}>{skill}</span>
                                        ))}
                                    </div>
                                </div>
                                <div className="cards-btn-box flex-row flex-sm-column flex-wrap">
                                    <button className='job-search-btn' onClick={() => redirectTojobDetails(job?.userNotIntrestedJobs?._id)}>View <GoArrowRight fontSize={20} /></button>
                                    {/* <button className='job-search-outline-btn'>Not Interested</button> */}
                                </div>
                            </div>
                        </div>
                    ))}
                    {allJobAfterFilter?.length > 0 && <div className="footer-pagination d-flex align-items-center justify-content-center flex-wrap gap-2 pb-3">
                        <p className='page-table-typo_archive'>Page No. {clipArrLength?.max / 10} / {Math.ceil(allJobAfterFilter?.length / 10)}</p>
                        <div className='d-flex align-items-center gap-2'>
                            <PaginationBtn className='pagination-btn'
                                onClick={handleDecPagination}
                                disabled={paginationBtnArr[0] === 1}>
                                <FaAngleLeft fontSize={20} />
                                Previous
                            </PaginationBtn>
                            {
                                paginationBtnArr.map((data, idx) => (
                                    <PaginationBtn
                                        className={`pagination-btn ${clipArrLength?.max / 10 === data && 'activePageBtn'}`}
                                        onClick={() => handlePaginationSize(data)}
                                        disabled={Math.ceil(data > Math.ceil(allJobAfterFilter?.length / 10))}
                                    >
                                        {data}
                                    </PaginationBtn>
                                ))
                            }
                            <PaginationBtn className={`pagination-btn ${allJobAfterFilter?.length <= 60 && 'd-none'}`} disabled>...</PaginationBtn>
                            <PaginationBtn
                                className='pagination-btn'
                                onClick={handleIncPagination}
                                disabled={paginationBtnArr[paginationBtnArr?.length - 1] > Math.ceil(allJobAfterFilter.length / 10)}
                            >
                                Next
                                <FaAngleRight fontSize={20} />
                            </PaginationBtn>
                        </div>

                    </div>}
                    {!allJobAfterFilter?.length > 0 && <h1 className='warning-none d-flex flex-column align-items-center'>
                        No Job Found
                        <Oval width="50" height="50px" color='#3284FF' />
                    </h1>}
                </div>
            </div>
        </>
    )
}
